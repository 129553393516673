import React, { useEffect, useContext, useState} from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { formatDate } from '../../utils.js'
import { useQuery } from 'react-query'
import { CurrentCompanyContext } from '../../App.js'

import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js'

import Spinner from '../shared/Spinner.js'
import Header, { HeaderActions } from '../shared/Header.js'

import { Container } from '../layout/AppLayout.js';
import MemberDetailSubmenu from './MemberDetailSubmenu.js'
import Row from 'react-bootstrap/Row';
import { defaultQueryFn } from '../../App.js'
import { toQueryString } from '../../utils.js'
import { useInfiniteQuery } from 'react-query'

function DactilarDetailCard({isLoading }){
    let content = null
  
    if( isLoading ){
      content = (
        <div className="text-center py-4 my-4">
          <Spinner />
        </div>
      )
    }else {
      content = (
        <>
          <div className='text-center blockquote'>
            <p>Próximamente</p>
          </div>
        </>
      )
    }
  
    return (
      <div className="card shadow-sm mb-3">
        <div className="card-header">Biometría dactilar</div>
        <div className="card-body">
          {content}
        </div>
      </div>
    )
  }
function OcularDetailCard({isLoading }){
    let content = null
  
    if( isLoading ){
      content = (
        <div className="text-center py-4 my-4">
          <Spinner />
        </div>
      )
    }else {
      content = (
        <>
          <div className='text-center blockquote'>
            <p>Próximamente</p>
          </div>
        </>
      )
    }
  
    return (
      <div className="card shadow-sm mb-3">
        <div className="card-header">Biometría ocular</div>
        <div className="card-body">
          {content}
        </div>
      </div>
    )
  }


  function EventItem({ item }) {
    const CheckFeatures = useFeatureChecker()
    const [onError, setOnError] = useState(false)
    const [isReady, setIsReady] = useState(false)
  
    if (onError) { return null }
  
    return (
      <div key={item.id} className="col mb-4 event-item">
        <div className={`card shadow-sm ${!isReady ? 'ph-item' : ''}`}>
          <div className={!isReady ? 'ph-picture' : ''}>
            <Link to={`/media/${item.id}`}>
              <img
                src={item.media_thumb_url}
                alt={item.created_at}
                className={!isReady ? 'd-none' : ''}
                onLoad={() => setIsReady(true)}
                width="100%"
                //height={(height * 0.35) + 'rem'}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  setOnError(true)
                  if (item.mime_type !== 'video/mp4') {
                    currentTarget.src = item.media_url
                  }
                }}
              />
            </Link>
          </div>
          <div className="px-3 py-2 pb-3 event-item-detail">
            <div className="event-date d-flex justify-content-between">
              <div>
                {formatDate(item.ts)}
              </div>
            </div>
            <div className="event-door">
              <CheckFeatures feature="DETAIL_SENSOR">
                <AllowedFeature>
                  <Link to={`/sensors/${item.sensor_id}`}>{item.sensor_name}</Link>
                </AllowedFeature>
                <DeniedFeature>
                  <span>{item.sensor_name}</span>
                </DeniedFeature>
              </CheckFeatures>  
              
            </div>
          </div>
        </div>
      </div>
    )
  }

function ImagesDetailCard({isLoading,query }){
  let pages = query.data && query.data.pages || []
  let currentEvent = null

  if (pages && pages.length && pages.length && pages[0].pagination.totalItems === 0) {
    return (
      <div className="card mb-4">
        <div className="card-header">Imágenes</div>
        <div className="text-center text-muted py-4 my-4">
          Sin resultados para los filtros especificados
        </div>
      </div>
    )
  }
  return (
    <div className="card mb-4">
      <div className="card-header">Imágenes</div>
      <div className="card-body">
        <div className="row row-cols-1 row-cols-sm-3 row-cols-md-4 row-cols-lg-6">
      {
        pages.map((group, i) => (
          <React.Fragment key={i}>
            {
              (group.result || []).map(item => (
                  <EventItem key={item.id} item={item} />
                  ))
                }
          </React.Fragment>
        ))
      }
      </div>
    </div>
        {
          query.hasNextPage ?
          <div className='card-footer'>
            <Row className="justify-content-center">
              <div className="col-auto col-3 ">
                <button
                  className="btn btn-sm btn-block btn-primary shadow-sm"
                  disabled={query.isFetchingNextPage || !query.hasNextPage}
                  onClick={() => query.fetchNextPage()}
                >
                  {
                    query.isFetchingNextPage ?

                      <Spinner color="#FFFFFF"size={5}/>
                    :
                      'Cargar más'
                  }
                </button>
              </div>
            </Row>
      </div>
          : null
        }
  </div>
  )
}

  
  export default function MembersIdentificationPage() {
  
    const params = useParams()
    const CheckFeatures = useFeatureChecker()
    const currentCompany = useContext(CurrentCompanyContext)
    const [previewItem, setPreviewItem] = useState(null)

    const fetchMedia = ({ pageParam }) => defaultQueryFn({
      queryKey: [`media/new?${toQueryString({
        page: pageParam,
        limit: 24,
        UserIdMedia:params.UserId,
        mimeType:0

      })}`]
    })
    
  
    const query = useInfiniteQuery('mediaListUser', fetchMedia, {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        const pagination = lastPage.pagination
  
        if( pagination.totalPages > pagination.page ){
          return pagination.page + 1
        }
      }
    })



    const currentEvents = !query.isLoading && query.data && query.data.result  || []
    useEffect(() => {
      if (currentEvents.length && !previewItem) {

        const videos = currentEvents.filter(x => x.mime_type === 'video/mp4')
  
        if (videos.length) {
          const first = videos[0]
          setPreviewItem({
            id: first.id,
            url: first.media_url,
            thumb_url: first.media_thumb_url,
            type: 'video'
          })
        }
      }
    }, [ currentEvents])
  
    return (
      <Container>
        <div className="row">
          <Header
            title="Detalle de usuario"
            items={[
              { label: "Listado de usuarios", to: "/members" },
              { label: "Detalle de usuario", to: `/members/${params.UserId}` },
              { label: "Identidades de usuario", to: `/members/${params.UserId}/identities` },
              { label: "Identificaciones de usuario"}
            ]}
          >

          </Header>
        </div>
        <div className="row">
          <MemberDetailSubmenu UserId={params.UserId}/>
        </div>
        <CheckFeatures feature="LIST_IDENTIFICATION">
          <AllowedFeature>
            <CheckFeatures  every={["DISABLE","IDENTIFICATION_DACTILAR"]}>
              <div className="row">
                <div className="col-12 col-md-12">
                  <DactilarDetailCard  isLoading={query.isLoading} />
                </div>
              </div>
            </CheckFeatures>
            <CheckFeatures every={["DISABLE","IDENTIFICATION_OCULAR"]}>
              <div className="row">
                <div className="col-12 col-md-12">
                  <OcularDetailCard  isLoading={query.isLoading} />
                </div>
              </div>
            </CheckFeatures>
            <CheckFeatures feature="IDENTIFICATION_IMAGES">
              <div className="row">
                <div className="col-12 col-md-12">
                  <ImagesDetailCard isLoading={query.isLoading}  query= {query}/>
                </div>
              </div>
            </CheckFeatures>
          </AllowedFeature>
          <DeniedFeature>
            <Navigate to={'/error?error=403'}/>
          </DeniedFeature>
        </CheckFeatures>
      </Container>
    )
  }  