import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import App from './App';

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import './assets/css/style.css'
import * as serviceWorker from './serviceWorker';

let IS_RENDERED = false;

function render(){
  if(IS_RENDERED){
    return;
  }

  const root = ReactDOM.createRoot(document.getElementById('root'))

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  )

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  //serviceWorker.register();
}

//localStorage.setItem('getSessionStorage', 'foobar');
//localStorage.removeItem('getSessionStorage', 'foobar');
//serviceWorker.register();
setTimeout(render, 50)

