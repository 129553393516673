import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";

import { StateContext, CurrentUserContext } from "../../App.js";

import { Container } from "../layout/AppLayout.js";
import Row from "react-bootstrap/Row";

import { useMutationClient } from "../shared/hooks.js";
import { useNotifications } from "../shared/Notifications.js";
import Header from "../shared/Header.js";
import Spinner from "../shared/Spinner.js";

import { isAllowed } from "../shared/FeatureChecker.js";
import FacilityDetailSubmenu from "../facilities/FacilityDetailSubmenu.js";

function SVAForm({ facility, company, save, isLoading }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    save({ name, description, facility_id: facility.id });
  }

  return (
    <div className="card shadow-sm">
      <div className="card-header">Detalles de SVA</div>

      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="font-weight-bold">Nombre</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus={true}
              disabled={isLoading}
            />
          </div>

          <div className="form-group">
            <label className="font-weight-bold">Observaciones</label>
            <textarea
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows="4"
              disabled={isLoading}
            ></textarea>
          </div>

          <div className="form-group">
            <label className="font-weight-bold">Industria</label>
            <p>{company.industry_name}</p>
          </div>

          <div className="form-group">
            <label className="font-weight-bold">Tipo de Instalación</label>
            <p>{facility.facility_type_name}</p>
          </div>

          <div className="form-group">
            <label className="font-weight-bold">Dirección Instalación</label>
            <p>{facility.address}</p>
          </div>

          <div className="form-group mb-0 mt-4">
            <input
              className="btn btn-primary"
              type="submit"
              value="Crear SVA"
              disabled={isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default function NewSvaPage() {
  const params = useParams();
  const mutationClient = useMutationClient();
  const notifications = useNotifications();
  const navigate = useNavigate();
  const { currentCompany } = useContext(StateContext);
  const currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "CREATE_SVA"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const query = useQuery(`facilities/${params.FacilityId}`);
  const currentFacility = query.data && query.data.result;

  const createSVA = useMutation((data) => mutationClient.post(`sva`, data), {
    onSuccess: (data, variables, context) => {
      console.log(data);
      if (data.status === 200) {
        //notifications.success('SVA creado correctamente')
        navigate(
          `/facilities/${currentFacility.id}/sva/${data.data.result.id}/wizard`
        );
      }
    },
    onError: () => {
      notifications.danger("Ha ocurrido un error al crear el SVA");
    },
  });

  return (
    <Container>
      <Row>
        <Header
          title="Nuevo SVA"
          items={[
            { label: `Listado de instalaciones`, to: "/facilities" },
            {
              label: `Detalle de instalación`,
              to: `/facilities/${params.FacilityId}`,
            },
            {
              label: "Listado de sva's",
              to: `/facilities/${params.FacilityId}/sva`,
            },
            { label: "Nuevo SVA" },
          ]}
        />
      </Row>

      <Row>
        <FacilityDetailSubmenu FacilityId={params.FacilityId} />
      </Row>

      <Row>
        {query.isLoading ? (
          <div className="col-12 text-center py-4 my-4">
            <Spinner />
          </div>
        ) : (
          <div className="col-6 mb-4">
            <SVAForm
              facility={currentFacility}
              company={currentCompany}
              isLoading={createSVA.isLoading}
              save={(data) => createSVA.mutate(data)}
            />
          </div>
        )}
      </Row>
    </Container>
  );
}
