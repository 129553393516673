import { useQuery } from 'react-query'
import Spinner from '../../../shared/Spinner.js';
import { Link } from 'react-router-dom';
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../../../shared/FeatureChecker.js';

export default function DetailsCard({ ZoneId }) {
    const query = useQuery(`departments/${ZoneId}`)
    const CheckFeatures = useFeatureChecker()
  
    return (
      <div className="card shadow-sm mb-3">
        <div className="card-header">Detalle de zona</div>
  
        <div className="card-body">
          {
            query.isLoading ?
              <Spinner />
              :
              <>
                <div className="form-group">
                  <label className="form-label font-weight-bold">Nombre</label>
                  <p>{query.data && query.data.result.name}</p>
                </div>
  
                <div className="form-group">
                  <label className="form-label font-weight-bold">Instalación</label>
                  <p>
                    {
                      query.data && query.data.result.facility_name ?

                        <CheckFeatures feature ='DETAIL_FACILITY'>
                          <AllowedFeature>
                            <Link to={`/facilities/${query.data.result.facility_id}`} >{query.data.result.facility_name}</Link>
                          </AllowedFeature>
                          <DeniedFeature>
                            {query.data.result.facility_name}
                          </DeniedFeature>
                        </CheckFeatures>
                        : '--'
                    }
                  </p>
                </div>
  
                <div className="form-group">
                  <label className="form-label font-weight-bold">Dirección</label>
                  <p>{query.data && query.data.result.address || query.data.result.facility_address}</p>
                </div>
              </>
          }
        </div>
      </div>
    )
  }