import React, { useContext, useEffect } from "react";

import { useQuery } from "react-query";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { CurrentCompanyContext } from "../../App.js";
import { formatDate, identityTypes } from "../../utils.js";

import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js'
import { useNotifications } from '../shared/Notifications.js';

import { Container } from "../layout/AppLayout.js";
import { DestroyButton } from "../shared/Buttons.js";
import Header, { HeaderActions } from "../shared/Header.js";
import Spinner from "../shared/Spinner.js";
import TableList from "../shared/TableList.js";

import MemberDetailSubmenu from "./MemberDetailSubmenu.js";

function IdentitiesTable({ identities, UserId, refetch }) {
  const CheckFeatures = useFeatureChecker();
  const currentCompany = useContext(CurrentCompanyContext);

  if (!identities.length) {
    return (
      <div className="text-center text-muted py-4 my-4">
        Sin identidades asociadas
      </div>
    );
  }

  function parseType(typeString) {
    const type = identityTypes.find((x) => x.value === typeString);

    if (type) {
      return type.label;
    } else {
      return "-";
    }
  }

  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="Nombre" />
        <TableList.HeaderItem value="Tipo" />
        <TableList.HeaderItem value="Fecha de creación" />
        <TableList.HeaderItem value="" width={150} />
      </TableList.Header>

      <TableList.Body>
        {identities.map((item) => (
          <TableList.Row key={item.id}>
            <TableList.Column value={item.identity} />
            <TableList.Column value={parseType(item.type)} />
            <TableList.Column value={formatDate(item.created_at)} />

            <TableList.Column>
              <CheckFeatures every={["UPDATE_USER", "DELETE_IDENTITIES"]}>
                <DestroyButton
                  label="Eliminar"
                  confirmMessage={`¿Está seguro que desea eliminar la identidad "${item.identity}"?`}
                  successMessage="Identidad eliminada correctamente"
                  deletePath={`companies/${currentCompany.id}/users/${UserId}/identities/${item.id}`}
                  invalidate={`companies/${currentCompany.id}/users/${UserId}/identities`}
                  redirect={`/members/${UserId}/identities`}
                />
              </CheckFeatures>
            </TableList.Column>
          </TableList.Row>
        ))}
      </TableList.Body>
    </TableList>
  );
}

export default function MemberIdentitiesPage() {
  const params = useParams();
  const CheckFeatures = useFeatureChecker();
  const currentCompany = useContext(CurrentCompanyContext);
  const navigate = useNavigate()
  const notifications = useNotifications()

  const query = useQuery(
    `companies/${currentCompany.id}/users/${params.UserId}/identities`
  );

  useEffect(() => {
    if (!query.isLoading && query.error && query.error.response && query.error.response.status == 403) {
      notifications.warning('Acceso no autorizado')
      navigate(`/members`)
    }
  }, [query.isLoading])

  return (
    <Container>
      <div className="row">
        <Header
          title="Detalle de usuario"
          items={[
            { label: "Listado de usuarios", to: "/members" },
            { label: "Detalle de usuario", to: `/members/${params.UserId}` },
            { label: "Identidades de usuario" },
          ]}
        >
          <HeaderActions>
            <CheckFeatures every={["UPDATE_USER", "CREATE_IDENTITIES", "LIST_TYPE_IDENTITIES"]}>
              <Link
                to={"/members/" + params.UserId + "/identities/new"}
                className="btn btn-sm btn-primary"
              >
                Agregar identidad
              </Link>
            </CheckFeatures>
          </HeaderActions>
        </Header>
      </div>

      <div className="row">
        <MemberDetailSubmenu UserId={params.UserId} />
      </div>
      
      <CheckFeatures feature="LIST_IDENTITIES">
        <AllowedFeature>
          <div className="row">
            {query.isLoading ? (
              <div className="col-12 text-center my-4 py-4">
                <Spinner />
              </div>
            ) : (
              <div className="col-12 mb-4">
                <IdentitiesTable
                  identities={(query.data && query.data.result) || []}
                  UserId={params.UserId}
                  refetch={query.refetch}
                />
              </div>
            )}
          </div>
        </AllowedFeature>
        <DeniedFeature>
          <Navigate to={'/error?error=403'}/>
        </DeniedFeature>
      </CheckFeatures>

    </Container>
  );
}
