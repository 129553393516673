import React, { useState, useContext, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from '../layout/AppLayout.js';

import Header, { HeaderActions } from "../shared/Header.js";
import { useNotifications } from '../shared/Notifications.js';
import Spinner from '../shared/Spinner.js';
import { useMutationClient } from '../shared/hooks.js';

import useFeatureChecker, { AllowedFeature, DeniedFeature, isAllowed } from '../shared/FeatureChecker.js';
import FileUploaderModal from '../shared/FileUploaderModal.js';
import SensorForm from './SensorForm.js';
import { CurrentUserContext } from '../../App.js';

function DoorsListItem({ item, selected, onCheckboxChange }) {
  const currentUser = useContext(CurrentUserContext)

  return (
    <div className="border-top-0 border-left-0 border-right-0 list-group-item d-flex justify-content-between align-items-center">
      <label className="m-0" style={{ cursor: 'pointer' }}>

            <input
              type="checkbox"
              className="mr-2"
              style={{ cursor: 'pointer' }}
              checked={selected}
              onChange={() => onCheckboxChange(item.id)}
              disabled={currentUser &&!isAllowed({user: currentUser, every:["UPDATE_SENSOR_DOOR","LIST_DOORS"]})}
            />

        <span>{item.name}</span>
      </label>

      <span>{item.zone_name}</span>
    </div>
  )
}

function DoorsList({ currentSensor, onSelectedItemsChange }) {
  const CheckFeatures = useFeatureChecker()
  const queryClient = useQueryClient()
  const mutationClient = useMutationClient()
  const notifications = useNotifications()

  const query = useQuery(`doors?limit=1000`)
  const doors = query.data && query.data.result || []

  const [selectedItems, setSelectedItems] = useState(currentSensor.door_ids);

  const handleCheckboxChange = (item) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(item)) {
        return prevSelectedItems.filter((selectedItem) => selectedItem !== item);
      } else {
        return [...prevSelectedItems, item];
      }
    });
  };
 
  useEffect(() => {
    onSelectedItemsChange(selectedItems);
  }, [selectedItems, onSelectedItemsChange]);

  return (
    <div className="list-group border-0 rounded-0 shadow-sm" style={{ maxHeight: '500px', overflowY: 'auto' }}>
      {
        query.isLoading ?
          <div className="text-center my-4 py-4">
            <Spinner />
          </div>
          :
          doors.length ?
            doors.map(item =>
              <DoorsListItem
                key={item.id}
                item={item}
                selected={selectedItems.includes(item.id)}
                onCheckboxChange={handleCheckboxChange}
              />
            )
            :
            <div className="text-muted text-center my-4 py-4">No se encontraron puertas</div>
      }
    </div>
  )
}

export default function EditSensorPage() {
  const CheckFeatures = useFeatureChecker()
  const params = useParams()
  const queryClient = useQueryClient()
  const mutationClient = useMutationClient()
  const navigate = useNavigate()
  const notifications = useNotifications()
  const currentUser = useContext(CurrentUserContext)
  const location = useLocation()
  const [selectedItemsForMutation, setSelectedItemsForMutation] = useState([]);
  
  const handleSelectedItemsChange = (selectedItems) => {
    setSelectedItemsForMutation(selectedItems);
  };

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "UPDATE_SENSORS"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const { isLoading, data } = useQuery(`sensors?SensorId=${params.SensorId}`, {
    //initialData: location.state && location.state.sensor && { result: [location.state.sensor] }
  })
  const [showMedia, setShowMedia] = useState(false)

  const currentSensor = data && data.result && data.result[0]

  const mutation = useMutation(data => {
    return mutationClient.put(`sensors/${data.id}`, data)
  }, {
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData('sensors?', previousData => {
        if (!previousData) {
          previousData = {
            pagination: {},
            result: []
          }
        }

        const currentData = {
          ...previousData,
          result: previousData.result.map(item => {
            if (item.id === variables.id) {
              return { ...item, ...data.data.result }
            } else {
              return { ...item }
            }
          })
        }

        return currentData
      })

      queryClient.setQueryData([`sensors?SensorId=${variables.id}`], (previousData) => {
        previousData = previousData || { pagination: {}, result: [] }

        return {
          ...previousData,
          result: [
            {
              ...previousData.result[0],
              ...data.data.result
            }
          ]
        }
      })

      notifications.success('Sensor actualizado correctamente')
      navigate(`/sensors/${variables.id}`)
    }
  })

  const MediaMutation = useMutation(
    data => mutationClient.post(`media/device/${params.SensorId}`, data),
    {
      onSuccess: (data, variables, context) => {
        navigate(`/sensors/${params.SensorId}`)
        notifications.success('Sensor actualizado correctamente')
      },
      onError: () => {
        notifications.danger('Ha ocurrido un error al actualizar el sensor')
      }
    }
  )
  const ChangeDoors =  useMutation(
    data => mutationClient.post(`doors/sensors/${params.SensorId}`, data),
    {
      onSuccess: (data, variables, context) => {
        notifications.success('Puertas actualizadas correctamente')
      },
      onError: () => {
        notifications.danger('Ha ocurrido un error al actualizar el sensor')
      }
    }
  )
  

  if (isLoading) {
    return <Spinner full={true} />
  }

  return (
    <Container>
      <div className="row">
        <Header title="Editar sensor" items={[
          { label: "Listado de sensores", to: "/sensors" },
          { label: "Detalle de sensor", to: `/sensors/${params.SensorId}` },
          { label: "Editar sensor" },
        ]}>

          <HeaderActions>
            <CheckFeatures feature="SENSOR_UPLOAD_MEDIA">
              <button
                className="btn btn-primary"
                onClick={() => setShowMedia(true)}
              >Subir media </button>
            </CheckFeatures>
            {
              showMedia ?
                <FileUploaderModal
                  title="Subir media"
                  onClose={() => setShowMedia(false)}
                  onSuccess={files => {
                    MediaMutation.mutate({
                      file_key: files.map(x => ({
                        id: x.id,
                        name: x.name,
                        size: x.size,
                        type: x.type,
                        file_key: x.file_key
                      }))
                    })
                  }}
                />
                : null
            }
          </HeaderActions>

        </Header>
      </div>

      <Row className="mb-4">
        <Col className="col-12 col-md-6">
          <Card className="shadow-sm">
            <Card.Header className="">Datos del sensor</Card.Header>
            <Card.Body>
              <CheckFeatures feature="UPDATE_SENSORS">
                  <SensorForm
                  sensor={currentSensor}
                  save={data => mutation.mutate(data)}
                  isLoading={mutation.isLoading}
                  />
              </CheckFeatures>
            </Card.Body>
          </Card>
        </Col>

          <Col className={`col-12 col-md-6 ${currentUser &&!isAllowed({user: currentUser,feature: "LIST_DOORS" })?'d-none' : ''}`}>
            <div className="card">
              <div className="card-header">
                Puertas asociadas
              </div>
              <DoorsList currentSensor={currentSensor} onSelectedItemsChange={handleSelectedItemsChange}/>
              <CheckFeatures every={["UPDATE_SENSOR_DOOR","LIST_DOORS"]}>
                <div className="form-group pt-3 ml-3" >
                    <button
                      className={`btn btn-primary`}
                      size="lg"
                      onClick={() => ChangeDoors.mutate({door_id:selectedItemsForMutation})}
                      disabled={ChangeDoors.isLoading}
                    >
                      <span>Guardar</span>
                      <div className='spinner-border' role='status'></div>
                    </button>
                </div>
              </CheckFeatures>
            </div>
          </Col>
      </Row>

    </Container>
  )
}
