import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Container } from '../../layout/AppLayout.js'
import Header, { HeaderActions } from "../../shared/Header.js"
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../../shared/FeatureChecker.js'
import Spinner from '../../shared/Spinner.js';
import ZoneDetailSubmenu from '../ZoneDetailSubmenu.js'
import  SensorsList  from './device/SensorsListPage'

export default function ZoneDevicesPage() {
  const params = useParams()
  const CheckFeatures = useFeatureChecker()

  return (
    <Container>
      <div className="row">
        <Header title="Detalle de zona - Dispositivos" items={[
          { label: `Listado de zonas`, to: "/zones" },
          { label: `Detalle de zona` },
        ]} />
      </div>

      <div className="row">
        <ZoneDetailSubmenu ZoneId={params.ZoneId}/>
      </div>

      <div className="row mb-2 pb-4">
        <div className="col-12 mb-2">
            <SensorsList/>
        </div>
      </div>
    </Container>
  )
}
