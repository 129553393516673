import React, { useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import {  useMutation, useQueryClient } from 'react-query'
import { CurrentCompanyContext } from '../../App.js'
import { CurrentUserContext } from '../../App.js';
import { isAllowed } from '../shared/FeatureChecker.js';
import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row';

import { useNotifications } from '../shared/Notifications.js'
import { useMutationClient } from '../shared/hooks.js'
import Header from "../shared/Header.js"
import FailureForm from './FailureForm.js';

export default function FailureNewPage() {
  const queryClient = useQueryClient()
  const currentCompany = useContext(CurrentCompanyContext)
  const mutationClient = useMutationClient()
  const navigate = useNavigate()
  const notifications = useNotifications()
  const params = useParams()
  const currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "CREATE_REPORTS"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])


  const mutation = useMutation(data => mutationClient.post(`devicereport/${params.SensorId}`, { ...data, company_id: currentCompany && currentCompany.id }), {
    onSuccess: (data, variables, context) => {
      if( data.status === 200 ){
        notifications.success('Reporte de fallas creado correctamente')
        navigate(`/sensors/${params.SensorId}/failure`)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al crear el reporte')
    }
  })

  return (
    <Container>
      <Row>
        <Header title="Nuevo informe de fallas" items={[
          { label: "Listado de dispositivos", to: "/sensors" },
            { label: "Detalle de dispostivo", to: `/sensors/${params.SensorId}` },
            { label: "Listado de fallas", to: `/sensors/${params.SensorId}/failure`},
            { label: "Nuevo reporte de fallas"}
        ]} />
      </Row>

      <Row className="mb-4">
        <div className="col-6 mb-4">
          <div className="card mb-3 shadow-sm">
            <div className="card-header">Crear informe de fallas</div>
            <div className="card-body">
              <FailureForm
                sensor={{}}
                save={data => mutation.mutate(data)}
                isLoading={mutation.isLoading}
              />
            </div>
          </div>
        </div>
      </Row>
    </Container>
  )
}