import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import useSound from 'use-sound';
import { Container } from '../../layout/AppLayout.js';
import TableList from '../../shared/TableList.js';
import mySound from './audio.mp3';

import {
    faVolumeMute,
    faVolumeUp
} from '@fortawesome/free-solid-svg-icons';
export default function MapCardAlert({ items }) {
    const [soundEnabled, setSoundEnabled] = useState(false)
    const [sound, setSound] = useState(false)


    const [play, { stop }] = useSound(mySound, {
        autoplay: false,
        loop: true,
    });



    useEffect(() => {
        if (soundEnabled == false || items.length == 0) {
            stop()
            setSound(false)
        } else {
            if (soundEnabled && sound == false) {
                if (items.length > 0) {
                    play()
                    setSound(true)
                }
            }
        }
    }, [soundEnabled, items])

    return (
        <div>
            <Container >
                <Col className="mb-2 col-12 text-center ">
                    <button
                        className="btn btn-primary"
                        onClick={() => setSoundEnabled(soundEnabled ? false : true)}
                    >
                        {soundEnabled ? "Desactivar notificación" : "Activar notificación"}
                        <FontAwesomeIcon icon={soundEnabled ? faVolumeMute : faVolumeUp} className="ml-2" />
                    </button>
                </Col>
            </Container>

            <Container >
                {
                    items.length == 0 ? (
                        <div className="text-center text-muted py-4 my-4">No hay notificaciones</div>
                    ) : (
                        <TableList>
                            <TableList.Header>
                                {/* <TableList.HeaderItem value="" width={200} /> */}
                            </TableList.Header>
                            <TableList.Body>

                                {
                                    items.map((item) => {
                                        return (
                                            <TableList.Row key={item.id}>
                                                <TableList.Column>
                                                    <Link to={`/doors/${item.id}`}>{item.name}</Link>
                                                </TableList.Column>
                                            </TableList.Row>
                                        )
                                    })
                                }
                            </TableList.Body>
                        </TableList>
                    )
                }
            </Container>
        </div>
    )
}