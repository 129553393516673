import { useLocation } from "react-router-dom";
import { parseISO } from "date-fns";
import { utcToZonedTime, format, toDate } from "date-fns-tz";
import { es } from "date-fns/locale";

export const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function getToken(state) {
  if (state) {
    return state.auth ? state.auth.token : null;
  } else {
    return null;
  }
}

export function getAuthHeaders(state) {
  const token = getToken(state);

  if (token) {
    return {
      Authorization: "Bearer " + token,
    };
  } else {
    return {};
  }
}

export function createAction(type, keys = []) {
  const fn = function () {
    const args = keys.reduce((acc, item, idx) => {
      acc[item] = arguments[idx];
      return acc;
    }, {});

    return { type, ...args };
  };

  return [type, fn];
}

export function useFromQueryString() {
  const query = new URLSearchParams(useLocation().search);
  const items = {};

  for (let entry of query.entries()) {
    items[entry[0]] = entry[1];
  }

  return items;
}

export function toQueryString(query) {
  return Object.keys(query)
    .reduce((acc, key) => {
      if (query[key]) {
        acc.push(`${key}=${query[key]}`);
      }

      return acc;
    }, [])
    .join("&");
}

export function toUTCString(date) {
  return date.toISOString().replace("T", " ").replace("Z", "");
}

export function formatDate(date, formatString = "dd/MM/yyyy HH:mm") {
  if (date === null || date === undefined) return;

  if (typeof date === "number") {
    date = toDate(date);
    return format(utcToZonedTime(date, TIME_ZONE), formatString, {
      locale: es,
    });
  } else {
    if (date.toUpperCase().endsWith("Z")) {
      return format(utcToZonedTime(date, TIME_ZONE), formatString, {
        locale: es,
      });
    } else {
      return format(utcToZonedTime(date + "Z", TIME_ZONE), formatString, {
        locale: es,
      });
    }
  }
}

export function parseDate(dateString) {
  if (dateString === null || dateString === undefined || dateString === "")
    return;

  if (dateString.toUpperCase().endsWith("Z")) {
    return parseISO(dateString);
  } else {
    return parseISO(dateString + "Z");
  }
}

export function convertToByteArray(input) {
  var sliceSize = 512;
  var bytes = [];

  for (var offset = 0; offset < input.length; offset += sliceSize) {
    var slice = input.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);

    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    bytes.push(byteArray);
  }

  return bytes;
}

export const identityTypes = [
  { value: "email", label: "Correo electrónico" },
  { value: "license_plate", label: "Patente" },
  { value: "mobile_phone", label: "Número de celular" },
  { value: "rut", label: "RUT" },
  { value: "rfid_card_26", label: "Tarjeta RFID 26 bits" },
  { value: "username", label: "Nombre de usuario" },
  { value: "rfid_card", label: "Tarjeta RFID" },
  { value: "qr_code", label: "Código QR" },
];

//export const deviceTypes = [
//{key: 'weather', name: 'Clima'},
//{key: 'switch' , name: 'Switch'},
//{key: 'camera', name: 'Cámara'},
//{key: 'floating', name: 'Floating'},
//{key: 'noise', name: 'Ruido'},
//{key: 'smoke', name: 'Humo'},
//{key: 'solenoid', name: 'Solenoide'},
//{key: 'type_S', name: 'Type_S'},
//{key: 'motor', name: 'Motor'}
//]

//export const SENSORS_VARIABLES = {
//'smoke': ['smoke'],
//'noise': ['noise'],
//'weather': ['temperature', 'humidity', 'pressure'],
//'switch': ['state'],
//'floating': ['floating'],
//}

//export const VARIABLE_NAMES = {
//'temperature': 'Temperatura ambiental',
//'pressure': 'Presión barométrica',
//'humidity': 'Humedad relativa',
//'smoke': 'Presencia de humo',
//'noise': 'Nivel de ruido',
//'state': 'Estado',
//'floating': 'Presencia de agua',
//}

//export const VARIABLE_VALUES_MAP = {
//'smoke': {
//'0': 'Hay presencia de humo',
//'1': 'No hay presencia de humo',
//},
//'noise': {
//'0': 'Hay presencia de ruido',
//'1': 'No hay presencia de ruido',
//},
//'floating': {
//'0': 'Hay presencia de agua',
//'1': 'No hay presencia de agua',
//},
//}

//export const VARIABLE_UNITS = {
//'temperature': 'ºC',
//'humidity': '%',
//'pressure': 'hPa',
//}

//export const CHART_COLORS = {
//'temperature': '#dc3545',
//'humidity': '#198754',
//'pressure': '#0dcaf0',
//'smoke': '#fd7e14',
//'noise': '#7D3C98',
//'state': '#0032CF',
//'floating': '#02D09E',
//}

//export const SENSORS_CUSTOM_FIELDS = {
//camera: [
//{
//label: 'Capacidad de almacenamiento (GB)',
//name: 'diskSize',
//options: [
//[16, '16 GB'],
//[32, '32 GB'],
//[128, '128 GB'],
//]
//}
//]
//}
