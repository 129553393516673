import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CurrentCompanyContext } from '../../App.js';
import { formatDate } from '../../utils.js';
import { DestroyButton } from '../shared/Buttons.js';
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';
import TableList from '../shared/TableList.js';

export default function DeviceInfoTable({ deviceInfo, refetch }) {
  const CheckFeatures = useFeatureChecker()
  const currentCompany = useContext(CurrentCompanyContext)

  if (!deviceInfo.length) {
    return (
      <div className="text-center text-muted py-4 my-4">No se encontraron dispositivos para los filtros especificados</div>
    )
  }

  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="Id" width={100} />
        <TableList.HeaderItem value="Mac" />
        <TableList.HeaderItem value="Creación" />
        <TableList.HeaderItem value="Usuario" />
        <TableList.HeaderItem width={200} />
      </TableList.Header>

      <TableList.Body>
        {
          deviceInfo.map(item => (
            <TableList.Row key={item.id}>
              <TableList.Column value={item.id} />
              <TableList.Column value={item.mac_address} />
              <TableList.Column>
                {formatDate(item.created_at)}
              </TableList.Column>
              <TableList.Column>
                <CheckFeatures feature="DETAIL_USER">
                  <AllowedFeature>
                    <Link className="py-3" to={"/members/" + item.user_id}> <span>{item.name}</span> </Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    <span>{item.name}</span>
                  </DeniedFeature>
                </CheckFeatures>
              </TableList.Column>
              <TableList.Column>
                <CheckFeatures feature="DESTROY_DEVICE_INFO">
                  <DestroyButton
                    label="Eliminar mac"
                    confirmMessage="¿Está seguro que desea eliminar la mac?"
                    successMessage="Mac eliminada correctamente"
                    errorMessage={"Mac no ha podido ser eliminada"}
                    deletePath={`deviceinfo/${item.id}?CompanyId=${currentCompany.id}`}
                    refetch={refetch}
                  />
                </CheckFeatures>
              </TableList.Column>

            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}
