import React from 'react';
import { Link } from 'react-router-dom'
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';
import TableList from '../shared/TableList.js';
import translateAlert from './translate_alert.js';
import translateType from './translate_type.js';

export default   function NotificationTable({ notifications }) {
  const CheckFeatures = useFeatureChecker()
  if(!notifications.length) {
    return <div className="text-center text-muted py-4 my-4">No hay personas para notificar</div>
  }

  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="Usuario" />
        <TableList.HeaderItem value="Tipo"  />
        <TableList.HeaderItem value="Notificación"  />
        <TableList.HeaderItem value="Alerta"/>
      </TableList.Header>

      <TableList.Body>
        {
          notifications.map(item => (
            <TableList.Row key={item.type+item.notification}>
              <CheckFeatures feature='DETAIL_USER'>
                <AllowedFeature>
                  <TableList.Column>
                    <Link to={"/members/" + item.user_id}> {item.name} </Link>
                  </TableList.Column>
                </AllowedFeature>
                <DeniedFeature>
                  <TableList.Column>
                    {item.name}
                  </TableList.Column>
                </DeniedFeature>
              </CheckFeatures>
              <TableList.Column value={translateType(item.notification_type)}/>
              <TableList.Column value={item.notification}/>
              <TableList.Column value={translateAlert(item.type)}/>
            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}
