import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import { useQuery } from 'react-query';
import { TIME_ZONE, toQueryString, useFromQueryString } from '../../utils.js';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Row from 'react-bootstrap/Row';
import { Container } from '../layout/AppLayout.js';

import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';
import Header, { HeaderActions } from "../shared/Header.js";
import Pagination from '../shared/Pagination.js';
import Spinner from '../shared/Spinner.js';
import TableList from '../shared/TableList.js';

import MembersListSubmenu from '../members/MembersListSubmenu.js';

function GroupsTable({ items }){
  const CheckFeatures = useFeatureChecker()

  if (!items.length) {
    return <div className="text-center text-muted py-4 my-4">No hay grupos de usuarios creados</div>
  }

  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="Nombre" />
        <TableList.HeaderItem value="Descripción" />
      </TableList.Header>

      <TableList.Body>
        {
          items.map(item => (
            <TableList.Row key={item.id}>
              <TableList.Column>
                <CheckFeatures feature="DETAIL_GROUP">
                  <AllowedFeature>
                    <Link className="py-3" to={`${item.id}`}>
                        <span>
                        {item.name}
                      </span>
                    </Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    <span>
                      {item.name}
                    </span>
                  </DeniedFeature>
                </CheckFeatures>
              </TableList.Column>

              <TableList.Column value={item.description} />
            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}

export default function MembersListPage() {
  const queryString = useFromQueryString()
  const CheckFeatures = useFeatureChecker()
  const navigate = useNavigate()

  const [currentFilters, setCurrentFilters] = useState({
    page: parseInt(queryString.page) || 1,
    limit: parseInt(queryString.limit) || 10,
    search: queryString.search || '',
    tz: TIME_ZONE
  });

  const query = useQuery(`groups`, {
    keepPreviousData: true,
  })
  const groups = query.data && query.data.result || []

  useEffect(() => {
    navigate('?' + toQueryString(currentFilters), { replace: true })
  }, [currentFilters]);

  return (
    <Container>
      <Row>
        <Header
          title="Grupos"
          items={[
            { label: "Grupos de usuarios" }
          ]}
        >
          <HeaderActions>
            <CheckFeatures feature={"CREATE_GROUP"}>
              <Link to="new" className="btn btn-primary">
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                <span>Crear grupo</span>
              </Link>
            </CheckFeatures>
          </HeaderActions>
        </Header>
      </Row>

      <Row className="mb-2">
        <MembersListSubmenu />
      </Row>

      <CheckFeatures feature="LIST_GROUPS">
        <AllowedFeature>
          <Row className="mb-2">
            <div className="col-12">
              {
                query.isLoading ?
                  <div className="text-center my-4 py-4">
                    <Spinner />
                  </div>
                :
                  <GroupsTable items={groups} />
              }
            </div>
          </Row>
        </AllowedFeature>
        <DeniedFeature>
          <Navigate to={'/error?error=403'}/>
        </DeniedFeature>
      </CheckFeatures>

      <Row className="mt-2">
        <Pagination
          pagination={query.data && query.data.pagination}
          isLoading={query.isFetching}
          onChange={page => setCurrentFilters(prev => ({ ...prev, page }))}
        />
      </Row>
    </Container>
  );
}

