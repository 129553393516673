import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useFromQueryString, toQueryString } from '../../utils.js'
import { useQuery } from 'react-query'

import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import Spinner from '../shared/Spinner.js';
import OptionSelector from '../shared/OptionSelector.js'
import Pagination from '../shared/Pagination.js'
import Header, { HeaderActions } from "../shared/Header.js"
import useFeatureChecker, { isAllowed } from '../shared/FeatureChecker.js'
import { FilterMenu } from '../shared/Filters.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faDoorClosed, faVectorSquare, faFilter } from '@fortawesome/free-solid-svg-icons';
import AccessTable from './AccessTable.js'
import { CurrentUserContext } from '../../App.js';

export default function AccessListPage(params){
  const queryString = useFromQueryString()
  const navigate = useNavigate()
  const filterDoorLabel = 'Filtrar por puerta';
  const CheckFeatures = useFeatureChecker()
  const currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,some: ["LIST_ALL_ACCESS","LIST_PERSONAL_ACCESS"]})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])


  const [ currentFilters, setCurrentFilters ] = useState({
    page: parseInt(queryString.page) || 1,
    limit: parseInt(queryString.limit) || 10,
    search: queryString.search || '',
    type: queryString.type || 'current',
    FacilityId: queryString.FacilityId || '',
    ZoneId: queryString.ZoneId || '',
    UserId: (queryString.UserId) || '',
    DoorId: (queryString.DoorId) || '',
    GroupId: queryString.GroupId || ''
  })

  const query = useQuery(`doors/access?${toQueryString(currentFilters)}`, {
    keepPreviousData: true,
  })

  useEffect(()=>{
    setCurrentFilters(prevFilters => ({
      ...prevFilters,
      page: 1
    }));
  },[queryString.FacilityId,queryString.ZoneId,queryString.UserId,queryString.DoorId,queryString.search,queryString.type])
  const [ openFilter, setOpenFilter ] = useState(false)

  useEffect(() => {
    navigate('?' + toQueryString(currentFilters), { replace: true })
  }, [ currentFilters ])

  return (
    <Container>
      <Row>
	<Header title="Permisos" items={[
	  { label: "Listado de permisos" },
	]}>
	  <HeaderActions>
	    <CheckFeatures feature="CREATE_ACCESS">
	      <Link to="/permissions/new" className="btn btn-primary">Crear permiso</Link>
	    </CheckFeatures>
	  </HeaderActions>
	</Header>
      </Row>

      <Row className="mb-2">
        <FilterMenu
          currentFilters={currentFilters}
          onChange={filters => setCurrentFilters(prev => ({ ...prev, ...filters }))}
          accessTypeFilter
        />
      </Row>

      <Row>
        <div className="col-12">
          {
            query.isLoading ?
              <div className="text-center my-4 py-4">
                <Spinner />
              </div>
            :
              <AccessTable accesses={query.data && query.data.result || []} />
          }
        </div>
      </Row>

      <Row className="mt-2">
        <Pagination
          pagination={query.data && query.data.pagination}
          isLoading={query.isFetching}
          onChange={page => setCurrentFilters(prev => ({ ...prev, page }))}
        />
      </Row>
    </Container>
  );
}

