import React from 'react'
import {useQuery,useMutation} from 'react-query'
import { Link } from 'react-router-dom'

// import { useParams, useNavigate, Link } from 'react-router-dom'

 import { useMutationClient } from '../../shared/hooks.js'
// import { useNotifications } from '../../shared/Notifications.js'
import Spinner from '../../shared/Spinner.js'

import { ThreatLevelIndicator } from './ThreatAssestment.js'
import { RISK_ACTIONS_LABELS } from './RiskAssestment.js'

function useThreats(SvaId) {
  const query = useQuery(`sva/${SvaId}/threats`)
  return [ query.data && query.data.result || [], query.isLoading ]
}

function useRisks(SvaId) {
  const query = useQuery(`sva/${SvaId}/risks`)
  return [ query.data && query.data.result || [], query.isLoading ]
}
function MitigationsItems({item}){
  const query = useQuery(`mitigations?SvaRiskId=${item.id}`)

  const mitigations= query.data && query.data.result || []
  
  function riskAction(action){
    return RISK_ACTIONS_LABELS[action] || ''
  }
 return(
  <div
    key={item.id}
    className='list-group-item'
  >
    <p className="">
      {item.name}
    </p>
    <div className="text-muted">
      <span>Acción: {riskAction(item.action)}</span>
    </div>
    <div className='mt-2'>Mitigationes</div>  
    {
      mitigations.length>0 ?
        mitigations.map(mitigation=>(
          <div
              key={mitigation.id}
              className="mt-1 ml-2"
            >
            {
              mitigation.metadata?
                <a href={mitigation.file_url} target="_blank">{mitigation.metadata.file_name}</a>
              : null
            }
            {
              mitigation.sensor_id?
                <Link className="py-3" to={"/sensors/" + mitigation.sensor_id}>
                  <span>{mitigation.name}</span>
                </Link>
              :null
            }
          </div>
        ))
      :
        <div className="text-muted mt-1">
          <span>No posee mitigationes</span>
        </div> 
    }  
  </div>
 )
}

export default function SvaSummary({currentSva}) {
  const [threats, loadingThreats] = useThreats(currentSva.id)
  const [risks, loadingRisks] = useRisks(currentSva.id)
  const isLoading = loadingThreats || loadingRisks

  if (isLoading) {
    return (
      <div className="text-center my-4 py-4">
        <Spinner />
      </div>
    )
  }

  return(
    <div className="row">
      <div className="col">
        <p className="alert alert-success shadow-sm">
          Se identificaron <b>{ threats.length } amenazas</b> y <b>{risks.length} riesgos</b>
        </p>
      </div>

      {
        threats.map(item => (
          <div key={item.id} className="col-12 mb-3">
            <div className='card shadow-sm'>

              <div className='card-body'>
                <div className="d-flex align-items-center pb-3">
                  <ThreatLevelIndicator {...item} size={2} />
                  <span className='ml-2 font-weight-bold'>{item.name}</span>
                </div>

                <div className='list-group'>
                  {
                    risks
                      .filter(x => x.sva_threat_id === item.id)
                      .map(risk_item => (
                        <MitigationsItems
                          key={risk_item.id}
                          item={risk_item}
                        />
                      ))
                  }
                </div>

              </div>


            </div>
          </div>
        ))
      }
    </div>
  );
}
