import React from 'react'

import { useParams,useNavigate, Navigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useNotifications } from '../shared/Notifications.js'

import { Container } from '../layout/AppLayout.js'
import Row from 'react-bootstrap/Row';

import { useMutationClient } from '../shared/hooks.js'
import Header from "../shared/Header.js"
import RoleForm from "./RoleForm.js"
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';

export default function NewRolePage() {
  const params = useParams()
  const mutationClient = useMutationClient()
  const notifications = useNotifications()
  const navigate = useNavigate()
  const CheckFeatures= useFeatureChecker()


  const createRole = useMutation(
    data => mutationClient.post(`roles`, data), {
    onSuccess: (data, variables, context) => {
      if (data.status === 200) {
        navigate(`/companies/${params.CompanyId}/roles`)
        notifications.success('Rol creado correctamente')
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al crear el rol')
    }
  })

  return (
    <Container>
      <Row>
        <Header title="Editar compañía" items={[
          { label: `Listado de compañías`, to: "/companies" },
          { label: `Detalle de compañía`, to: `/companies/${params.CompanyId}` },
          { label: `Listado de roles`, to: `/companies/${params.CompanyId}/roles` },
          { label: `Crear rol` }
        ]} />
      </Row>

      <Row className="mb-4">
        <div className="col-12 mb-2 col-lg-6">
          <div className="card shadow-sm">
            <div className="card-header">Crear Rol</div>
            <CheckFeatures feature='CREATE_ROLE'>
              <AllowedFeature>
                <div className="card-body">
                  <RoleForm save={data => createRole.mutate(data)} isLoading={createRole.isLoading} company_id={params.CompanyId} />
                </div>
              </AllowedFeature>
              <DeniedFeature>
                <Navigate to={'/error?error=403'}/>
              </DeniedFeature>
            </CheckFeatures>
          </div>
          
        </div>
      </Row>
    </Container>
  )
}

