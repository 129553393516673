import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from 'react-query'

import Header, { HeaderActions } from "../shared/Header.js"
import Spinner from "../shared/Spinner.js";

import { useFromQueryString, toQueryString, TIME_ZONE } from "../../utils.js";
import { FilterMenu } from '../shared/Filters.js';
import { isAllowed } from "../shared/FeatureChecker.js";
import { CurrentUserContext } from "../../App.js";

import { Container } from '../layout/AppLayout.js';
import Pagination from "../shared/Pagination.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import EventsTable from "../events/EventsTable.js";

export default function EventsListPage() {
  const queryString = useFromQueryString();
  const navigate = useNavigate();
  const currentUser = useContext(CurrentUserContext)

  const [currentFilters, setCurrentFilters] = useState({
    page: parseInt(queryString.page) || 1,
    limit: parseInt(queryString.limit) || 50,
    ZoneId: (queryString.ZoneId) || '',
    EventTypeFilter: queryString.EventTypeFilter || '',
    sort: "date,desc",
    tz: TIME_ZONE
  });

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "LIST_EVENTS"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  useEffect(()=>{
    setCurrentFilters(prevFilters => ({
      ...prevFilters,
      page: 1
    }));
  },[queryString.EventTypeFilter,queryString.ZoneId])

  const query = useQuery(`events?${toQueryString(currentFilters)}`)

  useEffect(() => {
    navigate("?" + toQueryString(currentFilters), { replace: true });
  }, [currentFilters]);

  return (
    <Container>
      <Row>
        <Header title="Eventos" items={[
          { label: "Listado de eventos" }
        ]}>
          <HeaderActions>
          </HeaderActions>
        </Header>
      </Row>

      <Row className="mb-2">
        <FilterMenu
          currentFilters={currentFilters}
          onChange={filters => setCurrentFilters(prev => ({ ...prev, ...filters }))}
          dateRangeFilter
        />
      </Row>

      <Row className="mb-2">
        <Col className="col-12">
          {
            query.isLoading ?
              <div className="text-center my-4 py-4">
                <Spinner />
              </div>
            :
              <EventsTable events={query.data && query.data.result && query.data.result.items || []} />
          }
        </Col>
      </Row>

      <Row className="mt-3">
        <Pagination
          pagination={query.data && query.data.pagination}
          isLoading={query.isFetching}
          onChange={page => setCurrentFilters(prev => ({ ...prev, page }))}
        />
      </Row>
    </Container>
  );
}
