import Iframe from 'react-iframe'
import { PlaceholderLoader } from '../shared/Widgets.js'
import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';
import NavDropdown from 'react-bootstrap/NavDropdown';


function GafanaCardMulti({ metric, titleMulti = "" }) {
    const [key, setKey] = useState(metric[0].name);

    const label = metric.map(item => item.name)
    const url = metric.map(item => item.url)
    return (
        <div>
            <div className="text-center text-muted my-1 py-1">{titleMulti}</div>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                {
                    metric.map(item => (
                        <Tab eventKey={item.name} title={item.name}>
                            <Iframe
                                url={item.url}
                                width="100%"
                                height='300wh'
                                className=""
                                id={item.name}
                                frameBorder="0"
                                allow="fullscreen"
                            />

                        </Tab>
                    ))
                }
            </Tabs>
        </div>
    );
}

function GrafanaCardRange({ title = '', range = [] }) {
    const [titleLabel, setTitleLabel] = useState(range[0].title);
    const [grafana, setGrafana] = useState(range[0].grafana);

    const handleSelect = (e) => {
        setTitleLabel(range[e].title)
        setGrafana(range[e].grafana)

    }

    return (
        <div className="bg-white border shadow-sm w-100 mb-3 ">
            <div className="text-center text-muted">
                <NavDropdown
                    alignRight
                    title={titleLabel}
                    onSelect={handleSelect}
                >
                    {
                        range.map((item,index) => (
                            <Dropdown.Item eventKey={index}>{item.title}</Dropdown.Item>
                        ))
                    }
                </NavDropdown>

            </div>
            <GafanaCardMulti metric={grafana} titleMulti='' />
        </div>
    )
}

export default function GafanaCard({ metric }) {
    const settings = JSON.parse(metric.settings)

    if (settings['multiUrl']) {
        var titleMulti = ""
        if (settings['title']) {
            titleMulti = settings['title']
        }

        return (
            <div className="bg-white border shadow-sm w-100 mb-3 ">
                <GafanaCardMulti metric={settings['multiUrl']} titleMulti={titleMulti} />
            </div>
        )
    }
    if (settings['url']) {
        return (
            <div className="bg-white border shadow-sm w-100 mb-3 ">
                {

                    <Iframe
                        url={settings['url']}
                        width="100%"
                        height='300wh'
                        className=""
                        id={metric.name}
                        frameBorder="0"
                        allow="fullscreen"
                    />
                }

            </div>

        )
    }
    if (settings['range']) {
        return < GrafanaCardRange title={settings['title']} range={settings['range']} />
    }
    return null
}