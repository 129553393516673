import React, { useEffect, useState } from "react";

import { useQuery } from 'react-query';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toQueryString, useFromQueryString } from "../../utils.js";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from '../layout/AppLayout.js';

import Header, { HeaderActions } from "../shared/Header.js";
import Pagination from '../shared/Pagination.js';
import useSearch from '../shared/Search.js';
import Spinner from '../shared/Spinner.js';

import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js'
import { FilterMenu } from '../shared/Filters.js';

import SensorsTable from "./SensorsTable.js";

export default function SensorsListPage() {
  const queryString = useFromQueryString();
  const navigate = useNavigate()
  const CheckFeatures = useFeatureChecker()

  const [currentFilters, setCurrentFilters] = useState({
    limit: parseInt(queryString.limit) || 25,
    page: parseInt(queryString.page) || 1,
    search: queryString.search || "",
    FacilityId: (queryString.FacilityId) || '',
    ZoneId: (queryString.ZoneId) || '',
    SensorTypeId: queryString.SensorType || '',
    //sort: queryString.sort || "created_at-desc",
  });

  const [sortedBy, sortOrder] = currentFilters.sort ? currentFilters.sort.split("-") : [];

  useEffect(() => {
    setCurrentFilters(prevFilters => ({
      ...prevFilters,
      page: 1
    }));
  }, [queryString.FacilityId, queryString.ZoneId, queryString.search, queryString.SensorTypeId])

  const Searchbox = useSearch(search => {
    if (currentFilters.search !== search) {
      setCurrentFilters({ ...currentFilters, search });
    }
  },
    { value: currentFilters.search }
  );

  const query = useQuery(`sensors?${toQueryString(currentFilters)}`, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    //refetchOnMount: false,
  })

  useEffect(() => {
    navigate('?' + toQueryString(currentFilters), { replace: true })
  }, [currentFilters]);

  return (
    <Container>
      <Row>
        <Header title="Dispositivos" items={[
          { label: "Listado de dispositivos" },
        ]}>
          <HeaderActions>
            <CheckFeatures feature="CREATE_SENSORS">
              <Link to="/sensors/new" className="btn btn-primary">Crear dispositivo</Link>
            </CheckFeatures>
          </HeaderActions>
        </Header>
      </Row>

      <Row className="mb-2">
        <FilterMenu
          currentFilters={currentFilters}
          onChange={filters => setCurrentFilters(prev => ({ ...prev, ...filters }))}
        />
      </Row>

      <Row>
        <CheckFeatures feature="LIST_SENSORS">
          <AllowedFeature>
            <Col className="col-12 mb-4">
            {
              query.isLoading ?
                <div className="text-center my-4 py-4">
                  <Spinner />
                </div>
                :
                <SensorsTable
                  sensors={query.data && query.data.result || []}
                />
            }
            </Col>
          </AllowedFeature>
          <DeniedFeature>
            <Navigate to={'/error?error=403'}/>
          </DeniedFeature>
        </CheckFeatures>
      </Row>

      <Row className="mt-2">
        <Pagination
          pagination={query.data && query.data.pagination}
          isLoading={query.isFetching}
          onChange={page => setCurrentFilters(prev => ({ ...prev, page }))}
        />
      </Row>
    </Container>
  )
}

