import { formatDistanceToNow } from 'date-fns'
import { es } from 'date-fns/locale'
import { Circle, FeatureGroup, MapContainer, Popup, TileLayer } from 'react-leaflet'
import { useQuery } from 'react-query'
import { Link, } from 'react-router-dom'
import { formatDate } from '../../../utils.js'
import MapCardAlert from './alertMap.js'

function DoorState({ stateDoor }) {

    if (stateDoor == null) {
        return null
    }
    let styleState = {
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'inline-block',
        height: '8px',
        width: '8px',
        backgroundColor: 'black',
        boxShadow: '0px 0px 8px 2px red',
    }
    let text = "Error"

    if (stateDoor.length > 0) {
        switch (stateDoor[0].state) {
            case 114:
                styleState = {
                    ...styleState,
                    backgroundColor: 'green',
                    boxShadow: '0px 0px 8px 2px green',
                }
                text = "Cerrada"
                break
            case 115:
                styleState = {
                    ...styleState,
                    backgroundColor: 'red',
                    boxShadow: '0px 0px 8px 2px red',
                }
                text = "Abierta"
                break
            case 116:
                styleState = {
                    ...styleState,
                    backgroundColor: 'black',
                    boxShadow: '0px 0px 8px 2px black',
                }
                text = "Error"
                break
            default:
                styleState = {
                    ...styleState,
                    backgroundColor: 'black',
                    boxShadow: '0px 0px 8px 2px black',
                }
                text = "Error"
                break
        }
    }

    return (
        <div>
            <div className="d-flex justify-content-start align-items-center mt-2">
                <span className="mr-2" style={styleState}></span>
                <span>Estado de tapa/puerta: {text}</span>
            </div>
        </div>
    )
}

function AlarmState({ stateAlarm }) {

    if (stateAlarm == null) {
        return null
    }

    let styleState = {
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'inline-block',
        height: '8px',
        width: '8px',
        backgroundColor: 'black',
        boxShadow: '0px 0px 8px 2px red',
    }
    let text = "Error"

    if (stateAlarm.length > 0) {

        switch (stateAlarm[0].state) {

            case 104:
                styleState = {
                    ...styleState,
                    backgroundColor: 'green',
                    boxShadow: '0px 0px 8px 2px green',
                }
                text = "Alarma activada"
                break

            case 105:
                styleState = {
                    ...styleState,
                    backgroundColor: 'red',
                    boxShadow: '0px 0px 8px 2px red',
                }
                text = "Alarma desactivada"
                break

            case 106:
                styleState = {
                    ...styleState,
                    backgroundColor: 'orange',
                    boxShadow: '0px 0px 8px 2px orange',
                }
                text = "Alarma está sonando"
                break

            case 107:
                styleState = {
                    ...styleState,
                    backgroundColor: 'black',
                    boxShadow: '0px 0px 8px 2px black',
                }
                text = "Error"
                break

            default:
                styleState = {
                    ...styleState,
                    backgroundColor: 'black',
                    boxShadow: '0px 0px 8px 2px black',
                }
                text = "Cerrada"
                break
        }
    }


    return (
        <div>
            <div className="d-flex justify-content-start align-items-center mt-2">
                <span className="mr-2" style={styleState}></span>
                <span>Estado de la alarma: {text}</span>
            </div>
        </div>
    )
}

function CreateCycle({ live, latitude, longitud, stateDoor,stateAlarm }) {

    if (latitude == null | longitud == null | live == null | stateDoor == null) {
        return null
    }

    let stateDoorcycle = null
    let stateLivecycle = null
    let stateAlarmcycle = null


    if (stateDoor.length > 0) {
        switch (stateDoor[0].state) {
            case 114:
                stateDoorcycle = <Circle center={[latitude, longitud]} radius={15} pathOptions={{ color: "green", fillOpacity: 0.2 }} />
            break
            case 115:
                stateDoorcycle =  <Circle center={[latitude, longitud]} radius={15} pathOptions={{ color: "red", fillOpacity: 0.2 }} />
            break
            case 116:
                stateDoorcycle =  <Circle center={[latitude, longitud]} radius={15} pathOptions={{ color: "black", fillOpacity: 0.2 }} />
            break
            default:
                stateDoorcycle =  <Circle center={[latitude, longitud]} radius={15} pathOptions={{ color: "black", fillOpacity: 0.2 }} />
            break
        }
    }

    if (live.length > 0) {
        switch (live[0].state) {
            case 0:
                stateLivecycle= <Circle center={[latitude, longitud]} radius={10} pathOptions={{ color: "green", fillOpacity: 0.2 }} />
            break
            case 1:
                stateLivecycle= <Circle center={[latitude, longitud]} radius={10} pathOptions={{ color: "orange", fillOpacity: 0.2 }} />
            break
            case 2:
                stateLivecycle= <Circle center={[latitude, longitud]} radius={10} pathOptions={{ color: "red", fillOpacity: 0.2 }} />
            break
            default:
                stateLivecycle =  <Circle center={[latitude, longitud]} radius={10} pathOptions={{ color: "black", fillOpacity: 0.2 }} />
            break
        }
    }

    if (stateAlarm.length > 0) {
        switch (stateAlarm[0].state) {
            case 104:
                stateAlarmcycle= <Circle center={[latitude, longitud]} radius={5} pathOptions={{ color: "green", fillOpacity: 0.2 }} />
            break
            case 105:
                stateAlarmcycle= <Circle center={[latitude, longitud]} radius={5} pathOptions={{ color: "red", fillOpacity: 0.2 }} />
            break
            case 106:
                stateAlarmcycle= <Circle center={[latitude, longitud]} radius={5} pathOptions={{ color: "orange", fillOpacity: 0.2 }} />
            break
            case 107:
                stateAlarmcycle= <Circle center={[latitude, longitud]} radius={5} pathOptions={{ color: "black", fillOpacity: 0.2 }} />
            break
            default:
                stateAlarmcycle =  <Circle center={[latitude, longitud]} radius={5} pathOptions={{ color: "black", fillOpacity: 0.2}} />
            break
        }
    }

    return (
        <div>
            {stateLivecycle}
            {stateAlarmcycle}
            {stateDoorcycle}
        </div>
    )

}

function DoorHealthState({ ts, live }) {


    if (ts == null) {
        return null
    }

    const tsState = Math.floor(new Date(ts).getTime())

    const t = new Date(tsState).toISOString().replace("T", " ").replace("Z", "");
    const elapsed = formatDistanceToNow(tsState, { addSuffix: false, locale: es })
    const formatted = formatDate(t, "dd/MM/yyyy HH:mm:ss")

    let styleState = {
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'inline-block',
        height: '8px',
        width: '8px',
    }

    if (live.length > 0) {
        switch (live[0].state) {
            case 0:
                styleState = {
                    ...styleState,
                    backgroundColor: 'green',
                    boxShadow: '0px 0px 8px 2px green',
                }
                break
            case 1:
                styleState = {
                    ...styleState,
                    backgroundColor: 'orange',
                    boxShadow: '0px 0px 8px 2px orange',
                }
                break
            case 2:
                styleState = {
                    ...styleState,
                    backgroundColor: 'red',
                    boxShadow: '0px 0px 8px 2px red',
                }
                break
            default:
                styleState = {
                    ...styleState,
                    backgroundColor: 'black',
                    boxShadow: '0px 0px 8px 2px black',
                }
                break
        }
    }


    return (
        <div className="d-flex justify-content-start align-items-center mt-2">
            <span className="mr-2" style={styleState}></span>
            <span title={formatted}>Último dato {elapsed}</span>
        </div>
    )
}

function InfoMap({ item, conf }) {
    const live = item && item.alertState && item.alertState.filter((e) => e.variable == "live") || null
    const other = item && item.alertState && item.alertState.filter((e) => e.variable != "live") || null

    const tsState = other && other[0] && other[0].updated_at || null
    const latitude = item && item.latitude || null
    const longitud = item && item.longitud || null


    const stateDoor = item && item.alertState && item.alertState.filter((e) => e.variable == "state-door[Entel]") || null
    const stateAlarm = item && item.alertState && item.alertState.filter((e) => e.variable == "alarm[Entel]") || null


    return (
        <FeatureGroup >
            <Popup >
                <div className="d-flex justify-content-start align-items-center">
                    <span><Link to={`/doors/${item.id}`} className="mr-2">{item.name}</Link></span>
                </div>

                <div>
                    <DoorState stateDoor={stateDoor} />
                </div>


                <div>
                    <AlarmState stateAlarm={stateAlarm} />
                </div>

                {
                    live.length == 0 ? null : (
                        <div>
                            <DoorHealthState ts={tsState} live={live} />
                        </div>
                    )
                }
            </Popup>
            <CreateCycle live={live} latitude={latitude} longitud={longitud} stateDoor={stateDoor} stateAlarm={stateAlarm} />
        </FeatureGroup>
    )
}


export default function MapCard({ setting }) {

    const query = useQuery(`doors/map`, { refetchInterval: 1000, refetchIntervalInBackground: true })
    const items = query.data && query.data.result && query.data.result.door || []
    if (items == [] || items.length < 1) { return null; }

    if (items[0].latitude == null || items[0].longitud == null) {
        return null;
    }
    const position = [items[0].latitude, items[0].longitud]
    let alertItem = items.filter((e) => {
        const stateDoor = e && e.alertState && e.alertState.filter((e) => e.variable == "state-door[Entel]") || null
        const stateAlarm = e && e.alertState && e.alertState.filter((e) => e.variable == "alarm[Entel]") || null
        if (stateDoor != null && stateAlarm != null) {
            if (stateDoor.length > 0 && stateAlarm.length > 0) {
                if (stateDoor[0].state == 115 && stateAlarm[0].state == 104) {
                    return e
                }
            }
        }
    })
    return (
        <div>
            <div className="row pb-4" >
                <div className="col-8">
                    <MapContainer center={position} zoom={20} scrollWheelZoom={false} style={{ height: '70vh' }}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {

                            items.map(item => {
                                if (item.latitude == null || item.longitud == null) {
                                    return null
                                }

                                return <InfoMap key={item.id} item={item} conf={setting} />;
                            })

                        }
                    </MapContainer>
                </div>
                <div className='col-4'>
                    <MapCardAlert items={alertItem} />
                </div>
            </div>
        </div>
    );
}