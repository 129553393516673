import React, { useState , forwardRef } from "react";
import { MaintanceSelector } from "../shared/Selectors.js";
import DatePicker from "react-datepicker";
import { toUTCString } from '../../utils.js'

const DateTimeInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      className="form-control"
      value={value}
      onChange={onChange}
      onClick={onClick}
      ref={ref}
    />
  ));


export default function MaintanceForm({ sensor, save,isLoading}) {
  const [comments, setComments] = useState("");
  const [type, setType] = useState ("maintance");
  const [maintanceTypeId, setMaintanceTypeId] = useState("")
  const [startsAt, setStartsAt] = useState("")


  const [errors, setErrors] = useState({});

  const validations = [];

  function validate(e) {
    e.preventDefault();

    if (!comments) {
      validations.push(["comments", "Comentario es requerido"]);
    }
    
    if (!maintanceTypeId){
        validations.push(["maintaceType", "Tipo de mantenimiento es requerido"]);
    }

    if( !startsAt){
        validations.push(["startsAt", "Fecha es requerida"]);
    }
   

    if (validations.length) {
      setErrors(
        validations.reduce((acc, item) => ({ ...acc, [item[0]]: item[1] }), {})
      );
      return;
    } else {
      setErrors([]);
    }
    save({
      comments,
      type,
      report_type_id: maintanceTypeId,
      created_at: toUTCString(startsAt)
    });
  }

  return (
    <form onSubmit={validate}>
      <div className="form-group">

            <label className="font-weight-bold">Fecha de la mantención</label>

            <DatePicker
            selected={startsAt}
            onChange={(date) => setStartsAt(date)}
            selectsStart
            startDate={startsAt}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={10}
            dateFormat="dd/MM/yyyy HH:mm"
            customInput={<DateTimeInput />}
            />
            <div className="invalid-feedback">{errors.startsAt}</div>


          <label className="font-weight-bold mt-2">Mantenimiento</label>

          <MaintanceSelector
            placeholder="Seleccione una tipo de mantenimiento"
            onChange={(value) => setMaintanceTypeId(value)}
            defaultValue={maintanceTypeId}
            disabled={isLoading}
            hasError={!!errors.maintaceType}
          />

          <div className="invalid-feedback">{errors.maintaceType}</div>

        <label className="font-weight-bold mt-2">Comentarios</label>
        <textarea
          rows={4}
          className={`form-control ${errors.comments ? "is-invalid" : ""}`}
          onChange={(e) => setComments(e.target.value)}
          value={comments}
          disabled={isLoading}
          autoFocus={true}
        />

        <div className="invalid-feedback">{errors.comments}</div>
      </div>

      
      <div className="form-group">
        <button
          className={`btn btn-primary ${isLoading ? "loading" : ""}`}
          disabled={isLoading}
        >
          <span>Guardar</span>
          <div className="spinner-border" role="status"></div>
        </button>
      </div>
    </form>
  );
}
