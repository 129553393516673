import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useParams } from "react-router-dom";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { useMutationClient } from '../../shared/hooks.js'
import { useNotifications } from '../../shared/Notifications.js'
import { DropdownSelector } from '../../shared/Filters'
import { Searchbox } from '../../shared/Search.js'
import { DestroyButton } from '../../shared/Buttons.js'
import Spinner from '../../shared/Spinner.js'

function LikelihoodSelector({ value, disabled, onChange }){
  const items = [
    { value: 1, label: 'Muy poco probable' },
    { value: 2, label: 'Poco probable' },
    { value: 3, label: 'Medianamente probable' },
    { value: 4, label: 'Muy probable' },
    { value: 5, label: 'Altamente probable' },
  ]

  const defaultItem = items.find(x => x.value === value)

  return (
    <DropdownSelector
      defaultLabel="Nivel de probabilidad"
      items={items}
      defaultItem={defaultItem}
      onChange={selection => selection ? onChange(selection.value) : onChange(null)}
      disabled={disabled}
    />
  )
}

function VulnerabilitySelector({ value, disabled, onChange }){
  const items = [
    { value: 1, label: 'Muy poco vulnerable' },
    { value: 2, label: 'Poco vulnerable' },
    { value: 3, label: 'Medianamente vulnerable' },
    { value: 4, label: 'Muy vulnerable' },
    { value: 5, label: 'Altamente vulnerable' },
  ]
  const defaultItem = items.find(x => x.value === value)

  return (
    <DropdownSelector
      defaultLabel="Nivel de vulnerabilidad"
      items={items}
      defaultItem={defaultItem}
      onChange={selection => selection ? onChange(selection.value) : onChange(null)}
      disabled={disabled}
    />
  )
}

function ZoneSelector({value,zones,disabled,onChange}){

  const defaultItem = zones.find(x => x.value === value)

  return(
    <DropdownSelector
      defaultLabel="Zona"
      items={zones}
      defaultItem={defaultItem}
      onChange={selection=>  selection ? onChange(selection.value) : onChange(null)}
      disabled={disabled}
    />
  )
}

function ThreatResultItem({ item, addThreat }){
  return (
    <div className="list-group-item list-group-item-action d-flex justify-content-between align-items-center p-2">
      <div>
        <div className="mr-4">{ item.name }</div>
      </div>

      <div>
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={() => addThreat(item.id)}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  )
}

function AddThreadCard({ currentSva, onChange }){
  const mutationClient = useMutationClient()
  const notifications = useNotifications()

  const [ search, setSearch ] = useState('')

  const query = useQuery(`threats?ExcludeSvaId=${currentSva.id}&search=${search}&limit=10`, {
    keepPreviousData: true
  })

  const threats = query.data && query.data.result || []

  const addThreat = useMutation(
    ThreatId => mutationClient.post(`sva/${currentSva.id}/threats`, { threat_id: ThreatId }), {
    onSuccess: (data) => {
      if( data.status === 200 ){
        notifications.success('Amenaza agregada!')
        query.refetch()
        onChange(data.data)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al agregar la amenaza al SVA')
    }
  })

  return (
    <div className="card shadow-sm mb-3">
      <div className="card-header">Identificación de amenazas</div>

      <div className="pt-2 px-2">
        <Searchbox
          onSearch={q => setSearch(q)}
          autoFocus={true}
        />
      </div>

      <div className="card-body p-0">
        {
          query.isLoading ?
            <div className="text-center py-4 my-4">
              <Spinner />
            </div>
          :
            <div className="list-group list-group-flush border-top mt-2">
              {
                threats.map(item => <ThreatResultItem key={item.id} item={item} addThreat={ThreatId => addThreat.mutate(ThreatId)} />)
              }
            </div>
        }
      </div>
    </div>
  )
}

function SvaThreatItem({ item, currentSva }){
  const mutationClient = useMutationClient()
  const notifications = useNotifications()
  const queryClient = useQueryClient()

  const [ probability, setProbability ] = useState(item.probability || null)
  const [ vulnerability, setVulnerability ] = useState(item.vulnerability || null)
  const [ zone_id,setZone]=useState(item.zone_id || null)

  const params = useParams();

  const query = useQuery(`zones?FacilityId=${params.FacilityId}`)
  const zones = query.data && query.data.result || []
  
  const items=zones.map( item=>({value:(item.id),label:(item.name)}))

  const updateThreat = useMutation(
    data => mutationClient.put(`sva/${currentSva.id}/threats/${data.id}`, data), {
    onSuccess: (data) => {
      let updatedItem = data.data.result
      updatedItem.name=item.name
      const cache = queryClient.getQueryData(`sva/${currentSva.id}/threats`)
      if( data.status === 200 ){
        notifications.success('Amenaza actualizada!')

        queryClient.setQueryData(`sva/${currentSva.id}/threats`, {
          ...cache,
          result: cache.result.map(item => {
            if( item.id === updatedItem.id ){
              console.log(updatedItem)
              return updatedItem
            }
            return item;
          }),    
        })
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al actualizar la amenaza')
    }
  })
  useEffect(() => {
    if( item.probability !== probability ){
      updateThreat.mutate({ id: item.id, probability })
    }
  }, [probability])

  useEffect(() => {
    if( item.vulnerability !== vulnerability ){
      updateThreat.mutate({ id: item.id, vulnerability })
    }
  }, [vulnerability])

  useEffect(()=>{
    if(item.zone_id !== zone_id){      
      updateThreat.mutate({id: item.id,zone_id})
    }
  },[zone_id])
  return (
    <div className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
      <div >
        <div className="d-flex align-items-center ">
          <div className="mr-4">{ item.name }</div>
          <div className="mr-1" >
              <ZoneSelector
                value={zone_id}
                zones={items}
                onChange={value=>setZone(value)}
                disable={updateThreat.isLoading}
              />
            </div>
        </div>
        <div className="d-flex align-items-center mt-1">
            <div className="mr-1">
              <LikelihoodSelector
                value={probability}
                onChange={value => setProbability(value)}
                disabled={updateThreat.isLoading}
              />
            </div>
            <div >
              <VulnerabilitySelector
                  value={vulnerability}
                  onChange={value => setVulnerability(value)}
                  disabled={updateThreat.isLoading}
                />
            </div>  
        </div>
      </div>

      <div className="d-flex align-items-center">
        <div className="mr-2">
          <ThreatLevelIndicator
            probability={probability}
            vulnerability={vulnerability}
            size={2}
          />
        </div>

        <div>
          <DestroyButton
            confirmMessage="¿Está seguro que desea eliminar esta amenaza?"
            successMessage="Amenaza eliminada correctamente"
            deletePath={`sva/${currentSva.id}/threats/${item.id}`}
            invalidate={`sva/${currentSva.id}/threats`}
            size="sm"
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </DestroyButton>
        </div>
      </div>
    </div>
  )
}

function CurrentThreatsCard({ currentSva, currentThreats }){
  return (
    <div className="card shadow-sm mb-3">
      <div className="card-header">Evaluación de amenazas identificadas</div>

      {
        !currentThreats.length ?
          <div className="p-3 my-4 py-4 text-center text-muted">
            Sin amenazas identificadas
          </div>
        :
          <div className="list-group list-group-flush">
            {
              currentThreats.map(item => (
                <SvaThreatItem
                  key={item.id}
                  item={item}
                  currentSva={currentSva}
                />
              ))
            }
          </div>
      }
    </div>
  )
}

export function ThreatLevelIndicator({ probability = 0, vulnerability = 0, size }){
  const threatLevel = probability * vulnerability

  if( threatLevel === 0 ){
    return null
  }

  let styles = {
    color: '#fff'
  }

  if( threatLevel < 3 ){
    styles.backgroundColor = '#0a3622'
  }else if( threatLevel < 5 ){
    styles.backgroundColor = '#146c43'
  }else if( threatLevel < 10 ){
    styles.backgroundColor = '#ffc107'
  }else if( threatLevel < 17 ){
    styles.backgroundColor = '#ca6510'
  }else{
    styles.backgroundColor = '#dc3545'
  }

  return (
    <div
      style={styles}
      className={`badge ${ size ? `p-${size}` : '' }`}
      title="Nivel de amenaza"
    >{ threatLevel }</div>
  )
}

export default function ThreatAssestment({ currentSva }){
  const query = useQuery(`sva/${currentSva.id}/threats`)
  const currentThreats = query.data && query.data.result || []

  return(
    <div className="row">
      <div className="col-6">
        <AddThreadCard
          currentSva={currentSva}
          onChange={() => query.refetch()}
        />
      </div>

      <div className="col-6">
        {
          query.isLoading ?
            <div className="text-center my-4 py-4">
              <Spinner />
            </div>
          : <CurrentThreatsCard currentSva={currentSva} currentThreats={currentThreats} />
        }
      </div>
    </div>
  )
}