import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header, { HeaderActions } from "../shared/Header.js"
import { Container } from '../layout/AppLayout.js'
import FacilityDetailSubmenu from './FacilityDetailSubmenu'
import FileUploaderModal from '../shared/FileUploaderModal.js'
import { DropdownSelector } from '../shared/Filters'
import { useFromQueryString, toQueryString,formatDate } from '../../utils.js'
import { Searchbox } from '../shared/Search.js'
import Spinner from '../shared/Spinner.js'
import { useMutationClient } from '../shared/hooks.js';
import { useMutation,useQuery } from 'react-query'
import useMap from './FacilityFloorplanMap'
import useFeatureChecker, { isAllowed } from '../shared/FeatureChecker.js'
import { CurrentUserContext } from '../../App.js';


function FloorplanSelector({ value, floorplan, disabled, onChange }){
  const defaultItem = value && floorplan.find(x => x.value === value.id)
  
  return (
    <DropdownSelector
      defaultLabel="Floorplans"
      items={floorplan}
      defaultItem={defaultItem}
      onChange={selection => selection ? onChange(selection.value) : onChange(null)}
      disabled={disabled}
      />
  )
}
export default function ZoneFloorplan() {
  const params = useParams()
  const mutationClient = useMutationClient()
  const navigate = useNavigate()
  const [ showForm, setShowForm ] = useState(false)
  const [ZoneId,setZoneId] = useState('')
  const queryString = useFromQueryString();
  const [currentFloorplanId, setCurrentFloorplanId] = useState(parseInt(queryString.FloorplanId) || null)

  const selectedFloorplanQuery = useQuery(`facilities/${params.FacilityId}/floorplan/${currentFloorplanId}`, { enabled: currentFloorplanId !== null })
  const allFloorplansQuery = useQuery(`facilities/${params.FacilityId}/floorplan`)
  const currentFloorplan = selectedFloorplanQuery.data && selectedFloorplanQuery.data.result
  const allFloorplans = allFloorplansQuery.data && allFloorplansQuery.data.result || []
  const CheckFeatures = useFeatureChecker()
  const currentUser = useContext(CurrentUserContext)

  const [ searchSensor, setSensorSearch ] = useState({
    limit: 10,
    search: ''
  })
  const [ searchAsset, setAssetSearch ] = useState({
    limit: 10,
    search:''
  })
  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: ["LIST_FLOORPLAN","UPDATE_FLOORPLAN"]})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const allSensorsQuery = useQuery(`sensors?FacilityId=${params.FacilityId}&${toQueryString(searchSensor)}&${toQueryString(ZoneId)}`)
  const allSensors= allSensorsQuery.data && allSensorsQuery.data.result

  const allAssetsQuery = useQuery(`assets?FacilityId=${params.FacilityId}&${toQueryString(searchAsset)}&${toQueryString(ZoneId)}`)
  const allAssets= allAssetsQuery.data && allAssetsQuery.data.result

  const items = allFloorplans.map(item => ({
    value: item.id,
    label: formatDate(item.created_at)
  }))
 
  useEffect(() => {
    if( !currentFloorplan && !currentFloorplanId && allFloorplans.length ){
      setCurrentFloorplanId(allFloorplans[0].id)
    }
  }, [allFloorplansQuery.isSuccess, currentFloorplan, currentFloorplanId])

  const map= useMap(
    currentFloorplan ? currentFloorplan :null,
    setZoneId
  )

  useEffect(() => {
    navigate('?' + toQueryString({ FloorplanId: currentFloorplanId }), { replace: true })
  }, [ currentFloorplanId ])

  const documentsMutation = useMutation(
    data => mutationClient.post(`facilities/${params.FacilityId}/floorplan`, data),
    {
      onSuccess: () => {
         allFloorplansQuery.refetch().then((_)=> setCurrentFloorplanId(null));
      }
    }
  )

  return (
    <Container>
      <div className="row">
        <Header title="Detalle de zona - Floorplan" items={[
            { label: `Listado de instalaciones`, to: "/facilities" },
            { label: `Detalle de instalación`, to: `/facilities/${params.FacilityId}` },
            { label: `Listado de sva's`,to:`/facilities/${params.FacilityId}/sva` },
            { label: "Floorplan's"}
        ]}>
          <HeaderActions>
            <CheckFeatures feature="CREATE_FLOORPLAN">
              <button
                className="btn btn-sm btn-light"
                onClick={() => setShowForm(true)}
              >+ Subir Floorplan </button>
            </CheckFeatures>
            {
              showForm ?
                <FileUploaderModal
                  title="Subir Floorplan"
                  onClose={() => setShowForm(false)}
                  onSuccess={files => {
                    documentsMutation.mutate({
                      file_key: files.map(x => ({
                        id: x.id,
                        name: x.name,
                        size: x.size,
                        type: x.type,
                        file_key: x.file_key
                      }))
                    })
                  }}
                />
              : null
            } 
          </HeaderActions>
        </Header>
      </div>
      <div className="row">
        <FacilityDetailSubmenu FacilityId={params.FacilityId}/>
      </div>
      <div className="row mb-2 pb-4">
        <div className="col-8 mb-2">
          {
            map.floorplanEl
          }
          <div className='d-flex justify-content-end mt-2'>
            { 
              <FloorplanSelector
                floorplan={items}
                value={currentFloorplan}
                onChange={value => setCurrentFloorplanId(value)}
                disabled={selectedFloorplanQuery.isLoading}
              />
            } 
          </div> 
        </div>
        <div className="col-4">
          <div className="bg-white rounded border">
            <div className="pl-2 pt-2 pr-2">
              <Searchbox          
                placeholder="Filtrar sensores"
                onSearch={s => setSensorSearch(prev => ({ ...prev, search:s }))}
              />
            </div>
            {

              allSensorsQuery.isLoading ?
                <div className="text-center my-4 py-4">
                  <Spinner />
                </div>
              :      
                !allSensors.length ?
                  <div className="text-center text-muted py-4 my-4">No se encontraron sensores</div>
                :
                  <ul className='list-group list-group-flush'>
                    {
                      allSensors.map(item => (
                        <li  onClick={()=>{map.Sensor(item)}} key={item.id} style={{fontSize: "0.9em",cursor:"pointer"}} className="list-group-item list-group-item-action">
                          {item.name+ " - "+item.zone_name}
                        </li>
                      ))
                    }
                  </ul>
            }
          </div>

          <div className="bg-white rounded border mt-2">
            <div className="pl-2 pt-2 pr-2">
              <Searchbox
                placeholder="Filtrar activos"
                onSearch={s => setAssetSearch(prev => ({ ...prev, search:s }))}
              />
            </div>
            {

              allAssetsQuery.isLoading ?
                <div className="text-center my-4 py-4">
                  <Spinner />
                </div>
              :      
                !allAssets.length ?
                  <div className="text-center text-muted py-4 my-4">No se encontraron activos</div>
                :
                  <ul className='list-group list-group-flush'>
                    {
                      allAssets.map(item => (
                        <li  onClick={()=>{map.Asset(item)}} key={item.id} style={{fontSize: "0.9em",cursor:"pointer"}} className="list-group-item list-group-item-action">
                          {item.name+ " - "+item.zone_name}
                        </li>
                      ))
                    }
                  </ul>
            }
          </div>
        </div>
      </div>
    </Container>
  )
}
