import React, { useEffect, useContext } from 'react';
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom';

import { Container } from '../layout/AppLayout.js'

import { useMutationClient } from '../shared/hooks.js'
import { useNotifications } from '../shared/Notifications.js'

import Header from "../shared/Header.js";

import ZoneDetailSubmenu from '../zones/ZoneDetailSubmenu.js';
import AssetForm from './AssetForm.js'
import { isAllowed } from '../shared/FeatureChecker.js';
import {  CurrentUserContext } from '../../App.js';

export default function NewAssetPage() {
  const params = useParams()
  const mutationClient = useMutationClient()
  const navigate = useNavigate()
  const notifications = useNotifications()
  const currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "CREATE_ASSETS"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const mutation = useMutation(
    data => mutationClient.post(`assets`, { ...data, zone_id: params.ZoneId }), {
    onSuccess: (data) => {
      if( data.status === 200 ){
        notifications.success('Activo creado correctamente')
        navigate(`/zones/${params.ZoneId}/assets`)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al crear el activo')
    }
  })

  return (
    <Container>
      <div className="row">
        <Header title="Detalle de zona - Creación de activo" items={[
          { label: `Listado de zonas`, to: "/zones" },
          { label: `Detalle de zona`, to: `/zones/${params.ZoneId}` },
          { label: `Activos`, to: `/zones/${params.ZoneId}/assets` },
          { label: `Agregar activo` },
        ]} />
      </div>

      <div className="row">
        <ZoneDetailSubmenu ZoneId={params.ZoneId}/>
      </div>

      <div className="row">
        <div className="col-6">
          <div className="card shadow-sm">
            <div className="card-header">Nuevo activo</div>
            <div className="card-body">
              <AssetForm
                asset={{}}
                save={data => mutation.mutate(data)}
                isLoading={mutation.isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
