import React, { useEffect, useState } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import { useFromQueryString, toQueryString, TIME_ZONE } from '../../utils.js'
import { useQuery } from 'react-query'
import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row';
import { FilterMenu } from '../shared/Filters.js'
import Pagination from '../shared/Pagination.js'
import Header from "../shared/Header.js"
import Spinner from '../shared/Spinner.js'
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';
import NotificationTable from './NotificationTable.js'

export default function NotificationsListPage(){
  const queryString = useFromQueryString()
  const navigate = useNavigate()
  const CheckFeatures = useFeatureChecker()

  const [ currentFilters, setCurrentFilters ] = useState({
    search: queryString.search || '',
    page: parseInt(queryString.page) || 1,
    limit: parseInt(queryString.limit) || 10,
    sort: queryString.sort || "created_at-desc",
    tz: TIME_ZONE
  })

  const query = useQuery(`notifications?${toQueryString(currentFilters)}`, {
    keepPreviousData: true,
  })
  useEffect(() => {
    navigate('?' + toQueryString(currentFilters), { replace: true })
  }, [currentFilters])

  return (
    <Container>
      <Row>
        <Header title="Notificaciones" items={[
          { label: "Personas a notificar" },
        ]}>
        </Header>
      </Row>
          <Row className="mb-2">
            <FilterMenu
              currentFilters={currentFilters}
              onChange={filters => setCurrentFilters(prev => ({ ...prev, ...filters }))}
            />
          </Row>
        <CheckFeatures feature="ALERT_NOTIFICATION">
          <AllowedFeature>

            <Row className="mb-2">
              <div className="col-12">
                {
                  query.isLoading ?
                    <div className="text-center my-4 py-4">
                      <Spinner />
                    </div>
                  :
                  <NotificationTable notifications={query.data && query.data.result || []} />
                }
              </div>
            </Row>

            <Row className="mt-2">
              <Pagination
                pagination={query.data && query.data.pagination}
                isLoading={query.isFetching}
                onChange={page => setCurrentFilters(prev => ({ ...prev, page }))}
              />
            </Row>
          </AllowedFeature>
          <DeniedFeature>
            <Navigate to={'/error?error=403'}/>
          </DeniedFeature>
       </CheckFeatures>
    </Container>
  )
}
