import React, { useContext, useEffect, useState } from 'react';
import { /* CurrentUserContext, */ StateContext, ActionsContext } from '../../App.js'
import { useQuery, useQueryClient } from 'react-query'

/* import Safe from 'react-safe'
 * import Dropdown from 'react-bootstrap/Dropdown';
 * import Figure from 'react-bootstrap/Figure';
 *  */
import { NavLink,  Link, /* useNavigate */ } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  /* faExclamationTriangle, */
  faUsers,
  faUnlockAlt,
  faClipboardList,
  faMicrochip,
  faPowerOff,
  faVectorSquare,
  faPhotoVideo,
  /* faHardHat, */
  faKey,
  faDoorClosed,
  faIndustry,
  faInfoCircle,
  faCaretDown,
  faCaretUp,
  /* faAddressCard, */
  /* faBell, */
  faEye,
  faBullhorn,
  faBook,
  faMobile,
  faUser,
  faList
} from '@fortawesome/free-solid-svg-icons';

import useFeatureChecker from '../shared/FeatureChecker.js'
import Spinner from '../shared/Spinner.js'

function CompanySelectorItem({ item, isActive, onChange }){
  const [ isHover, setIsHover ] = useState(false)

  const inactiveStyle = {
    backgroundColor: isHover ? '#333' : '#222',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '.9em'
  }

  const activeStyle = {
    backgroundColor: '#333',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '.9em'
  }

  return (
    <Link style={{textDecoration:'none'}} to={`/`}>
      <li
        className={`list-group-item py-2 px-3`}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={isActive ? activeStyle : inactiveStyle}
        onClick={() => onChange(item.id)}
      >{item.name}
   
      </li>
    </Link>
  )
}

function CompanySelector({ CurrentCompanyId, onChange }){
  const query = useQuery(`companies`, { meta: { withoutCompanyId: true } })
  const companies = query.data && query.data.result || []

  if( query.isLoading ){
    return (
      <div className="text-center py-4">
        <Spinner color="#FFFFFF" size={5}/>
      </div>
    )
  }

  return (
    <ul
      className="list-group list-group-flush rounded mb-2"
      style={{ maxHeight: '250px', overflowY: 'scroll' }}
    >
      {
        companies.map(item => (
          <CompanySelectorItem
            key={item.id}
            item={item}
            isActive={CurrentCompanyId === item.id}
            onChange={onChange}
          />
        ))
      }
    </ul>
  )
}

function Header({ currentCompany }) {
  const { SetCompany, SignOut } = useContext(ActionsContext)
  const [ showMenu, setShowMenu ] = useState(false)
  const [ selectedCompanyId, setSelectedCompanyId ] = useState(null)

  const queryClient = useQueryClient()
  /* const navigate = useNavigate()
   */
  const selectedCompanyQuery = useQuery(`companies/${selectedCompanyId}`, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!selectedCompanyId,
    meta: { withoutCompanyId: true }
  })

  useEffect(() => {
    if( selectedCompanyQuery.isSuccess && selectedCompanyId !== currentCompany.id ){
      SetCompany(selectedCompanyQuery.data.result)
      queryClient.invalidateQueries('*')
      setShowMenu(false)
      window.location.reload()
    }
  }, [selectedCompanyQuery.isFetching])

  return (
    <div>
      <h5
        className="d-flex justify-content-between align-items-center text-white font-weight-bold m-0 p-3"
        onClick={() => setShowMenu(!showMenu)}
        style={{ cursor: 'pointer' }}
      >
        { currentCompany ? currentCompany.name : 'Octoberus' }

        <FontAwesomeIcon icon={showMenu ? faCaretUp : faCaretDown } className="ml-1 text-white" />
      </h5>

      {
        showMenu && currentCompany ?
          <CompanySelector
            CurrentCompanyId={currentCompany.id}
            onChange={selected => setSelectedCompanyId(selected)}
          />
        : null
      }
    </div>
  )
}

const MENU_ITEMS = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: faTachometerAlt,
    features: ["LIST_DASHBOARD"]
  },
  {
    name: 'Puertas',
    path: '/doors',
    icon: faDoorClosed,
    features: ["LIST_DOORS", "LIST_PERSONAL_DOORS"]
  },
  {
    name: 'Media',
    path: '/media',
    icon: faPhotoVideo,
    features: ["LIST_MEDIA"]
  },
  {
    name: 'Instalaciones',
    path: '/facilities',
    icon: faIndustry,
    features: ["LIST_FACILITIES"]
  },
  {
    name: 'Zonas',
    path: '/zones',
    icon: faVectorSquare,
    features: ["LIST_ZONES"]
  },
  {
    name: 'Permisos',
    path: '/permissions',
    icon: faKey,
    features: ["LIST_ALL_ACCESS", "LIST_PERSONAL_ACCESS"]
  },
  {
    name: 'Accesos',
    path: '/entries',
    icon: faUnlockAlt,
    features: ["LIST_ENTRIES","LIST_PERSONAL_ENTRIES"]
  },
  {
    name: 'Eventos',
    path: '/events',
    icon: faEye,
    features: ["LIST_EVENTS"]
  },
  {
    name: 'Alertas',
    path: '/alerts',
    icon: faBullhorn,
    features: ["LIST_ALERTS"]
  },
  {
    name: 'Notificaciones',
    path: '/notifications',
    icon: faMobile,
    features: ["ALERT_NOTIFICATION"],
  },
  {
    name: 'Dispositivos',
    path: '/sensors',
    icon: faMicrochip,
    features: ["LIST_SENSORS"]
  },
  {
    name: 'Cambios',
    path: '/revisions',
    icon: faClipboardList,
    features: ["LIST_LOGS"]
  },
  {
    name: 'Usuarios',
    path: '/members',
    icon: faUsers,
    features: ["LIST_USERS"]
  },
  {
    name: 'Compañías',
    path: '/companies',
    icon: faIndustry,
    features: ["LIST_COMPANY"]
  },
  {
    name: 'Lista de macs',
    path: '/mac',
    icon: faList,
    features: ["LIST_DEVICE_INFO"]
  },
  {
    name:'Mi perfil',
    path: '/password/change',
    icon: faUser
  }
]

function MenuItem({ item }){
  const [ isHover, setIsHover ] = useState(false)

  const icon = (
    <FontAwesomeIcon
      icon={item.icon}
      className="mr-2"
      style={{
        width: '30px'
      }}
    />
  )

  const activeStyle = {
    backgroundColor: '#fff',
    fontWeight: 'bold',
    color: '#000'
  }

  const inactiveStyle = {
    backgroundColor: isHover ? '#333' : '#000',
    color: '#fff'
  }

  return (
    <li
      className="nav-item mb-2"
      style={{

      }}
    >
      {
        item.url ?
          <a className="nav-link" href={item.url} target="_BLANK" style={inactiveStyle}>
            { icon }
            <span>{ item.name }</span>
          </a>
        :
          <NavLink
            to={item.path}
            className="nav-link"
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            style={({ isActive }) =>
              isActive ? activeStyle : inactiveStyle
            }
          >
            { icon }
            <span>{ item.name }</span>
          </NavLink>
      }
    </li>
  )
}

function CurrentUserMenu({ currentUser }){
  const { SignOut } = useContext(ActionsContext)

  if( !currentUser ){
    return null
  }

  return (
    <div
      className="text-white rounded d-flex justify-content-between align-items-center p-3 mb-2"
      style={{ backgroundColor: '#111' }}
    >
      <div>
        <div className="font-weight-bold">
          {
            //<Link to="/profile" style={{ color: '#fff' }}>{ currentUser.name }</Link>
          }
          { currentUser.name }
        </div>
        <div className="text-muted">{ currentUser.role_name }</div>
      </div>

      <button
        title="Cerrar sesión"
        className="btn btn-sm btn-dark"
        onClick={() => SignOut()}
      >
        <FontAwesomeIcon icon={faPowerOff} />
      </button>
    </div>
  )
}

export default function Sidebar({ onChange }) {
  const { currentUser, currentCompany } = useContext(StateContext)
  const CheckFeatures = useFeatureChecker()

  return (
    <div className="sidebar ml-3 mr-4">
      <Header currentCompany={currentCompany} />

      <CurrentUserMenu currentUser={currentUser} />

      <nav>
        <ul className="nav nav-pills flex-column">
          {
            MENU_ITEMS.map((item, idx) => (
              <React.Fragment key={idx}>
                {
                  item.features ?
                    <CheckFeatures some={item.features}>
                      <MenuItem item={item} />
                    </CheckFeatures>
                  : <MenuItem item={item} />
                }
              </React.Fragment>
            ))
          }
        </ul>
      </nav>
    </div>
  )
}
