import React, { useState, useEffect } from 'react';
import axios from 'axios'

import { useFromQueryString } from '../../utils.js'
import { Link, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons'

import Spinner from '../shared/Spinner.js'

function useInvitation(token){
  const [ record, setRecord ] = useState(null)
  const [ error, setError ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    async function getInvitation(){
      try{
        const res = await axios.get(`${window.config.API_URL}invitations/${token}`);
        setIsLoading(false)

        if( res.status === 200 ){
          setRecord(res.data.result)
        }else{
          navigate('/login')
        }
      }catch(e){
        setError(e.response.data.error)
        setIsLoading(false)
      }
    }

    if(token){
      setIsLoading(true)
      getInvitation()
    }
  }, [])

  return [record, error, isLoading];
}

function AcceptButton({ token }){
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isSuccess, setIsSuccess ] = useState(null)

  function acceptInvitation(){
    async function accept(){
      try{
        const res = await axios.post(`${window.config.API_URL}invitations/accept`, { token });

        setIsLoading(false)
        if( res.status >= 200 && res.status < 300 ){
          setIsSuccess(true)
        }else{
          setIsSuccess(false)
        }
      }catch(e){
        setIsLoading(false)
        setIsSuccess(false)
      }
    }

    setIsLoading(true)
    accept()
  }

  if( isLoading ){
    return (
      <div className="text-center py-3">
        <Spinner inline={true} />
      </div>
    )
  }else{
    if( isSuccess === null ){
      return (
        <button
          className="btn btn-lg btn-primary btn-block"
          onClick={() => acceptInvitation()}
        >Aceptar invitación</button>
      )
    }else if( isSuccess ){
      return (
        <div className="text-center">
          <div>La invitación fue aceptada correctamente.</div>

          <div className="d-flex justify-content-center align-items-center my-3">
            <a
              href="https://play.google.com/store/apps/details?id=com.octoInc.octo_door"
              className="d-block bg-dark text-white border px-3 mx-3"
              style={{ borderRadius: '15px' }}
            >
              <FontAwesomeIcon icon={faGooglePlay} size="4x" className="my-3" />
              <p>Descargar aplicación para Android</p>
            </a>

            <a
              href="https://apps.apple.com/us/app/octo-door/id1491066941"
              className="d-block bg-dark text-white border px-3 mx-3"
              style={{ borderRadius: '15px' }}
            >
              <FontAwesomeIcon icon={faApple} size="4x" className="my-3" />
              <p>Descargar aplicación para iPhone</p>
            </a>
          </div>

          <Link to={'/login'}>Iniciar sesión en la web</Link>
        </div>
      )
    }else{
      return (
        <p className="alert alert-danger text-center">Ocurrió un error al aceptar la invitación. Por favor intente más tarde o contáctese con el administrador</p>
      )
    }
  }
}

export default function InvitationPage() {
  const queryString = useFromQueryString()
  const navigate = useNavigate()

  const [invitation, invitationError, isLoading] = useInvitation(queryString.t)

  if( !queryString.t ){
    navigate('/login')
  }

  let content = null;

  if( isLoading ){
    content = (
      <div className="text-center py-3">
        <Spinner />
      </div>
    )
  }else{
    if( invitationError ){
      if( invitationError.type === 'NotFound' ){
        content = (
          <p className="alert alert-warning text-center mb-0">
            La invitación ya fue utilizada o no existe. <Link to={'/login'}>Volver al inicio</Link>
          </p>
        )
      }else{
        content = (
          <p className="alert alert-warning text-center mb-0">
            El link de la invitación ha vencido o no es válido.<Link to={'/login'}>Volver al inicio</Link>
          </p>
        )
      }
    }else if(invitation){
      content = (
        <>
          <h4 className="py-2 text-center text-muted">Invitación a {invitation.company_name}</h4>
          <p className="text-justify">Has sido invitado a la cuenta <strong>{invitation.company_name}</strong> puedes aceptar la invitación en el siguiente botón, luego podrás comenzar a utilizar la plataforma de seguridad.</p>
          <AcceptButton token={queryString.t} />
        </>
      )
    }
  }

  return (
    <div className="col-12 col-md-6 col-lg-3 mx-auto">
      <div className="card shadow-sm">
        <div className="card-body">
          { content }
        </div>
      </div>
    </div>
  )
}

