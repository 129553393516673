import React, { useState } from 'react'

import { IndustryTypeSelector } from '../shared/Selectors.js'

export default function CompanyForm({ company, save, isLoading }){
  const [ errors, setErrors ] = useState({})
  const [ name, setName ] = useState(company.name || '')
  const [ industry_id, setIndustry ] = useState(company.industry_id || '')

  function validate(e){
    e.preventDefault()
 
    const validations = []

    if(!name){
      validations.push(['name', 'El nombre es requerido'])
    }

    //if(!industry_id){
      //validations.push(['industry', 'El tipo de industria es requerido'])
    //}

    if( validations.length ){
      return setErrors(validations.reduce((acc, item) => ({...acc, [item[0]]: item[1]}), {}))
    }else{
      setErrors([])

      const data = {
        name,
        industry_id: industry_id ? industry_id : null
      }

      if( company.id ){
        data.id = company.id
      }

      save(data)
    }
  }

  return (
    <form onSubmit={validate} noValidate>
      <div className="form-group">
        <label>Nombre</label>

        <input
          type="text"
          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
          onChange={(x) => setName(x.target.value)}
          value={name}
          autoFocus={true}
          disabled={isLoading}
        />

        <div className="invalid-feedback">{errors.name}</div>
      </div>

      <div className="form-group">
        <label>Industria</label>

        <IndustryTypeSelector
          placeholder="Seleccione tipo industria"
          onChange={value => setIndustry(value)}
          defaultValue={industry_id}
          disabled={isLoading}
          //hasError={!!errors.industry}
        />

        <div className="invalid-feedback">{errors.industry}</div>
      </div>

      <div className="form-group">
        <button className={`btn btn-primary ${isLoading ? 'loading' : ''}`} disabled={isLoading}>
          <span>Guardar</span>
          <div className='spinner-border' role='status'></div>
        </button>
      </div>
    </form>
  )
}

