export default function (eventName) {
  switch (eventName) {
    case "LicensePlateDetected":
      return "Patente Detectada";
    case "PersonDetected":
      return "Persona Detectada";
    case "BycicleDetected":
      return "Bicicleta Detectada";
    case "CameraMotion":
      return "Movimiento Detectado";
    case "AuthorizedEntry":
      return "Acceso Autorizado";
    case "CarDetected":
      return "Vehículo Detectado";
    case "UnauthorizedEntry":
      return "Acceso Denegado";
    case "NoiseStarted":
      return "Inicio Ruido";
    case "NoiseEnded":
      return "Fin Ruido";
    case "PressureChanged":
      return "Cambio Presión Ambiental"
    case "HumidityChanged":
      return "Cambio Humedad Ambiental"
    case "TemperatureChanged":
      return "Cambio Temperatura Ambiental";
    case "DiskUsageChanged":
      return "Cambio en uso del disco";
    case "MagneticOpen":
      return "Magnético Abierto";
    case "MagneticClosed":
      return "Magnético Cerrado";
    case "SmokeStarted":
      return "Inicio de Humo";
    case "SmokeEnded":
      return "Fin de Humo";
    case "LostInternetConnection":
      return "Nodo perdió conexion a internet";
    case "RecoveredInternetConnection":
      return "Nodo recuperó conexion a internet";
    case "AlarmStarted":
      return "Alarma Iniciada";
    case "AlarmEnded":
      return "Alarma Finalizada";
    case "LostEnergy":
      return "Nodo Perdió la Energía"
    case "RecoveredEnergy":
      return "Nodo Recuperó la Energía";
    case "DoorActionEnter":
      return "Relé activado"
    case "DoorActionNoAction":
      return "Relé en reposo"
    case "AuthorizedHttp":
      return "Apertura por Http autorizada"
    case "UnauthorizedHttp":
      return "Apertura por Http no autorizada"
    case "AuthorizedBle":
      return "Apertura/Cierre por BLE autorizada"
    case "UnauthorizedBle":
      return "Apertura/Cierre por BLE no autorizada"
    case "AuthorizedQr":
      return "Apertura por QR autorizada"
    case "UnauthorizedQr":
      return "Apertura por QR no autorizada"
    case "AuthorizedRfid":
      return "Apertura por RFID autorizada"
    case "UnauthorizedRfid":
      return "Apertura por RFID no autorizada"
    case "MotorcycleDetected":
      return "Motocicleta detectada"

    case "ActiveElectromagnet":
      return "Electroiman activado"
    case "DeactivateElectromagnet":
      return "Electroiman desactivado"
    case "NotbusinessDay":
      return "Apertura en día no hábil"
    case "NotbusinessTime":
      return "Apertura fuera de horario laboral"
    case "NotOpen":
      return "No hubo apertura"
    case "AuthorizedButton":
      return "Apertura por botón"
    case "stateChange1To0":
      return "Cambio de estado 1 a 0"
    case "stateChange0To1":
      return "Cambio de estado 0 a 1"
    case "lastEqualsCurrent (door state)": 
      return "Ultimo igual actual  (estado de la puerta)" 
    case "activateBle":
      return "Activar-ble"
    case "deactivateBle":
      return "Desactivar-ble"
    case "deactivateAlarm":
      return "Desactivar Alarma"
    case "activateAlarm":
      return "Activar Alarma"
    case "activateBuzzer":
      return "Activar-buzzer"
    case "deactivateBuzzer":
      return "Desactivar-buzzer"
    case "alarmIsRingingTimeout":
      return "Alarma está sonando, ya paso el tiempo máximo de que suena"
    case "bleOffAndWillTurnOn":
      return "ble apagado y se va a prender"
    case "closedAndAlarmActivated":
      return "Cerrado y alarma activada"
    case "closedAlarmNotActivatedAndNoCycleLimit":
      return "Cerrado , alarma no activada y  no limite de ciclos"
    case "forceActivateClosedCycleLimit":
      return "Forzar activar límite de ciclos cerrados"
    case "alarmTurnedOffAndWillNotRing":
      return "Alarma apagada y no va sonar"      
    default:
      return eventName;
  }
}
