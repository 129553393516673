import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router'
import { useMutation, useQueryClient } from 'react-query'

import { CurrentCompanyContext } from '../../App.js'
import { useNotifications } from '../shared/Notifications.js'

import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { useMutationClient } from '../shared/hooks.js'
import Header from "../shared/Header.js";

import FacilityForm from './FacilityForm.js'

export default function NewFacilityPage() {
  const queryClient = useQueryClient()
  const currentCompany = useContext(CurrentCompanyContext)
  const mutationClient = useMutationClient()
  const navigate = useNavigate()
  const notifications = useNotifications()

  const createFacility = useMutation(
    data => mutationClient.post(`facilities`, { ...data, company_id: currentCompany && currentCompany.id }), {
    onSuccess: (data, variables, context) => {
      if( data.status === 200 ){
        notifications.success('Instalación creada correctamente')
        navigate(`/facilities`)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al la instalación')
    }
  })

  return (
    <Container>
      <Row>
        <Header
          title="Nueva instalación"
          items={[
            { label: `Listado de instalaciones`, to: "/facilities" },
            { label: `Nueva instalación` },
          ]}
        />
      </Row>

      <Row className="mb-3">
        <div className="col-12 mb-2 col-lg-6">
          <div className="card shadow-sm">
            <div className="card-header">Nueva instalación</div>

            <div className="card-body">
              <FacilityForm
                facility={{}}
                currentCompany={currentCompany}
                save={data => createFacility.mutate(data)}
                isLoading={createFacility.isLoading}
              />
            </div>
          </div>
        </div>
      </Row>
    </Container>
  )
}
