import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useQuery } from 'react-query'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from '../../../layout/AppLayout.js';
import { useFromQueryString, toQueryString } from "../../../../utils.js";
import Pagination from '../../../shared/Pagination.js'
import useSearch from '../../../shared/Search.js'
import Spinner from '../../../shared/Spinner.js';
import useFeatureChecker from '../../../shared/FeatureChecker.js'
import { FilterMenu, FacilityFilter, ZoneFilter, SensorTypeFilter } from '../../../shared/Filters.js';
import { CurrentUserContext } from "../../../../App.js";
import { isAllowed } from "../../../shared/FeatureChecker.js";
import SensorsTable from "./SensorsTable.js"

export default function SensorsList(){
  const params = useParams()
  const queryString = useFromQueryString();
  const navigate = useNavigate()
  const currentUser = useContext(CurrentUserContext)

  const [currentFilters, setCurrentFilters] = useState({
    limit: parseInt(queryString.limit) || 25,
    page: parseInt(queryString.page) || 1,
    search: queryString.search || "",
    SensorTypeId: queryString.SensorType || '',
    //DoorId: parseInt(queryString.DoorId) || ''
  });

  const [sortedBy, sortOrder] = currentFilters.sort ? currentFilters.sort.split("-") : [];

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "LIST_SENSORS"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const Searchbox = useSearch(search => {
      if (currentFilters.search !== search) {
        setCurrentFilters({ ...currentFilters, search });
      }
    },
    { value: currentFilters.search }
  );

  const query = useQuery(`sensors?ZoneId=${params.ZoneId}&${toQueryString(currentFilters)}`, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    //refetchOnMount: false,
  })

  useEffect(() => {
    navigate('?' + toQueryString(currentFilters), { replace: true })
  }, [currentFilters]);

  return (
    <Container>
      <Row className="mb-2">
        <FilterMenu
          currentFilters={currentFilters}
          onChange={filters => setCurrentFilters(prev => ({ ...prev, ...filters }))}
        />
      </Row>

      <Row>
        <Col className="col-12 mb-4">
          {
            query.isLoading ?
              <div className="text-center my-4 py-4">
                <Spinner />
              </div>
            :
              <SensorsTable
                sensors={query.data && query.data.result || []}
              />
          }
        </Col>
      </Row>

      <Row className="mt-2">
        <Pagination
          pagination={query.data && query.data.pagination}
          isLoading={query.isFetching}
          onChange={page => setCurrentFilters(prev => ({ ...prev, page }))}
        />
      </Row>
    </Container>
  )
}

