import React, { useState, useEffect, useContext } from 'react'

import { Link, useNavigate } from 'react-router-dom'
import { useFromQueryString, toQueryString } from '../../utils.js'
import { useQuery } from 'react-query'

import { Container } from '../layout/AppLayout.js'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import Pagination from '../shared/Pagination.js'
import { FilterMenu, FacilityFilter } from '../shared/Filters.js';
import Spinner from '../shared/Spinner.js';
import useSearch from '../shared/Search.js'
import Header, { HeaderActions } from "../shared/Header.js";
import useFeatureChecker, { isAllowed } from '../shared/FeatureChecker.js'
import { CurrentUserContext } from '../../App.js'

import ZonesTable from './ZonesTable.js'

const ZONES_STATUSES = [
  { label: 'Todas', value: 'all' },
  { label: 'Activas', value: 'enabled' },
  { label: 'Desactivadas', value: 'disabled' },
]

export default function ZonesListPage() {
  const queryString = useFromQueryString()
  const navigate = useNavigate()
  const CheckFeatures = useFeatureChecker()
  const currentUser = useContext(CurrentUserContext)


  const [currentFilters, setCurrentFilters] = useState({
    page: parseInt(queryString.page) || 1,
    search: queryString.search || '',
    FacilityId: queryString.FacilityId || ''
  })

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "LIST_ZONES"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const query = useQuery(`zones?${toQueryString(currentFilters)}`, {
    keepPreviousData: true,
  })

  useEffect(()=>{
    setCurrentFilters(prevFilters => ({
      ...prevFilters,
      page: 1
    }));
  },[queryString.FacilityId,queryString.search])
  useEffect(() => {
    navigate('?' + toQueryString(currentFilters), { replace: true })
  }, [currentFilters])

  function handleStatusChange(status) {
    if (currentFilters.status !== status) {
      setCurrentFilters({ ...currentFilters, status })
    }
  }

  return (
    <Container>
      <Row>
        <Header title="Zonas" items={[
          { label: `Listado de zonas` },
        ]}>

          <HeaderActions>
            <CheckFeatures feature="CREATE_ZONE">
              <Link to="/zones/new" className="btn btn-primary">Crear zona</Link>
            </CheckFeatures>
          </HeaderActions>
        </Header>
      </Row>

      <Row className="mb-2">
        <FilterMenu
          currentFilters={currentFilters}
          onChange={filters => setCurrentFilters(prev => ({ ...prev, ...filters }))}
        />
      </Row>

      <Row>
        <div className="col-12">
          {
            query.isLoading ?
              <div className="text-center my-4 py-4">
                <Spinner />
              </div>
            :
              <ZonesTable zones={query.data && query.data.result || []} />
          }
        </div>
      </Row>

      <Row className="mt-2">
        <Pagination
          pagination={query.data && query.data.pagination}
          isLoading={query.isFetching}
          onChange={page => setCurrentFilters(prev => ({ ...prev, page }))}
        />
      </Row>
    </Container>
  )
}

