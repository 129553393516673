import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { useFromQueryString, toQueryString } from '../../utils.js'
import { useQuery } from 'react-query'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { formatDate } from '../../utils.js'
import Header, { HeaderActions } from "../shared/Header.js"
import Spinner from '../shared/Spinner.js'
import TableList from '../shared/TableList.js';
import useFeatureChecker, { isAllowed, AllowedFeature, DeniedFeature} from '../shared/FeatureChecker.js'
import { CurrentUserContext } from '../../App.js';

import FacilityDetailSubmenu from '../facilities/FacilityDetailSubmenu.js'

function SvaTable({ items }){
  const CheckFeatures = useFeatureChecker()

  if( !items.length ){
    return (
      <div className="text-center text-muted py-4 my-4">Esta instalación no tiene SVA's</div>
    )
  }

  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="Nombre" />
      </TableList.Header>

      <TableList.Body>
        {
          items.map(item => (
            <TableList.Row key={item.id}>
              <TableList.Column>
                <CheckFeatures feature ='UPDATE_SVA'>
                  <AllowedFeature>
                    <Link
                      className="py-3"
                      to={`/facilities/${item.facility_id}/sva/${item.id}/wizard`}
                    >{ formatDate(item.created_at) }</Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    { formatDate(item.created_at) }
                  </DeniedFeature>
                </CheckFeatures>
                
              </TableList.Column>
            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}

export default function SvaListPage(){
  const params = useParams()
  const queryString = useFromQueryString()
  const navigate = useNavigate()
  const CheckFeatures = useFeatureChecker()
  const currentUser = useContext(CurrentUserContext)

  const [ currentFilters, setCurrentFilters ] = useState({
    page: parseInt(queryString.page) || 1,
  })

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "LIST_SVA"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const query = useQuery(`sva?FacilityId=${params.FacilityId}&${toQueryString(currentFilters)}`, {
    keepPreviousData: true,
  })

  useEffect(() => {
    //dispatch(RevisionsActions.fetchAll(currentFilters))
    //navigate.replace('?' + toQueryString(currentFilters))
  }, [currentFilters])

  return (
    <Container fluid={true}>
      <Row>
        <Header title="Security Vulnerability Assessment" items={[
          { label: `Listado de instalaciones`, to: "/facilities" },
          { label: `Detalle de instalación`, to: `/facilities/${params.FacilityId}` },
          { label: "Listado de sva's" },
        ]}>
          {
            <HeaderActions>
              <CheckFeatures feature="CREATE_SVA">
                <Link
                  to={`/facilities/${params.FacilityId}/sva/new`}
                  className="btn btn-primary"
                >Crear nuevo SVA</Link>
              </CheckFeatures>
            </HeaderActions>
          }
        </Header>
      </Row>

      <Row>
        <FacilityDetailSubmenu FacilityId={params.FacilityId} />
      </Row>

      <Row>
        <div className="col-12 mb-4">
          {
            query.isLoading ?
              <div className="text-center my-4 py-4">
                <Spinner />
              </div>
            :
              <SvaTable
                items={query.data && query.data.result || []}
              />
          }
        </div>
      </Row>
    </Container>
  )
}

