import React from 'react';
import Submenu from "../shared/Submenu.js";

export default function MembersListSubmenu({ children }){
  return (
    <Submenu items={[
      { to: `/members`, label: 'Usuarios', end: true },
      { to: `/members/groups`, label: 'Grupos', features: ['LIST_GROUPS']  },
    ]}/>
  )
}
