import React, { useEffect, useState, useContext,useRef } from "react";
import { useNavigate, Link } from 'react-router-dom'
import { useInfiniteQuery } from 'react-query'

import DatePicker,{registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";
import es from "date-fns/locale/es";
import { format, parseISO, startOfDay, endOfDay, subDays, addDays } from 'date-fns'

import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { defaultQueryFn, StateContext } from '../../App.js'
import Header from "../shared/Header.js"
import Spinner from '../shared/Spinner.js';
import { useFromQueryString, toQueryString, TIME_ZONE } from '../../utils.js'
import { FilterMenu } from '../shared/Filters.js';
import { isAllowed } from "../shared/FeatureChecker.js";
import { CurrentUserContext } from "../../App.js";

import MediaTable from './MediaTable.js'
registerLocale("es",es);

const CustomDateInput = React.forwardRef(({ value, onClick, onChange }, ref) => (
  <button
    className={`btn btn-sm`}
    onClick={onClick}
  >{ value }</button>
));

function MediaDateFilter({ currentDate, isLoading, onChange }){
  const [startDate, setStartDate] = useState(currentDate ? parseISO(currentDate) : new Date());

  useEffect(() => {
    onChange(startDate)
  }, [startDate])

  return (
    <div className="d-flex justify-content-between align-items-center">
      <button
        className="btn btn-sm"
        disabled={isLoading}
        onClick={() => setStartDate(prev => subDays(prev, 1))}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>

      {
        isLoading ?
          <button className="btn btn-sm" disabled>
            { format(startDate, 'dd MMMM yyyy',{locale: es}) }
          </button>
        :
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            customInput={<CustomDateInput />}
            maxDate={new Date()}
            dateFormat="dd MMMM yyyy"
            todayButton="Hoy"
            calendarStartDay={1}
            disabled={isLoading}
            locale="es"
          />
      }


      <button
        className="btn btn-sm"
        disabled={isLoading}
        onClick={() => setStartDate(prev => addDays(prev, 1))}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  )
}

export default function MediaListPage(){
  const queryString = useFromQueryString()
  const navigate = useNavigate()
  const { scrollBottom } = useContext(StateContext)
  const doorRef= useRef(null)
  const facilityRef=useRef(null)
  const zoneRef=useRef(null)
  const currentUser = useContext(CurrentUserContext)

  const [ currentFilters, setCurrentFilters ] = useState({
    DoorId: (queryString.DoorId) || '',
    CameraId: (queryString.CameraId) || '',
    //MediaTagId: parseInt(queryString.MediaTagId) || '',
    //UserId: parseInt(queryString.UserId) || '',
    UserIdMedia: (queryString.UserIdMedia) || '',
    FacilityId: (queryString.FacilityId) || '',
    ZoneId: (queryString.ZoneId) || '',
    start_date: queryString.start_date || startOfDay(new Date()).toISOString(),
    end_date: queryString.end_date || endOfDay(new Date()).toISOString(),
    mimeType:queryString.mimeType || ''
  })

  if(!doorRef.current){
    doorRef.current = currentFilters.DoorId
  }
  if(!facilityRef.current){
    facilityRef.current = currentFilters.FacilityId
  }
  if(!zoneRef.current){
    zoneRef.current = currentFilters.ZoneId
  }
  
  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "LIST_MEDIA"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  useEffect(()=>{
    if(facilityRef.current!==currentFilters.FacilityId){
      facilityRef.current=currentFilters.FacilityId
      setCurrentFilters(prev=>({...prev,DoorId:'',ZoneId:'',CameraId:''}))
    }
    if(zoneRef.current!==currentFilters.ZoneId){
      zoneRef.current=currentFilters.ZoneId
      setCurrentFilters(prev=>({...prev,DoorId:'',CameraId:''}))
    }
    if(doorRef.current!==currentFilters.DoorId){
      doorRef.current=currentFilters.DoorId
      setCurrentFilters(prev=>({...prev,CameraId:''}))
    }
  }
  ,[currentFilters])

  const fetchMedia = ({ pageParam }) => defaultQueryFn({
    queryKey: [`media/new?${toQueryString({
      ...currentFilters,
      page: pageParam,
      tz: TIME_ZONE,
      limit: 20
    })}`]
  })
  

  const query = useInfiniteQuery('mediaList', fetchMedia, {
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      const pagination = lastPage.pagination

      if( pagination.totalPages > pagination.page ){
        return pagination.page + 1
      }
    }
  })

  useEffect(() => {
    navigate('?' + toQueryString(currentFilters), { replace: true })
    query.refetch({ ...currentFilters, pageParam: 1 })
  }, [currentFilters])

/*   useEffect(() => {
    if( scrollBottom && query.hasNextPage ){
      query.fetchNextPage(currentFilters)
    }
  }, [scrollBottom]) */

  function resultsSummary(){
    const pagination = query.data &&
      query.data.pages &&
      query.data.pages.length &&
      query.data.pages[query.data.pages.length - 1].pagination

    if( query.isFetching ){
      return 'Cargando..'
    }

    if(pagination){
      if( pagination.totalItems ){
        return (
          pagination.totalPages === pagination.page ?
            `Mostrando ${pagination.totalItems} de ${pagination.totalItems} resultados`
          :
            `Mostrando ${pagination.page * pagination.limit} de ${pagination.totalItems} resultados`
        )
      }
    }
  }

  return (
    <Container>
      <Row>
        <Header title="Media" items={[
          { label: `Imágenes y videos` }
        ]}>
        </Header>
      </Row>

      <Row className="justify-content-between align-items-center">
        <FilterMenu
          currentFilters={currentFilters}
          onChange={filters => setCurrentFilters(prev => ({ ...prev, ...filters }))}
        >
          <FilterMenu.Left>
            <div className="d-flex justify-content-start align-items-center">
              <MediaDateFilter
                currentDate={currentFilters.start_date}
                onChange={date => setCurrentFilters(prev => {
                  const start_date = startOfDay(date).toISOString()
                  const end_date = endOfDay(date).toISOString()
                  return { ...prev, start_date, end_date }
                })}
                isLoading={query.isFetching}
              />

              <span className="text-muted" style={{ fontSize: '.9em' }}>
                { resultsSummary() }
              </span>
            </div>
          </FilterMenu.Left>
        </FilterMenu>
      </Row>

      <Row>
        <div className="col-12 pt-2 pb-0 mb-2">
          {
            query.isLoading ?
              <div className="text-center py-4 my-4">
                <Spinner />
              </div>
            :
              <MediaTable pages={query.data && query.data.pages || []} />
          }
        </div>
      </Row>

      {
        query.hasNextPage ?
          <Row className="mb-4 justify-content-center">
            <div className="col-auto col-3 mb-4 pb-4">
              <button
                className="btn btn-lg btn-block btn-primary shadow-sm"
                disabled={query.isFetchingNextPage || !query.hasNextPage}
                onClick={() => query.fetchNextPage(currentFilters)}
              >
                {
                  query.isFetchingNextPage ?
                    <Spinner color="#FFFFFF" size={5}/>
                  :
                    'Cargar más'
                }
              </button>
            </div>
          </Row>
        : null
      }
    </Container>
  )
}

