import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import TableList from '../shared/TableList.js';
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';

import './AlertsTable.css'
import translateAlert from './translate.js';

const formatEventDate = (date) => {
  const d = new Date(Date.parse(date))
  const region = "es-CL"
  return `${d.toLocaleDateString(region)} ${d.toLocaleTimeString(region)}`
}

export default function AlertsTable({ alerts }) {
  const CheckFeatures= useFeatureChecker()
  if (!alerts || !alerts.length) {
    return (
      <div className="text-center text-muted py-4 my-4">No se encontraron eventos para los filtros especificados</div>
    )
  }

  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="Id"/>
        <TableList.HeaderItem value="Tipo"/>
        <TableList.HeaderItem value="Fecha"/>
        <TableList.HeaderItem value="Puerta"/>
        <TableList.HeaderItem value="Zona" />
      </TableList.Header>

      <TableList.Body>
        {
          alerts.map(item => (
            <TableList.Row key={item.id}>
              <TableList.Column>
                <CheckFeatures feature="DETAIL_SECURITY_ALERTS">
                  <AllowedFeature>
                    <Link to={`/alerts/${item.id}`}>
                      { item.id }
                    </Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    {item.id}
                  </DeniedFeature>
                </CheckFeatures>
              </TableList.Column>

              <TableList.Column>
                { translateAlert(item.name) }
              </TableList.Column>

              <TableList.Column>
                { item.created_at ? formatEventDate(item.created_at) : '' }
              </TableList.Column>

              <TableList.Column>
                <CheckFeatures feature="DETAIL_DOOR">
                  <AllowedFeature>
                    <Link to={`/doors/${item.door_id}`}>
                      { item.door_name }
                    </Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    { item.door_name }
                  </DeniedFeature>
                </CheckFeatures>
              </TableList.Column>
              <TableList.Column>
                <CheckFeatures feature="DETAIL_ZONE">
                  <AllowedFeature>
                    <Link to={`/zones/${item.zone_id}`}>
                      { item.zone_name }
                    </Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    { item.zone_name }
                  </DeniedFeature>
                </CheckFeatures>
              </TableList.Column>
            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}
