import React, { useState, useEffect } from 'react';
import { Outlet } from "react-router-dom";

export default function UnauthorizedLayout() {
  return (
    <div className="d-flex align-items-center" style={{ height: '100%' }}>
      <Outlet />
    </div>
  )
}

