export default function(alertType,alertState) {
    
    switch (alertType) {
        case "live":
            switch(alertState){
                case 0:
                    return "Online"
                case 1:
                    return "Inestabilidad"
                case 2:
                    return "Offline"
                default:
                    return alertState
            }
        case "live-battery":
            switch(alertState){
                case 3:
                    return "Bajo (< 10V)"
                case 4:
                    return "Media"
                case 5:
                    return "Alto (>11 V)"
                default:
                    return alertState
            }
        case "live-alarmState":
            switch(alertState){
                case 6:
                    return "Alarma está sonando"
                case 7:
                    return "Alarma activada"
                case 8:
                    return "Alarma desactivada"
                default:
                    return alertState
            }
        case "live-signal":
            switch(alertState){
                case 10:
                    return "Error"
                case 11:
                    return "Marginal"
                case 12:
                    return "Ok"
                case 13:
                    return "Good"
                case 14:
                    return "Excellent"    
            }
        case "battery[Entel]":
            switch(alertState){
                case 100:
                    return "B < 10V"
                case 101: 
                    return "10 < B < 11"
                case 102:
                    return "B > 11"
                case 103:
                    return "Error"
            }
        case "alarm[Entel]":
            switch (alertState){
                case 104:
                    return "Alarma activada"
                case 105: 
                    return "Alarma desactivada"
                case 106:
                    return "Alarma está sonando"
                case 107:
                    return "Error"
            }
        case "signal[Entel]":
            switch (alertState){
                case 108:
                    return "Error"
                case 109:
                    return "Marginal"
                case 110:
                    return "Ok"
                case 111:
                    return "Good"
                case 112:
                    return "Excelente"
                case 113:
                    return "Error"
            }
        case "state-door[Entel]":
            switch (alertState){
                case 114:
                    return "Cerrada"
                case 115:
                    return "Abierta"
                case 116:
                    return "Error"
            }
      default:
        return alertState;
    }
  }
  