import React, { useEffect, useState, useContext } from "react";
import FileSaver from 'file-saver';
import { useNavigate,useLocation } from "react-router-dom";
import { useQuery, useInfiniteQuery } from 'react-query'
import { CurrentCompanyContext, defaultQueryFn, StateContext } from '../../App.js'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import Header from "../shared/Header.js"
import Spinner from "../shared/Spinner.js";

import { useMutationClient } from '../shared/hooks.js'
import { useFromQueryString, toQueryString, TIME_ZONE } from "../../utils.js";
import { FilterMenu } from '../shared/Filters.js';
import { isAllowed } from "../shared/FeatureChecker.js";
import { CurrentUserContext } from "../../App.js";
import { Container } from '../layout/AppLayout.js';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import LogsTable from "../logs/LogsTable.js";


function LogsDownloadButton({ filters }) {
  const [isLoading, setIsLoading] = useState(false)
  const mutationClient = useMutationClient()
  const currentCompany = useContext(CurrentCompanyContext)

  useEffect(() => {
    async function download() {
      const res = await mutationClient.get(`alertevents/entries?download=true&${toQueryString(filters)}&CompanyId=${currentCompany.id}`)
      setIsLoading(false)

      if (res.status === 200) {
        var blob = new Blob([res.data], { type: "text/plain;charset=utf-8" });
        FileSaver.saveAs(blob, `eventos.csv`);
      } else {
        console.log('Error al descargar archivo');
      }
    }

    if (isLoading) {
      download()
    }
  }, [isLoading])

  return (
    <button
      className={`btn btn-primary d-none d-lg-inline-block ${isLoading ? "loading" : ""}`}
      onClick={() => setIsLoading(true)}
      disabled={isLoading}
    >
      <FontAwesomeIcon icon={faDownload} className="mr-2" />
      {
        isLoading ?
          <span>Generando reporte...</span>
          :
          <span>Descargar CSV2</span>
      }
    </button>
  )
}

export default function LogsListPage({ isFull = false }) {
  const queryString = useFromQueryString();
  const navigate = useNavigate();
  const filterDoorLabel = `Filtrar por puerta`
  const { scrollBottom } = useContext(StateContext)
  const location = useLocation();
  const currentUser = useContext(CurrentUserContext)

  const [openFilter, setOpenFilter] = useState(false)
  
  const initialFilters = {
    limit: parseInt(queryString.limit) || 15,
    search: queryString.search || "",
    sort: queryString.sort || "created_at-desc",
    start_date: queryString.start_date || '',
    end_date: queryString.end_date || '',
    UserId: (queryString.UserId) || '',
    DoorId: (queryString.DoorId) || '',
    ZoneId: (queryString.ZoneId) || '',
    FacilityId: (queryString.FacilityId) || '',    
    tz: TIME_ZONE
  };
  

  //SOLO AUTORIZADOS
  const [currentFilters, setCurrentFilters] = useState(initialFilters);

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,some: ["LIST_ENTRIES","LIST_PERSONAL_ENTRIES"]})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  useEffect(() => {
      setCurrentFilters(initialFilters);
  }, [location["pathname"]]);

  const fetchEntry = ({ pageParam }) => defaultQueryFn({
    queryKey: [`alertevents/entries?${toQueryString({
      ...currentFilters,
      page: pageParam,
      tz: TIME_ZONE,
      limit: 15,
      state: isFull ? null : 1,
    })}`]
  })
  const query = useInfiniteQuery('EntryList', fetchEntry, {
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      const pagination = lastPage.pagination

      if (pagination.totalPages > pagination.page) {
        return pagination.page + 1
      }
    }
  })

  useEffect(() => {
    navigate('?' + toQueryString(currentFilters), { replace: true })
    query.refetch({ ...currentFilters, pageParam: 1 })
  }, [currentFilters])

  useEffect(() => {
    if (scrollBottom && query.hasNextPage) {
      query.fetchNextPage(currentFilters)
    }
  }, [scrollBottom])

  return (
    <Container>
      <Row>
        <Header title={isFull ? "Instrucciones de aperturas" : "Accesos"} items={[
          { label: isFull ? "Listado de instrucción de apertura" : "Listado de accesos" }
        ]}>
          {/* <HeaderActions>
            <LogsDownloadButton filters={currentFilters} />
          </HeaderActions> */}
        </Header>
      </Row>

      <Row className="mb-2">
        <FilterMenu
          currentFilters={currentFilters}
          onChange={filters => setCurrentFilters(prev => ({ ...prev, ...filters }))}
          dateRangeFilter
        />
      </Row>

      <Row className="mb-2">
        <Col className="col-12">
          {
            query.isLoading ?
              <div className="text-center my-4 py-4">
                <Spinner />
              </div>
              : <LogsTable logs={query.data && query.data.pages || []} />
          }
        </Col>
      </Row>

      {
        query.hasNextPage ?
          <Row className="mb-4 justify-content-center">
            <div className="col-auto col-3 mb-4 pb-4">
              <button
                className="btn btn-lg btn-block btn-primary shadow-sm"
                disabled={query.isFetchingNextPage || !query.hasNextPage}
                onClick={() => query.fetchNextPage(currentFilters)}
              >
                {
                  query.isFetchingNextPage ?
                    <Spinner color="#FFFFFF" size={5} />
                    :
                    'Cargar más'
                }
              </button>
            </div>
          </Row>
          : null
      }
    </Container>
  );
}
