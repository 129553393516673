import React, { useState, useContext, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNotifications } from '../shared/Notifications.js';
import Spinner from '../shared/Spinner.js';
import { useMutationClient } from '../shared/hooks.js';
import { CurrentUserContext } from '../../App.js';
import useFeatureChecker, { isAllowed, AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';

function ZoneListItem({ item, selected, removeItem, addItem }) {
    const CheckFeatures = useFeatureChecker()
    return (
      <div className="border-top-0 border-left-0 border-right-0 list-group-item d-flex justify-content-between align-items-center">
        <label className="m-0" style={{ cursor: 'pointer' }}>
          <CheckFeatures every={["EDIT_ROLE_ZONES"]}>
            <AllowedFeature>
              <input
                type="checkbox"
                className="mr-2"
                style={{ cursor: 'pointer' }}
                defaultChecked={selected}
                onChange={(e) => {
                  if (e.target.checked) {
                    addItem(item)
                  } else {
                    removeItem(item)
                  }
                }}
              />
            </AllowedFeature>
            <DeniedFeature>
              <input
                type="checkbox"
                className="mr-2"
                style={{ cursor: 'pointer' }}
                defaultChecked={selected}
                disabled={true}
              />
            </DeniedFeature>
          </CheckFeatures>
          <span>{item.name} - {item.facility_name ?? "Sin instalación"}</span>
        </label>
  
        <span>{item.zone_name}</span>
      </div>
    )
  }
  
  export default function RoleZoneList({ currentRole }) {
    const CheckFeatures = useFeatureChecker()
    const queryClient = useQueryClient()
    const mutationClient = useMutationClient()
    const notifications = useNotifications()
    const currentUser = useContext(CurrentUserContext)
    const query = useQuery(`zones?limit=1000`)
    const zones = query.data && query.data.result || []
    const [enablePassword,setEnablePassword]= useState(currentRole.zones.length>0)

    const addZone = useMutation(
      data => mutationClient.post(`roles/${currentRole.id}/zones?CompanyId=${currentRole.company_id}`, data),
      {
        onSuccess: (data) => {
          notifications.success('Zona agregada exitosamiente')
          queryClient.invalidateQueries(`roles/${currentRole.id}`)
        }
      }
    )
  
    const removeZone = useMutation(
      data => mutationClient.delete(`roles/${currentRole.id}/zones/${data.ZoneId}?CompanyId=${currentRole.company_id}`),
      {
        onSuccess: (data) => {
          notifications.success('Zona eliminada exitosamente')
          queryClient.invalidateQueries(`roles/${currentRole.id}`)
        }
      }
    )
  
    function isSelected(item) {
      const found = currentRole.zones && currentRole.zones.some((data) => data.zone_id === item.id);
      return found
    }

    
    const handleChange= event=>{
      setEnablePassword(current=> !current);
    }
  

    return (
      <div key={"zones"}>
        <div className="bg-light rounded border py-2 px-3 mb-1">
          <div className="form-group">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={enablePassword}
                onChange={handleChange}
                id={`passwordCheck-zones`}
                disabled={currentUser &&!isAllowed({user: currentUser,feature: "LIST_ROLE_ZONES" })}
              />
              <label className="form-check-label" htmlFor={`passwordCheck-zones`}>
                Limitar zonas del rol
              </label>
            </div>
          </div>
        </div>

        <div className="list-group" style={{ maxHeight: '500px', overflowY: 'auto' }}>
          {enablePassword ? (
            query.isLoading ? (
              <div className="text-center my-4 py-4">
                <Spinner />
              </div>
            ) : zones.length ? (
              zones.map(item => (
                <ZoneListItem
                  key={item.id}
                  item={item}
                  selected={isSelected(item)}
                  addItem={(item) => addZone.mutate({ zone_id: item.id })}
                  removeItem={(item) => removeZone.mutate({ ZoneId: item.id })}
                />
              ))
            ) : (
              <div className="text-muted text-center my-4 py-4">No se encontraron zonas</div>
            )
          ) : null}
        </div>
      </div>

    )
  }
  