import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useFromQueryString, toQueryString } from '../../utils.js'
import { useQuery } from 'react-query'

import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row';

import Spinner from '../shared/Spinner.js'
import Pagination from '../shared/Pagination.js'
import Header, { HeaderActions } from "../shared/Header.js"
import { FilterMenu } from '../shared/Filters.js';
import useFeatureChecker, { isAllowed } from '../shared/FeatureChecker.js'
import { CurrentUserContext } from '../../App.js';

import FacilitiesTable from './FacilitiesTable.js'

export default function FacilitiesListPage(){
  const queryString = useFromQueryString()
  const navigate = useNavigate()
  const CheckFeatures = useFeatureChecker()
  const currentUser = useContext(CurrentUserContext)

  const [ currentFilters, setCurrentFilters ] = useState({
    page: parseInt(queryString.page) || 1,
    search: queryString.search || ''
  })

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "LIST_FACILITIES"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const query = useQuery(`facilities?${toQueryString(currentFilters)}`, {
    keepPreviousData: true,
  })

  useEffect(() => {
    navigate('?' + toQueryString(currentFilters), { replace: true })
  }, [currentFilters])

  return (
    <Container>
      <Row>
        <Header title="Instalaciones" items={[
          { label: "Listado de Instalaciones" },
        ]}>
          {
            <HeaderActions>
              <CheckFeatures feature="CREATE_FACILITY">
                <Link to="/facilities/new" className="btn btn-primary">Nueva instalación</Link>
              </CheckFeatures>
            </HeaderActions>
          }
        </Header>
      </Row>

      <Row className="mb-2">
        <FilterMenu
          currentFilters={currentFilters}
          onChange={filters => setCurrentFilters(prev => ({ ...prev, ...filters }))}
        />
      </Row>

      <Row className="mb-2">
        <div className="col-12">
          {
            query.isLoading ?
              <div className="text-center my-4 py-4">
                <Spinner />
              </div>
            :
              <FacilitiesTable
                facilities={query.data && query.data.result || []}
                isLoading={query.isLoading}
              />
          }
        </div>
      </Row>

      <Row className="mt-2">
        <Pagination
          pagination={query.data && query.data.pagination}
          isLoading={query.isFetching}
          onChange={page => setCurrentFilters(prev => ({ ...prev, page }))}
        />
      </Row>
    </Container>
  )
}

