import React, { useEffect, useState, useContext } from "react";
import { useMutation, useQuery } from 'react-query';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import { toQueryString } from '../../utils.js';
import { useNotifications } from '../shared/Notifications.js';
import { useMutationClient } from '../shared/hooks.js';
import { CurrentUserContext } from "../../App.js";

import Row from 'react-bootstrap/Row';
import { Container } from '../layout/AppLayout.js';

import useFeatureChecker from '../shared/FeatureChecker.js';
import Header, { HeaderActions } from "../shared/Header.js";

import OptionSelector from '../shared/OptionSelector.js';
import { Searchbox } from '../shared/Search.js';
import Spinner from '../shared/Spinner.js';

import CompanyDetailSubmenu from '../companies/CompanyDetailSubmenu.js';

import { AllowedFeature, DeniedFeature, isAllowed } from '../shared/FeatureChecker.js';
import SubCardFeature from './SubCardFeature.js';
import RoleZoneList from './RoleZoneList.js';
import RoleFacilityList from './RoleFacilityList.js';

function RoleFormCard({ currentRole }) {
  const [name, setName] = useState(currentRole.name || '')

  return (
    <div className="card shadow-sm">
      <div className="card-header">Edición de rol</div>

      <div className="card-body">
        <div className="row form-group">
          <div className="col-6">
            <label>Nombre</label>
            <input
              type="text"
              className={`form-control`}
              onChange={e => setName(e.target.value)}
              value={name}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const FEATURES_FILTERS = [
  { value: 'all', label: 'Todas las funcionalidades', description: 'Mostrar todas las funcionalidades' },
  { value: 'selected', label: 'Funcionalidades asignadas', description: 'Mostrar solo las funcionalidades asignadas a este rol' },
  { value: 'unselected', label: 'Funcionalidades sin asignar', description: 'Mostrar funcionalidades sin asignar a este rol' },
]

function FeaturesCard({ currentRole, isEdit }) {
  
  const [filteredFeatures, setFilteredFeatures] = useState([])
  const [currentFilters, setCurrentFilters] = useState({
    type: 'selected',
    search: ''
  })

  const query = useQuery(`roles/features?${toQueryString(currentFilters)}`, {
    keepPreviousData: true
  })

  const selectedFeaturesQuery = useQuery(`roles/features?RoleId=${currentRole.id}`, { enabled: !!currentRole })
  const features = query.data && query.data.result || []

  const featuresByModule = filteredFeatures.reduce((acc, item) => {
    if (!acc[item.module]) {
      acc[item.module] = []
    }

    acc[item.module].push(item)
    return acc
  }, {})

  const selectedFeatures = selectedFeaturesQuery.data && selectedFeaturesQuery.data.result || []
  
  function isSelected(FeatureId) {
    return selectedFeatures.filter(x => x.id === FeatureId).length
  }

  useEffect(() => {
    if (features.length && selectedFeatures.length) {
      if (currentFilters.type === 'selected') {
        setFilteredFeatures(features.filter(x => isSelected(x.id)))
      } else if (currentFilters.type === 'unselected') {
        setFilteredFeatures(features.filter(x => !isSelected(x.id)))
      } else {
        setFilteredFeatures(features)
      }
    }
    else if (features.length && !selectedFeatures.length){
      if (currentFilters.type === 'selected') {
        setFilteredFeatures(features.filter(x => isSelected(x.id)))
      } else if (currentFilters.type === 'unselected') {
        setFilteredFeatures(features.filter(x => !isSelected(x.id)))
      } else {
        setFilteredFeatures(features)
      }
    }
  }, [currentFilters.type, selectedFeatures, features])


  return (
    <div className="mt-3">
      <div className="row justify-content-between mb-3">
        <div className="col-3">
          <div className="d-flex justify-content-start align-items-center">
            <Searchbox
              placeholder="Buscar funcionalidades"
              onSearch={search => setCurrentFilters(prev => ({ ...prev, search }))}
            />
            <div className="ml-2">
              <Spinner inline show={query.isFetching} />
            </div>
          </div>
        </div>

        <div className="col-6 text-right">
          <OptionSelector
            options={FEATURES_FILTERS}
            type='dropdown'
            selected={currentFilters.type}
            onChange={type => {
              if (type !== currentFilters.type) {
                setCurrentFilters(prev => ({ ...prev, type }))
              }
            }}
          />
        </div>
      </div>

      {
        query.isLoading ?
          <div className="col-12 my-4 py-4">
            <Spinner />
          </div>
          :
          Object.keys(featuresByModule).map(moduleName => (
            <div key={moduleName}>
              <div className="bg-light rounded border py-2 px-3 mb-1">{moduleName}</div>

              <div className="list-group mb-4">
                {
                  featuresByModule[moduleName].map(item => < SubCardFeature key={`subCardFeature-${item.id}`} id={item.id} isSelected={isSelected(item.id)} name={item.name} isEdit={isEdit} />)
                }
              </div>
            </div>
          ))
      }
    </div>
  )
  }

function RoleDetailCard({ currentRole,save,isLoading }) {

  const [ errors, setErrors ] = useState({})
  const [ name, setName ] = useState(currentRole.name)
  const [ level, setLevel ] = useState(currentRole.level)
  const CheckFeatures = useFeatureChecker()

  function validate(e){
    e.preventDefault()
 
    const validations = []

    if(!name){
      validations.push(['name', 'El nombre es requerido'])
    }
    if(!level){
      validations.push(['level', 'El nivel es requerido'])
    }
    if(level <1){
      validations.push (['level', 'El nivel debe ser mayor a 0'])
    }

    if( validations.length ){
      return setErrors(validations.reduce((acc, item) => ({...acc, [item[0]]: item[1]}), {}))
    }else{
      setErrors([])
      const data = {
        name,
        level
      }
      save(data)
    }
  }

  return (
    <form onSubmit={validate} noValidate>
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="font-weight-bold">Nombre del rol</label>
                <input
                  type="text"
                  className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                  onChange={(x) => setName(x.target.value)}
                  value={name}
                  disabled={isLoading}
                />
                <div className="invalid-feedback">{errors.name}</div>
            </div>
          </div>
         

          <div className="col-md-6">
            <div className="form-group">
              <label className="font-weight-bold">Nivel</label>
              <CheckFeatures feature="UPDATE_ROLE">
                <AllowedFeature>
                  <input
                    type='number'
                    className={`form-control ${errors.level ? 'is-invalid' : ''}`}
                    onChange={
                      (x) => {
                        let value = parseInt(x.target.value, 10);
                        if (value < 0) {
                          value = 1;
                        }
                        setLevel(value);
                      }
                    }
                    value={level}
                    disabled={isLoading}
                  />
                </AllowedFeature>
                <DeniedFeature>
                  <input
                    type='number'
                    className={`form-control ${errors.level ? 'is-invalid' : ''}`}
                    onChange={(x) => setLevel(parseInt(x.target.value, 10))}
                    value={level}
                    disabled={true}
                  />
                </DeniedFeature>
              </CheckFeatures>
              

              
              <div className="invalid-feedback">{errors.level}</div>
            </div>
          </div>
        </div>
       

        <CheckFeatures feature='UPDATE_ROLE'>
          <button className={`btn btn-primary ${isLoading ? 'loading' : ''}`} disabled={isLoading}>
            <span>Guardar</span>
            <div className='spinner-border' role='status'></div>
          </button>
        </CheckFeatures>
      </div>
    </div>
    </form>
  )
}

export default function RoleDetailPage() {
  const params = useParams()
  const CheckFeatures = useFeatureChecker()
  const [isEdit, setEdit] = useState(false)
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const mutationClient = useMutationClient()
  const notifications = useNotifications()
  const currentUser = useContext(CurrentUserContext)
  const navigate = useNavigate()

  const query = useQuery(`roles/${params.RoleId}`, {
    refetchOnMount: false
  })

  const currentRole = query.data && query.data.result || {}

  const handleEditToggle = () => {
    setEdit(prev => !prev);
    setShouldRefresh(true); 
  };

  useEffect(() => {
    if (shouldRefresh && !isEdit) {
      window.location.reload(); 
      setShouldRefresh(false); 
    }
  }, [shouldRefresh, isEdit]);

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "LIST_FEATURES"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const updateRole = useMutation(
    data => mutationClient.put(`roles/${params.RoleId}`, data), {
    onSuccess: (data) => {
      if (data.status === 200) {
        notifications.success('Rol actualizado correctamente')
        window.location.reload(); 
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al actualizar el rol')
    }
  })


  return (
    <Container>
      <Row>
        <Header title="Detalle de compañía - Roles" items={[
          { label: `Listado de compañías`, to: "/companies" },
          { label: `Detalle de compañía`, to: `/companies/${params.CompanyId}` },
          { label: `Listado de roles`, to: `/companies/${params.CompanyId}/roles` },
          { label: `Detalles del rol` }
        ]}>

          <HeaderActions> 
          <CheckFeatures every={["UPDATE_ROLE_FEATURES","LIST_FEATURES"]}>
              <button
                className={`btn btn-primary d-none d-lg-inline-block `}
                onClick={handleEditToggle}
              >
                <span>
                  {isEdit ? "Deshabilitar el editar" : "Habilitar el editar"}
                </span>
              </button>
            </CheckFeatures>

          </HeaderActions>

        </Header>
      </Row>

      <CheckFeatures feature='DETAIL_COMPANY'>
        <div className="row">
          <CompanyDetailSubmenu CompanyId={params.CompanyId} />
        </div>
      </CheckFeatures>

      {
        query.isLoading ?
          <div className="text-center my-4 py-4">
            <Spinner />
          </div>
          :
          <Row>
            <div className="col-12">
              <RoleDetailCard currentRole={currentRole} save={data => updateRole.mutate(data)} isLoading={updateRole.isLoading}/>
            </div>
              <div  className={`col-12 mt-3 ${currentUser &&!isAllowed({user: currentUser,feature: "LIST_ROLE_FACILITIES" })?'d-none' : ''}`}>
                <RoleFacilityList currentRole={currentRole}/>
              </div>
              <div className={`col-12 mt-3 ${currentUser &&!isAllowed({user: currentUser,feature: "LIST_ROLE_ZONES" })?'d-none' : ''}`}>
                <RoleZoneList currentRole={currentRole}/>
              </div>
            
            <div className="col-12 mt-3">
              <FeaturesCard currentRole={currentRole} isEdit={isEdit} />
            </div>
          </Row>
      }
    </Container>
  )
}

