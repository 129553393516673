import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils.js';
import translate from './translate.js';


import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';
import TableList from '../shared/TableList.js';

export default   function RevisionsTable({ revisions }) {
  const CheckFeatures = useFeatureChecker()

  if(!revisions.length) {
    return <div className="not-found-table-items">No se encontraron cambios</div>
  }

  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="Fecha" />
        <TableList.HeaderItem value="Autor"  />
        <TableList.HeaderItem value="Cambio"  />
        <TableList.HeaderItem value="Tipo de cambio"/>
      </TableList.Header>

      <TableList.Body>
        {
          revisions.map(item => (
            <TableList.Row key={item.id}>
              <TableList.Column>
                <CheckFeatures feature='DETAIL_LOGS'>
                  <AllowedFeature>
                    <Link to={`/revisions/${item.id}`}>
                      {formatDate(item.created_at)}
                    </Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    {formatDate(item.created_at)}
                  </DeniedFeature>
                </CheckFeatures>
              </TableList.Column>

              <TableList.Column> 
                  {item.author_id ?
                  <CheckFeatures feature="DETAIL_USER">
                    <AllowedFeature>
                      <Link to={"/members/" + item.author_id}> {item.author_name} </Link>
                    </AllowedFeature>
                    <DeniedFeature>
                      <span>{item.author_name}</span>
                    </DeniedFeature>
                  </CheckFeatures>
                  : 'No encontrado'}
                </TableList.Column> 

          
              <TableList.Column value={item.entity_type}/>
              <TableList.Column value= {translate(item.action)}/>
            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}
