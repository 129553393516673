import React, { useEffect, useContext} from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useNotifications } from '../shared/Notifications.js'

import { Container } from '../layout/AppLayout.js'

import Header, { HeaderActions } from "../shared/Header.js"
import Spinner from '../shared/Spinner.js';
import useFeatureChecker, { isAllowed } from '../shared/FeatureChecker.js';
import { CurrentUserContext } from '../../App.js';


import alertConnection from './alerts_connection.js';


const formatAlertDate = (date) => {
  const d = new Date(Date.parse(date))
  const region = "es-CL"
  return `${d.toLocaleDateString(region)} ${d.toLocaleTimeString(region)}`
}

function EventsCard({  changes, variable }) {


  const Table = (changes,variable) => {
    return (
      <table className="table mb-0" style={{ fontSize: '0.9em' }}>
        <thead>
          <tr>
            <th>
              Fecha
            </th>

            <th>
              Estado anterior
            </th>

            <th>
              Estado nuevo
            </th>
          </tr>
        </thead>

        <tbody>
          {
            changes.map(item => (
              <tr key={item.id}>
                
                <td>
                  { formatAlertDate(item.created_at) }
                </td>
                <td>
                  {alertConnection(variable,parseInt(item.prev_state )) }
                </td>
                <td>
                  { alertConnection(variable,parseInt(item.current_state)) }
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    )
  }

  const NoEventsMessage = () => {
    return (
      <div className="card-body">
        <p>
          No se encontraron eventos asociados a esta alerta
        </p>
      </div>
    )
  }

  const Loading = () => {
    return (
      <div className="card-body">
        <Spinner/>
      </div>
    )
  }

  return (
    <div className="card shadow-sm mb-3">
      <div className="card-header">Cambios de estados de alerta</div>

      { changes ? changes.length > 0
                               ? Table(changes,variable)
                               : NoEventsMessage()
                               : Loading() }
    </div>
  )
}

function DetailsCard({ alert }){
  return (
    <div className="card shadow-sm mb-3">
      <div className="card-header">Detalle de alerta</div>

      <div className="card-body">
        { alert ? (
            <>
              <div className="form-group">
                <label className="form-label font-weight-bold">Nombre</label>
                <p>
                  { alert.name }
                </p>
              </div>
              <div className="form-group">
                <label className="form-label font-weight-bold">Estado</label>
                <p>
                  { alertConnection(alert.variable,alert.state) }
                </p>
              </div>

              <div className="form-group">
                <label className="form-label font-weight-bold">Sensor</label>
                <p>
                  { alert.sensor_name }
                </p>
              </div>
              <div className="form-group">
                <label className="form-label font-weight-bold">Zona</label>
                <p>
                  { alert.zone_name}
                </p>
              </div>
              { alert.updated_at ? (
                  <div className="form-group">
                    <label className="form-label font-weight-bold">Fecha de actualización</label>
                    <p>
                      { formatAlertDate(alert.updated_at) }
                    </p>
                  </div>
              )
                : null
              }
            </>
        ) : <Spinner/> }
      </div>
    </div>
  )
}

export default function AlertConnectionDetailtPage() {
  const params = useParams()

  const AlertId = params.AlertId;
  const query = useQuery(`alertstate/${AlertId}`)
  const alert = query.isSuccess && query.data && query.data.result || null

  const query_changes = useQuery(`alertstate/${AlertId}/changes`)
  const changes = query_changes.isSuccess && query_changes.data && query_changes.data.result || null
  const currentUser = useContext(CurrentUserContext)
  const navigate = useNavigate()
  const CheckFeatures = useFeatureChecker()

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "DETAIL_STATE_ALERTS"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const notifications = useNotifications()

  useEffect(() => {
    if( !query.isLoading && query.error  && query.error.response && query.error.response.status==403){
        //notifications.warning('Acceso no autorizado')
        navigate(`/error?error=403`)
    }
  }, [ query.isLoading])

  return (
    <Container>
      <div className="row">
        <Header title="Detalle de alerta" items={[
          { label: `Listado de alertas`, to: "/alerts/connection" },
          { label: `Detalle de alerta` },
        ]}>
          <HeaderActions>
          </HeaderActions>
        </Header>
      </div>

      <div className="row">
      </div>

      <div className="row mb-2 pb-4">
        <div className="col-12 mb-2 col-lg-4">
          <DetailsCard alert={alert} />
        </div>

        {
          <div className="col-12 mb-2 col-lg-8">
            <CheckFeatures feature='LIST_STATE_ALERTS_CHANGES'>
              <EventsCard changes={changes ? changes : null} variable={alert? alert.variable : null} />
            </CheckFeatures>
          </div>
        }
      </div>
    </Container>
  )
}
