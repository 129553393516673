import React from 'react';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { useMutationClient } from '../shared/hooks.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons'

import { useNotifications } from '../shared/Notifications.js'

const MySwal = withReactContent(Swal)

export function useOpenDoor(){
  const mutationClient = useMutationClient()

  const mutation = useMutation(
    data => mutationClient.post(`doors/${data.DoorId}/open`, data),
    {
      onSuccess: () => {
        MySwal.fire(
          '¡Apertura en proceso!',
          'Se ha enviado el comando de apertura',
          'success'
        )
      },
      onError: () => {
        MySwal.fire(
          '¡Apertura fallida!',
          'Ha ocurrido un error al realizar la apertura. Verifique la validez del permiso.',
          'error'
        )
      }
    }
  )

  return {
    confirm: ({ door, position = {} }) => {
      MySwal.fire({
        title: `¿Está seguro que desea abrir la puerta "${door.name}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar apertura',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          mutation.mutate({
            DoorId: door.id,
            position
          })
        }
      })
    }
  }
}

export function PostButton({
  label,
  className = '',
  confirmMessage,
  successMessage,
  errorMessage,
  post,
  invalidate,
  refetch,
  redirect,
  reload,
}){
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const mutationClient = useMutationClient()
  const notifications = useNotifications()

  const mutation = useMutation(
    () => mutationClient.post(post),
    {
      onSuccess: () => {
        if( successMessage ){
          notifications.success(successMessage)
        }

        if( invalidate ){
          queryClient.invalidateQueries(invalidate)
        }

        if( redirect ){
          navigate(redirect)
        }

        if( refetch ){
          refetch()
        }

        if( reload ){
          window.location.reload()
        }
      },
      onError: () => {
        if( errorMessage ){
          notifications.danger(errorMessage)
        }
      }
    }
  )

  function buttonHandler(){
    if( confirmMessage ){
      MySwal.fire({
        title: confirmMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          mutation.mutate()
        }
      })
    }else{
      mutation.mutate()
    }
  }

  return (
    <button
      className={`btn ${className}`}
      onClick={() => buttonHandler()}
      disabled={mutation.isLoading}
    >
      { mutation.isLoading ? 'Cargando...' : label }
    </button>
  )
}

export function EnableButton({ label, confirmMessage, successMessage, errorMessage, post, invalidate, refetch, redirect }){
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const mutationClient = useMutationClient()
  const notifications = useNotifications()

  const mutation = useMutation(
    data => mutationClient.post(post),
    {
      onSuccess: (data, variables, context) => {
        if( successMessage ){
          notifications.danger(successMessage)
        }

        if( invalidate ){
          queryClient.invalidateQueries(invalidate)
        }

        if( redirect ){
          navigate(redirect)
        }

        if( refetch ){
          refetch()
        }
      },
      onError: (data, variables, context) => {
        if( errorMessage ){
          notifications.danger(errorMessage)
        }
      }
    }
  )

  function buttonHandler(){
    MySwal.fire({
      title: confirmMessage,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        mutation.mutate()
      }
    })
  }

  return (
    <button
      className="btn btn-outline-success"
      onClick={() => buttonHandler()}
    >{ label }</button>
  )
}

export function DisableButton({ label, confirmMessage, successMessage, errorMessage, post, invalidate, refetch, redirect }){
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const mutationClient = useMutationClient()
  const notifications = useNotifications()

  const mutation = useMutation(
    data => mutationClient.post(post),
    {
      onSuccess: (data, variables, context) => {
        if( successMessage ){
          notifications.success(successMessage)
        }

        if( invalidate ){
          queryClient.invalidateQueries(invalidate)
        }

        if( redirect ){
          navigate(redirect)
        }

        if( refetch ){
          refetch()
        }
      },
      onError: (data, variables, context) => {
        if( errorMessage ){
          notifications.danger(errorMessage)
        }
      }
    }
  )

  function buttonHandler(){
    MySwal.fire({
      title: confirmMessage,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        mutation.mutate()
      }
    })
  }

  return (
    <button
      className="btn btn-outline-danger"
      onClick={() => buttonHandler()}
    >{ label }</button>
  )
}

export function DestroyButton({ label, confirmMessage, successMessage, errorMessage, deletePath, invalidate, refetch, redirect, children, size }){
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const mutationClient = useMutationClient()
  const notifications = useNotifications()

  const mutation = useMutation(
    () => mutationClient.delete(deletePath),
    {
      onSuccess: () => {
        if( successMessage ){
          notifications.success(successMessage)
        }

        if( invalidate ){
          queryClient.invalidateQueries(invalidate)
        }

        if( redirect ){
          navigate(redirect)
        }

        if( refetch ){
          refetch()
        }
      },
      onError: () => {
        if( errorMessage ){
          notifications.danger(errorMessage)
        }
      }
    }
  )

  function destroyHandler(){
    MySwal.fire({
      title: confirmMessage,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        mutation.mutate()
      }
    })
  }

  return (
    <button
      className={`btn btn-outline-danger ${size ? 'btn-' + size : ''}`}
      onClick={() => destroyHandler()}
      disabled={mutation.isLoading}
    >{children ? children : null} { label }</button>
  )
}

export function OpenDoorButton({ access, position = {}, bluetooth = false}){
  const mutationClient = useMutationClient()
  const notifications = useNotifications()

  const mutation = useMutation(
    data => mutationClient.post(`doors/${data.DoorId}/open`, data),
    {
      onSuccess: (data, variables, context) => {
        MySwal.fire(
          '¡Apertura en proceso!',
          'Se ha enviado el comando de apertura',
          'success'
        )
      },
      onError: () => {
        MySwal.fire(
          '¡Apertura fallida!',
          'Ha ocurrido un error al realizar la apertura. Verifique la validez del permiso.',
          'error'
        )
      }
    }
  )

  function handleClick(){
    MySwal.fire({
      title: `¿Está seguro que desea abrir la puerta "${access.Door.name}"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar apertura',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        mutation.mutate({
          DoorId: access.door_id,
          position
        })
      }
    })
  }

  return (
    <button
      className="btn btn-success btn-block mt-2"
      onClick={() => handleClick()}
      disabled={mutation.isLoading}
    >
      {
        mutation.isLoading ?
          <span className="font-weight-bold loading ml-2">Abriendo...</span>
        : <>
            <FontAwesomeIcon icon={faUnlockAlt} size="lg" className="mr-2"></FontAwesomeIcon>
            <span className="font-weight-bold">Abrir puerta { bluetooth? "mediante bluetooth" : ""}</span>
          </>
      }
    </button>
  )
}

