import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from 'react-query'

import Header, { HeaderActions } from "../shared/Header.js"
import Spinner from "../shared/Spinner.js";

import { useFromQueryString, toQueryString, TIME_ZONE } from "../../utils.js";
import { FilterMenu } from '../shared/Filters.js';
import Pagination from "../shared/Pagination.js";

import { Container } from '../layout/AppLayout.js';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AlertsTable from "../alerts/AlertsTable.js";
import AlertSubmenu from "./AlertSubmenu.js";
import { isAllowed } from "../shared/FeatureChecker.js";
import { CurrentUserContext } from "../../App.js";


export default function AlertsListPage() {
  const queryString = useFromQueryString();
  const navigate = useNavigate();
  const currentUser = useContext(CurrentUserContext)

  const [currentFilters, setCurrentFilters] = useState({
    page: parseInt(queryString.page) || 1,
    limit: parseInt(queryString.limit) || 100,
    AlertTypeFilter: queryString.AlertTypeFilter || "",
    ZoneId: (queryString.ZoneId) || '',
    sort: "date,desc",
    tz: TIME_ZONE
  });

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "LIST_ALERTS"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const query = useQuery(`alertevents?${toQueryString(currentFilters)}`, {
    keepPreviousData: true,
  })

  useEffect(()=>{
    setCurrentFilters(prevFilters => ({
      ...prevFilters,
      page: 1
    }));
  },[queryString.AlertTypeFilter,queryString.ZoneId])

  useEffect(() => {
    navigate("?" + toQueryString(currentFilters), { replace: true });
  }, [currentFilters]);

  return (
    <Container>
      <Row>
        <Header title="Alertas" items={[
          { label: "Listado de alertas" }
        ]}>
          <HeaderActions>
          </HeaderActions>
        </Header>
      </Row>
      <div className="row">
        <AlertSubmenu filters={currentFilters}/>
      </div>

      <Row className="mb-2">
        <FilterMenu
          currentFilters={currentFilters}
          onChange={filters => setCurrentFilters(prev => ({ ...prev, ...filters }))}
          dateRangeFilter
        />
      </Row>

      <Row className="mb-2">
        <Col className="col-12">
          {
            query.isLoading ?
              <div className="text-center my-4 py-4">
                <Spinner />
              </div>
            :
              <AlertsTable alerts={query.data && query.data.result || []} />
          }
        </Col>
      </Row>

      <Row className="mt-3">
        <Pagination
          pagination={query.data && query.data.pagination}
          isLoading={query.isFetching}
          onChange={page => setCurrentFilters(prev => ({ ...prev, page }))}
        />
      </Row>
    </Container>
  );
}
