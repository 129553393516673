import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useCurrentUser, useAppReducer } from './app.reducer.js'

import axios from 'axios';

import Spinner from "./app/shared/Spinner.js";

import UnauthorizedLayout from "./app/layout/UnauthorizedLayout.js"
import AppLayout from "./app/layout/AppLayout.js"

import LoginPage from "./app/login/LoginPage.js";
import WelcomePage from "./app/welcome/WelcomePage.js"
import InvitationPage from "./app/welcome/InvitationPage.js"
import RecoverPasswordPage from "./app/login/RecoverPassword.js"
import ChangePasswordPage from "./app/login/ChangePasswordPage.js"
import ProfilePage from "./app/profile/ProfilePage.js"

import DashboardPage from "./app/dashboard/DashboardPage.js";

import AlertsListPage from "./app/alerts/AlertsListPage.js";
import AlertsDetailPage from "./app/alerts/AlertsDetailPage.js";
import AlertMediaDetailPage from "./app/alerts/AlertsDetailsMediaPage.js";



import EventsListPage from "./app/events/EventsListPage.js";

import DoorsListPage from "./app/doors/DoorsListPage.js";
import DoorDetailPage from "./app/doors/DoorDetailPage.js";
import DoorSensorsPage from "./app/doors/DoorSensorsPage.js";
import EditDoorPage from "./app/doors/EditDoorPage.js";
import NewDoorPage from "./app/doors/NewDoorPage.js"

import MediaListPage from "./app/media/MediaListPage.js"
import MediaDetailPage from "./app/media/DetailsMediaPage"

//import MediaDetailPage from "./app/media/MediaDetailPage.js"
//import MediaEventDetailPage from "./app/media/MediaEventDetailPage.js"

import FacilitiesListPage from "./app/facilities/FacilitiesListPage.js";
import NewFacilityPage from "./app/facilities/NewFacilityPage.js";
import FacilityDetailsPage from "./app/facilities/FacilityDetailPage.js";
import FacilityFloorPlanPage from "./app/facilities/FacilityFloorplanPage";
import EditFacilityPage from "./app/facilities/EditFacilityPage.js";

import SvaListPage from "./app/sva/SvaListPage.js";
import NewSvaPage from "./app/sva/NewSvaPage.js";
import SvaWizardPage from "./app/sva/SvaWizardPage.js";

import ZonesListPage from "./app/zones/ZonesListPage.js";
import ZoneFloorplanPage from "./app/zones/ZoneFloorplanPage.js";
import ZoneAlertsPage from "./app/zones/ZoneAlertsPage.js";
import EditZonePage from "./app/zones/EditZonePage.js";
import NewZonePage from "./app/zones/NewZonePage.js";
import ZoneMediaPage from "./app/zones/detail/ZoneMediaPage.js";
import ZoneDevicesPage from "./app/zones/detail/ZoneDevicesPage.js";
import ZoneDetailPage from "./app/zones/ZoneDetailPage.js";

import AssetsListPage from "./app/assets/AssetsListPage.js";
import NewAssetPage from "./app/assets/NewAssetPage.js";
import AssetDetailPage from "./app/assets/AssetDetailPage.js";
import EditAssetPage from "./app/assets/EditAssetPage.js";

import AccessListPage from "./app/access/AccessListPage.js";
import NewAccessPage from "./app/access/NewAccessPage.js";
import AccessDetailPage from "./app/access/AccessDetailPage.js";
import EditAccessPage from "./app/access/EditAccessPage.js";

import EntriesListPage from "./app/logs/EntriesListPage.js";
import EntryDetailPage from "./app/logs/EntryDetailPage.js";
import InstructionListPage from "./app/logs/IntructionListPage.js";

import SensorsListPage from "./app/sensors/SensorsListPage.js";
import NewSensorPage from "./app/sensors/NewSensorPage.js"
import EditSensorPage from "./app/sensors/EditSensorPage.js"
import SensorDetailPage from "./app/sensors/SensorDetailPage.js";
import MaintanceListPage from "./app/maintance/MaintanceListPage.js";
import MaintanceNewPage from "./app/maintance/MaintanceNewPage.js";
import FailureListPage from "./app/maintance/FailureListPage.js";
import FailureNewPage from "./app/maintance/FailureNewPage.js";

import RevisionsListPage from "./app/revisions/RevisionsListPage.js";
import RevisionsDetailsPage from "./app/revisions/RevisionsDetailPage.js";

import MembersListPage from "./app/members/MembersListPage.js"
import MemberDetailPage from "./app/members/MemberDetailPage.js"
import MemberEditPage from './app/members/MemberEditPage.js'
import MemberIdentitiesPage from './app/members/MemberIdentitiesPage.js'
import NewMemberIdentityPage from './app/members/NewMemberIdentityPage.js'
import InviteMemberPage from "./app/members/InviteMemberPage.js"
import CreateMemeberPage from "./app/members/CreateMemberPage.js"
import MembersIdentificationPage from "./app/members/MembersIdentificationPage.js";
import MembersIdentificationMediaPage from "./app/members/MembersIdentificationMediaPage.js";
import ProfileMemberPage from "./app/members/ProfileMemberPage.js";

import GroupsListPage from "./app/groups/GroupsListPage.js"
import NewGroupPage from "./app/groups/NewGroupPage.js"
import GroupDetailPage from "./app/groups/GroupDetailPage.js";
import EditGroupPage from "./app/groups/EditGroupPage.js"

import CompaniesListPage from "./app/companies/CompaniesListPage.js";
import CompanyDetailPage from "./app/companies/CompanyDetailPage.js";
import NewCompanyPage from "./app/companies/NewCompanyPage.js";
import EditCompanyPage from "./app/companies/EditCompanyPage.js";

import RolesListPage from "./app/roles/RolesListPage.js"
import RoleDetailPage from "./app/roles/RoleDetailPage.js"
import NewRolePage  from "./app/roles/RoleCreate.js"

import ManualDetailPage from "./app/manual/ManualDetailPage.js";
import AlertStateListPage from "./app/alerts/AlertStateListPage.js";
import AlertStateDetailPage from "./app/alerts/AlertsStateDetailPage.js";
import AlertConnectionListPage from "./app/alerts/AlertConnectionListPage.js";
import AlertConnectionDetailPage from "./app/alerts/AlertConnectionDetailPage.js"

import NotificationsListPage from "./app/notifications/NotificationListPage.js";

import DeviceInfoListPage from "./app/deviceInfo/DeviceInfoListPage.js";

import ErrorDisplayPage from "./app/error/ErrorDisplayPage.js";

//import UsersListPage from "./app/users/UsersListPage.js";
//import UserDetailPage from "./app/users/UserDetailPage.js";
//import EditUserPage from "./app/users/EditUserPage.js";
//import NewUserPage from "./app/users/NewUserPage.js";
//import SubcompaniesListPage from "./app/subcompanies/SubcompaniesListPage.js";
//import EditSubcompanyPage from "./app/subcompanies/EditSubcompanyPage.js";
//import NewSubcompanyPage from "./app/subcompanies/NewSubcompanyPage.js";
//import NewChartPage from "./app/sensors/NewChartPage.js"
//import EditChartPage from "./app/sensors/EditChartPage.js"
//import ProfilePage from "./app/profile/ProfilePage.js"
//import AlertsListPage from "./app/alerts/AlertsListPage.js"
//import LoginChangePasswordPage from "./app/login/LoginChangePassword.js"

export const ActionsContext = React.createContext({})
export const StateContext = React.createContext({})
export const CurrentUserContext = React.createContext({})
export const CurrentCompanyContext = React.createContext({})

export const defaultQueryFn = async({ queryKey, meta, ...props }) => {
  let sessionData = {};
  let currentCompany = {};

  try{
    sessionData = JSON.parse(localStorage.getItem('sessionData'))
    currentCompany = JSON.parse(localStorage.getItem('CurrentCompany'))
  }catch(e){
    console.log('No token found');
  }

  let pagination = null
  let params = {}
  let headers = {}

  if( meta === undefined || (meta !== undefined && !meta.withoutCompanyId) ){
    params["CompanyId"] = currentCompany && currentCompany.id
  }

  if( sessionData ){
    headers['Authorization'] = `Bearer ${sessionData.token}`
  }

  const res = await axios.get(`${window.config.API_URL}${queryKey[0]}`,
    { params, headers }
  );

  if( res.headers['x-total-pages'] ){
    pagination = {
      page: parseInt(res.headers['x-page']),
      limit: parseInt(res.headers['x-limit']),
      totalItems: parseInt(res.headers['x-total-items']),
      totalPages: parseInt(res.headers['x-total-pages'])
    }
  }

  return {
    result: res.data && res.data.result,
    pagination
  };
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
    },
  },
})

const prefetchQueries = async () => {
  await queryClient.prefetchQuery('doors?page=1')
  await queryClient.prefetchQuery('sensors?page=1')
  //await queryClient.prefetchQuery(`events?page=1&tz=${TIME_ZONE}`)
  //await queryClient.prefetchQuery('users?page=1&status=enabled')
  //await queryClient.prefetchQuery('departments?page=1')
  //await queryClient.prefetchQuery('entries')
}

function UnauthorizedRoutes(){
  return (
    <Routes>
      <Route element={<UnauthorizedLayout />}>
        <Route
          path="/"
          element={
            <React.Fragment>
              <Navigate to="/login" />
            </React.Fragment>
          }
        />

        <Route path="/login" element={<LoginPage />} />

        <Route path="/password/recover" exact element={<RecoverPasswordPage />} />
        <Route path="/password/change" element={<ChangePasswordPage />} />
        <Route path="/welcome" exact element={<WelcomePage />} />
        <Route path="/invitations" exact element={<InvitationPage />} />

        <Route
          path="*"
          element={
            <React.Fragment>
              <Navigate to="/login" />
            </React.Fragment>
          }
        />
      </Route>
    </Routes>
  );
}

function AuthorizedRoutes(){
  const { currentUser, isLoading } = useCurrentUser()
  const { currentCompany } = React.useContext(StateContext)

  if( isLoading ){
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner />
      </div>
    )
  }

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <CurrentCompanyContext.Provider value={currentCompany}>
        <Routes>
          <Route element={<AppLayout />}>
            <Route
              path="/"
              exact
              element={
                <React.Fragment>
                  {
                    currentCompany ?
                      <Navigate to="/doors" />
                      :
                      <Navigate to="/companies" />
                  }
                </React.Fragment>
              }
            />

            <Route
              path="/invitations"
              exact
              element={
                <React.Fragment>
                  <Navigate to="/companies" />
                </React.Fragment>
              }
            />

            <Route path="/dashboard" exact element={<DashboardPage />} />

            <Route path="/doors">
              <Route path="" element={<DoorsListPage />} />
              <Route path="new" exact element={<NewDoorPage />} />
              <Route path=":DoorId" exact element={<DoorDetailPage />} />
              <Route path=":DoorId/devices" exact element={<DoorSensorsPage />} />
              <Route path=":DoorId/edit" exact element={<EditDoorPage />} />
            </Route>

            <Route path="/media">
              <Route path="" exact element={<MediaListPage />} />
              <Route path=":MediaId" exact element={<MediaDetailPage />} />
              {/* <Route path=":MediaId" exact element={<MediaDetailPage />} /> */}
              {/* <Route path=":MediaId/events/:EventId" exact element={<MediaEventDetailPage />} /> */}
            </Route>

            <Route path="/facilities">
              <Route path="" exact element={<FacilitiesListPage />} />
              <Route path="new" exact element={<NewFacilityPage />} />
              <Route path=":FacilityId" exact element={<FacilityDetailsPage />} />
              <Route path=":FacilityId/edit" exact element={<EditFacilityPage />} />
              <Route path=":FacilityId/floorplan" exact element={<FacilityFloorPlanPage />} />


              <Route path=":FacilityId/sva">
                <Route path="" exact element={<SvaListPage />} />
                <Route path="new" exact element={<NewSvaPage />} />
                <Route path=":SvaId/wizard" exact element={<SvaWizardPage />} />
              </Route>
            </Route>

            <Route path="/zones">
              <Route path="" exact element={<ZonesListPage />} />
              <Route path="new" exact element={<NewZonePage />} />
              <Route path=":ZoneId" exact element={<ZoneDetailPage />} />
              <Route path=":ZoneId/edit" exact element={<EditZonePage />} />
              <Route path=":ZoneId/alerts" exact element={<ZoneAlertsPage />} />
              <Route path=":ZoneId/media" exact element={<ZoneMediaPage />} />
              <Route path=":ZoneId/devices" exact element={<ZoneDevicesPage />} />

              <Route path=":ZoneId/assets" >
                <Route path="" exact element={<AssetsListPage />} />
                <Route path=":AssetId" exact element={<AssetDetailPage />} />
                <Route path=":AssetId/edit" exact element={<EditAssetPage />} />
                <Route path="new" exact element={<NewAssetPage />} />
              </Route>
            </Route>

            <Route path="/events">
              <Route path="" exact element={<EventsListPage />} />
            </Route>

            <Route path="/alerts">
              <Route path="" exact element={<AlertsListPage />} />
              <Route path=":AlertId" exact element={<AlertsDetailPage />} />
              <Route path=":AlertId/media/:MediaId" exact element={<AlertMediaDetailPage />} />
              <Route path="state" exact element={ <AlertStateListPage/>} />
              <Route path=":AlertId/state" exact element={<AlertStateDetailPage/>}/>
              <Route path="connection" exact element={<AlertConnectionListPage/>}/>
              <Route path=":AlertId/connection" exact element={<AlertConnectionDetailPage/>}/>
            </Route>

            <Route path="/permissions">
              <Route path="" exact element={<AccessListPage />} />
              <Route path="new" exact element={<NewAccessPage />} />
              <Route path=":AccessId" exact element={<AccessDetailPage />} />
              <Route path=":AccessId/edit" exact element={<EditAccessPage />} />
            </Route>

            <Route path="/entries">
              <Route path="" exact element={<EntriesListPage isFull={false} />} />
              {/* <Route path=":EntryId" exact element={<EntryDetailPage  isInstruction={false}/>} /> */}
              <Route path=":AlertId" exact element={<AlertsDetailPage  isEntry={true}/>} />
              <Route path=":AlertId/media/:MediaId" exact element={<AlertMediaDetailPage isEntry={true}/>} />

            </Route>
            
            <Route path="/instructions">
              <Route path="" exact element={<InstructionListPage isFull={true}/>} />
              <Route path=":EntryId" exact element={<EntryDetailPage isInstruction={true}/>} />
            </Route>

            <Route path="/sensors">
              <Route path="" exact element={<SensorsListPage />} />
              <Route path="new" exact element={<NewSensorPage />} />
              <Route path="new/:ZoneId" exact element={<NewSensorPage />} />
              <Route path=":SensorId" exact element={<SensorDetailPage />} />
              <Route path=":SensorId/edit" exact element={<EditSensorPage />} />
              <Route path=":SensorId/maintance" exact element={<MaintanceListPage/>}/>
              <Route path=":SensorId/maintance/new" exact element={<MaintanceNewPage/>}/>
              <Route path=":SensorId/failure" exact element={<FailureListPage/>}/>
              <Route path=":SensorId/failure/new" exact element={<FailureNewPage/>}/>
            </Route>

            <Route path="/revisions">
              <Route path="" exact element={<RevisionsListPage />}/>
              <Route path=":LogId" exact element={<RevisionsDetailsPage/>}/>
            </Route>

            <Route path="/notifications" exact element={<NotificationsListPage/>}/>

            <Route path="/members">
              <Route path="" exact element={<MembersListPage />} />
              <Route path="invite" exact element={<InviteMemberPage />} />
              <Route path="new" exact element={<CreateMemeberPage/>}/>

              <Route path="groups">
                <Route path="" exact element={<GroupsListPage />} />
                <Route path="new" exact element={<NewGroupPage />} />
                <Route path=":GroupId" exact element={<GroupDetailPage />} />
                <Route path=":GroupId/edit" exact element={<EditGroupPage />} />
              </Route>

              <Route path=":UserId" exact element={<MemberDetailPage />} />
              <Route path=":UserId/edit" exact element={<MemberEditPage />} />
              <Route path=":UserId/identities" exact element={<MemberIdentitiesPage />} />
              <Route path=":UserId/identities/new" exact element={<NewMemberIdentityPage />} />
              <Route path=":UserId/identification" exact element={< MembersIdentificationPage/>} />
              <Route path=":UserId/identification/media/:MediaId"  exact element={<MembersIdentificationMediaPage/>}/>
            </Route>
            <Route path="/password/change" element={<ProfileMemberPage />} />

            <Route path="/companies">
              <Route path="" exact element={<CompaniesListPage />} />
              <Route path="new" exact element={<NewCompanyPage />} />
              <Route path=":CompanyId" exact element={<CompanyDetailPage />} />
              <Route path=":CompanyId/edit" exact element={<EditCompanyPage />} />

              <Route path=":CompanyId/roles" exact element={<RolesListPage />} />
              <Route path=":CompanyId/roles/:RoleId" exact element={<RoleDetailPage />} />
              <Route path=":CompanyId/roles/new" exact element={<NewRolePage />} />
            </Route>

            <Route path="/profile">
              <Route path="" exact element={<ProfilePage />} />
            </Route>

            <Route path="/manual">
              <Route path="" exact element={<ManualDetailPage/>}/>
            </Route>

            <Route path="/mac">
              <Route path="" exact element={<DeviceInfoListPage/>}/>
            </Route>

            <Route path="/error">
              <Route path="" exact element={<ErrorDisplayPage/>}/>
            </Route>

            <Route
              path="*"
              exact
              element={
                <ErrorDisplayPage/>
              }
            />
          </Route>
        </Routes>
      </CurrentCompanyContext.Provider>
    </CurrentUserContext.Provider>
  )
}

export default function App() {
  const { state, actions } = useAppReducer()

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />

      <StateContext.Provider value={state}>
        <ActionsContext.Provider value={actions}>
          { state.isLoggedIn ? <AuthorizedRoutes /> : <UnauthorizedRoutes /> }
        </ActionsContext.Provider>
      </StateContext.Provider>
    </QueryClientProvider>
  );
}
