import React, { useEffect, useState, useContext } from "react";

import { useNavigate, useParams, Link } from "react-router-dom";
import { useFromQueryString, toQueryString } from "../../utils.js";
import { useQuery } from 'react-query'

import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Pagination from '../shared/Pagination.js'
import Header, { HeaderActions } from "../shared/Header.js"
import Spinner from '../shared/Spinner.js';

import useFeatureChecker, { isAllowed } from '../shared/FeatureChecker.js'
import { FilterMenu } from '../shared/Filters.js';
import { CurrentUserContext } from "../../App.js";

import FailureTable from "./FailureTable.js";

export default function FailureListPage(){
  const queryString = useFromQueryString();
  const navigate = useNavigate()
  const CheckFeatures = useFeatureChecker()
  const params = useParams()
  const currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "LIST_REPORTS"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const [currentFilters, setCurrentFilters] = useState({
    limit: parseInt(queryString.limit) || 25,
    page: parseInt(queryString.page) || 1,
    search: queryString.search || "",
    FailureTypeId: queryString.FailureTypeId || ""
  });

  
  useEffect(()=>{
    setCurrentFilters(prevFilters => ({
      ...prevFilters,
      page: 1
    }));
  },[queryString.search,queryString.FailureTypeId])


  const query = useQuery(`devicereport/${params.SensorId}?type=failure&${toQueryString(currentFilters)}`, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    //refetchOnMount: false,
  })

  useEffect(() => {
    navigate('?' + toQueryString(currentFilters), { replace: true })
  }, [currentFilters]);

  return (
    <Container>
      <Row>
      <Header title="Lista de fallas" items={[
          { label: "Listado de dispositivos", to: "/sensors" },
            { label: "Detalle de dispostivo", to: `/sensors/${params.SensorId}` },
            { label: "Listado de fallas"},
        ]} >
          
          <HeaderActions>
            <CheckFeatures feature="CREATE_REPORTS">
              <Link to={`/sensors/${params.SensorId}/failure/new`} className="btn btn-primary">Crear reporte de fallas</Link>
            </CheckFeatures>
          </HeaderActions>
        </Header>
      </Row>
      <Row className="mb-2">
        <FilterMenu
          currentFilters={currentFilters}
          onChange={filters => setCurrentFilters(prev => ({ ...prev, ...filters }))}
        />
      </Row>
      <Row>
        <Col className="col-12 mb-4">
          {
            query.isLoading ?
              <div className="text-center my-4 py-4">
                <Spinner />
              </div>
            :
              <FailureTable
                failure={query.data && query.data.result || []}
              />
          }
        </Col>
      </Row>

      <Row className="mt-2">
        <Pagination
          pagination={query.data && query.data.pagination}
          isLoading={query.isFetching}
          onChange={page => setCurrentFilters(prev => ({ ...prev, page }))}
        />
      </Row>
    </Container>
  )
}

