import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { CurrentCompanyContext } from '../../App.js';
import { formatDate } from '../../utils.js';

import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';

import { DestroyButton } from '../shared/Buttons.js';
import Header, { HeaderActions } from '../shared/Header.js';
import { useNotifications } from '../shared/Notifications.js';
import Spinner from '../shared/Spinner.js';

import { Container } from '../layout/AppLayout.js';
import MemberDetailSubmenu from './MemberDetailSubmenu.js';
import translateAlert from './translate.js';

function UserDetailCard({ currentUser, isLoading }) {
  const CheckFeatures = useFeatureChecker()
  let content = null

  if (isLoading) {
    content = (
      <div className="text-center py-4 my-4">
        <Spinner />
      </div>
    )
  } else if (currentUser) {
    content = (
      <>
        <div className="form-group">
          <label className="form-label font-weight-bold">Nombre completo</label>
          <p>{currentUser.name}</p>
        </div>

        <div className="form-group">

          <label className="form-label font-weight-bold">Rol</label>
          <p>
            <CheckFeatures feature="DETAIL_ROLE">
              <AllowedFeature>
                {
                  currentUser.Role ? <Link className="py-3" to={"/companies/" + currentUser.Role.company_id + "/roles/" + currentUser.Role.id}> <span>{currentUser.Role.name}</span> </Link> : <span>----</span>
                }
              </AllowedFeature>
              <DeniedFeature>
                <span>{currentUser.Role ? currentUser.Role.name : '----'}</span>
              </DeniedFeature>
            </CheckFeatures>
          </p>
        </div>

        <div className="form-group">
          <label className="form-label font-weight-bold">Correo electrónico</label>
          <p>{currentUser.email}</p>
        </div>


        {
          currentUser.UsersCompanies && currentUser.UsersCompanies.section_name ?
            <CheckFeatures feature="DISABLE">
              <div className="form-group">
                <label className="form-label font-weight-bold">Área</label>
                <p>{currentUser.UsersCompanies.section_name}</p>
              </div>
            </CheckFeatures>
            : null
        }

        {
          currentUser.UsersCompanies && currentUser.UsersCompanies.child_section_name ?
            <CheckFeatures feature="DISABLE">
              <div className="form-group">
                <label className="form-label font-weight-bold">Unidad</label>
                <p>{currentUser.UsersCompanies.child_section_name}</p>
              </div>
            </CheckFeatures>
            : null
        }

        <div className="form-group">
          <label className="form-label font-weight-bold">Fecha de creación</label>
          <p>{formatDate(currentUser.UsersCompanies.created_at, 'dd/MM/yyyy HH:mm:ss')}</p>
        </div>

        <div className="form-group">
          <label className="form-label font-weight-bold">Última actualización</label>
          <p>{formatDate(currentUser.UsersCompanies.updated_at, 'dd/MM/yyyy HH:mm:ss')}</p>
        </div>

        {
          currentUser.Groups && currentUser.Groups.length ?
            <div className="form-group">
              <label className="form-label font-weight-bold">Grupos</label>
              <div>
                {
                  currentUser.Groups.map(
                    item => (
                      <CheckFeatures feature="DETAIL_GROUP">
                        <AllowedFeature>
                          <Link className="py-3" to={"/members/groups/" + item.id}>
                            <span
                              className="badge badge-primary ml-2"
                              key={item.id}
                            >
                              {item.name}
                            </span>
                          </Link>
                        </AllowedFeature>
                        <DeniedFeature>
                          <span
                            className="badge badge-primary ml-2"
                            key={item.id}
                          >
                            {item.name}
                          </span>
                        </DeniedFeature>
                      </CheckFeatures>
                    )
                  )
                }
              </div>
            </div>
            : null
        }
      </>
    )
  }

  return (
    <div className="card shadow-sm mb-3">
      <div className="card-header">Detalles del usuario</div>
      <div className="card-body">
        {content}
      </div>
    </div>
  )
}

function UserEntriesCard({ currentUser, isLoading }) {
  const CheckFeatures = useFeatureChecker()
  let content = null

  const query = useQuery(`alertevents/entries?UserId=${currentUser.id}&limit=5&sort=created_at-desc`, {
    enabled: !!(currentUser && currentUser.id)
  })

  const entries = !query.isLoading && query.data && query.data.result && query.data.result || []

  if (isLoading) {
    content = (
      <div className="text-center py-4 my-4">
        <Spinner />
      </div>
    )
  } else {
    if (entries.length) {
      content = (
        <table className="table table-hover m-0" style={{ fontSize: '.9em' }}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Fecha</th>
              <th>Puerta</th>
              <th>Tipo</th>
            </tr>
          </thead>
          <tbody>
            {
              entries.map(item => (
                <tr key={item.id}>
                  <td style={{ fontFamily: 'monospace' }}>
                    <CheckFeatures feature="DETAIL_ENTRY">
                      <AllowedFeature>
                        <Link to={`/entries/${item.id}`} key={"entry_" + item.id} >#{item.id}</Link>
                      </AllowedFeature>
                      <DeniedFeature>
                        <span>#{item.id}</span>
                      </DeniedFeature>
                    </CheckFeatures>



                  </td>
                  <td>{formatDate(item.created_at)}</td>

                  <td>
                    <CheckFeatures feature="DETAIL_DOOR">
                      <AllowedFeature>
                        <Link to={`/doors/${item.door_id}`} key={"door_" + item.door_id}>{item.door_name}</Link>
                      </AllowedFeature>
                      <DeniedFeature>
                        <span>{item.door_name}</span>
                      </DeniedFeature>
                    </CheckFeatures>
                  </td>


                  <td>{translateAlert(item.name)}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      )
    } else {
      content = (
        <div className="text-center text-muted py-4 border-top">No se encontraron eventos de acceso</div>
      )
    }
  }

  return (
    <div className="card shadow-sm mb-3">
      <div className="card-header border-bottom-0 d-flex justify-content-between align-items-center">
        <div>
          Últimos accesos
        </div>

        <CheckFeatures feature="LIST_ENTRIES">
          <div style={{ fontSize: '.9em' }}>
            {
              entries.length ?
                <a target="_blank" href={`/entries?sort=created_at-desc&UserId=${currentUser.id}&tz=America/Santiago`}>Ver todo</a>
                : null
            }
          </div>
        </CheckFeatures>
      </div>

      {content}
    </div>
  )
}

function UserAccessCard({ currentUser, isLoading }) {
  const CheckFeatures = useFeatureChecker()

  let content = null

  const query = useQuery(`doors/access?UserId=${currentUser.id}&type=current&limit=5`, {
    enabled: !!(currentUser && currentUser.id)
  })

  const access = !query.isLoading && query.data && query.data.result && query.data.result || []

  if (isLoading) {
    content = (
      <div className="text-center py-4 my-4">
        <Spinner />
      </div>
    )
  } else {
    if (access.length) {
      content = (
        <table className="table table-hover m-0" style={{ fontSize: '.9em' }}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Fecha de inicio</th>
              <th>Puerta</th>
            </tr>
          </thead>
          <tbody>
            {
              access.map(item => (
                <tr key={item.id}>
                  <td style={{ fontFamily: 'monospace' }}>
                    <CheckFeatures feature="DETAIL_ACCESS">
                      <AllowedFeature>
                        <Link to={`/permissions/${item.id}`} key={"access_id_" + item.id}>#{item.id}</Link>
                      </AllowedFeature>
                      <DeniedFeature>
                        <span>#{item.id}</span>
                      </DeniedFeature>
                    </CheckFeatures>


                  </td>
                  <td>{formatDate(item.date_start)}</td>
                  <td>
                    <CheckFeatures feature="DETAIL_DOOR">
                      <AllowedFeature>
                        <Link to={`/doors/${item.Door.id}`} key={"access_door_" + item.Door.id}>{item.Door.name}</Link>
                      </AllowedFeature>
                      <DeniedFeature>
                        <span>{item.Door.name}</span>
                      </DeniedFeature>
                    </CheckFeatures>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      )
    } else {
      content = (
        <div className="text-center border-top text-muted py-4">No se encontraron permisos en curso</div>
      )
    }
  }

  return (
    <div className="card shadow-sm mb-3">
      <div className="card-header border-bottom-0 d-flex justify-content-between align-items-center">
        <div>Permisos en curso</div>
        <CheckFeatures feature="LIST_ALL_ACCESS">
          <div style={{ fontSize: '.9em' }}>
            {
              access.length ?
                <a target="_blank" href={`/permissions?UserId=${currentUser.id}`}>Ver todo</a>
                : null
            }
          </div>
        </CheckFeatures>
      </div>

      {content}
    </div>
  )
}

function UserIdentitiesCard({ currentUser, isLoading }) {
  let content = null

  if (isLoading) {
    content = (
      <div className="text-center py-4 my-4">
        <Spinner />
      </div>
    )
  } else {
    if (currentUser && currentUser.Identities && currentUser.Identities.length) {
      content = (
        <table className="table table-hover m-0" style={{ fontSize: '.9em' }}>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Identidad</th>
              <th>Fecha de creación</th>
            </tr>
          </thead>
          <tbody>
            {
              currentUser.Identities.map(item => (
                <tr key={item.id}>
                  <td>{item.type}</td>
                  <td>{item.identity}</td>
                  <td>{formatDate(item.created_at)}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      )
    } else {
      content = (
        <div className="text-center border-top text-muted py-4">No se encontraron permisos en curso</div>
      )
    }
  }

  return (
    <div className="card shadow-sm mb-3">
      <div className="card-header border-bottom-0">Identidades</div>
      {content}
    </div>
  )
}

export default function MemberDetailPage() {
  const params = useParams()
  const CheckFeatures = useFeatureChecker()
  const currentCompany = useContext(CurrentCompanyContext)
  const navigate = useNavigate()
  const notifications = useNotifications()

  const query = useQuery(`companies/${currentCompany.id}/users?UserId=${params.UserId}`, { refetchOnWindowFocus: true })

  useEffect(() => {
    if (!query.isLoading && query.error && query.error.response && query.error.response.status == 403) {
      notifications.warning('Acceso no autorizado')
      navigate(`/members`)
    }
  }, [query.isLoading])
  const currentUser = !query.isLoading && query.data && query.data.result && query.data.result.length && query.data.result[0]

  return (
    <Container>
      <div className="row">
        <Header
          title="Detalle de usuario"
          items={[
            { label: "Listado de usuarios", to: "/members" },
            { label: "Detalle de usuario" },
          ]}
        >
          <HeaderActions>
            {!currentUser ? null
              : (
                <>
                  <CheckFeatures feature="UPDATE_USER">
                    <Link
                      to={"/members/" + params.UserId + "/edit"}
                      className="btn btn-primary"
                    >Editar usuario</Link>
                  </CheckFeatures>

                  <CheckFeatures feature="COMPANY_DELETE_USER">
                    <div className="ml-2 d-inline">
                      <DestroyButton
                        label="Eliminar usuario"
                        confirmMessage="¿Está seguro que desea eliminar el usuario?"
                        successMessage="Usuario eliminado correctamente"
                        deletePath={`companies/${currentCompany.id}/users/${params.UserId}`}
                        invalidate={`users`}
                        redirect="/members"
                      />
                    </div>
                  </CheckFeatures>
                </>)}
          </HeaderActions>
        </Header>
      </div>
      <div className="row">
        <MemberDetailSubmenu UserId={params.UserId} />
      </div>
      {
        !currentUser ? (
          <div className="text-center py-4 my-4">
            <Spinner />
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-md-6">
              <CheckFeatures feature="DETAIL_USER" >
                <AllowedFeature>
                  <UserDetailCard currentUser={currentUser} isLoading={query.isLoading} />
                </AllowedFeature>
                <DeniedFeature>
                  <Navigate to={'/error?error=403'}/>
                </DeniedFeature>
              </CheckFeatures>
              <CheckFeatures feature="LIST_IDENTITIES">
                <UserIdentitiesCard currentUser={currentUser} isLoading={query.isLoading} />
              </CheckFeatures>
            </div>
            <div className="col-12 col-md-6">
              <CheckFeatures feature="LIST_PERSONAL_ENTRIES">
                <UserEntriesCard currentUser={currentUser} isLoading={query.isLoading} />
              </CheckFeatures>

              <CheckFeatures feature="LIST_PERSONAL_ACCESS">
                <UserAccessCard currentUser={currentUser} isLoading={query.isLoading} />
              </CheckFeatures>
            </div>
          </div>
        )
      }
    </Container>
  )
}

