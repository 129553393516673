import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query'
import { useNotifications } from '../shared/Notifications.js'

import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import AccessForm from './AccessForm.js'
import Header from "../shared/Header.js";
import { useMutationClient } from '../shared/hooks.js'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AccessFormBasic from './AccessFormBasic.js';

export default function NewAccessPage() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const mutationClient = useMutationClient()
  const notifications = useNotifications()

  const createAccess = useMutation(
    data => mutationClient.post(`doors/access/new`, data), {
    onSuccess: (data, variables, context) => {
      if (data.status === 200) {
        notifications.success('Permiso creado exitosamente')
        navigate(`/permissions`)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al crear el permiso')
    }
  })

  const [key, setKey] = useState(0);

  return (
    <Container>
      <Row>
        <Header title="Nuevo permiso" items={[
          { label: "Listado de permisos", to: "/permissions" },
          { label: "Nuevo permiso" },
        ]} />
      </Row>
      <AccessFormBasic
        access={{}}
        save={data => createAccess.mutate(data)}
        isLoading={createAccess.isLoading}
      />
    </Container>
  )
}

