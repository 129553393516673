import React from "react";
import { useQuery } from 'react-query';

import { Link, useNavigate, useParams, Navigate } from 'react-router-dom';
import { useFromQueryString } from '../../utils.js';

import Header, { HeaderActions } from "../shared/Header.js";

import TableList from '../shared/TableList.js';

import CompanyDetailSubmenu from '../companies/CompanyDetailSubmenu.js';
import { Container } from '../layout/AppLayout.js';
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';
 

function RolesTable({ roles, CompanyId }) {
  const CheckFeatures = useFeatureChecker()

  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="Nombre" />
      </TableList.Header>

      <TableList.Body>
        {
          roles.map(item => (
            <TableList.Row key={item.id}>
              <TableList.Column>
                  <CheckFeatures feature="DETAIL_ROLE">
                    <AllowedFeature>
                    <Link to={`/companies/${CompanyId}/roles/${item.id}`}>{item.name}</Link>
                    </AllowedFeature>
                    <DeniedFeature>
                      <span>{item.name}</span>
                    </DeniedFeature>
                  </CheckFeatures>
              </TableList.Column>
            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}

export default function RolesListPage() {
  const params = useParams()
  const queryString = useFromQueryString()
  const navigate = useNavigate()
  const CheckFeatures = useFeatureChecker()

  const query = useQuery(`roles/list?CompanyId=${params.CompanyId}`,{ meta: { withoutCompanyId: true}})

  return (
    <Container>
      <div className="row">
        <Header title="Detalle de compañía - Roles" items={[
          { label: `Listado de compañías`, to: "/companies" },
          { label: `Detalle de compañía`, to: `/companies/${params.CompanyId}` },
          { label: `Listado de roles` }
        ]}>
          <HeaderActions>
            <CheckFeatures feature="CREATE_ROLE">
              <Link to={`/companies/${params.CompanyId}/roles/new`} className="btn btn-primary mr-2">
                <span>Crear Rol</span>
              </Link>
            </CheckFeatures>

          </HeaderActions>
        </Header>
      </div>

      
      <CheckFeatures feature='DETAIL_COMPANY'>
        <div className="row">
          <CompanyDetailSubmenu CompanyId={params.CompanyId} />
        </div>
      </CheckFeatures>

      <CheckFeatures feature="LIST_ROLES">
        <AllowedFeature>
          <div className="row">
            <div className="col-12 pb-0">
              <RolesTable
                CompanyId={params.CompanyId}
                roles={query.data && query.data.result || []}
              />
            </div>
          </div>
        </AllowedFeature>
        <DeniedFeature>
          <Navigate to={'/error?error=403'}/>
        </DeniedFeature>
      </CheckFeatures>
    </Container>
  )
}

