import React, { useEffect, useState,useContext } from 'react';
import { useQuery } from 'react-query';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';

import { formatDistanceToNow, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

import { faHdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from '../layout/AppLayout.js';
import Spinner from '../shared/Spinner.js';

import Carousel from 'better-react-carousel';
import { formatDate } from '../../utils.js';
import { DestroyButton } from '../shared/Buttons.js';
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';
import Header, { HeaderActions } from "../shared/Header.js";
import { useNotifications } from '../shared/Notifications.js';
import SensorHealth from '../shared/SensorHealth.js';
import { useMutationClient, useSensorType } from '../shared/hooks.js';


import { CurrentUserContext } from '../../App.js';

const formatAlertDate = (date) => {
  const d = new Date(Date.parse(date))
  const region = "es-CL"
  return `${d.toLocaleDateString(region)} ${d.toLocaleTimeString(region)}`
}

function SensorDetail({ currentSensor }) {
  const currentUser = useContext(CurrentUserContext)
  const CheckFeatures = useFeatureChecker()
  const RoleLevel = currentUser && currentUser.role_level

  return (
    <div>
      <div className="form-group">
        <div className="row pb-0">
          <div className="col">
            <label className="form-label font-weight-bold">Nombre</label>
            <p className="m-0">{currentSensor.name}</p>
          </div>

          <div className="col">
            <label className="form-label font-weight-bold">Último dato recibido</label>
            <p className="m-0">
              <SensorHealth sensor={currentSensor} />
            </p>
          </div>
        </div>
      </div>
      {
      RoleLevel === 0 ?
      <CheckFeatures feature="DISABLE">
        {

          <>
            <div className="form-group">
              <label className="form-label font-weight-bold">Tópico de acciones MQTT</label>
              <p>
                <kbd>{currentSensor.topic ? currentSensor.topic : `devices/${currentSensor.device_id}/data`}</kbd>
              </p>
            </div>
          </>
        }
      </CheckFeatures>
      :null
      }


      <div className="form-group">
        <label className="form-label font-weight-bold">Tipo</label>
        <p>
          {currentSensor.type_label}
        </p>
      </div>
      <div className="form-group">
        <label className="form-label font-weight-bold">Zona</label>
        <p>
          <CheckFeatures feature="DETAIL_ZONE">
            <AllowedFeature>
              {
                currentSensor.zone_name ? 
                  <Link to={`/zones/${currentSensor.zone_id}`}>
                    <span>
                      {currentSensor.zone_name }
                    </span>
                  </Link>
                :
                  <span>---- </span>
              }
            </AllowedFeature>
            <DeniedFeature>
              <span>{currentSensor.zone_name ? currentSensor.zone_name : "----"}</span>
            </DeniedFeature>
          </CheckFeatures>
        </p>
      </div>

      <div className="form-group">
        <label className="form-label font-weight-bold">Device Id</label>
        <p>
          <kbd>{currentSensor.device_id}</kbd>
        </p>
      </div>
    </div >
  )
}

function DoorsList({ SensorId }) {
  const CheckFeatures = useFeatureChecker()
  const query = useQuery(`doors?SensorId=${SensorId}`)
  const doors = query.data && query.data.result || []

  return (
    <div className="list-group border-0 rounded-0">
      {
        doors.length ?
          doors.map(item => (
            <div key={item.id} className="border-top-0 list-group-item d-flex justify-content-between">
              <CheckFeatures feature="DETAIL_DOOR">
                <AllowedFeature>
                  <Link to={`/doors/${item.id}`}>{item.name}</Link>
                </AllowedFeature>
                <DeniedFeature>
                  <span>{item.name ? item.name : "----"}</span>
                </DeniedFeature>
              </CheckFeatures>


              <CheckFeatures feature="DETAIL_ZONE">
                <AllowedFeature>
                  {
                    item.zone_name ?
                      <Link to={`/zones/${item.zone_id}`}>
                        <span>
                          {item.zone_name}
                        </span>
                      </Link>
                    :
                      <span>----</span>
                  }
                </AllowedFeature>
                <DeniedFeature>
                  <span>{item.zone_name ? item.zone_name : "----"}</span>
                </DeniedFeature>
              </CheckFeatures>
            </div>
          ))
          :
          <div className="py-4 my-4 text-center text-muted">Sin puertas asociadas</div>
      }
    </div>
  )
}

function MediaList({ SensorId }) {
  const query = useQuery(`media/device?sensorId=${SensorId}`)
  const media = query.data && query.data.result || []
  return (
    <div className="list-group border-0 rounded-0">
      {
        media.length ?
          <div>
            <Carousel showDots={true} >
              {
                media.map(item => (
                  <Carousel.Item >
                    <img width="100%" src={item.media_url} alt="" />
                  </Carousel.Item>
                ))
              }
            </Carousel>
          </div>
          :
          <div className="py-4 my-4 text-center text-muted">Sin media asociadas</div>
      }
    </div>
  )
}

function DiskUsageWidget({ currentSensor }) {
  const diskSize = currentSensor.metadata.diskSize
  const [diskUsage, setDiskUsage] = useState('')
  const [elapsedDate, setElapsedDate] = useState()
  const [formattedDate, setFormattedDate] = useState()
  const [usagePercent, setUsagePercent] = useState(null)
  const [usageColor, setUsageColor] = useState('')

  const query = useQuery(`sensors/${currentSensor.id}/data?limit=1&fields=diskUsage`)

  useEffect(() => {
    if (query.data) {
      const item = query.data.result.items && query.data.result.items.length && query.data.result.items[0]

      if (item) {
        const total = Object.values(item.diskUsage).reduce((acc, x) => acc + x, 0)
        const totalGB = total / 1000000
        let usageInPercent = (totalGB * 100 / diskSize)

        if (usageInPercent >= 100) {
          usageInPercent = 100;
        }

        if (usageInPercent > 90) {
          setUsageColor('danger')
        } else if (usageInPercent > 80) {
          setUsageColor('warning')
        } else {
          setUsageColor('info')
        }

        setDiskUsage(totalGB.toFixed(2))
        setUsagePercent(usageInPercent.toFixed(1))

        const ts = parseISO(item.date)
        const elapsed = formatDistanceToNow(ts, { addSuffix: true, locale: es })
        const formatted = formatDate(item.date, "dd/MM/yyyy HH:mm:ss")

        setFormattedDate(formatted)
        setElapsedDate(elapsed)
      }

    }
  }, [query.data])

  return (
    <div className="card shadow-sm mt-3">
      <div className="card-header">Espacio de disco utilizado</div>
      <div className="card-body">
        {
          query.isLoading ?
            <div className="text-center">
              <Spinner />
            </div>
            :
            diskUsage ?
              <div className="d-flex">
                <div className="mr-3">
                  <FontAwesomeIcon icon={faHdd} size="4x" />
                </div>

                <div className="flex-fill flex-frow-1">
                  <div className="mb-2 d-flex justify-content-between">
                    <span>{diskUsage} / {diskSize} GB</span>
                    <em className="text-muted" style={{ fontSize: '.9em' }} title={formattedDate}>{elapsedDate}</em>
                  </div>
                  <div>
                    <div title={`${usagePercent}% usado`} className="rounded" style={{ position: 'relative', width: '100%', backgroundColor: '#f0f0f0', height: '20px' }}>
                      <div className={`rounded bg-${usageColor}`} style={{ position: 'abosulte', width: `${usagePercent}%`, height: '20px' }}></div>
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className="text-muted text-center">No se encontraron datos</div>
        }
      </div>
    </div>
  )
}

function SensorWidgets({ currentSensor }) {
  if (currentSensor && currentSensor.metadata && currentSensor.metadata.diskSize) {
    return (
      <DiskUsageWidget currentSensor={currentSensor} />
    )
  }

  return null
}

function ExtraDetailsCard({ currentSensor }) {
  const sensorType = useSensorType(currentSensor ? currentSensor.sensor_type_id : null)
  const CheckFeatures = useFeatureChecker()

  if (sensorType && sensorType.custom_fields) {
    return (
      <CheckFeatures feature="DATA_SENSORS">
        <Card className="shadow-sm">
          <Card.Header>Información adicional</Card.Header>

          <Card.Body>
            {
              sensorType.custom_fields.map(item => (
                <div className="form-group" key={item.name}>
                  <label className="form-label font-weight-bold">{item.label}</label>
                  <p className="m-0">{currentSensor && currentSensor.metadata && currentSensor.metadata[item.name]}</p>
                </div>
              ))
            }
          </Card.Body>

        </Card>
      </CheckFeatures>
    )
  }

  return null
}

function FailureDetailPage({ currentSensor }){

  const query = useQuery(`devicereport/${currentSensor}?limit=5&type=failure`)
  const fallas = query.data && query.data.result || []

  if(!fallas.length) {
    return (
      <div className="card shadow-sm mb-3">
        <div className="card-header d-flex justify-content-between align-items-center">
          <span>
            Últimas fallas
          </span>
          <Link to={`/sensors/${currentSensor}/failure`} className="btn btn-link">
            <span>Ver detalle</span>
          </Link>
        </div>
        <div className="table mb-0" style={{ fontSize: '0.9em' }} >
          <div className="text-center text-muted py-4 my-4">No se encontraron fallas</div>
        </div>
      </div>    
    ) 
  }

  return (
    <div className="card shadow-sm mb-3">
      <div className="card-header d-flex justify-content-between align-items-center">
        <span>
          Últimas fallas
        </span>
        <Link to={`/sensors/${currentSensor}/failure`} className="btn btn-link">
          <span>Ver detalle</span>
        </Link>
      </div>

      <table className="table mb-0" style={{ fontSize: '0.9em' }}>
        <thead>
          <tr>
            <th>
              Fecha
            </th>
            <th>
              Usuario
            </th>
            <th>
              Tipo
            </th>
            <th>
              Comentario
            </th>
          </tr>
        </thead>

        <tbody>
          {
            fallas.map(item => (
              <tr key={item.id}>
                
                <td>
                  { formatAlertDate(item.created_at) }
                </td>
                <td>
                  {item.user_name }
                </td>
                <td>
                  { item.report_type }
                </td>
                <td>
                  {item.comments}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>

  )
}


function MaintenanceDetailPage({ currentSensor }){

  const query = useQuery(`devicereport/${currentSensor}?limit=5&type=maintance`)
  const maintenance = query.data && query.data.result || []

  if(!maintenance.length) {
    return (
      <div className="card shadow-sm mb-3 mt-3">
        <div className="card-header d-flex justify-content-between align-items-center">
          <span>
            Últimas mantenciones
          </span>
          <Link to={`/sensors/${currentSensor}/maintance`} className="btn btn-link">
            <span>Ver detalle</span>
          </Link>
        </div>
        <div className="table mb-0" style={{ fontSize: '0.9em' }} >
          <div className="text-center text-muted py-4 my-4">No se encontraron mantenciones</div>
        </div>
      </div>
    )
  }

  return (
    <div className="card shadow-sm mb-3 mt-3">
      <div className="card-header d-flex justify-content-between align-items-center">
        <span>
        Últimas mantenciones
        </span>
        <Link to={`/sensors/${currentSensor}/maintance`} className="btn btn-link">
        <span>Ver detalle</span>
      </Link>
      </div>

      <table className="table mb-0" style={{ fontSize: '0.9em' }}>
        <thead>
          <tr>
            <th>
              Fecha
            </th>
            <th>
              Usuario
            </th>
            <th>
              Tipo
            </th>
            <th>
              Comentario
            </th>
          </tr>
        </thead>

        <tbody>
          {
            maintenance.map(item => (
              <tr key={item.id}>
                
                <td>
                  { formatAlertDate(item.created_at) }
                </td>
                <td>
                  {item.user_name }
                </td>
                <td>
                  { item.report_type }
                </td>
                <td>
                  {item.comments}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      
    </div>

  )
}
export default function SensorDetailPage() {
  const CheckFeatures = useFeatureChecker()
  const params = useParams()
  const mutationClient = useMutationClient()
  const navigate = useNavigate()
  const notifications = useNotifications()

  const currentUser = useContext(CurrentUserContext)
  const RoleLevel = currentUser && currentUser.role_level

  const { isLoading, data } = useQuery(`sensors?SensorId=${params.SensorId}`)
  const currentSensor = data && data.result && data.result.length && data.result[0]

  useEffect(() => {
    if (!isLoading && !currentSensor) {
      notifications.warning('El sensor solicitado no existe')
      navigate(`/sensors`)
    }
  }, [currentSensor, isLoading])

  return (
    <Container>
      <Row>
        <Header title="Detalle de dispositivo" items={[
          { label: "Listado de dispositivos", to: "/sensors" },
          { label: "Detalle de dispositivo" }
        ]}>
          <HeaderActions>
            <CheckFeatures feature="UPDATE_SENSORS">
              <Link
                className="btn btn-primary"
                to={{
                  pathname: `/sensors/${params.SensorId}/edit`,
                  state: { sensor: currentSensor }
                }}
              >Editar sensor</Link>
            </CheckFeatures>

            <CheckFeatures feature="DESTROY_SENSORS">
              <div className="d-inline ml-2">
                <DestroyButton
                  label="Eliminar dispositivo"
                  confirmMessage="¿Está seguro que desea eliminar el dispositivo?"
                  successMessage="Dispositivo eliminado correctamente"
                  deletePath={`sensors/${params.SensorId}`}
                  invalidate={`sensors`}
                  redirect="/sensors"
                />
              </div>
            </CheckFeatures>
          </HeaderActions>
        </Header>
      </Row>

      <Row className="mb-2">
        <Col className="col-12 col-md-6 pb-3 pb-md-0">
          <Card className="shadow-sm mb-3">
            <Card.Header>Detalles</Card.Header>
            <Card.Body>
              <CheckFeatures feature="DETAIL_SENSORS">
                <AllowedFeature>
                  {
                  isLoading ?
                    <div className="text-center my-4 py-4">
                      <Spinner />
                    </div>
                    :
                    <SensorDetail currentSensor={currentSensor} />
                  }
                </AllowedFeature>
                <DeniedFeature>
                  <Navigate to={'/error?error=403'}/>
                </DeniedFeature>
              </CheckFeatures>
            </Card.Body>
          </Card>
          {//RoleLevel === 0 ?
            <>
              <ExtraDetailsCard currentSensor={currentSensor} />
              <SensorWidgets currentSensor={currentSensor} />
            </>
          //: null
          }
        </Col>

        <Col className="col-12 col-md-6">
          <CheckFeatures feature="LIST_DOORS_SENSOR">
            <Card className="shadow-sm">
              <Card.Header>Puertas</Card.Header>
              <DoorsList SensorId={params.SensorId} />
            </Card>
          </CheckFeatures>
          <CheckFeatures feature="LIST_MEDIA_SENSOR">
            <Card className="shadow-sm mt-4">
              <Card.Header>Media</Card.Header>
              <MediaList SensorId={params.SensorId} />
            </Card>
          </CheckFeatures>
        </Col>
        <CheckFeatures feature="LIST_REPORTS">
          <Col className="col-12 col-md-12 pb-3 pb-md-0" >
            <MaintenanceDetailPage currentSensor={params.SensorId}/>
            <FailureDetailPage currentSensor={params.SensorId}/>
          </Col>
        </CheckFeatures>

      </Row>
    </Container>
  )
}
