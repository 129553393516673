import React, { useContext, useEffect} from 'react';
import { useQuery } from 'react-query'
import { Link, useParams, useNavigate } from 'react-router-dom';

import { Container } from '../layout/AppLayout.js'
import { DestroyButton } from "../shared/Buttons.js"
import Header, { HeaderActions } from "../shared/Header.js";
import useFeatureChecker, { isAllowed } from '../shared/FeatureChecker.js'
import { CurrentUserContext } from '../../App.js';

import ZoneDetailSubmenu from '../zones/ZoneDetailSubmenu.js';
import Spinner from '../shared/Spinner.js';

function TagsList({ tags }){
  if( tags.length ){
    return (
      <div>
        {
          tags.map(item => (
            <div
              key={item.id}
              className="badge badge-info py-1 px-2 mr-2"
              style={{ fontSize: '.9em' }}
            >{ item.name }</div>
          ))
        }
      </div>
    )
  }else{
    return "Sin etiquetas asignadas"
  }
}

function DetailCard({ asset, isLoading }){
  return (
    <div className="card shadow-sm">
      <div className="card-header">Detalle del activo</div>
      <div className="card-body">
        {
          isLoading ?
            <div className="text-center py-4 my-4">
              <Spinner />
            </div>
          :
            asset ?
              <React.Fragment>
                <div className="form-group">
                  <label className="font-weight-bold">Nombre</label>
                  <p>{ asset.name }</p>
                </div>

                <div className="form-group">
                  <label className="font-weight-bold">Descripción</label>
                  <p>{ asset.description ? asset.description : '--' }</p>
                </div>

                <div className="form-group">
                  <label className="font-weight-bold">Etiquetas</label>

                  <div>
                    <TagsList tags={asset.tags} />
                  </div>
                </div>
              </React.Fragment>
            : null
        }

      </div>
    </div>
  )

}
export default function AssetDetailPage() {
  const params = useParams()
  const CheckFeatures = useFeatureChecker()
  const navigate = useNavigate()
  const currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "DETAIL_ASSETS"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const query = useQuery(`assets/${params.AssetId}`)

  return (
    <Container>
      <div className="row">
        <Header title="Detalle de zona - Detalle de activo" items={[
          { label: `Listado de zonas`, to: "/zones" },
          { label: `Detalle de zona`, to: `/zones/${params.ZoneId}` },
          { label: `Activos`, to: `/zones/${params.ZoneId}/assets` },
          { label: `Detalle de activo` },
        ]}>
          <HeaderActions>
            <CheckFeatures feature="UPDATE_ASSETS">
              <Link className="btn btn-primary mr-3" to="edit">Editar activo</Link>
            </CheckFeatures>

            <CheckFeatures feature="DESTROY_ASSETS">
              <DestroyButton
                label="Eliminar activo"
                confirmMessage="¿Está seguro que desea eliminar el activo?"
                successMessage="Activo eliminado correctamente"
                deletePath={`assets/${params.AssetId}`}
                invalidate="assets"
                redirect={`/zones/${params.ZoneId}/assets`}
              />
            </CheckFeatures>
          </HeaderActions>
        </Header>
      </div>

      <div className="row">
        <ZoneDetailSubmenu ZoneId={params.ZoneId}/>
      </div>

      <div className="row">
        <div className="col-6">
          <DetailCard
            asset={query.data && query.data.result}
            isLoading={query.isLoading}
          />
        </div>
      </div>
    </Container>
  )
}