import React, { useEffect } from 'react'

import { useNavigate, Link, useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useNotifications } from '../shared/Notifications.js'

import { Container } from '../layout/AppLayout.js'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { useMutationClient } from '../shared/hooks.js'
import Spinner from '../shared/Spinner.js'
import Header from "../shared/Header.js"

import CompanyForm from './CompanyForm.js'

export default function EditCompanyPage(){
  const params = useParams()
  const queryClient = useQueryClient()
  const mutationClient = useMutationClient()
  const navigate = useNavigate()
  const notifications = useNotifications()

  const query = useQuery(`companies/${params.CompanyId}`)

  const updateCompany = useMutation(
    data => mutationClient.put(`companies/${params.CompanyId}`, data), {
    onSuccess: (data, variables, context) => {
      if( data.status === 200 ){
        notifications.success('Compañía actualizada correctamente')
        queryClient.invalidateQueries(`companies`)
        navigate(`/companies/${params.CompanyId}`)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al actualizar la compañía')
    }
  })

  return (
    <Container>
      <Row>
        <Header title="Editar compañía" items={[
          { label: "Listado de compañías", to: "/companies" },
          { label: "Editar compañía" },
        ]} />
      </Row>

      <Row className="mb-4">
        <div className="col-12 mb-2 col-lg-6">
          <div className="card shadow-sm">
            <div className="card-header">Editar compañía</div>

            <div className="card-body">
              {
                query.isLoading ?
                  <div className="text-center py-4 my-4">
                    <Spinner />
                  </div>
                :
                  <CompanyForm
                    company={query.data && query.data.result || {}}
                    save={data => updateCompany.mutate(data)}
                    isLoading={updateCompany.isLoading}
                  />
              }
            </div>
          </div>
        </div>
      </Row>
    </Container>
  )
}

