import React, { useState,useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLinkAlt,
  faSms,
  faEnvelope,
  faCog,
  faCamera,
  faCheckCircle,
  faMinusCircle,
} from '@fortawesome/free-solid-svg-icons';

import { formatDistanceToNow, parseISO  } from 'date-fns'
import { es } from 'date-fns/locale'

import { formatDate } from '../../utils.js'
import { useSensorType } from '../shared/hooks.js'
import { useNotifications } from '../shared/Notifications.js'
import { DestroyButton } from '../shared/Buttons.js';
import Header, { HeaderActions } from "../shared/Header.js"
import Spinner from '../shared/Spinner.js';
import useFeatureChecker, { isAllowed, AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js'
import { CurrentUserContext } from '../../App.js';
import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import DoorDetailSubmenu from './DoorDetailSubmenu.js'

function SensorItem({ sensor }){
  const query = useQuery(`sensors/${sensor.id}/data?limit=1`)
  const sensorType = useSensorType(sensor.sensor_type_id)

  const attributes = sensorType && sensorType.variables || []

  function variableValue(data, attribute){
    let value = '';
    let factor = 1;
    if(data && data.items && data.items.length){
      value = data.items[0][attribute.field]

      if( value === undefined ){
        return 'Sin datos encontrados'
      }
      if(data.metadata && data.metadata.factors){
        if (attribute.field in data.metadata.factors){
          factor=data.metadata.factors[attribute.field]
        }
      }
      if( attribute.values ){
        value = attribute.values[value]*factor || ''

      }else{
        if( !Number.isInteger(value) ){
          try{
            value = value.toFixed(2) * factor
          }catch(e){
          }
        }else{
          value = value * factor
          if( !Number.isInteger(value) ){
            try{
              value = value.toFixed(2)
            }catch(e){
            }
          }
        }

        if( attribute.unit ){
          value = `${value} ${attribute.unit}`
        }
      }
    }

    return value
  }

  function parsedDate(data, isoDateFormat){
    let value = '';

    if(data && data.items && data.items.length){
      if( isoDateFormat ){
        value = formatDate(data.items[0].date, 'dd/MM/yyyy HH:mm:ss')
      }else{
        value = formatDistanceToNow(parseISO(data.items[0].date.split(' ').join('T') + 'Z'), { addSuffix: true, locale: es })
      }
    }

    return value
  }

  return (
    <>
      {
        attributes.map(item => (
          <tr key={item.field}>
            <td>
              { item.label }
            </td>

            <td
              style={{
                opacity: (query.isFetching || query.isLoading) ? 0.5 : 1
              }}
            >
              {
                query.isLoading ?
                  'cargando...'
                :
                  variableValue(query.data && query.data.result, item)
              }
            </td>

            <td
              align="right"
              title={parsedDate(query.data && query.data.result, true)}
              style={{
                opacity: (query.isFetching || query.isLoading) ? 0.5 : 1
              }}
            >
              {
                query.isLoading ?
                  'cargando...'
                :
                  parsedDate(query.data && query.data.result, false)
              }
            </td>
          </tr>
        ))
      }
    </>
  )
}

function SensorsDataCard({ DoorId }){
  const query = useQuery(`sensors?DoorId=${DoorId}`, { enabled: !!DoorId })
  const devices = query.isSuccess && query.data && query.data.result || []

  if( query.isLoading ){
    return <Spinner />
  }else{
    if( query.isSuccess && !devices.length ){
      return (
        <div className="text-muted text-center my-4 py-4">No se encontraron dispositivos asociados</div>
      )
    }

    return (
      <table className="table mb-0" style={{ fontSize: '0.9em' }}>
        <tbody>
          {
            devices.map(item => <SensorItem key={item.id} sensor={item} />)
          }
        </tbody>
      </table>
    )
  }
  return null
}

function DoorDetail({ door }){
  const CheckFeatures = useFeatureChecker()
  const currentUser = useContext(CurrentUserContext)
  const RoleLevel = currentUser && currentUser.role_level

  return (
    <div>
      <div className="form-group">
        <label className="form-label font-weight-bold">Nombre</label>
        <p>{ door.name }</p>
      </div>

      {
        door.address ?
          <div className="form-group">
            <label className="form-label font-weight-bold">Dirección</label>
            <p>
              <CheckFeatures feature ='FACILITY_ADDRESS'>
                <AllowedFeature>
                  <a target="_blank" href={`https://www.google.com/maps/?q=${door.latitude},${door.longitude}`}>
                    {door.address}
                    <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
                  </a>
                </AllowedFeature>
                <DeniedFeature>
                  {door.address}
                </DeniedFeature>
              </CheckFeatures>
              
            </p>
          </div>
        : null
      }

      <div className="form-group">
        <label className="form-label font-weight-bold">Tipo de puerta</label>
        <p>
          {
            door.door_type ? door.door_type : 'No seleccionado'
          }
        </p>
      </div>

      <div className="form-group">
        <label className="form-label font-weight-bold">Zona</label>
        <p>
          {
            door.zone_id ?
              <CheckFeatures feature ='DETAIL_ZONE'>
                <AllowedFeature>
                  <Link to={`/zones/${door.zone_id}`}>{door.zone_name}</Link>
                </AllowedFeature>
                <DeniedFeature>
                  {door.zone_name}
                </DeniedFeature>
              </CheckFeatures>
            : 'Sin zona asignada'
          }
        </p>
      </div>

      { RoleLevel == 0 ?
        <div className="form-group">
          <label className="form-label font-weight-bold">Identificador Único</label>
          <p>
            <kbd>{ door.uuid }</kbd>
          </p>
        </div>
      :null}
      
      <div className="form-group">
        <label className="form-label font-weight-bold">Modo de apertura</label>

        <div className="mb-2">
          <span className="mr-2">
            {
              door.httpEnabled ?
                <FontAwesomeIcon icon={faCheckCircle} className="text-primary" />
              :
                <FontAwesomeIcon icon={faMinusCircle} className="text-secondary" />
            }
          </span>

          <span>
            { door.httpEnabled ? 'Apertura remota habilitada' : 'Apertura remota deshabilitada' }
          </span>
        </div>

        <div className="mb-2">
          <span className="mr-2">
            {
              door.bleEnabled ?
                <FontAwesomeIcon icon={faCheckCircle} className="text-primary" />
              :
                <FontAwesomeIcon icon={faMinusCircle} className="text-secondary" />
            }
          </span>

          <span>
            { door.bleEnabled ? 'Apertura por bluetooth habilitada' : 'Apertura por bluetooth deshabilitada' }
          </span>
        </div>
        <div className="mb-2">
          <span className="mr-2">
            {
              door.isNFC ?
                <FontAwesomeIcon icon={faCheckCircle} className="text-primary" />
              :
                <FontAwesomeIcon icon={faMinusCircle} className="text-secondary" />
            }
          </span>

          <span>
            { door.isNFC ? 'Apertura por NFC habilitada' : 'Apertura por NFC deshabilitada' }
          </span>
        </div>
        
        <div className="mb-2">
          <span className="mr-2">
            {
              door.isQR ?
                <FontAwesomeIcon icon={faCheckCircle} className="text-primary" />
              :
                <FontAwesomeIcon icon={faMinusCircle} className="text-secondary" />
            }
          </span>

          <span>
            { door.isQR ? 'Apertura por QR habilitada' : 'Apertura por QR deshabilitada' }
          </span>
        </div>
      </div>
    </div>
  )
}

function DoorSensorsTable({ DoorId }){
  const query = useQuery(`sensors/?DoorId=${DoorId}&limit=10`, { enabled: !!DoorId })
  const sensors = !query.isLoading && query.data.result || []
  const CheckFeatures = useFeatureChecker()

  if( query.isLoading ){
    return (
      <div className="text-center my-4 py-4">
        <Spinner />
      </div>
    )
  }

  if( !sensors.length ){
    return <div className="text-center py-4 my-4 text-muted">No se encontraron dispositivos</div>
  }

  return (
    <table className="table table-hover m-0" style={{ fontSize: '.9em' }}>
      <tbody>
        {
          sensors.map(item => (
            <tr key={item.id}>
              <td className="table-tr-td">
                <CheckFeatures feature='DETAIL_SENSORS'>
                  <AllowedFeature>
                    <Link to={ "/sensors/" + item.id }>{ item.type_label }</Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    {item.type_label}
                  </DeniedFeature>
                </CheckFeatures>
              </td>

              <td className="table-tr-td">
                { item.name }
              </td>

              <td className="table-tr-td">
                <span style={{ fontFamily: 'monospace' }}>{ item.device_id }</span>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

function DoorAccessLogs({ DoorId }){
  const query = useQuery(`alertevents/entries?DoorId=${DoorId}&limit=10&sort=created_at-desc`, { enabled: !!DoorId })
  const entries = !query.isLoading && query.data && query.data.result || []
  const CheckFeatures = useFeatureChecker()

  if( query.isLoading ){
    return (
      <div className="text-center my-4 py-4">
        <Spinner />
      </div>
    )
  }


  if (!entries.length) {
    return <div className="text-center text-muted my-4 py-4">No se encontraron registros</div>
  }

  return (
    <table className="table table-stripped table-hover m-0" style={{ fontSize: '0.85em' }}>
      <thead>
        <tr>
          <th scope="row" className="table-tr-th">#</th>
          <th scope="row" className="table-tr-th">Fecha y hora</th>
          <th scope="row" className="table-tr-th">Usuario</th>
        </tr>
      </thead>
      <tbody>
        {
          entries.map(item => (
            <tr key={item.id}>
              <td className="table-tr-td">
                <CheckFeatures feature='DETAIL_ENTRY'>
                  <AllowedFeature>
                    <Link style={{ fontFamily: 'monospace' }} to={"/entries/" + item.id}>#{item.id}</Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    #{item.id}
                  </DeniedFeature>
                </CheckFeatures>
              </td>

              <td className="table-tr-td">
                { formatDate(item.created_at) }
              </td>

              <td className="table-tr-td">
                { item.user_name ? item.user_name : '--' }
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

export default function DoorDetailPage(){
  const params = useParams()
  const navigate = useNavigate()
  const CheckFeatures = useFeatureChecker()
  const notifications = useNotifications()
  const currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "DETAIL_DOOR"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const query = useQuery(`doors/?DoorId=${params.DoorId}`)
  const currentDoor = query.data && query.data.result && query.data.result.length && query.data.result[0]

  useEffect(() => {
    if( !query.isLoading && !currentDoor ){
      notifications.warning('La puerta no existe')
      navigate(`/doors`)
    }
  }, [currentDoor, query.isLoading])

  return (
    <Container>
      <Row>
        <Header title="Detalle de puerta" items={[
          { label: `Listado de puertas`, to: "/doors" },
          { label: `Detalle de puerta` },
        ]}>
          <HeaderActions>
            <CheckFeatures feature="UPDATE_DOOR">
              <Link to={ "/doors/" + params.DoorId + "/edit" } className="btn btn-primary mr-2">Editar puerta</Link>
            </CheckFeatures>


            <CheckFeatures feature="DESTROY_DOOR">
              <DestroyButton
                label="Eliminar puerta"
                confirmMessage="¿Está seguro que desea eliminar la puerta?"
                successMessage="Puerta eliminada correctamente"
                deletePath={`doors/${params.DoorId}`}
                invalidate="doors"
                redirect="/doors"
              />
            </CheckFeatures>
          </HeaderActions>
        </Header>
      </Row>

      <div className="row">
        <DoorDetailSubmenu DoorId={params.DoorId}/>
      </div>

      <Row className="mb-2 pb-4">
        <div className="col-12 mb-2 col-lg-6">
          <Card className="shadow-sm mb-3">
            <Card.Header className="card-hearder">
              <span>Información de puerta</span>
            </Card.Header>

            <Card.Body>
              {
                query.isLoading ?
                  <div className="text-center my-4">
                    <Spinner />
                  </div>
                :
                  <DoorDetail door={currentDoor} />
              }
            </Card.Body>
          </Card>

          {
            <CheckFeatures feature ='LIST_SENSORS'>
              <Card className="shadow-sm">
                <Card.Header className="card-hearder">
                  <span>Dispositivos asociados</span>
                </Card.Header>

                <DoorSensorsTable DoorId={params.DoorId} />
              </Card>
            </CheckFeatures>

          }
        </div>

        <div className="col-12 col-lg-6">
          {
            <CheckFeatures feature='LIST_ENTRIES'>
              <Card className="shadow-sm mb-3">
                <Card.Header className="card-hearder">
                  <span>Últimos accesos</span>
                </Card.Header>

                <DoorAccessLogs DoorId={params.DoorId} />
              </Card>
            </CheckFeatures>

          }

          <CheckFeatures feature='DATA_SENSORS'>
            <Card className="shadow-sm mb-3">
              <Card.Header>Últimos datos</Card.Header>
              <Card.Body className="p-0">
                {
                  <SensorsDataCard DoorId={params.DoorId} />
                }
              </Card.Body>
            </Card>
          </CheckFeatures>
        </div>
      </Row>
    </Container>
  )
}

