import Spinner from '../../../shared/Spinner.js';
import { useQuery } from 'react-query'
import translateEvent from '../../../events/translate.js';
import { Link } from 'react-router-dom';
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../../../shared/FeatureChecker.js';


const formatEventDate = (date) => {
    const d = new Date(Date.parse(date))
    const region = "es-CL"
    return `${d.toLocaleDateString(region)} ${d.toLocaleTimeString(region)}`
}

function EventsItem(event) {
    const CheckFeatures = useFeatureChecker() 
    const door = event && event.value && event.value.door || null
    return (
      <tr key={event.id}>
        <td>
          {
            formatEventDate(event.captured_at)
          }
        </td>
  
        <td>
          {
            event && event.value && event.value.events_media_id ? (
              <CheckFeatures feature ='DETAIL_MEDIA'>
                <AllowedFeature>
                  <Link to={`/media/${event.value.events_media_id}`}>
                    {translateEvent(event.type)}
                  </Link>
                </AllowedFeature>
                <DeniedFeature>
                  {translateEvent(event.type)}
                </DeniedFeature>
              </CheckFeatures>

            ) : translateEvent(event.type)
          }
  
        </td>
  
        <td>
          {event && event.value && event.value.door ? (
            <CheckFeatures feature='DETAIL_DOOR'>
              <AllowedFeature>
                <Link to={`/doors/${event.value.door.id}`}>
                  {event.value.door.name}
                </Link>
              </AllowedFeature>
              <DeniedFeature>
                {event.value.door.name}
              </DeniedFeature>
            </CheckFeatures>

          ) : event && event.value && event.value.sensor ? (
            <CheckFeatures feature='DETAIL_SENSORS'>
              <AllowedFeature>
                <Link to={`/sensors/${event.value.sensor.id}`}>
                  {event.value.sensor.name}
                </Link>
              </AllowedFeature>
              <DeniedFeature>
                {event.value.sensor.name}
              </DeniedFeature>
            </CheckFeatures>
            
          ) : null}
        </td>
      </tr>
    )
  }

export default function EventsCard({ ZoneId }) {
    const query = useQuery(`events?ZoneId=${ZoneId}&limit=5`)
    const events = query.isSuccess && query.data && query.data.result || []
  
    const EventsTable = (events) => (
      <>
        <table className="table mb-0" style={{ fontSize: '0.9em' }}>
          <thead>
            <tr>
              <th scope="row" className="table-tr-th">Fecha y hora</th>
              <th scope="row" className="table-tr-th">Evento</th>
              <th scope="row" className="table-tr-th">Puerta/Sensor</th>
            </tr>
          </thead>
          <tbody>
            {
              events.items.map(event => EventsItem(event))
            }
          </tbody>
        </table>
      </>
    )
  
    const NoEventsMessage = () => (
      <>
        <div className="text-muted text-center my-4 py-4">
          No se encontraron eventos asociados a esta zona
        </div>
      </>
    )
  
    return (
      <div className="card shadow-sm mb-3">
        <div className="card-header">Eventos</div>
  
        <div className="card-body">
          {
            query.isLoading
              ? <Spinner />
              : query.isSuccess && ("items" in events)
                ? EventsTable(events)
                : NoEventsMessage()
          }
        </div>
      </div>
    )
}