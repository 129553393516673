import React, { useState, useEffect, useRef } from 'react';
import {  useParams } from 'react-router-dom';

import { DropdownSelector } from '../shared/Filters'
import { useFromQueryString,formatDate } from '../../utils.js'
import Overlay from 'ol/Overlay';

import ImageLayer from 'ol/layer/Image';
import Map from 'ol/Map';
import Projection from 'ol/proj/Projection';
import Static from 'ol/source/ImageStatic';
import View from 'ol/View';
import {getCenter} from 'ol/extent';
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import {Draw,Select,Modify }from 'ol/interaction'
import GeoJSON from 'ol/format/GeoJSON';
import { useNotifications } from '../shared/Notifications.js'

import { useMutationClient,useSensorType } from '../shared/hooks.js';
import { useMutation,useQuery } from 'react-query'

import { formatDistanceToNow, parseISO  } from 'date-fns'
import { es } from 'date-fns/locale'
import useFeatureChecker from '../shared/FeatureChecker.js'

import Feature from 'ol/Feature.js';
import { Point } from 'ol/geom.js';
import Style from 'ol/style/Style.js';
import Icon from 'ol/style/Icon.js';

function ZoneSelector({value,zones,onChange,deshabilitar}){
    const defaultItem =  zones.find(x => x.value === value)
  
    return(
      <DropdownSelector
        defaultLabel="Zona"
        items={zones}
        defaultItem={defaultItem}
        onChange={selection=>  selection ? onChange(selection.value) : onChange(null)}
        disabled={deshabilitar}
        />
      )
  } 
function createPoint(map,item){
    const coordinates = new Feature({
      geometry: new Point ([item.pos_x,item.pos_y]),
      name: item.name
    })
    if(item.sensor_type_id){
        coordinates.set("type",item.sensor_type_id)
        coordinates.set("id",item.sensor_id)
    }
    if(item.tags && item.tags.length>0){
      coordinates.set("tags",item.tags)
    }
   
    /*const iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        scale: 0.4,
        src: 'https://i.ibb.co/j36tDvM/Prueba-Icono.png',
      }),
    });
    coordinates.setStyle(iconStyle);*/
  
    const vector= new VectorSource({
      features:[coordinates]
    })
    const layer=new VectorLayer({
      source: vector
    })
    map.addLayer(layer)
  
    return([layer,vector])
  
}
function createPolygon(floorplan_zone){
    let geojsonObject = {
      'type': 'FeatureCollection',
      'crs': {
        'type': 'name',
        'properties': {
          'name': 'EPSG:3857',
        },
      },
      'features': [
      ],
    };
    for (let item of floorplan_zone){
      const coordinates_ = item.polygon.map(items=>[items.x,items.y])
      const feature={
        'type': 'Feature',
        'geometry': {
          'type': 'Polygon',
          'coordinates': [
              coordinates_
          ],
        },
      }
      geojsonObject.features.push(feature)
    }
    const new_source = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObject),
    });
    return (new_source)
}
function variableValue(data, attribute){
    let value = '';
  
    if(data && data.items && data.items.length){
      value = data.items[0][attribute.field]
  
      if( value === undefined ){
        return 'Sin datos encontrados'
      }
  
      if( attribute.values ){
        value = attribute.values[value] || ''
      }else{
        if( !Number.isInteger(value) ){
          try{
            value = value.toFixed(2)
          }catch(e){
          }
        }
  
        if( attribute.unit ){
          value = `${attribute.label}:  ${value} ${attribute.unit}`
        }
      }
    }
  
    return value
}
  
function parsedDate(data, isoDateFormat){
    let value = '';
  
    if(data && data.items && data.items.length){
      if( isoDateFormat ){
        value = formatDate(data.items[0].date, 'dd/MM/yyyy HH:mm:ss')
      }else{
        value = formatDistanceToNow(parseISO(data.items[0].date.split(' ').join('T') + 'Z'), { addSuffix: true, locale: es })
      }
    }
  
    return value
}
/*
function text(data, attributes){  
    let message='Último dato: '
    attributes.map(item=>{
        message=message+(variableValue(data.data && data.data.result, item))+"\n"
    })
    message=message+parsedDate(data.data && data.data.result, false)
    return (message);
  
}*/
function text(data, attributes){  
    return (
        <div>
            <div className='p-2' style={{fontSize:"0.9rem"}}> Último dato: {parsedDate(data.data && data.data.result, false)}</div>
            {
                attributes?
                 attributes.map(item=>{
                    return(<div key={item.field}  className='p-2' style={{fontSize:"0.9rem"}}>{variableValue(data.data && data.data.result, item)}</div>)
                })
                :null
            }
        </div>
    )  
}
function assetText(data){
  return(
    <div>
        <div className='p-2' style={{fontSize:"0.9rem"}}>Tags disponibles</div>
        {
          data.map(item=>{
            return(<div key={item.id}  className='p-2' style={{fontSize:"0.9rem"}}>- {item.name}</div>)
          })
        }
    </div>
  )
}
export default function useMap(currentFloorplan,setZoneId){
    
    // ES EL MAPA QUE SE RENDERIZA
    const mapa = useRef(null);
    // ES LA URL DE LA IMAGEN
    const map_url=useRef(null);
    // ES LA ID DEL FLOORPLAN
    const map_id=useRef(null);
    //ES EL OBJETO QUE PERMITE DIBUJAR
    const draw= useRef(null);
    // ES EL OBJECTO QUE SELECCIONA
    const selectRef=useRef(null);
    // ES EL POPUP DEL MENSAJE
    const popup=useRef(null);
    // STATE QUE GUARDA SI SE PUEDE SELECCIONAR
    const [isSelect,setIsSelect]=useState(false)
    //STATE QUE GUARDA SI SE SELECCIONO LA ZONA
    const [isSelectZone,setIsSelectZone]=useState(false)
    //STATE QUE GUARDA EL POLIGONO CON LA ZONA SELECCIONADA
    const [zone,setZone]=useState([])
    //STATE QUE GUARDA LA ZONA_ID
    const [zone_id,setIdZone]=useState(null)
    //STATE QUE GUARDA EL OBJECTO SELECCIONADO
    const [selectedZone,setSelectedZone]=useState(null)
    const params = useParams();
    const queryString = useFromQueryString();
    const mutationClient = useMutationClient()
    const notifications = useNotifications()
    //STATE QUE GUARDA LAS COORDENADAS DE LOS SENSORES
    const [sensorsCoordinates,setSensorsCoordinate]=useState([])
    //STATE QUE GUARDA EL PUNTO AL MOVERSE DE LOS SENSORES
    const [sensor,setSensor]=useState(null)
    //STATE QUE GUARDA LAS COORDENADAS DE LOS ASSETS
    const [assetsCoordinates,setAssetsCoordinates]=useState([])
    //STATE QUE GUARDA EL PUNTO DEL ASSETS
    const [asset,setAsset]=useState(null)
    // GUARDA EL TEXTO QUE SE MUESTRA 
    const [popupContent, setPopupContent] = useState("")
    const [popupData,setPopupDataContent]=useState("")
  
    //STATE QUE SIRVEN PARA TRAER LOS DATOS DEL SENSOR SELECCIONADO
    const [sensorId,SetSensorId]= useState(null)
    const [sensorType,setSensorType]=useState(null)
    //STATE QUE SIRVE PARA TRAER LOS TAGS DE LOS ACTIVOS
    const [tags,setTags]=useState(null)
  
    const sensorData = useQuery(`sensors/${sensorId}/data?limit=1`,{enabled: !!sensorId})
    const sensorTypeData = useSensorType(sensorType)
  
    const CheckFeatures = useFeatureChecker()

   /* useEffect(()=>{
  
      if(sensorTypeData && sensorData.data && sensorData.data.result){
        const attributes = sensorTypeData && sensorTypeData.variables || []
  
        setPopupDataContent( text(sensorData,attributes))
      }
    },[sensorId, sensorData])*/
    
    const query = useQuery(`zones?FacilityId=${params.FacilityId}`)
    const zones = query.data && query.data.result || []
    const items=zones.map( item=>({value:(item.id),label:(item.name)}))
  
    const extent = [0, 0, 1980, 1080];
    const projection = new Projection({
      code: 'xkcd-image',
      units: 'pixels',
      extent: extent,
    });
  
    useEffect(() => {
      if(map_url){
        if( !mapa.current ){
          //CREA EL MAPA
          const map = new Map({
            target: 'map',
            view: new View({
              center: getCenter(extent),
              projection: projection,
              zoom: 2,
              maxZoom: 4,
            }),
          })
          mapa.current=map
        }  
      }
      if((currentFloorplan && map_url.current!=currentFloorplan.url && map_id.current!=currentFloorplan.id) ){
        setIsSelect(false)
        setZone([])
        setAssetsCoordinates([])
        setSensorsCoordinate([])
        const drawVector= new VectorLayer({
          source: new VectorSource(),
          style:{
            'stroke-color':'rgba(100,255,0,1)',
            'stroke-width': 2,
            'fill-color': 'rgba(100,255,0,0.3)',
          },
        });
        draw.current=new Draw({
          type: "Polygon",
          source: drawVector.getSource(),
          trace: true,
          style: {
            'stroke-color': 'rgba(255,255,100,0.5)',
            'stroke-width': 1.5,
            'fill-color': 'rgba(255,255,100,0.25)',
            'circle-radius':6,
            'circle-fill-color':'rgba(255,255,100,0.5)'
          }
        });
        selectRef.current=new Select();
        map_url.current=currentFloorplan.url
        map_id.current=currentFloorplan.id
        //SETEA LA IMAGEN DEL MAPA
        mapa.current.setLayers([
          new ImageLayer({
            source: new Static({
              url: map_url.current,
              projection: projection,
              imageExtent: extent,
            }),
          }),drawVector
        ])
        // RELLENA LOS POLIGONOS GUARDADOS EN DB
        if( currentFloorplan.floorplan_zone && currentFloorplan.floorplan_zone.length){
  
          const source=createPolygon(currentFloorplan.floorplan_zone)
          let count=0;
          for(let index in source.uidIndex_){
            const id_zone=currentFloorplan.floorplan_zone[count].zone_id
            const polygon_zone=currentFloorplan.floorplan_zone[count].polygon
            setZone(prev => [...prev, {
              "uid": source.uidIndex_[index].geometryChangeKey_.target.ol_uid,
              "zone_id": id_zone,
              "polygon": polygon_zone
            }])
            count=count+1;
          }
  
          const new_layer= new VectorLayer({
            source: source,
            style: {
              'stroke-color':'rgba(100,255,0,1)',
              'stroke-width': 2,
              'fill-color': 'rgba(100,255,0,0.3)',
            }
          })
  
          mapa.current.addLayer(new_layer)
  
        }
        //VACIA LAS INTERACCIONES
        while(mapa.current.getInteractions().array_.length>9){
          mapa.current.getInteractions().pop()
        }
        //VACIA LOS OVERLAYS
        while(mapa.current.getOverlays().array_.length>0){
          mapa.current.getOverlays().pop()
        }
        //UBICA LOS PUNTOS DE LOS ASSETS GUARDADOS
        if(currentFloorplan.floorplan_assets && currentFloorplan.floorplan_assets.length){
          for (let item of currentFloorplan.floorplan_assets){
            const [layer,vector]=createPoint(mapa.current,item)
            const modify_asset= new Modify({
              hitDetection: layer,
              source: vector
            })
            modify_asset.on(['modifyend'],function(e){
              setAsset({"asset_id":item.asset_id,"floorplan_id":item.floorplan_id,"pos_x":e.features.array_[0].geometryChangeKey_.target.flatCoordinates[0],"pos_y":e.features.array_[0].geometryChangeKey_.target.flatCoordinates[1]})
            })
            setAssetsCoordinates(prev=>[...prev,{"asset_id":item.asset_id,"floorplan_id":item.floorplan_id,"pos_x":item.pos_x,"pos_y":item.pos_y}])
            mapa.current.addInteraction(modify_asset)
          }
        }
        //UBICA LOS PUNTOS DE LOS SENSORES GUARDADOS
        if(currentFloorplan.floorplan_sensor && currentFloorplan.floorplan_sensor.length){
          for (let item of currentFloorplan.floorplan_sensor){
            const [layer,vector]=createPoint(mapa.current,item)
            const modify_sensor= new Modify({
              hitDetection: layer,
              source: vector
            })
            modify_sensor.on(['modifyend'],function(e){
              setSensor({"sensor_id":item.sensor_id,"floorplan_id":item.floorplan_id,"pos_x":e.features.array_[0].geometryChangeKey_.target.flatCoordinates[0],"pos_y":e.features.array_[0].geometryChangeKey_.target.flatCoordinates[1]})
            })
            setSensorsCoordinate(prev=>[...prev,{"sensor_id":item.sensor_id,"floorplan_id":item.floorplan_id,"pos_x":item.pos_x,"pos_y":item.pos_y}])
            mapa.current.addInteraction(modify_sensor)
          }
        }
        //MUESTRA EL NOMBRE DEL PUNTO SELECCIONADO
        mapa.current.on('click', function (evt) {
          const feature = mapa.current.forEachFeatureAtPixel(evt.pixel, function (feature) {
            return feature;
          });
          if (!feature) {
            setPopupContent("")
            setPopupDataContent("")
            SetSensorId(null)
            setSensorType(null)
            setTags(null)
            return;
          }
          if(feature.values_.features){
            setPopupDataContent("")
            if(feature.values_.features[0].get('id') && feature.values_.features[0].get('type')){
                SetSensorId(feature.values_.features[0].get('id'))
                setSensorType(feature.values_.features[0].get('type'))
            }else{
                SetSensorId(null)
                setSensorType(null)            
            }
            if(feature.values_.features[0].get('tags')){
              setTags(feature.values_.features[0].get('tags'))
            }else{
              setTags(null)
            }
            setPopupContent(feature.values_.features[0].get('name'))
          }else{
            SetSensorId(null)
            setSensorType(null)
            setTags(null)
            setPopupContent("")
            setPopupDataContent("")
          }
  
          const popup_ = new Overlay({
            element: popup.current,
            positioning: 'bottom-center',
            stopEvent: false,
          });
          mapa.current.addOverlay(popup_);
          popup_.setPosition(evt.coordinate);
  
          
        });
  
        // AGREGA INTERACCIONES DE DIBUJAR Y DE SELECCIONAR
        mapa.current.addInteraction(selectRef.current)
        mapa.current.addInteraction(draw.current)
        selectRef.current.setActive(false)
  
        //GUARDA LA ZONA SELECIONADA EN STATE
        selectRef.current.on('select',(e)=>{
          if(e.selected.length>0){
            setIsSelectZone(true) 
            setSelectedZone(e.selected[0].geometryChangeKey_.target)
          }else{
            setIsSelectZone(false)
            setSelectedZone(null)
            setZoneId('')
          }
        })
      }
    }, [mapa,map_url,currentFloorplan]);
    useEffect(()=>{
      // ALTERNA ENTRE DIBUJAR Y SELECCIONAR
      if(isSelect && draw.current && selectRef.current){
        selectRef.current.setActive(true)
        setIsSelectZone(false)
        draw.current.setActive(false)
      }
      if(!isSelect &&draw.current && selectRef.current){
        selectRef.current.setActive(false)
        draw.current.setActive(true)
      }
    },[isSelect])
       
    useEffect(()=>{
      //ACTUALIZA EL DROPDOWN DE LA ZONA AL ESCOGER UN POLIGONO
      if(selectedZone){
        if(!zone.find(x=>x.uid===selectedZone.ol_uid)){
          setIdZone(null)
          setZoneId('')
        }else{
          let foundIndex = zone.findIndex(x => x.uid == selectedZone.ol_uid);
          setIdZone(zone[foundIndex].zone_id)        
          setZoneId({ZoneId:zone[foundIndex].zone_id})
    
        }
      }
    },[selectedZone])
    useEffect(()=>{
      //GUARDA LOS POLIGONOS CREADOS CON SU ZONA
      if(zone_id && selectedZone){
        if(!zone.find(x=>x.uid===selectedZone.ol_uid)){
          let polygon=[]
          for (let x=0;x<selectedZone.flatCoordinates.length-2;x=x+2){
            polygon.push({x:selectedZone.flatCoordinates[x],y:selectedZone.flatCoordinates[x+1]})
          }
          setZoneId({ZoneId:zone_id})
          setZone(prev => [...prev, {"uid":selectedZone.ol_uid,"zone_id":zone_id,"polygon":polygon}])
        }else{
          let foundIndex = zone.findIndex(x => x.uid == selectedZone.ol_uid);
          zone[foundIndex].zone_id = zone_id;
          setZoneId({ZoneId:zone_id})
  
        }
      }
    },[zone_id])
    const handleChange = () => {
      setIsSelect(prev =>!prev);
    };
    const polygonMutation = useMutation(
      data => mutationClient.put(`facilities/${params.FacilityId}/floorplan/${currentFloorplan.id}`, data),
      {
        onSuccess: () => {
          notifications.success('Floorplan guardado!')
        },
        onError: () => {
          notifications.danger('Ha ocurrido un error al guardar el floorplan')
        }
      }
    )
  
    const Asset=(asset)=>{
      //CREA PUNTOS DE LOS ASSETS
      if(!assetsCoordinates.find(x=>x.asset_id===asset.id)){ 
        const item={pos_x: getCenter(extent)[0],pos_y:getCenter(extent)[1],name:asset.name}
        const [layer,vector]=createPoint(mapa.current,item)
        const modify_asset= new Modify({
          hitDetection: layer,
          source: vector
        })
        modify_asset.on(['modifyend'],function(e){
          setAsset({"asset_id":asset.id,"floorplan_id":queryString.FloorplanId,"pos_x":e.features.array_[0].geometryChangeKey_.target.flatCoordinates[0],"pos_y":e.features.array_[0].geometryChangeKey_.target.flatCoordinates[1]})
        })
        SetSensorId(null)
        setSensorType(null)
        setPopupContent(null)
        setTags(null)
        setAssetsCoordinates(prev=>[...prev,{"asset_id":asset.id,"floorplan_id":queryString.FloorplanId,"pos_x":getCenter(extent)[0],"pos_y":getCenter(extent)[1]}])
        mapa.current.addInteraction(modify_asset)
      }else{
        let foundIndex = assetsCoordinates.findIndex(x => x.asset_id === asset.id);
        const popup_ = new Overlay({
          element: popup.current,
          positioning: 'bottom-center',
          stopEvent: false,
        });
        mapa.current.addOverlay(popup_);
        SetSensorId(null)
        setSensorType(null)
        if(asset.tags.length>0){
          setTags(asset.tags)
        }else{
          setTags(null)
        }
        setPopupContent(asset.name)
        popup_.setPosition([assetsCoordinates[foundIndex].pos_x,assetsCoordinates[foundIndex].pos_y]);
      }
    }
    useEffect(()=>{
      //ACTUALIZA LAS COORDENADAS DEL PUNTO DE LOS ASSETS
      if(asset){
        if(assetsCoordinates.find(x => x.asset_id == asset.asset_id)){
          let foundIndex = assetsCoordinates.findIndex(x => x.asset_id == asset.asset_id);
          assetsCoordinates[foundIndex]=asset
        }
      }
    },[asset])
    const Sensor=(sensor)=>{
    //const Sensor=(id,name)=>{
      //CREA LOS PUNTOS DE LOS SENSORES
      if(!sensorsCoordinates.find(x=>x.sensor_id===sensor.id)){
        const item={pos_x: getCenter(extent)[0],pos_y:getCenter(extent)[1],name:sensor.name,sensor_type_id:sensor.sensor_type_id,sensor_id:sensor.id}
        const [layer,vector]=createPoint(mapa.current,item)
        const modify_sensor= new Modify({
              hitDetection: layer,
              source: vector
            })
          modify_sensor.on(['modifyend'],function(e){
          setSensor({"sensor_id":sensor.id,"floorplan_id":queryString.FloorplanId,"pos_x":e.features.array_[0].geometryChangeKey_.target.flatCoordinates[0],"pos_y":e.features.array_[0].geometryChangeKey_.target.flatCoordinates[1]})
        })
        SetSensorId(null)
        setSensorType(null)
        setPopupContent(null)
        setTags(null)
        setSensorsCoordinate(prev=>[...prev,{"sensor_id":sensor.id,"floorplan_id":queryString.FloorplanId,"pos_x":getCenter(extent)[0],"pos_y":getCenter(extent)[1]}])
        mapa.current.addInteraction(modify_sensor)
      }else{
        let foundIndex = sensorsCoordinates.findIndex(x => x.sensor_id === sensor.id);
        const popup_ = new Overlay({
          element: popup.current,
          positioning: 'bottom-center',
          stopEvent: false,
        });
        mapa.current.addOverlay(popup_);
        setPopupContent(sensor.name)
        SetSensorId(sensor.id)
        setSensorType(sensor.sensor_type_id)
        setTags(null)
        popup_.setPosition([sensorsCoordinates[foundIndex].pos_x,sensorsCoordinates[foundIndex].pos_y]);
      }
    }
    useEffect(()=>{
      //ACUTALIZA LAS COORDENADAS DE LOS SENSORES
      if(sensor){
        if(sensorsCoordinates.find(x => x.sensor_id == sensor.sensor_id)){
          let foundIndex = sensorsCoordinates.findIndex(x => x.sensor_id == sensor.sensor_id);
          sensorsCoordinates[foundIndex]=sensor
        }
      }
    },[sensor])
  
    //style={{ color:"white", borderRadius:"5px", padding:"5px"}}
    const floorplanEl = (
      <div>
        <div className="d-flex justify-content-start">
          <CheckFeatures feature="UPDATE_FLOORPLAN">
            <button
              className='btn btn-primary btn-sm mr-2'
              onClick={()=>{
                polygonMutation.mutate({"zones":zone,"assets":assetsCoordinates,"sensors":sensorsCoordinates})
              }}
            >
              Guardar
            </button>
          </CheckFeatures>
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label className={`btn btn-primary mr-1 btn-sm ${!isSelect ? 'active':''} `}>Agregar Zona
              <input 
                type="radio" 
                name="options"
                id="option1" 
                onChange={handleChange}
                checked={isSelect==false}
              /> 
            </label>
            <label className={`btn btn-primary btn-sm mr-2 ${isSelect? 'active':''}` } >Seleccionar Zona
              <input 
                type="radio" 
                name="options"
                id="option2" 
                onChange={handleChange}
                value={false}
                checked={isSelect==true}
              /> 
            </label>
          </div>
          <button 
            className='btn btn-primary btn-sm mr-2 ml-2'
            onClick={() => {
              mapa.current.removeInteraction(draw.current)
              mapa.current.addInteraction(draw.current)
            }}
          >Reiniciar dibujo</button>
            {
            <ZoneSelector
              value={zone_id}
              zones={items}
              onChange={value=>setIdZone(value)}
              deshabilitar={!isSelectZone}                  
            />
          }
        </div>
        <div className="card mt-2" >
          <div id="map" className="map card-body " style={{"height" : 500, "width" : '100%'}}/>
          <div ref={popup} >
            {
              !popupContent=='' ?
                <div className='bg-white border shadow-sm rounded-lg'>
                  <div className='bg-light border-bottom p-2'style={{fontSize:"0.9rem"}}> { popupContent }</div>
                  {/*
                    !popupData==''?
                        <div className='p-2' style={{fontSize:"0.9rem"}}> {popupData}</div>
                    :null*/

                    sensorData.isSuccess && sensorTypeData ?
                        text(sensorData,sensorTypeData.variables)
                    :
                    tags ?
                      (assetText(tags))
                    :null
                    
                  }
                </div>
              : null  
            }
          </div>
        </div>
      </div>
    )
    return {
      floorplanEl,
      Asset,
      Sensor
    }     
}