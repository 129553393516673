import React, { useEffect, useState, useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { CurrentCompanyContext } from '../../App.js'

import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import Header from '../shared/Header.js'
import Spinner from '../shared/Spinner.js'
import { RoleSelector } from '../shared/Selectors.js'
import { useMutationClient } from '../shared/hooks.js'
import { useNotifications } from '../shared/Notifications.js'
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js'

function InviteForm({ item, onChange, onRemove }){
  const [email, setEmail] = useState('')
  const [RoleId, setRoleId] = useState('')

  useEffect(() => {
    const isValid = (email !== "" && RoleId !== "")

    onChange({
      ...item,
      email,
      role_id: RoleId,
      isValid
    })
  }, [email, RoleId])

  return (
    <div className="row px-3 pb-3">
      <div className="col-6">
        <div className="form-group m-0">
          <input
            className="form-control"
            type="text"
            placeholder="nombre@dominio.com"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>

      <div className="col-4">
        <div className="form-group m-0">
          <RoleSelector
            defaultValue={RoleId}
            onChange={value => setRoleId(value)}
          />
        </div>
      </div>

      <div className="col-2">
        <div className="form-group my-1">
          <button
            className="btn btn-block btn-sm btn-outline-danger"
            onClick={e => onRemove()}
          >Eliminar</button>
        </div>
      </div>
    </div>
  )
}

function UsersList({ onChange }){
  const [ users, setUsers ] = useState({})
  const [ currentId, setCurrentId ] = useState(0)

  function addUser(){
    setUsers({ ...users, [currentId]: { id: currentId, isValid: false } })
    setCurrentId(prev => prev + 1)
  }

  function handleRemove(id){
    const items = { ...users }
    delete(items[id])
    setUsers(items)
  }

  useEffect(() => {
    addUser()
  }, [])

  useEffect(() => {
    const isValid = Object.values(users).map(x => x.isValid).every(item => item)
    const items = Object.values(users).map(x => ({ email: x.email, role_id: x.role_id }))
    onChange({ users: items, isValid })
  }, [users])

  function removeUser(id){
    const items = { ...users }
    delete(items[id])
    setUsers(items)
  }

  return (
    <>
      {
        Object.values(users).map((item, idx) =>
          <InviteForm
            key={item.id}
            item={item}
            onChange={user =>
              setUsers(prev => ({
                ...prev,
                [user.id]: {
                  ...prev[user.id],
                  ...user
                }
              }))
            }
            onRemove={() => removeUser(item.id)}
          />
        )
      }

      <div className="row mb-4">
        <div className="col-12">
          <div className="border rounded mx-3 p-1 bg-light">
            <button
              className="btn btn-sm btn-link"
              onClick={() => addUser()}
            >+ Agregar otro usuario</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default function InviteMemberPage(){
  const [ users, setUsers ] = useState([])
  const [ hasErrors, setHasErrors ] = useState(false)
  const mutationClient = useMutationClient()
  const navigate = useNavigate()
  const currentCompany = useContext(CurrentCompanyContext)
  const notifications = useNotifications()
  const CheckFeatures = useFeatureChecker()

  const inviteMutation = useMutation(
    data => mutationClient.post(`companies/${currentCompany.id}/users`, data), {
    onSuccess: (data, variables, context) => {
      if( data.status === 200 ){
        notifications.success('Invitaciones enviadas correctamente')
        navigate(`/members`)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al enviar invitaciones')
    }
  })

  function sendInvitations(){
    inviteMutation.mutate({ users })
  }

  return (
    <Container>
      <div className="row">
        <Header
          title="Invitar usuarios"
          items={[
            { label: "Listado de usuarios", to: "/members" },
            { label: "Invitar usuarios" },
          ]}
        />
      </div>

      <div className="row">
        <div className="col-12 col-md-8 mx-auto">
          <div className="card shadow-sm">

            <div className="row px-3 pt-3">
              <div className="col-8">
                <div className="form-group m-0">
                  <label className="font-weight-bold">Correo electrónico</label>
                </div>
              </div>

              <div className="col-4">
                <div className="form-group m-0">
                  <label className="font-weight-bold">Rol</label>
                </div>
              </div>
            </div>
            <UsersList
                onChange={({ users, isValid }) => {
                  if(isValid){
                    setHasErrors(false)
                    setUsers(users)
                  }else{
                    setHasErrors(true)
                  }
                }}
              />
            <div className="row mb-4">
              <div className="col-12">
                <div className="d-flex justify-content-start align-items-center">
                  <CheckFeatures  every={["COMPANY_ADD_USER","LIST_ROLES"]}>
                    <AllowedFeature>
                      <div className="mx-3">
                        {
                          <button
                            className="btn btn-success"
                            onClick={e => sendInvitations()}
                            disabled={inviteMutation.isLoading}
                          >
                            {
                              inviteMutation.isLoading ? "Enviando invitaciones..." : "Enviar invitaciones"
                            }
                          </button>
                        }
                      </div>
                    </AllowedFeature>
                    <DeniedFeature>
                      <Navigate to={'/error?error=403'}/>
                    </DeniedFeature>
                  </CheckFeatures>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Container>
  )
}

