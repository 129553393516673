import React, { useState, useEffect, useRef, useContext } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import { ActionsContext } from '../../App.js'

import { useThrottleCallback } from '@react-hook/throttle'
import { useSwipeable } from "react-swipeable";
import { slide as Menu } from 'react-burger-menu'
import useWindowSize from "../shared/WindowSize.js";

import Notifications, { useNotifications } from '../shared/Notifications.js'
import Sidebar from "./Sidebar.js";

function SidebarWrapper({ isOpen, setOpen }) {
  //const winSize = useWindowSize()
  //const [isMobile, setIsMobile] = useState(winSize.width <= 768)

  //useEffect(() => {
    //setIsMobile(winSize.width <= 768)
    //setOpen(winSize.width >= 768)
  //}, [winSize])

  //return (
    //<Menu
      //className="col-8 col-md-3 col-lg-3 col-xl-2 d-md-block p-0 sidebar"
      //isOpen={isOpen}
      //onClose={() => setOpen(false)}
      //noTransition={!isMobile}
      //disableCloseOnEsc={true}
      //noOverlay
      //disableOverlayClick
    //>
      //<nav>
        //<Sidebar onChange={() => {
          //if (isMobile) {
            //setOpen(false)
          //}
        //}} />
      //</nav>
    //</Menu>
  //)

  return (
    <div
      className="col-8 col-md-3 col-lg-3 col-xl-2 d-md-block"
    >
      <Sidebar
        onChange={() => {}}
      />
    </div>
  )
}

export function Container({ children }){
  return (
    <div className="container-fluid mb-4">{children}</div>
  )
}

export default function AppLayout() {
  const [ isSidebarOpen, setSidebarOpen ] = useState(false)
  const contentRef = useRef(null)
  const actions = useContext(ActionsContext)
  const [ isBottom, setIsBottom ] = useState(false)
  const location = useLocation();
  const notifications = useNotifications()
  const searchParams = new URLSearchParams(location.search);

  useEffect(()=>{
    if(searchParams.get('Warning') && searchParams.get('Warning') === 'True'){
      notifications.warning('Acceso no autorizado')
    }
  },[location])
  
  useEffect(useThrottleCallback(() => {
    actions.ChangeScroll({ bottom: isBottom })
  }, 2), [isBottom])

  //const isSidebarOpen = useSelector(getSidebarStatus)

  //const handlers = useSwipeable({
    //delta: 150,
    //trackMouse: true,
    //onSwipedRight: () => {
      ////setOpen(true)
      ////dispatch(LayoutActions.toggleSidebar())
      ////dispatch(LayoutActions.openSidebar())
    //},
    //onSwipedLeft: () => {
      ////dispatch(LayoutActions.closeSidebar())
    //}
  //});

  useEffect(() => {
    if( contentRef.current ){
      const handleScroll = () => {
        const topOffset = window.pageYOffset
        const { scrollHeight, clientHeight } = contentRef.current
        const { innerHeight, outerHeight } = window

        if( topOffset >= (scrollHeight - innerHeight) ){
          setIsBottom(true)
        }else{
          setIsBottom(false)
        }
      }

      window.addEventListener("scroll", handleScroll)
      return () => window.removeEventListener("scroll", handleScroll)
    }
  }, [contentRef.current]);

  return (
    <div className="container-fluid p-0" style={{ backgroundColor: '#f0f0f0' }}>
      <Notifications />

      <div className="row m-0">
        <div
          className="col-8 col-md-3 col-lg-3 col-xl-2 d-md-block p-0 shadow"
          style={{
            backgroundColor: '#000',
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            zIndex: 100,
          }}
        >
          <Sidebar
            onChange={() => {}}
          />
        </div>

        <div
          className="col-12 col-md-9 col-lg-9 col-xl-10 ml-sm-auto"
          style={{ height: '100vh' }}
          ref={contentRef}
        >
          <Outlet />
        </div>
      </div>
    </div>
  )
}

