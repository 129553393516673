import React from 'react';
import Submenu from "../shared/Submenu.js";

export default function ZoneDetailSubmenu({ ZoneId }){
  return (
    <Submenu items={[
      { to: `/zones/${ZoneId}`, label: 'General', end: true },
      { to: `/zones/${ZoneId}/assets`, label: 'Activos', features: ['LIST_ASSETS'] },
      //{ to: `/zones/${ZoneId}/alerts`, label: 'Alertas' },
      { to: `/zones/${ZoneId}/media`, label: 'Media', features: ['LIST_MEDIA']},
      { to: `/zones/${ZoneId}/devices`, label: 'Dispositivos', features: ['LIST_SENSORS'] },
    ]}/>
  )
}
