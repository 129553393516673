import React, { useRef } from 'react';

function ModalBody({ children }){
  return (
    <div className="modal-body">
      {children}
    </div>
  )
}

function ModalFooter({ children }){
  return (
    <div className="modal-footer">
      {children}
    </div>
  )
}

export function BaseModal({ title, size, onClose, children }){
  const backdropRef = useRef(null)
  const modalRef = useRef(null)

  const body = React.Children.toArray(children).find(x => x.type.name === ModalBody.name)
  const footer = React.Children.toArray(children).find(x => x.type.name === ModalFooter.name)

  return(
    <React.Fragment>
      <div
        className={`modal show d-block`}
        tabIndex="-1"
        ref={modalRef}
      >
        <div className={`modal-dialog modal-${size}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{ title }</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => onClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            { body }
            { footer }
          </div>
        </div>
      </div>

      <div
        className={`modal-backdrop show`}
        ref={backdropRef}
        //onClick={e => onClose()}
      ></div>
    </React.Fragment>
  )
}

BaseModal.Body = ModalBody
BaseModal.Footer = ModalFooter

