import React, { useState, useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNotifications } from '../shared/Notifications.js';
import Spinner from '../shared/Spinner.js';
import { useMutationClient } from '../shared/hooks.js';
import useFeatureChecker, { isAllowed, AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';
import { CurrentUserContext } from '../../App.js';

function FacilityListItem({ item, selected, removeItem, addItem }) {
    const CheckFeatures = useFeatureChecker()
    return (
      <div className="border-top-0 border-left-0 border-right-0 list-group-item d-flex justify-content-between align-items-center">
        <label className="m-0" style={{ cursor: 'pointer' }}>
          <CheckFeatures every={["EDIT_ROLE_FACILITIES"]}>
            <AllowedFeature>
              <input
                type="checkbox"
                className="mr-2"
                style={{ cursor: 'pointer' }}
                defaultChecked={selected}
                onChange={(e) => {
                  if (e.target.checked) {
                    addItem(item)
                  } else {
                    removeItem(item)
                  }
                }}
              />
            </AllowedFeature>
            <DeniedFeature>
              <input
                type="checkbox"
                className="mr-2"
                style={{ cursor: 'pointer' }}
                defaultChecked={selected}
                disabled={true}
              />
            </DeniedFeature>
          </CheckFeatures>
          <span>{item.name}</span>
        </label>
  
        <span>{item.zone_name}</span>
      </div>
    )
  }
  
  export default function RoleZoneList({ currentRole }) {
    const CheckFeatures = useFeatureChecker()
    const queryClient = useQueryClient()
    const mutationClient = useMutationClient()
    const notifications = useNotifications()
    const currentUser = useContext(CurrentUserContext)
    const query = useQuery(`facilities?limit=1000`)
    const facilities = query.data && query.data.result || []
    const [enablePassword,setEnablePassword]= useState(currentRole.facilities.length>0)

    const addFacility = useMutation(
      data => mutationClient.post(`roles/${currentRole.id}/facilities?CompanyId=${currentRole.company_id}`, data),
      {
        onSuccess: (data) => {
          notifications.success('Instalacion agregada exitosamiente')
          queryClient.invalidateQueries(`roles/${currentRole.id}`)
        }
      }
    )
  
    const removeFacility = useMutation(
      data => mutationClient.delete(`roles/${currentRole.id}/facilities/${data.FacilityId}?CompanyId=${currentRole.company_id}`),
      {
        onSuccess: (data) => {
          notifications.success('Instalación eliminada exitosamente')
          queryClient.invalidateQueries(`roles/${currentRole.id}`)
        }
      }
    )
  
    function isSelected(item) {
      const found = currentRole.facilities && currentRole.facilities.some((data) => data.facility_id === item.id);
      return found
    }

    const handleChange= event=>{
      setEnablePassword(current=> !current);
  }

    return (
        <div key={"facilities"}>
        <div className="bg-light rounded border py-2 px-3 mb-1">
          <div className="form-group">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={enablePassword}
                onChange={handleChange}
                id={`passwordCheck-facilities`}
                disabled={currentUser &&!isAllowed({user: currentUser,feature: "LIST_ROLE_FACILITIES" })}
              />
              <label className="form-check-label" htmlFor={`passwordCheck-facilities`}>
                Limitar instalaciones del rol
              </label>
            </div>
          </div>
        </div>

        <div className="list-group" style={{ maxHeight: '500px', overflowY: 'auto' }}>
          {enablePassword ? (
            query.isLoading ? (
              <div className="text-center my-4 py-4">
                <Spinner />
              </div>
            ) : facilities.length ? (
              facilities.map(item => (
                <FacilityListItem
                  key={item.id}
                  item={item}
                  selected={isSelected(item)}
                  addItem={(item) => addFacility.mutate({ facility_id: item.id })}
                  removeItem={(item) => removeFacility.mutate({ FacilityId: item.id })}
                />
              ))
            ) : (
              <div className="text-muted text-center my-4 py-4">No se encontraron instalacioness</div>
            )
          ) : null}
        </div>
      </div>


    )
  }
  