import React from 'react'
import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useNavigate, Navigate } from 'react-router-dom';
import CompanyForm from './CompanyForm.js'
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js'
import { useMutation } from 'react-query';
import { useMutationClient } from '../shared/hooks.js';
import { useNotifications } from '../shared/Notifications.js';

import Header from "../shared/Header.js"

export default function NewCompanyPage(){
  //const dispatch = useDispatch()
  const CheckFeatures= useFeatureChecker()
  const mutationClient = useMutationClient()
  const notifications = useNotifications()
  const navigate = useNavigate()

  /*
  function createCompany(data){
    //dispatch(CompaniesActions.createCompany(data))
  }
  */

  const createCompany = useMutation(
    data => mutationClient.post(`companies`, { ...data}), {
    onSuccess: (data, variables, context) => {
      if( data.status === 200 ){
        notifications.success('Compañía creada correctamente')
        navigate(`/companies/${data.data.result.id}`)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al crear la compañía')
    }
  })

  return (
    <Container>
      <Row>
        <Header title="Nueva compañía" items={[
          { label: "Listado de compañías", to: "/companies" },
          { label: "Nueva compañía" }
        ]} />
      </Row>

      <Row>
        <Col className="col-12 col-lg-6">
          <Card className="shadow">
            <Card.Header className="card-title">
              <span>Nueva compañía</span>
            </Card.Header>
            <Card.Body>
              <CheckFeatures feature={'CREATE_COMPANY'}>
                <AllowedFeature>
                  <CompanyForm 
                    company={{}} 
                    save={data => createCompany.mutate(data)}
                    isLoading={createCompany.isLoading}
                  />
                </AllowedFeature>
                <DeniedFeature>
                  <Navigate to={'/error?error=403'}/>
                </DeniedFeature>
              </CheckFeatures>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

