import React, { useEffect, useState, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import './OptionSelector.css'

function ButtonDropdown({ options, selected, onChange, currentSelection, setCurrentSelection, toggle, ...props }){
  const [ isOpen, setIsOpen ] = useState(false)
  const chosen = options.find(x => x.value === selected)
  const wrapperRef = useRef(null)

  useEffect(() => {
    if( isOpen ){
      function handleClick(e){
        if( !wrapperRef.current.contains(e.target) ){
          setIsOpen(false)
        }
      }

      document.addEventListener('click', handleClick)

      return () => {
        document.removeEventListener('click', handleClick)
      }
    }
  }, [isOpen])

  function changeSelection(item){
    setCurrentSelection(item.value)
    setIsOpen(false)
  }

  return (
    <div
      className={ `dropdown dropleft option-selector ${props.className ? props.className : ''} ${isOpen ? 'show': ''}` }
      //style={{ display: 'inline-block' }}
      ref={wrapperRef}
    >
      <button
        className="btn btn-sm btn-outline-primary dropdown-toggle d-block"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        {chosen.label}
      </button>

      <div
        className={ `dropdown-menu shadow ${isOpen ? 'show' : ''}` }
      >
        {
          options.map(item => (
            <div
              key={item.value}
              className="dropdown-item"
              onClick={() => changeSelection(item)}
            >
              {
                currentSelection === item.value ?
                  <FontAwesomeIcon icon={faCheck} className="icon" />
                :
                  <span className="icon"></span>
              }
              <span>{item.label}</span>
              {
                item.description ? <span className="description text-muted">{item.description}</span> : ''
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}

function ButtonRadio({ options, selected, onChange, currentSelection, setCurrentSelection, toggle, ...props }){
  return (
    <div
      className={ `btn-group btn-group-toggle ${props.className ? props.className : ''}` }
      data-toggle="buttons"
    >
      {
        options.map(item => (
          <label
            key={item.value}
            className={`btn btn-sm btn-outline-primary ${currentSelection === item.value ? 'active' : ''}`}
          >
            <input
              type="radio"
              checked={ currentSelection === item.value }
              value={item.value}
              onChange={toggle}
            />
            <span>{item.label}</span>
          </label>
        ))
      }
    </div>
  )
}

export default function OptionSelector(props){
  const onChange = props.onChange
  const [ currentSelection, setCurrentSelection ] = useState(props.selected || null)

  useEffect(() => {
    if( currentSelection ){
      onChange(currentSelection)
    }
  }, [currentSelection])

  function toggle(e){
    setCurrentSelection(e.target.value)
  }

  if(props.type === 'dropdown'){
    return (
      <div className="field-filter-wrapper d-block d-md-inline-block">
        <ButtonDropdown
          currentSelection={currentSelection}
          setCurrentSelection={setCurrentSelection}
          toggle={toggle}
          {...props}
        />
      </div>
    )
  }else if(props.type === 'radio'){
    return (
      <ButtonRadio
        currentSelection={currentSelection}
        setCurrentSelection={setCurrentSelection}
        toggle={toggle}
        {...props}
      />
    )
  }
}


