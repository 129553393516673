import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useParams } from "react-router-dom";
import { toQueryString } from '../../../utils.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useMutationClient } from '../../shared/hooks.js'
import { useNotifications } from '../../shared/Notifications.js'
import { DropdownSelector } from '../../shared/Filters'
import { Searchbox } from '../../shared/Search.js'
import { DestroyButton } from '../../shared/Buttons.js'
import Spinner from '../../shared/Spinner.js'
import { faTrash } from '@fortawesome/free-solid-svg-icons';

function AddMitigationCard({ ZoneId, Risk,Type, onChange }){
    const mutationClient = useMutationClient()
    const notifications = useNotifications()
    const [ currentFilters, setCurrentFilters ] = useState({
        ZoneId: ZoneId || '',
        Type: Type || ''
    })
    const [ search, setSearch ] = useState('')

    const query = useQuery(`sensors?ExcludeSvaId=${Risk.id}&${toQueryString({ search })}&${toQueryString(currentFilters)}`)
    const sensors = query.data && query.data.result || []

    const addMitigation = useMutation(
      SensorId => mutationClient.post(`mitigations`, { SensorId: SensorId ,type:Type,SvaRiskId:Risk.id}), {
      onSuccess: (data) => {
        if( data.status === 204 ){
          notifications.success('Sensor agregado!')
          query.refetch()
          onChange(data.data)
        }
      },
      onError: () => {
        notifications.danger('Ha ocurrido un error al agregar un Sensor al riesgo')
      }
    })
  
    return (
      <div className="card shadow-sm mb-3">
        <div className="card-header">Seleccionar dispositivos</div>
  
        <div className="border-bottom py-2 px-2">
          <Searchbox
            onSearch={q => setSearch(q)}
            autoFocus={true}
          />
        </div>
  
        <div className="card-body p-0">
          {
            query.isLoading ?
              <div className="text-center py-4 my-4">
                <Spinner />
              </div>
            :
              <div className="list-group border-0" style={{ height: '300px', overflowY: 'auto', fontSize: '.9em' }}>
                {
                  sensors.map(item => <MitigationResultItem key={item.id} item={item} addMitigation={SensorId=> addMitigation.mutate(SensorId)} />)
                }
              </div>
          }
        </div>
      </div>
    )
  }
  function MitigationResultItem({ item,addMitigation }){
    return (
      <div className="list-group-item list-group-item-action d-flex justify-content-between align-items-center p-2">
        <div>
          <div className="mr-4">{ item.name }</div>
        </div>
  
        <div>
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={() => addMitigation(item.id)}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
    )
}
function CurrentMitigationsCard({ ExcluseQuery, SensorQuery, ZoneId, Risk, Type, currentSensors }){
    return (
      <div className="card shadow-sm">
        <div className="card-header d-flex justify-content-between align-items-center">Dispositivos seleccionados</div>
  
        {
          !currentSensors.length ?
            <div className="p-3 my-4 py-4 text-center text-muted">
              Sin dispositivos seleccionados
            </div>
          :
            <ul className="list-group border-0" style={{ height: '350px', overflowY: 'auto', fontSize: '.9em' }}>
              {
                currentSensors.map(item => (
                  
                    <div
                        key={item.id}
                        className="d-flex justify-content-between bg-light border rounded  p-2"
                        style={{ fontSize: '.9em' }}
                    >
                        <a>{item.name}</a>
                        <DestroyButton
                        confirmMessage="¿Está seguro que desea eliminar este sensor?"
                        successMessage="Sensor eliminado correctamente"
                        deletePath={`mitigations/${item.id}`}
                        invalidate={`sensors?ExcludeSvaId=${Risk.id}&ZoneId=${ZoneId}&Type=${Type}`}
                        refetch={()=>{
                            SensorQuery()
                            ExcluseQuery()  
                        }}
                        size="sm"
                        >
                        <FontAwesomeIcon icon={faTrash} />
                        </DestroyButton>
                    </div>
                ))
              }
            </ul>
        }
      </div>
    )
}
export default function RiskSelector({ ZoneId, Risk,Type}){

    const query = useQuery(`mitigations?SvaRiskId=${Risk.id}&type=${Type}`)
    const excludeQuery = useQuery(`sensors?ExcludeSvaId=${Risk.id}&ZoneId=${ZoneId}&Type=${Type}`)

    const currentSensors= query.data && query.data.result ||[]
    return(
        <>
          <div className="col-6 pl-0 pr-md-3">
            <AddMitigationCard
              ZoneId={ZoneId}
              Risk={Risk}
              Type={Type}
              onChange={() => query.refetch()}
            />
          </div>
    
          <div className="col-6 pl-0 pr-md-3">
            {
              query.isLoading ?
                <div className="text-center my-4 py-4">
                  <Spinner />
                </div>
              : <CurrentMitigationsCard ExcluseQuery={excludeQuery.refetch} SensorQuery={query.refetch} ZoneId={ZoneId} Risk={Risk} Type={Type} currentSensors={currentSensors} />
              
            }
          </div>
        </>
      )
}