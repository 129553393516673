import React from 'react'
import { useQuery, useMutation } from 'react-query'
import { useParams, useNavigate } from 'react-router-dom'

import AssetsIdentification from './AssetsIdentification.js'
import ThreatAssestment from './ThreatAssestment.js'
import RiskAssestment from './RiskAssestment.js'
import RisksMitigation from './RisksMitigation.js'
import SvaSummary from './SvaSummary.js'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

import { useMutationClient } from '../../shared/hooks.js'
import { useNotifications } from '../../shared/Notifications.js'
import Spinner from '../../shared/Spinner.js'
const MySwal = withReactContent(Swal)


const steps = [
  {
    component: AssetsIdentification,
    title: 'Identificación de activos',
  },
  {
    component: ThreatAssestment,
    title: 'Identificación de amenazas',
  },
  {
    component: RiskAssestment,
    title: 'Identificación de riesgos',
  },
  {
    component: RisksMitigation,
    title: 'Mitigación de riesgos',
  },
  {
    component: SvaSummary,
    title: 'Resumen',
  }
]

function StepTitle({ step, title, isActive }){
  const activeStyle = {
    backgroundColor: '#333',
    color: '#fff',
    fontWeight: 'bold'
  }

  const inactiveStyle = {
    backgroundColor: '#dee2e6',
    color: '#6c757d'
  }

  return(
    <div className="d-flex align-items-center mb-3 mr-2">
      <div
        className="rounded-left border-right py-2 px-3"
        style={isActive ? activeStyle : inactiveStyle}
      >Paso {step + 1}</div>

      <div
        className="rounded-right py-2 px-2"
        style={isActive ? activeStyle : inactiveStyle}
      >{title}</div>
    </div>
  )
}

function Wizard({ currentSva, isLoading, save }){
  //const [step, setStep] = useState(currentSva.step);
  const step = currentSva.step
  let CurrentStep;
  if(step>4){
     CurrentStep = steps[4]
  }
  else{
     CurrentStep = steps[step]
  }
  
  return (
    <React.Fragment>
      <div className="d-flex">
        {
          steps.map((item, idx) => (
            <StepTitle
              key={idx}
              step={idx}
              title={item.title}
              isActive={step === idx}
            />
          ))
        }
      </div>
      <CurrentStep.component
        currentSva={currentSva}
        isLoading={isLoading}
        save={save}
      />

      <div className="my-4">
        <div className="d-flex justify-content-end">
          {
            step > 0 && step<steps.length?
              <button
                className="btn btn-light shadow-sm mr-3"
                onClick={() => save({ step: step - 1 })}
                disabled={ isLoading || step === 0 }
              >Anterior</button>
            : null
          }
          {
            step < 4 ?
              <button
                className="btn btn-primary shadow-sm"
                onClick={() => {
                  if( step < steps.length ){
                    save({ step: step + 1 })
                  }
                }}
                disabled={ isLoading || step + 1 === steps.length }
              >Guardar y continuar</button>
            :
              <button
                className="btn btn-primary shadow-sm"
                onClick={() => {
                  MySwal.fire({
                    title: `¿Está seguro que finalizar el SVA?`,
                    icon: 'warning',
                    text: 'Esta opción no se podrá deshacer',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirmar finalización',
                    cancelButtonText: 'Cancelar'
                  }).then((result) => {
                    if (result.isConfirmed) {
                      if( step < steps.length ){
                        save({ step: step + 1 })
                      }
                  }})
                  /*if( step < steps.length ){
                    save({ step: step + 1 })
                  }*/
                }}
                disabled={ isLoading || step === steps.length }
            >Finalizar</button>
            }
        </div>
      </div>
    </React.Fragment>
  )
}

export default function SvaWizard(){
  const params = useParams()
  const mutationClient = useMutationClient()
  const notifications = useNotifications()
  const navigate = useNavigate()

  const query = useQuery(`sva/${params.SvaId}`)
  const currentSva = query.data && query.data.result

  const updateSVA = useMutation(
    data => mutationClient.put(`sva/${params.SvaId}`, data), {
    onSuccess: data => {
      console.log(data);
      if( data.status === 200 ){
        query.refetch()
        //notifications.success('SVA creado correctamente')
        //navigate(`/facilities/${currentFacility.id}/sva/${data.data.result.id}/wizard`)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al guardar el SVA')
    }
  })

  return (
    <div>
      {
        query.isLoading ?
          <div className="text-center my-4 py-4">
            <Spinner />
          </div>
        :
          <Wizard
            currentSva={currentSva}
            save={data => updateSVA.mutate(data)}
            isLoading={updateSVA.isLoading}
          />
      }
    </div>
  )
}