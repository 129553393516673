import { Container } from "../layout/AppLayout";
import Header from '../shared/Header.js'
import { RoleSelector } from '../shared/Selectors.js'
import {  useNavigate } from 'react-router-dom'
import React, { useEffect, useState , useContext } from 'react';
import { Card,Row } from "react-bootstrap";
import { CurrentCompanyContext } from '../../App.js';
import { useMutation } from 'react-query'
import { useNotifications } from '../shared/Notifications.js'
import { useMutationClient } from '../shared/hooks.js'
import { isAllowed } from '../shared/FeatureChecker.js'
import { CurrentUserContext } from "../../App.js";


export default function CreateMemberPage(){
    const [RoleId, setRoleId] = useState('')
    const [email, setEmail] = useState('')
    const [name,setName]= useState('')
    const [password,setPassword]= useState('')
    const [enablePassword,setEnablePassword]= useState(false)
    const currentCompany = useContext(CurrentCompanyContext)
    const mutationClient = useMutationClient()
    const notifications = useNotifications()
    const [ errors, setErrors ] = useState({});
    const navigate = useNavigate()
    const currentUser = useContext(CurrentUserContext)

    const validations = [];

    useEffect(() => {
        if(currentUser){
          const allowed= isAllowed({user: currentUser, every: ["CREATE_USER","LIST_ROLES"]})
          if (!allowed){
            navigate('/error?error=403')
          }
        }
    
      },[currentUser])
  
    const createUser = useMutation(
        data => mutationClient.post(`users`, { ...data, company_id: currentCompany && currentCompany.id }), {
        onSuccess: (data) => {
          if( data.status === 200 ){
            notifications.success('Usuario creado correctamente')
            navigate(`/members`)
          }
        },
        onError: () => {
          notifications.danger('Ha ocurrido un error al crear el usuario')
        }
      })

    function validate(e) {
      e.preventDefault();
  
      if (!name) {
          validations.push(['name', 'Nombre es requerido']);
      }
  
      if (!RoleId) {
        validations.push(['RoleId', 'Rol es requerido']);
      }
      if(enablePassword && !password){
        validations.push(['password', 'Contraseña es requerida']);
      }
      if(validations.length > 0){
        setErrors(validations.reduce((acc, item) => ({...acc, [item[0]]: item[1]}), {}));
        return;
      }else{
        setErrors([])
        let data = {
            name,
            role_id: RoleId
        } 
        if(email){
            data={
                ...data,
                email
            }
        } 
        if(password){
            data={
                ...data,
                password
            }
        }
        createUser.mutate(data)
      }
    }
   
    const handleChange= event=>{
        setEnablePassword(current=> !current);
    }
    return(
        <Container>
            <Row>
                <Header
                    title="Crear usuario"
                    items={[
                        { label: "Listado de usuarios", to: "/members" },
                        { label: "Crear usuario" },
                      ]}
                />

            </Row>

            <Row className="mb-3">
                <div className="col-12 col-lg-6">
                    <form onSubmit={validate}>
                        <Card className="mb-2 shadow-sm">
                            <Card.Header>
                                <span>Nuevo usuario</span>
                            </Card.Header>
                            <Card.Body>
                                <div className="form-group">
                                    <label className="font-weight-bold">Nombre</label>
                                    <input
                                        className={`form-control  ${errors.name ? 'is-invalid' : ''}`}
                                        type="text"
                                        placeholder="Nombre"
                                        onChange={e => setName(e.target.value)}
                                    />
                                    <div className="invalid-feedback">{errors.name}</div>
                                </div>
                                <div className="form-group">
                                    <label className="font-weight-bold">Rol</label>    
                                    <RoleSelector
                                        defaultValue={RoleId}
                                        onChange={value => setRoleId(value)}
                                        hasError={errors.RoleId}
                                    />
                                    <div className="invalid-feedback">{errors.RoleId}</div>

                                </div>
                                <div className="form-group">
                                    <label className="font-weight-bold">Email </label>
                                    <label className="text-muted">(opcional)</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="nombre@dominio.com"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value={enablePassword} onChange={handleChange} id="passwordCheck"></input>
                                        <label className="form-check-label">
                                            Habilitar ingreso web
                                        </label>
                                    </div> 

                                </div>
                                {
                                    enablePassword ?
                                        <div className="form-group">
                                            <label className="font-weight-bold">Contraseña</label>
                                            <input
                                                className={`form-control ${errors.password ? 'is-invalid': ''}`}
                                                type="password"
                                                placeholder="Password"
                                                onChange={(e)=>setPassword(e.target.value)}
                                            />
                                            <div className="invalid-feedback">{errors.password}</div>

                                        </div>
                                    :null
                                }
                                <div className="form-group">
                                    <button
                                        className="btn btn-block btn-sm btn-outline-primary"
                                    >Crear usuario</button>
                                </div>
                            </Card.Body>
                        </Card>
                    </form>

                </div>
            </Row>
        </Container>
    )
}