import React, { useState, useEffect } from "react";
import { FacilitySelector } from "../shared/Selectors.js";
import useFeatureChecker, { AllowedFeature, DeniedFeature} from "../shared/FeatureChecker.js";

export default function ZoneForm({ zone, FacilityId, save, isLoading }) {
  const [name, setName] = useState(zone.name || "");
  const [facility_id, setFacilityId] = useState(
    FacilityId ? FacilityId : zone.facility_id || ""
  );

  const CheckFeatures = useFeatureChecker()
  //const [latCoord, setLatCoord] = useState(0);
  //const [lngCoord, setLngCoord] = useState(0);

  const [errors, setErrors] = useState({});

  const validations = [];

  function validate(e) {
    e.preventDefault();

    if (!name) {
      validations.push(["name", "Nombre es requerido"]);
    }
   

    if (validations.length) {
      setErrors(
        validations.reduce((acc, item) => ({ ...acc, [item[0]]: item[1] }), {})
      );
      return;
    } else {
      setErrors([]);
    }

    save({
      ...zone,
      name,
      facility_id: facility_id ? facility_id : null,
      //ubication: [{ lat: latCoord, lng: lngCoord }]
    });
  }

  return (
    <form onSubmit={validate}>
      <div className="form-group">
        <label>Nombre</label>
        <input
          type="text"
          className={`form-control ${errors.name ? "is-invalid" : ""}`}
          onChange={(e) => setName(e.target.value)}
          value={name}
          disabled={isLoading}
          autoFocus={true}
        />

        <div className="invalid-feedback">{errors.name}</div>
      </div>

      {!FacilityId ? (
        <div className="form-group">
          <label>Instalación</label>

          <CheckFeatures feature='LIST_FACILITIES'>
            <AllowedFeature>
              <FacilitySelector
                placeholder="Seleccione una instalación"
                onChange={(value) => setFacilityId(value)}
                defaultValue={facility_id}
                disabled={isLoading}
                hasError={!!errors.facility}
              />
            </AllowedFeature>
            <DeniedFeature>
              <FacilitySelector
                placeholder="Seleccione una instalación"
                onChange={(value) => setFacilityId(value)}
                defaultValue={facility_id}
                disabled={true}
                hasError={!!errors.facility}
              />
            </DeniedFeature>

          </CheckFeatures>
          

          <div className="invalid-feedback">{errors.facility}</div>
        </div>
      ) : null}
      <div className="form-group">
        <button
          className={`btn btn-primary ${isLoading ? "loading" : ""}`}
          disabled={isLoading}
        >
          <span>Guardar</span>
          <div className="spinner-border" role="status"></div>
        </button>
      </div>
    </form>
  );
}
