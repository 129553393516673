import { Link, useParams, useNavigate } from 'react-router-dom';
import { Container } from '../layout/AppLayout.js'
import { useEffect, useContext } from 'react';
import useFeatureChecker,{ isAllowed } from '../shared/FeatureChecker.js';

import Header, { HeaderActions } from "../shared/Header.js"
import ZoneDetailSubmenu from './ZoneDetailSubmenu.js'
import { CurrentUserContext } from '../../App.js';

import EventsCard from './detail/general/EventsCard.js'
import DetailsCard from './detail/general/DetailsCard.js'
//import LastAccessMetrics from './detail/general/MetricsCard.js'
//import LastAccess from './detail/general/LastAccessCard.js'

export default function ZoneDetailsPage() {
  const params = useParams()
  const CheckFeatures = useFeatureChecker()
  const currentUser = useContext(CurrentUserContext)
  const navigate = useNavigate()

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "LIST_ZONES"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  return (
    <Container>
      <div className="row">
        <Header title="Detalle de zona" items={[
          { label: `Listado de zonas`, to: "/zones" },
          { label: `Detalle de zona` },
        ]}>
          <HeaderActions>
            <CheckFeatures feature="UPDATE_ZONE">
              <Link to={"/zones/" + params.ZoneId + "/edit"} className="btn btn-primary">Editar zona</Link>
            </CheckFeatures>
          </HeaderActions>
        </Header>
      </div>

      <div className="row">
        <ZoneDetailSubmenu ZoneId={params.ZoneId} />
      </div>

      <div className="row mb-2 pb-4">
        <div className="col-12 mb-2 col-lg-6">
          <DetailsCard ZoneId={params.ZoneId} />
          {/* <LastAccessMetrics ZoneId={params.ZoneId} /> */}

        </div>

        {
          <div className="col-12 mb-2 col-lg-6">
            <CheckFeatures feature ='LIST_EVENTS'>
              <EventsCard ZoneId={params.ZoneId} />
            </CheckFeatures>
            {/* <LastAccess ZoneId={params.ZoneId} /> */}
          </div>
        }
      </div>
    </Container>
  )
}
