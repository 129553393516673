import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query'

import { Container } from '../layout/AppLayout.js'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';

import { useNotifications } from '../shared/Notifications.js'
import { useMutationClient } from '../shared/hooks.js';
import Spinner from '../shared/Spinner.js';
import Header from "../shared/Header.js";
import { isAllowed } from '../shared/FeatureChecker.js';
import { CurrentUserContext } from '../../App.js';

import ZoneForm from './ZoneForm.js';

export default function EditZonePage() {
  const params = useParams()
  const navigate = useNavigate()
  const mutationClient = useMutationClient()
  const notifications = useNotifications()
  const currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "UPDATE_ZONE"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const query = useQuery(`zones?ZoneId=${params.ZoneId}`)

  const currentZone = query.data
    && query.data.result
    && query.data.result.length
    && query.data.result[0]

  const updateZone = useMutation(
    data => mutationClient.put(`zones/${params.ZoneId}`, data), {
    onSuccess: (data, variables, context) => {
      if( data.status === 200 ){
        notifications.success('Zona actualizada correctamente')
        navigate(`/zones/${params.ZoneId}`)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al actualizar la zona')
    }
  })

  return (
    <Container>
      <Row>
        <Header title="Editar zona" items={[
          { label: `Listado de zonas`, to: "/zones" },
          { label: `Detalle de zona`, to: `/zones/${params.ZoneId}` },
          { label: 'Editar zona' },
        ]} />
      </Row>

      <Row className="mb-2">
        <Col className="col-12 mb-2 col-lg-6">
          <Card className="mb-2 shadow-sm">
            <Card.Header>
              <span>Editar zona</span>
            </Card.Header>

            <Card.Body>
              {
                query.isLoading || !currentZone ?
                  <div className="text-center my-4 py-4">
                    <Spinner />
                  </div>
                :
                  <ZoneForm
                    zone={currentZone}
                    save={data => updateZone.mutate(data)}
                    isLoading={updateZone.isLoading}
                  />
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
