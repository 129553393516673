import React, { useState , useEffect} from 'react'
import { FacilitySelector } from '../shared/Selectors.js'

import { AssetsTagsMultiSelector } from '../shared/Selectors.js'

export default function AssetForm({ asset, save, isLoading }){
  const [ name, setName ] = useState(asset.name || '');
  const [ description, setDescription ] = useState(asset.description || '');
  const [ tag_ids, setTagIds ] = useState(asset && asset.tags && asset.tags.map(x => x.id) || [])

  const [ errors, setErrors ] = useState({});
  const validations = [];

  function validate(e) {
    e.preventDefault();

    if (!name) {
      validations.push(['name', 'Nombre es requerido']);
    }

    if(validations.length){
      setErrors(validations.reduce((acc, item) => ({...acc, [item[0]]: item[1]}), {}));
      return;
    }else{
      setErrors([]);
    }

    save({
      ...asset,
      name,
      description,
      tag_ids,
    });
  }

  return (
    <form onSubmit={validate}>
      <div className="form-group">
        <label className="font-weight-bold">Nombre</label>
        <input
          type="text"
          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
          onChange={e => setName(e.target.value)}
          value={name}
          disabled={isLoading}
          autoFocus={true}
        />

        <div className="invalid-feedback">{errors.name}</div>
      </div>

      <div className="form-group">
        <label className="font-weight-bold">Etiquetas</label>

        <AssetsTagsMultiSelector
          defaultValue={tag_ids}
          onChange={data => setTagIds(data)}
        />
      </div>

      <div className="form-group">
        <label className="font-weight-bold">Descripción</label>

        <textarea
          className="form-control"
          value={description}
          onChange={e => setDescription(e.target.value)}
        ></textarea>
      </div>

      <div className="form-group">
        <button className={`btn btn-primary ${isLoading ? 'loading' : ''}`} disabled={isLoading}>
          <span>Guardar</span>
          <div className='spinner-border' role='status'></div>
        </button>
      </div>
    </form>
  )
}



