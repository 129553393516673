import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useMutationClient } from '../shared/hooks.js';

import Header from '../shared/Header.js';
import Spinner from '../shared/Spinner.js';

import { CurrentUserContext } from '../../App';
import { Container } from '../layout/AppLayout.js';
import useFeatureChecker, { AllowedFeature , DeniedFeature } from '../shared/FeatureChecker.js';
import { Navigate } from 'react-router-dom';

function MemberDetailsForm({ member}){
  const [name, setName] = useState(member.name || '');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('')
  const mutationClient = useMutationClient()
  const CheckFeatures = useFeatureChecker()

  const mutation = useMutation(
    data => mutationClient.put(`users/${member.id}`, data),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setSuccessMessage('El nombre se ha modificado correctamente')
          window.location.reload()
        }
      },
    }
  )

  let content
  function handleSubmit(e){
    e.preventDefault()
    setErrorMessage('')

    if( name === ''  ){
      setErrorMessage('El nombre no puede estar vacio')
      setSuccessMessage('')
      return
    }

    mutation.reset()
    mutation.mutate({ name })
  }

  content = (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Nombre completo</label>

        <input
          className="form-control"
          type="text"
          onChange={e => setName(e.target.value)}
          value={name}
          disabled={mutation.isLoading}

        />
      </div>

      <div className="form-group">
        <label>Correo principal</label>

        <input
          type="text"
          className="form-control"
          defaultValue={member.email}
          disabled={true}
        />
      </div>
      {
        mutation.isError ?
          <p className="text-danger text-center">Hubo un error al cambiar el nombre</p>
        : null
      }

      {
        errorMessage !== '' ?
          <p className="text-danger text-center">{errorMessage}</p>
        : null
      }
      {
        successMessage !== '' ?
        <p className="text-center">{successMessage}</p>
        :null
      }
      <CheckFeatures feature='UPDATE_ME'>
        <div className="form-group text-center">
          <input
            type="submit"
            className="btn btn-primary btn-block"
            value="Guardar"
            disabled={mutation.isLoading}
          />
        </div>
      </CheckFeatures> 
    </form>
  )

  return (
    <div className="card shadow-sm">
      <div className="card-header">Editar usuario</div>
      <div className="card-body">
        { content }
      </div>
    </div>
  )
}

function PasswordDetailForm({currentMember}){
  
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('')
  const mutationClient = useMutationClient()

  const mutation = useMutation(
    data => mutationClient.put(`users/changePassword`, data),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRepeatPassword("")
          setPassword("")
          setSuccessMessage('La contraseña se ha modificado correctamente')
        }
      },
    }
  )
  let content
  function handleSubmit(e){
    e.preventDefault()
    setErrorMessage('')

    if( password === '' || repeatPassword === '' ){
      setErrorMessage('La contraseña no puede estar vacía')
      setSuccessMessage('')
      return
    }
    if( password !== repeatPassword ){
      setErrorMessage('Las contraseñas no coinciden')
      setSuccessMessage('')
      return
    }
    mutation.reset()
    mutation.mutate({ password, repeatPassword})
  }

  content = (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label className="font-weight-bold">Nueva contraseña</label>

        <input
          className="form-control"
          type="password"
          onChange={e => setPassword(e.target.value)}
          value={password}
          disabled={mutation.isLoading}
        />
      </div>

      <div className="form-group">
        <label className="font-weight-bold">Confirmar nueva contraseña</label>

        <input
          className="form-control"
          type="password"
          onChange={e => setRepeatPassword(e.target.value)}
          value={repeatPassword}
          disabled={mutation.isLoading}
        />
      </div>

      {
        mutation.isError ?
          <p className="text-danger text-center">Hubo un error al cambiar la contraseña</p>
        : null
      }

      {
        errorMessage !== '' ?
          <p className="text-danger text-center">{errorMessage}</p>
        : null
      }
      {
        successMessage !== '' ?
        <p className="text-center">{successMessage}</p>
        :null
      }
      <div className="form-group text-center">
        <input
          type="submit"
          className="btn btn-primary btn-block"
          value="Cambiar"
          disabled={mutation.isLoading}
        />
      </div>
        
    </form>
  )

  return (
    <div className="card shadow-sm">
      <div className="card-header">Cambio de contraseña</div>
      <div className="card-body">
        { content }
      </div>
    </div>
  )
}

export default function MemberEditPage() {
  const CheckFeatures = useFeatureChecker()

  const currentUser = useContext(CurrentUserContext)
  
  return (
    <Container>
      <div className="row">
        <Header
          title="Mi perfil"
        />
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          {
            !currentUser ?
              <div className="text-center my-5 py-4">
                <Spinner />
              </div>
            :
              <MemberDetailsForm
                member={currentUser}
              />
          }
        </div>
        <div className="col-12 col-md-6">
          {
            currentUser  ?
            <CheckFeatures feature="CHANGE_PASSWORD">
              <PasswordDetailForm
                currentMember = {currentUser}
              />
            </CheckFeatures>
            
            : null
          }
        </div>
      </div>
    </Container>
  )
}

