import Row from 'react-bootstrap/Row';
import React, { useEffect, useState, useContext} from "react";
import { useInfiniteQuery } from 'react-query'
import { useParams, useNavigate } from 'react-router-dom';
import { defaultQueryFn,StateContext, CurrentUserContext } from '../../../../App.js'
import { toQueryString, TIME_ZONE } from '../../../../utils.js'
import { Container } from '../../../layout/AppLayout.js';
import Spinner from '../../../shared/Spinner.js';
import MediaTable from '../../../media/MediaTable.js'
import {  isAllowed} from '../../../shared/FeatureChecker.js';



export default function MediaListPage() {
  const params = useParams()
  const { scrollBottom } = useContext(StateContext)
  const currentUser = useContext(CurrentUserContext)
  const navigate = useNavigate()

  const [ currentFilters] = useState({
    ZoneId: params.ZoneId,
    MediaTagId:6,
  })

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "LIST_MEDIA"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const fetchMedia = ({ pageParam }) => defaultQueryFn({
    queryKey: [`media/new?${toQueryString({
      ...currentFilters,
      page: pageParam,
      tz: TIME_ZONE,
      limit: 12,
      mimeType:0
    })}`]
  })


  useEffect(() => {
    if( scrollBottom && query.hasNextPage ){
      query.fetchNextPage(currentFilters)
    }
  }, [scrollBottom])

  

  const query = useInfiniteQuery('mediaListZone', fetchMedia, {
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      const pagination = lastPage.pagination

      if (pagination.totalPages > pagination.page) {
        return pagination.page + 1
      }
    }
  })

  return (
    <Container>
      <Row>
        <div className="col-12 pt-2 pb-0 mb-2">
          {
            query.isLoading ?
              <div className="text-center py-4 my-4">
                <Spinner />
              </div>
              :
              <MediaTable pages={query.data && query.data.pages || []} />
          }
        </div>
      </Row>
      {
        query.hasNextPage ?
          <Row className="mb-4 justify-content-center">
            <div className="col-auto col-3 mb-4 pb-4">
              <button
                className="btn btn-lg btn-block btn-primary shadow-sm"
                disabled={query.isFetchingNextPage || !query.hasNextPage}
                onClick={() => query.fetchNextPage(currentFilters)}
              >
                {
                  query.isFetchingNextPage ?
                    <Spinner color="#FFFFFF" size={5}/>
                  :
                    'Cargar más'
                }
              </button>
            </div>
          </Row>
        : null
      }
    </Container>
  )
}

