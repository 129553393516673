import React, { useState, useEffect, useContext } from 'react';

//import { withGoogleMap, withScriptjs, GoogleMap, DirectionsRenderer, Marker, Polygon } from 'react-google-maps';
//import { SearchBox } from "react-google-maps/lib/components/places/SearchBox"
//import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager"

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import { useAddressSearch } from '../shared/Search.js'
import { ZoneSelector, DoorTypeSelector } from '../shared/Selectors.js'
import Spinner from '../shared/Spinner.js';
import useFeatureChecker, { isAllowed, AllowedFeature, DeniedFeature} from '../shared/FeatureChecker.js';
import { CurrentUserContext } from '../../App.js';


//const AddressMap = withScriptjs(withGoogleMap(props => {
  //const coords = props.coordinates.latitude ?
    //{ lat: props.coordinates.latitude, lng: props.coordinates.longitude }
  //: null

  //return (
    //<GoogleMap
      ////fullscreenControl={false}
      ////streetViewControl={false}
      //defaultZoom={15}
      ////defaultCenter={coords}
      //center={coords}
      //options={{
        //disableDefaultUI: true
      //}}
    //>
      //<Marker
        //draggable={true}
        //defaultPosition={coords}
        ////position={coords}
        //onDragEnd={e =>
          //props.onMarkerChange({
            //latitude: e.latLng.lat(),
            //longitude: e.latLng.lng()
          //})
        //}
      ///>
    //</GoogleMap>
  //)
//}))


//function AddressSelector({ door, onChange }){
  //const [ address, setAddress ] = useState(door.address || '')
  ////const [ latitude, setLatitude ] = useState(door.latitude || '')
  ////const [ longitude, setLongitude ] = useState(door.longitude || '')

  //const [ coordinates, setCoordinates ] = useState(door.latitude && door.longitude ? { latitude: door.latitude, longitude: door.longitude } : {})

  //const Searchbox = useAddressSearch({
    //defaultValue: address,
    //onChange: (data) => {
      //if(data){
        //setCoordinates(prev => ({ ...prev, ...data }))
        //setAddress(data.address ? data.address : '')
      //}
    //}
  //})

  //useEffect(() => {
    //onChange({
      //address,
      //...coordinates
    //})
  //}, [address, coordinates])

  //return (
    //<div className="form-group">
      //<label>Dirección</label>

      //{ Searchbox }

      //{
        //address ?
          //<AddressMap
            //coordinates={coordinates}
            //onMarkerChange={coords => setCoordinates(prev => ({ ...prev, ...coords }))}
            //googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCSvFpLP_WJc2vE4gkQ0GVlpCE6chx0MpA"
            //loadingElement={
              //<div style={{ height: `100%` }}>
                //<Spinner></Spinner>
              //</div>
            //}
            //containerElement={
              //<div className="mt-2" style={{ height: `300px` }} />
            //}
            //mapElement={
              //<div style={{ height: `100%` }} />
            //}
          ///>
        //: null
      //}
    //</div>
  //)
//}

export default function DoorForm({ currentDoor, save, isLoading }) {
  const [ name, setName ] = useState(currentDoor.name || '');
  const [ ZoneId, setZoneId ] = useState(currentDoor.zone_id || '')
  const [ DoorTypeId, setDoorTypeId ] = useState(currentDoor.door_type_id || '')

  const [ isHttpEnabled, setIsHttpEnabled ] = useState(currentDoor.httpEnabled || 0)
  const [ isBleEnabled, setIsBleEnabled ] = useState(currentDoor.bleEnabled || 0)
  const [ isQREnabled, setIsQREnabled ] = useState(currentDoor.isQR || 0)
  const [ isNFCEnabled, setIsNFCEnabled ] = useState(currentDoor.isNFC || 0)

  const [ bleName, setBleName ] = useState(currentDoor.name_ble || '');
  const [ bleKey, setBleKey ] = useState(currentDoor.key_ble || '');
  const [ outputPin, setOutputPin ] = useState(currentDoor.output_pin || 1);
  const [ inputPin, setInputPin ] = useState(currentDoor.input_pin || 1);
  const [ uuid, setUuid ] = useState(currentDoor.uuid || '')

  const [ address, setAddress ] = useState(currentDoor.address || '');
  const [ longitude, setLongitude ] = useState(currentDoor.longitude || '');
  const [ latitude, setLatitude ] = useState(currentDoor.latitude || '');

  const [ errors, setErrors ] = useState({});
  const CheckFeatures = useFeatureChecker()
  const currentUser = useContext(CurrentUserContext)
  const RoleLevel = currentUser && currentUser.role_level

  const validations = [];

  function validate(e) {
    e.preventDefault();

    if (!name) {
        validations.push(['name', 'Nombre es requerido']);
    }

    if (!DoorTypeId) {
      validations.push(['DoorTypeId', 'Tipo de puerta es requerido']);
    }

    if(validations.length > 0){
      setErrors(validations.reduce((acc, item) => ({...acc, [item[0]]: item[1]}), {}));
      return;
    }else{
      setErrors([])
      const data = {
        name,
        uuid,
        address,
        zone_id: ZoneId === '' ? null : ZoneId,
        door_type_id: DoorTypeId === '' ? null : DoorTypeId,
        httpEnabled: isHttpEnabled,
        bleEnabled: isBleEnabled,
        key_ble: bleKey,
        name_ble: bleName,
        output_pin: outputPin,
        input_pin: inputPin,
        longitude: longitude ? longitude : null,
        latitude: latitude ? latitude : null,
        isNFC:isNFCEnabled,
        isQR:isQREnabled
      }

      if(currentDoor.id){
        data.id = currentDoor.id
      }

      save(data);
    }
  }

  return (
    <form onSubmit={validate}>
      <div className="form-group">
        <label>Nombre</label>
        <input
          type="text"
          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
          onChange={e => setName(e.target.value)}
          value={name}
          disabled={isLoading}
          autoFocus={true}
        />

        <div className="invalid-feedback">{errors.name}</div>
      </div>

      <div className="form-group">
        <label>Zona</label>

        <CheckFeatures feature='LIST_ZONES'>
          <AllowedFeature>
            <ZoneSelector
              disabled={isLoading}
              onChange={ZoneId => setZoneId(ZoneId)}
              placeholder="Seleccione la zona"
              defaultValue={ZoneId}
            />
          </AllowedFeature>
          <DeniedFeature>
            <ZoneSelector
              disabled={true}
              placeholder="Seleccione la zona"
              defaultValue={ZoneId}
            />
          </DeniedFeature>
        </CheckFeatures>
       

        <div className="invalid-feedback">{errors.ZoneId}</div>
      </div>

      {
        //currentDoor.id ?
          //<AddressSelector
            //door={currentDoor}
            //onChange={data => {
              ////console.log(data);
              //setAddress(data.address || '')
              //setLongitude(data.longitude || '')
              //setLatitude(data.latitude || '')
            //}}
          ///>
        //: null
      }

      <div className="form-group">
        <label>Tipo de puerta</label>
        <CheckFeatures feature ='LIST_DOOR_TYPES'>
          <AllowedFeature>
            <DoorTypeSelector
              disabled={isLoading}
              onChange={DoorTypeId => setDoorTypeId(DoorTypeId)}
              placeholder="Seleccione el tipo de puerta"
              defaultValue={DoorTypeId}
              hasError={errors.DoorTypeId}
            />
          </AllowedFeature>
          <DeniedFeature>
            <DoorTypeSelector
              disabled={true}
              placeholder="Seleccione el tipo de puerta"
              defaultValue={DoorTypeId}
              hasError={errors.DoorTypeId}
            />
          </DeniedFeature>
        </CheckFeatures>
        

        <div className="invalid-feedback">{errors.DoorTypeId}</div>
      </div>

      {
        RoleLevel == 0 ?

          <div>
            <div className="mb-3">
              <Container fluid>
                <Row>
                  <Col sm={6} className={`pl-0 ${currentUser &&!isAllowed({user: currentUser,feature: "LIST_DOOR_CUSTOM_FIELD" })?'d-none' : ''}`}>
                    <label>Pin de entrada</label>
                    <input
                      type="number"
                      min="1"
                      max="5"
                      className={`form-control`}
                      onChange={e => setInputPin(e.target.value)}
                      value={inputPin}
                      disabled={isLoading}
                    />
                  </Col>

                  <Col sm={6} className={`px-0 ${currentUser &&!isAllowed({user: currentUser,feature: "LIST_DOOR_CUSTOM_FIELD" })?'d-none' : ''}`}>
                    <label>Pin de salida</label>
                    <input
                      type="number"
                      min="1"
                      max="5"
                      className={`form-control `}
                      onChange={e => setOutputPin(e.target.value)}
                      value={outputPin}
                      disabled={isLoading}
                    />
                  </Col>
                </Row>
              </Container>
            </div>

            <div className={`form-group ${currentUser &&!isAllowed({user: currentUser,feature: "LIST_DOOR_CUSTOM_FIELD" })?'d-none' : ''}`}>
              <label>Identificador Único</label>
              <input
                type="text"
                className={`form-control ${errors.uuid ? 'is-invalid' : ''} `}
                onChange={e => setUuid(e.target.value)}
                value={uuid}
                disabled={isLoading}
                style={{
                  fontFamily: 'monospace'
                }}
              />
            </div>

            <CheckFeatures feature ='LIST_DOOR_CUSTOM_FIELD'>
              <>
                <div className="form-group">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={isHttpEnabled}
                      onChange={(x) => setIsHttpEnabled(x.currentTarget.checked)}
                      id="http"
                    />

                    <label className="custom-control-label" htmlFor="http">{isHttpEnabled ? 'Apertura remota habilitada' : 'Apertura remota deshabilitada'}</label>
                  </div>

                  <div className="invalid-feedback">{errors.isHttpEnabled}</div>
                </div>

                <div className="form-group">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={isBleEnabled}
                      onChange={(x) => setIsBleEnabled(x.currentTarget.checked)}
                      id="ble"
                    />
                    <label className="custom-control-label" htmlFor="ble">{isBleEnabled ? 'Apertura por bluetooth habilitada' : 'Apertura por bluetooth deshabilitada'}</label>
                  </div>

                  <div className="invalid-feedback">{errors.isBleEnabled}</div>
                </div>

                
                <div className="form-group">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={isNFCEnabled}
                      onChange={(x) => setIsNFCEnabled(x.currentTarget.checked)}
                      id="nfc"
                    />

                    <label className="custom-control-label" htmlFor="nfc">{isNFCEnabled ? 'Apertura por NFC habilitada' : 'Apertura por NFC deshabilitada'}</label>
                  </div>

                  <div className="invalid-feedback">{errors.isNFCEnabled}</div>
                </div>
                <div className="form-group">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={isQREnabled}
                      onChange={(x) => setIsQREnabled(x.currentTarget.checked)}
                      id="qr"
                    />

                    <label className="custom-control-label" htmlFor="qr">{isQREnabled ? 'Apertura por QR habilitada' : 'Apertura por QR deshabilitada'}</label>
                  </div>
                  <div className="invalid-feedback">{errors.isQREnabled}</div>
                </div>
              </>
            </CheckFeatures>


              
            {
              isBleEnabled ?
                <>
                  <div className={`form-group ${currentUser &&!isAllowed({user: currentUser,feature: "LIST_DOOR_CUSTOM_FIELD" })?'d-none' : ''}`}>
                    <label>Nombre de interfaz bluetooth</label>

                    <input
                      type="text"
                      className={`form-control ${errors.bleName ? 'is-invalid' : ''}`}
                      onChange={e => setBleName(e.target.value)}
                      value={bleName}
                      disabled={isLoading}
                    />

                    <div className="invalid-feedback">{errors.bleName}</div>
                  </div>

                  <div className={`form-group ${currentUser &&!isAllowed({user: currentUser,feature: "LIST_DOOR_CUSTOM_FIELD" })?'d-none' : ''}`}>
                    <label>Llave privada AES256 para cifrado bluetooth</label>

                    <input
                      type="text"
                      className={`form-control ${errors.bleKey ? 'is-invalid' : ''}`}
                      onChange={e => setBleKey(e.target.value)}
                      value={bleKey}
                      disabled={isLoading}
                      style={{
                        fontFamily: 'monospace'
                      }}
                    />

                    <div className="invalid-feedback">{errors.bleKey}</div>
                  </div>
                </>
              : null
            }
          </div>

      : null
    }
      <div className="form-group">
        <button className={`btn btn-primary ${isLoading ? 'loading' : ''}`} disabled={isLoading}>
          <span>Guardar</span>
          <div className='spinner-border' role='status'></div>
        </button>
      </div>
    </form>
  )
};
