import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useFromQueryString } from '../../utils.js'
import { useMutation } from 'react-query'
import { useMutationClient } from '../shared/hooks.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export const CurrentUserContext = React.createContext({})

function validatePassword(password) {
  var va = {
      'capital' : /[A-Z]/,
      'digit'   : /\d/,
      'minLength'    : /[A-Za-z0-9\w\W]{6,}$/
  };
  return va.capital .test(password) &&
         va.digit   .test(password) &&
         va.minLength    .test(password);
}

export default function ChangeRecoverPasswordPage() {
  const queryString = useFromQueryString()
  const mutationClient = useMutationClient()
  const navigate = useNavigate()
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const token = queryString.t

  if( !token ){
    navigate('/login')
  }

  const mutation = useMutation(
    data => mutationClient.post('login/password/change', data)
  )

  function handleSubmit(e){
    e.preventDefault()
    setErrorMessage('')

    if( password === '' || repeatPassword === '' ){
      setErrorMessage('La contraseña no puede estar vacía')
      return
    }

    if( password !== repeatPassword ){
      setErrorMessage('Las contraseñas no coinciden')
      return
    }

    mutation.reset()
    mutation.mutate({ password, repeatPassword, token: queryString.t })
  }

  return (
    <div className="col-12 col-md-3 col-lg-3 mx-auto">
      <div className="card shadow-sm">

        {
          mutation.isSuccess ?
            <div className="card-body text-center">
              <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '3em' }} className="my-4 text-success" />
              <p>La contraseña se ha reestablecido correctamente</p>
              <Link className="btn btn-link" to="/login">Iniciar sesión</Link>
            </div>
          :
            <div className="card-body">
              <h4 className="py-2 text-center text-muted">Reestablecer contraseñea</h4>

              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="font-weight-bold">Nueva contraseña</label>

                  <input
                    className="form-control"
                    type="password"
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                    disabled={mutation.isLoading}
                  />
                </div>

                <div className="form-group">
                  <label className="font-weight-bold">Confirmar nueva contraseña</label>

                  <input
                    className="form-control"
                    type="password"
                    onChange={e => setRepeatPassword(e.target.value)}
                    value={repeatPassword}
                    disabled={mutation.isLoading}
                  />
                </div>

                {
                  mutation.isError ?
                    <p className="text-danger text-center">Hubo un error al cambiar la contraseña</p>
                  : null
                }

                {
                  errorMessage !== '' ?
                    <p className="text-danger text-center">{errorMessage}</p>
                  : null
                }

                <div className="form-group text-center">
                  <input
                    type="submit"
                    className="btn btn-primary btn-block"
                    value="Cambiar"
                    disabled={mutation.isLoading}
                  />
                </div>
              </form>
            </div>
        }

      </div>
    </div>
  )
}
