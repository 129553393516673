import React, { useState, useEffect } from 'react';

import { useQuery, useMutation } from 'react-query'
import { Link, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons'

import { useFromQueryString } from '../../utils.js'
import { useMutationClient } from '../shared/hooks.js'
import Spinner from '../shared/Spinner.js'

function RegisterForm({ onSave, isLoading, isError }){
  const [ name, setName ] = useState('')
  const [ lastname, setLastname ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ repeatPassword, setRepeatPassword ] = useState('')
  const [ errors, setErrors ] = useState([])

  function handleSubmit(e){
    e.preventDefault()
    const errors = []
    setErrors(errors)

    if( !name ){
      errors.push('Nombre es requerido')
    }

    if( !lastname ){
      errors.push('Apellido es requerido')
    }

    if( !password ){
      errors.push('Contraseña es requerida')
    }

    if( !repeatPassword ){
      errors.push('Confirmación de contraseña es requerida')
    }

    if( password !== '' && repeatPassword !== '' ){
      if( password !== repeatPassword ){
        errors.push('Las contraseñas no coinciden')
      }
    }

    if( errors.length ){
      setErrors(errors)
    }else{
      onSave({
        name,
        lastname,
        password,
        repeatPassword,
      })
    }
  }

  return (
    <div className="card-body">
      <h4 className="py-2 text-center text-muted">Registro de usuario</h4>
      <p className="">Has sido invitado a la plataforma de seguridad Octoberus. Completa la siguiente información para crear y activar tu cuenta.</p>
      <hr />

      {
        errors.length ?
          <div className="alert alert-danger">
            <ul className="p-0 m-0" style={{ listStyle: 'none' }}>
              {
                errors.map((x, i) => <li key={i}>{x}</li>)
              }
            </ul>
          </div>
        : null
      }

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Nombre</label>
          <input
            className="form-control"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            disabled={isLoading}
          />
        </div>

        <div className="form-group">
          <label>Apellido</label>
          <input
            className="form-control"
            type="text"
            value={lastname}
            onChange={e => setLastname(e.target.value)}
            disabled={isLoading}
          />
        </div>

        <div className="form-group">
          <label>Contraseña</label>
          <input
            className="form-control"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            disabled={isLoading}
          />
        </div>

        <div className="form-group">
          <label>Confirmar contraseña</label>
          <input
            className="form-control"
            type="password"
            value={repeatPassword}
            onChange={e => setRepeatPassword(e.target.value)}
            disabled={isLoading}
          />
        </div>

        {
          isError ?
            <div className="form-group">
              <p className="text-danger text-center">Hubo un error al crear la cuenta. Por favor intente más tarde o contáctese con el administrador</p>
            </div>
          : null
        }

        <div className="form-group text-center">
          <input
            type="submit"
            className="btn btn-primary btn-block"
            value={`${ isLoading ? 'Creando cuenta...' : 'Crear mi cuenta' }`}
            disabled={isLoading}
          />
        </div>
      </form>
    </div>
  )
}

export default function WelcomePage() {
  const queryString = useFromQueryString()
  const mutationClient = useMutationClient()

  const mutation = useMutation(
    data => mutationClient.post('register', { ...data, token: queryString.t })
  )

  const query = useQuery(`register/token?t=${queryString.t}`, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  return (
    <div className="col-12 col-md-6 col-lg-3 mx-auto">
      {

        mutation.isSuccess ?
          <div className="card shadow-sm">
            <div className="card-body text-center">
              <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '3em' }} className="my-4 text-success" />
              <p>Tu cuenta ha sido creada exitosamente</p>

              <div className="d-flex justify-content-center align-items-center my-3">
                <a
                  href="https://play.google.com/store/apps/details?id=com.octoInc.octo_door"
                  className="d-block bg-dark text-white border px-3 mx-3"
                  style={{ borderRadius: '15px' }}
                >
                  <FontAwesomeIcon icon={faGooglePlay} size="4x" className="my-3" />
                  <p>Descargar aplicación para Android</p>
                </a>

                <a
                  href="https://apps.apple.com/us/app/octo-door/id1491066941"
                  className="d-block bg-dark text-white border px-3 mx-3"
                  style={{ borderRadius: '15px' }}
                >
                  <FontAwesomeIcon icon={faApple} size="4x" className="my-3" />
                  <p>Descargar aplicación para iPhone</p>
                </a>
              </div>

              <Link className="btn btn-link" to="/login">Iniciar sesión en la web</Link>
            </div>
          </div>
        :
          <div className="card shadow-sm">
            {
              query.isLoading ?
                <div className="p-4 m-4 text-center">
                  <Spinner />
                </div>
              :
                query.isSuccess ?
                  <React.Fragment>
                    <RegisterForm
                      onSave={data => {
                        mutation.reset()
                        mutation.mutate(data)
                      }}
                      isLoading={mutation.isLoading}
                      isError={mutation.isError}
                    />
                  </React.Fragment>
                :
                  <div className="text-center alert alert-danger rounded-sm m-0 py-4">
                    <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '3em' }} className="my-4" />

                    <p className="p-0">Esta invitación ya fue utilizada o hubo un error en el servidor. Por favor, intente de nuevo más tarde o contáctese con el administrador.</p>
                  </div>
            }
          </div>
      }
    </div>
  )
}

