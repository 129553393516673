import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router';

import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useNotifications } from '../shared/Notifications.js'

import { useMutationClient } from '../shared/hooks.js'
import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import Spinner from '../shared/Spinner.js';
import Header from "../shared/Header.js"

import DoorForm from './DoorForm.js';
import { isAllowed } from '../shared/FeatureChecker.js';
import { CurrentUserContext } from '../../App.js';

export default function EditDoorPage() {
  const params = useParams()
  const navigate = useNavigate()
  const mutationClient = useMutationClient()
  const notifications = useNotifications()
  const currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "UPDATE_DOOR"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const query = useQuery(`doors/?DoorId=${params.DoorId}`)
  const currentDoor = query.data && query.data.result && query.data.result.length && query.data.result[0]

  const updateDoor = useMutation(
    data => mutationClient.put(`doors/${data.id}`, data), {
    onSuccess: (data, variables, context) => {
      if( data.status === 200 ){
        notifications.success('Puerta actualizada correctamente')
        navigate(`/doors/${data.data.result.id}`)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al actualizar la puerta')
    }
  })

  return (
    <Container>
      <Row>
        <Header title="Editar puerta" items={[
          { label: `Listado de puertas`, to:"/doors" },
          { label: `Detalle de puerta` , to: `/doors/${params.DoorId}` },
          { label: `Editar de puerta` }
        ]} />
      </Row>

      <Row className="mb-2">
        <div className="col-12 mb-2 col-lg-6">
          <Card className="shadow-sm">
            <Card.Header>Datos de puerta</Card.Header>
            <Card.Body>
              {
                query.isLoading ?
                  <div className="text-center">
                    <Spinner />
                  </div>
                :
                  <DoorForm
                    currentDoor={currentDoor}
                    save={data => updateDoor.mutate(data)}
                    isLoading={updateDoor.isLoading}
                  />
              }
            </Card.Body>
          </Card>
        </div>
      </Row>
    </Container>
  )
}
