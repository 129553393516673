import { useMutation } from 'react-query'
import { useMutationClient } from '../shared/hooks.js'
import { useNotifications } from '../shared/Notifications.js'
import { useState } from "react";
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';



export default function SubCardFeature({ id, isSelected, name, isEdit }) {
    const mutationClient = useMutationClient()
    const notifications = useNotifications()

    const createAccess = useMutation(
        data => mutationClient.post(`roles/${params.RoleId}/features/${data['type']}`, { "FeatureId": id }), {
        onSuccess: (data, variables, context) => {
          if (data.status === 204) {
            setLoading(false)
            setChecked(!checked)
            notifications.success('Funcionalidad editada correctamente')
          }
        },
        onError: () => {
            setLoading(false)
            notifications.danger('Ha ocurrido un error al editar la funcionalidad')
        }
      })

    const [checked, setChecked] = useState(isSelected);
    const [isLoading, setLoading] = useState(false)

    const params = useParams()

    return <div
        key={id}
        className="list-group-item"
    >
        {
            isLoading ? (
                <div>
                    <FontAwesomeIcon icon={faSpinner} className="mr-2 text-secondary" spin />
                    <span>{name}</span>
                </div>
            ) :
                isEdit ? (
                    <Form>
                        <Form.Check
                            type="switch"
                            id={`switch-${id}`}
                            label={name}
                            defaultChecked={checked}
                            onChange={(_)=>{setLoading(true);checked?createAccess.mutate({"type":"remove"}):createAccess.mutate({"type":"add"})}}
                            disabled ={id == 132?true:false }
                        />
                    </Form>

                ) :
                    isSelected || checked? (
                        <div>
                            <FontAwesomeIcon icon={faCheckCircle} className="mr-2 text-success" />
                            <span>{name}</span>
                        </div>
                    )
                        : (
                            <div>
                                <FontAwesomeIcon icon={faTimesCircle} className="mr-2 text-dark" />
                                <span>{name}</span>
                            </div>
                        )

        }

    </div >;
}
