import React, { useEffect, useContext} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom';

import { Container } from '../layout/AppLayout.js'

import { useMutationClient } from '../shared/hooks.js'
import { useNotifications } from '../shared/Notifications.js'

import Header from "../shared/Header.js";

import ZoneDetailSubmenu from '../zones/ZoneDetailSubmenu.js';
import AssetForm from './AssetForm.js'
import Spinner from '../shared/Spinner.js';
import { isAllowed } from '../shared/FeatureChecker.js';
import { CurrentUserContext } from '../../App.js';

export default function EditAssetPage() {
  const params = useParams()
  const mutationClient = useMutationClient()
  const navigate = useNavigate()
  const notifications = useNotifications()
  const queryClient = useQueryClient()
  const currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "UPDATE_ASSETS"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])


  const query = useQuery(`assets/${params.AssetId}`)
  const currentAsset = query.data && query.data.result

  const mutation = useMutation(
    data => mutationClient.put(`assets/${params.AssetId}`, data), {
    onSuccess: (data) => {
      if( data.status === 200 ){
        notifications.success('Activo actualizado correctamente')
        navigate(`/zones/${params.ZoneId}/assets/${params.AssetId}`)
        queryClient.invalidateQueries(`assets`)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al actualizar el activo')
    }
  })

  return (
    <Container>
      <div className="row">
        <Header title="Detalle de zona - Editar activo" items={[
          { label: `Listado de zonas`, to: "/zones" },
          { label: `Detalle de zona`, to: `/zones/${params.ZoneId}` },
          { label: `Activos`, to: `/zones/${params.ZoneId}/assets` },
          { label: `Detalle de activo`, to: `/zones/${params.ZoneId}/assets/${params.AssetId}` },
          { label: `Editar activo` },
        ]} />
      </div>

      <div className="row">
        <ZoneDetailSubmenu ZoneId={params.ZoneId}/>
      </div>

      <div className="row">
        <div className="col-6">
          <div className="card shadow-sm">
            <div className="card-header">Editar activo</div>
            <div className="card-body">
              {
                query.isLoading ?
                  <div className="text-center">
                    <Spinner />
                  </div>
                :
                  <AssetForm
                    asset={currentAsset || {}}
                    save={data => mutation.mutate(data)}
                    isLoading={mutation.isLoading}
                  />
              }
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
