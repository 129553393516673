import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query'

import Header, { HeaderActions } from "../shared/Header.js"
import useFeatureChecker, { isAllowed, AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js'
import Spinner from '../shared/Spinner.js';
import { DestroyButton } from '../shared/Buttons.js';

import { Container } from '../layout/AppLayout.js';
import FacilityDetailSubmenu from './FacilityDetailSubmenu.js'
import { CurrentUserContext } from '../../App.js';

function DetailsCard({ FacilityId }){
  const query = useQuery(`facilities/${FacilityId}`)

  return (
    <div className="card shadow-sm mb-3">
      <div className="card-header">Detalle de instalación</div>

      <div className="card-body">
        {
          query.isLoading ?
            <Spinner />
          :
            <>
              <div className="form-group">
                <label className="form-label font-weight-bold">Nombre</label>
                <p>{ query.data && query.data.result.name }</p>
              </div>

              <div className="form-group">
                <label className="form-label font-weight-bold">Tipo de instalación</label>
                <p>{ query.data && query.data.result.facility_type_name }</p>
              </div>

              <div className="form-group">
                <label className="form-label font-weight-bold">Dirección</label>
                <p>{ query.data && query.data.result.address }</p>
              </div>
            </>
        }
      </div>
    </div>
  )
}

function ZonesListCard({ FacilityId }){
  const query = useQuery(`zones?FacilityId=${FacilityId}&limit=10`)
  const zones = query.data && query.data.result || []
  const CheckFeatures = useFeatureChecker()

  return (
    <div className="card shadow-sm mb-3">
      <div className="card-header">Zonas</div>

      <div className="card-body p-0">
        {
          query.isLoading ?
            <div className="text-center my-4 py-4">
              <Spinner />
            </div>
          :
            <div className="list-group list-group-flush">
              {
                zones.length ?
                  zones.map(item => (
                    <div key={item.id} className="list-group-item">
                      <CheckFeatures feature='DETAIL_ZONE'>
                        <AllowedFeature>
                          <Link to={`/zones/${item.id}`}>{item.name}</Link>
                        </AllowedFeature>
                        <DeniedFeature>
                          {item.name}
                        </DeniedFeature>
                      </CheckFeatures>
                    </div>
                  ))
                :
                  <div className="text-muted text-center my-4 py-4">
                    No se encontraron zonas dentro de esta instalación
                  </div>
              }
            </div>
        }
      </div>
    </div>
  )
}

export default function FacilityDetailsPage() {
  const params = useParams()
  const CheckFeatures = useFeatureChecker()
  const navigate = useNavigate()
  const currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "DETAIL_FACILITY"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])


  return (
    <Container>
      <div className="row">
        <Header title="Detalle de instalación" items={[
          { label: `Listado de instalaciones`, to: "/facilities" },
          { label: `Detalle de instalación` },
        ]}>
          <HeaderActions>
            <CheckFeatures feature="UPDATE_FACILITY">
              <Link
                to={`/facilities/${params.FacilityId}/edit`}
                className="btn btn-primary mr-2"
              >Editar Instalación</Link>
            </CheckFeatures>

            <CheckFeatures feature="DESTROY_FACILITY">
              <DestroyButton
                label="Eliminar instalación"
                confirmMessage="¿Está seguro que desea eliminar la instalación?"
                successMessage="Instalación eliminada correctamente"
                deletePath={`facilities/${params.FacilityId}`}
                invalidate="facilities"
                redirect="/facilities"
              />
            </CheckFeatures>
          </HeaderActions>
        </Header>
      </div>

      <div className="row">
        <FacilityDetailSubmenu FacilityId={params.FacilityId} />
      </div>

      <div className="row mb-2 pb-4">
        <div className="col-12 mb-2 col-lg-6">
          <DetailsCard FacilityId={params.FacilityId} />
        </div>

        <div className="col-12 mb-2 col-lg-6">
          <CheckFeatures feature ='LIST_ZONES'>
            <ZonesListCard FacilityId={params.FacilityId} />
          </CheckFeatures>
        </div>
      </div>
    </Container>
  )
}

