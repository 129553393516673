import React, { useState, useEffect, useContext } from 'react';
import Submenu from "../shared/Submenu.js";

export default function MemberDetailSubmenu({ UserId }){
  return (
    <Submenu items={[
      { to: `/members/${UserId}`, label: 'General', end: true },
      { to: `/members/${UserId}/identities`, label: 'Identidades' , features: ['LIST_IDENTITIES'] },
      { to: `/members/${UserId}/identification`, label: 'Identificación', features: ['LIST_IDENTIFICATION']}
    ]}/>
  )
}
