import FileSaver from 'file-saver';
import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import { useQuery } from 'react-query';
import { CurrentCompanyContext } from '../../App.js';
import { TIME_ZONE, toQueryString, useFromQueryString } from '../../utils.js';

import { faDownload, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Row from 'react-bootstrap/Row';
import { Container } from '../layout/AppLayout.js';

import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';
import { FilterMenu } from '../shared/Filters.js';
import Header, { HeaderActions } from "../shared/Header.js";
import Pagination from '../shared/Pagination.js';
import { useMutationClient } from '../shared/hooks.js';

import MembersListSubmenu from './MembersListSubmenu.js';
import MembersTable from './MembersTable.js';

function UsersDownloadButton({ filters }){
  const [ isLoading, setIsLoading ] = useState(false)
  const currentCompany = useContext(CurrentCompanyContext)
  const mutationClient = useMutationClient()

  useEffect(() => {
    async function download(){
      const res = await mutationClient.get(`companies/${currentCompany.id}/users?download=true&${toQueryString(filters)}`)
      setIsLoading(false)

      if(res.status === 200){
        var blob = new Blob([res.data], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, `registros.csv`);
      }else{
        console.log('Error al descargar archivo');
      }
    }

    if( isLoading ){
      download()
    }
  }, [isLoading])

  return (
    <button
      className={`btn btn-outline-info btn-ml-1 ml-2 d-none d-lg-inline-block ${ isLoading ? "loading" : "" }`}
      onClick={() => setIsLoading(true)}
      disabled={isLoading}
    >
      <FontAwesomeIcon icon={faDownload} className="mr-2" />
      {
        isLoading ?
          <span>Generando reporte...</span>
        :
          <span>Descargar CSV</span>
      }
    </button>
  )
}

export default function MembersListPage() {
  const queryString = useFromQueryString()
  const CheckFeatures = useFeatureChecker()
  const currentCompany = useContext(CurrentCompanyContext)
  const navigate = useNavigate()

  const [currentFilters, setCurrentFilters] = useState({
    page: parseInt(queryString.page) || 1,
    limit: parseInt(queryString.limit) || 10,
    GroupId: queryString.GroupId || '',
    status: queryString.status || 'enabled',
    search: queryString.search || '',
    tz: TIME_ZONE
  });

  useEffect(()=>{
    setCurrentFilters(prevFilters => ({
      ...prevFilters,
      page: 1
    }));
  },[queryString.GroupId])
  
  const query = useQuery(`companies/${currentCompany.id}/users?${toQueryString(currentFilters)}`, {
    keepPreviousData: true,
  })

  useEffect(() => {
    navigate('?' + toQueryString(currentFilters), { replace: true })
  }, [currentFilters]);

  return (
    <Container>
      <Row>
        <Header
          title="Usuarios"
          items={[
            { label: "Listado de usuarios" }
          ]}
        >

          <HeaderActions>
          <CheckFeatures every={["CREATE_USER","LIST_ROLES"]}>
              <Link to="/members/new" className="btn btn-primary mr-2">
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                <span>Crear usuarios</span>
              </Link>
            </CheckFeatures>

            <CheckFeatures every={["COMPANY_ADD_USER","LIST_ROLES"]}>
              <Link to="/members/invite" className="btn btn-primary">
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                <span>Invitar usuarios</span>
              </Link>
            </CheckFeatures>
    
            {/*
              <CheckFeatures some={["LIST_USERS_DOWNLOAD"]}>
                <UsersDownloadButton filters={currentFilters} />
              </CheckFeatures> 
            */}
          </HeaderActions>

        </Header>
      </Row>

      <Row className="mb-2">
        <MembersListSubmenu/>    
      </Row>

      <Row className="mb-2">
        <FilterMenu
          currentFilters={currentFilters}
          onChange={filters => setCurrentFilters(prev => ({ ...prev, ...filters }))}
          memberStatusFilter
        />
      </Row>

      <CheckFeatures feature="LIST_USERS">
        <AllowedFeature>
          <Row className="mb-2">
            <div className="col-12">
              <MembersTable
                users={(query.data && query.data.result || [])}
                isLoading={query.isLoading}
                refetch={query.refetch}
                listType={currentFilters.status}
                onSort={sort => {
                  setCurrentFilters(prev => ({...prev, sort: sort.join("-")}))
                }}
              />
            </div>
          </Row>
        </AllowedFeature>
        <DeniedFeature>
          <Navigate to={'/error?error=403'}/>
        </DeniedFeature>
      </CheckFeatures>

      <Row className="mt-2">
        <Pagination
          pagination={query.data && query.data.pagination}
          isLoading={query.isFetching}
          onChange={page => setCurrentFilters(prev => ({ ...prev, page }))}
        />
      </Row>
    </Container>
  );
}

