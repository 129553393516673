import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { CurrentCompanyContext, CurrentUserContext } from '../../App.js'

import { toQueryString, useFromQueryString } from '../../utils.js'

import Row from 'react-bootstrap/Row'
import { Container } from '../layout/AppLayout.js'

import { FilterMenu } from '../shared/Filters.js'
import Header, { HeaderActions } from "../shared/Header.js"
import Pagination from '../shared/Pagination.js'
import Spinner from '../shared/Spinner.js'
import TableList from '../shared/TableList.js'

import { PostButton } from '../shared/Buttons.js'

import useFeatureChecker,{ AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js'


function CompaniesTable({ companies }) {
  const CheckFeatures = useFeatureChecker()

  if (!companies.length) {
    return <div className="text-center my-4 py-4 text-muted">No se econtraron compañías asociadas a tu cuenta</div>
  }

  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="Nombre" />
        <TableList.HeaderItem value="Industria" />
      </TableList.Header>

      <TableList.Body>
        {
          companies.map(item => (
            <TableList.Row key={item.id}>
              <TableList.Column>
                  <CheckFeatures feature="DETAIL_COMPANY">
                    <AllowedFeature>
                    <Link className="py-3" to={`/companies/${item.id}`}>{item.name}</Link>
                    </AllowedFeature>
                    <DeniedFeature>
                      <span>{item.name}</span>
                    </DeniedFeature>
                  </CheckFeatures>
              </TableList.Column>

              <TableList.Column value={item.industry_name} />
            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}

function PendingInvitationsList(){
  const query = useQuery('users/me/invites')
  const invites = query.data && query.data.result || []

  if( !invites.length ){
    return null
  }

  return (
    <div className="col-12 p-0 mb-4">
      <div className="card shadow">
        <div className="card-body">
          <div className="alert alert-warning">
            Tienes invitaciones a compañías pendientes
          </div>

          <div className="list-group shadow-sm">
            {
              invites.map(item => (
                <div
                  key={item.id}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div>
                    { item.name }
                  </div>

                  <div>
                    <PostButton
                      label="Aceptar"
                      className="btn-primary mr-2"
                      confirmMessage="¿Está seguro que desea aceptar esta invitación?"
                      post={`users/me/invites/${item.id}/accept`}
                      successMessage="Invitación aceptada"
                      errorMessage="Hubo un error al procesar la invitación"
                      reload
                    />

                    <PostButton
                      label="Rechazar"
                      className="btn-danger mr-2"
                      confirmMessage="¿Está seguro que desea rechazar esta invitación?"
                      post={`users/me/invites/${item.id}/reject`}
                      successMessage="Invitación rechazada"
                      errorMessage="Hubo un error al procesar la invitación"
                      reload
                    />
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default function CompaniesListPage() {
  const queryString = useFromQueryString()
  const CheckFeatures = useFeatureChecker()

  const currentUser = useContext(CurrentUserContext)
  const currentCompany = useContext(CurrentCompanyContext)
  const navigate = useNavigate()

  const [currentFilters, setCurrentFilters] = useState({
    search: queryString.search || '',
    page: parseInt(queryString.page) || 1,
    limit: parseInt(queryString.limit) || 10,
  });

  const query = useQuery(`companies?${toQueryString(currentFilters)}`, {
    keepPreviousData: true,
    meta: { withoutCompanyId: true }
  })

  useEffect(() => {
    if( currentUser && currentUser.role_level>0 && currentCompany){ 
      navigate(`/companies/${currentCompany.id}`)
    }
  }, [currentUser,query])

  useEffect(() => {
    navigate('?' + toQueryString(currentFilters), { replace: true })
  }, [currentFilters])

  return (
    <Container>
      <Row>
        <Header title="Compañías" items={[
          { label: "Listado de compañías" }
        ]}>
          <HeaderActions>
            <CheckFeatures feature="CREATE_COMPANY">
              <Link to="/companies/new" className="btn btn-primary">Crear compañía</Link>
            </CheckFeatures>
          </HeaderActions>
        </Header>
      </Row>

      <Row>
        <PendingInvitationsList />
      </Row>
      <Row className="mb-2">
        <FilterMenu
          currentFilters={currentFilters}
          onChange={filters => setCurrentFilters(prev => ({ ...prev, ...filters }))}
        />
      </Row>
      <CheckFeatures feature='LIST_COMPANY'>
        <AllowedFeature>

          <Row>
            <div className="col-12 pb-0">
              {
                query.isLoading ?
                  <div className="text-center py-4 my-4">
                    <Spinner />
                  </div>
                :
                  <CompaniesTable companies={query.data && query.data.result || []} />
              }
            </div>
          </Row>

          <Row className="mt-2">
            <Pagination
              pagination={query.data && query.data.pagination}
              isLoading={query.isFetching}
              onChange={page => setCurrentFilters(prev => ({ ...prev, page }))}
            />
          </Row>
        </AllowedFeature>
        <DeniedFeature>
          <Navigate to={'/error?error=403'}/>
        </DeniedFeature>
      </CheckFeatures>
    </Container>
  )
}

