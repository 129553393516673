import React, { useState, useEffect, useContext } from 'react';
import Submenu from "../shared/Submenu.js";

export default function FacilityDetailSubmenu({ FacilityId }){
  return (
    <Submenu items={[
      { to: `/facilities/${FacilityId}`, label: 'General', end: true },
      { to: `/facilities/${FacilityId}/sva`, label: 'SVA',features: ['LIST_SVA'] },
      { to: `/facilities/${FacilityId}/floorplan`, label: 'Floorplan' ,features: ['LIST_FLOORPLAN','UPDATE_FLOORPLAN']},

      //{ to: `/facilities/${FacilityId}/zones`, label: 'Zonas' },
    ]}/>
  )
}

