import React, { useEffect, useState, useContext } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import { Container } from '../layout/AppLayout.js'

import Header, { HeaderActions } from "../shared/Header.js"
import useFeatureChecker, { AllowedFeature, DeniedFeature, isAllowed } from '../shared/FeatureChecker.js'
import Spinner from '../shared/Spinner.js';

import translateAlert from "./translate.js";
import translateEvent from '../events/translate.js';
import { EventLink } from '../events/EventsTable.js';
import { formatDate, TIME_ZONE } from '../../utils.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CurrentUserContext } from "../../App.js";

import {
  faImage,
  faVideo,
  faUser
} from '@fortawesome/free-solid-svg-icons';
const formatAlertDate = (date) => {
  const d = new Date(Date.parse(date))
  const region = "es-CL"
  return `${d.toLocaleDateString(region)} ${d.toLocaleTimeString(region)}`
}
function MediaList({ media, AlertId ,isEntry=false}) {
  let content = null

  if (media.length) {
    content = (
      <div className="row row-cols-1 row-cols-sm-3 row-cols-md-4 row-cols-lg-6">
        {
          media.map(
            item => (
              <MediaListItem item={item} AlertId={AlertId} key={item.id + "media"} isEntry={isEntry}/>
            )
          )
        }
      </div>
    )
  }

  return content
}

function MediaListItem({ item, AlertId,isEntry=false }) {
  const CheckFeatures = useFeatureChecker()
  const [onError, setOnError] = useState(false)
  const tags = item.tags.filter((t) => t.name === "Person")
  if (item.mime_type == 'video/mp4' && onError) { return null }
  return (
    <div key={item.id + "col"} className="col">
      <div key={item.id + "card"} className={`card mb-3 mt-3 ml-1 mr-1 shadow-sm`} >
        {
          <CheckFeatures feature ="DETAIL_ALERT_MEDIA">
            <AllowedFeature>
              <Link to={`/${isEntry ?"entries":"alerts"}/${AlertId}/media/${item.id}`}>
                <img
                  width="100%"
                  src={item.media_thumb_url}
                  alt="" onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    setOnError(true)
                    if (item.mime_type !== 'video/mp4') {
                      currentTarget.src = item.media_url
                    }
                  }}
                />
              </Link>
            </AllowedFeature>
            <DeniedFeature>
              <img
                width="100%"
                src={item.media_thumb_url}
                alt="" onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  setOnError(true)
                  if (item.mime_type !== 'video/mp4') {
                    currentTarget.src = item.media_url
                  }
                }}
              />
            </DeniedFeature>
          </CheckFeatures>

        }
        <div className={`card-body p-2`} style={{ fontSize: '.85em' }} >
          <div className="d-flex justify-content-between align-items-center">
            {formatDate(item.ts, 'dd-MM-yyy HH:mm:ss')}
            <div className="d-flex">
              {
                <div key={item.id + "icon"} className="px-1">
                  <FontAwesomeIcon icon={item.mime_type === 'video/mp4' ? faVideo : faImage} />
                </div>
              }
              {
                item.tags.length > 0 ?
                  <div key={item.id + "user"} className="px-1">
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                  : null
              }
            </div>
          </div>
        </div>
        <div className={`card-body p-2`} style={{ fontSize: '.85em' }} >
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              {
                item.tags.length > 0 ?

                  <Link to={`/members/${item.tags[0].user_id}`}>
                    {item.tags[0].user_name}
                  </Link>
                  : null
              }
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

function EventsCard({ events, isEntry = false }) {

  const Table = (events) => {
    return (
      <table className="table mb-0" style={{ fontSize: '0.9em' }}>
        <thead>
          <tr>
            <th>
              Fecha
            </th>

            <th>
              Tipo
            </th>
          </tr>
        </thead>

        <tbody>
          {
            events.map(item => (
              <tr key={item.id + Math.random()}>
                <td>
                  {formatAlertDate(item.date)}
                </td>

                <td>
                  {translateEvent(item.name)}
                </td>

              </tr>
            ))
          }
        </tbody>
      </table>
    )
  }

  const NoEventsMessage = (isEntry = false) => {
    return (
      <div className="card-body">
        <p>
          No se encontraron eventos asociados a {isEntry ? "a este acceso" : "esta alerta"}
        </p>
      </div>
    )
  }

  const Loading = () => {
    return (
      <div className="card-body">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="card shadow-sm mb-3">
      <div className="card-header">Eventos asociados {isEntry ? "al acceso" : " a la alerta"}</div>

      {events ? events.length > 0
        ? Table(events)
        : NoEventsMessage()
        : Loading()}
    </div>
  )
}
function EventsMediaCard({ events, AlertId, isEntry = false }) {

  const Table = (events) => {
    return (<MediaList media={events} AlertId={AlertId} isEntry={isEntry}/>)
  }

  const NoEventsMessage = (isEntry = false) => {
    return (
      <div className="card-body">
        <p>
          No se encontraron Fotos/videos asociadas {isEntry ? "a este acceso" : "a esta alerta"}
        </p>
      </div>
    )
  }

  const Loading = () => {
    return (
      <div className="card-body">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="card shadow-sm mb-3">
      <div className="card-header">Medias asociadas {isEntry ? "a este acceso" : "a esta alerta"} </div>

      {events ? events.length > 0
        ? Table(events)
        : NoEventsMessage()
        : Loading()}
    </div>
  )
}
function DetailsCard({ alert, isEntry = false }) {
  const CheckFeatures = useFeatureChecker()
  const media = [alert?.security_events_media?.map((media) => media.tags.filter((tag) => tag['name'] === "Person"))].flat()
  const dataArr = new Set(media);
  const result = [...dataArr].flat();
  var isUser = []
  if(alert?.user_id){
    isUser= result.filter((u)=>u.user_id ==alert.user_id)
  }

  return (
    <div className="card shadow-sm mb-3">
      <div className="card-header">Detalle  {isEntry ? "del acceso" : "de alerta"}</div>

      <div className="card-body">
        {alert ? (
          <>
            <div className="form-group">
              <label className="form-label font-weight-bold">Tipo</label>
              <p>
                {translateAlert(alert.name)}
              </p>
            </div>

            <div className="form-group">
              <label className="form-label font-weight-bold">Puerta</label>
              <p>
                {alert.door_id
                  ? (
                    <CheckFeatures feature='DETAIL_DOOR'>
                      <AllowedFeature>
                        <Link to={`/doors/${alert.door_id}`} key={"door" + Math.random()}>
                          {alert.door_name}
                        </Link>
                      </AllowedFeature>
                      <DeniedFeature>
                        {alert.door_name}
                      </DeniedFeature>
                    </CheckFeatures>
                    
                  )
                  : (
                    <>
                      {alert.door_name}
                    </>
                  )
                }

              </p>
            </div>


            <div className="form-group">
              <label className="form-label font-weight-bold">Zona</label>
              <p>
                {alert.zone_id
                  ? (
                    <CheckFeatures feature='DETAIL_ZONE'>
                      <AllowedFeature>
                        <Link to={`/zones/${alert.zone_id}`} key={"zones" + Math.random()}>
                          {alert.zone_name}
                        </Link>
                      </AllowedFeature>
                      <DeniedFeature>
                        {alert.zone_name}
                      </DeniedFeature>
                    </CheckFeatures>
                    
                  )
                  : (
                    <>
                      {alert.zone_name}
                    </>
                  )
                }

              </p>
            </div>

            {alert.user_id ? (
              <div className="form-group">
                <label className="form-label font-weight-bold">Usuario</label>
                <p>
                  <CheckFeatures feature='DETAIL_USER'>
                    <AllowedFeature>
                      <Link to={`/members/${alert.user_id}`}  key={"members" + Math.random()}>
                        {alert.user_name}
                      </Link>
                    </AllowedFeature>
                    <DeniedFeature>
                      {alert.user_name}
                    </DeniedFeature>
                  </CheckFeatures>

                </p>
              </div>
            )
              : null

            }

            {
              result.length > 0 ? (
                <div className="form-group">
                  <label className="form-label font-weight-bold">Usuarios reconocido por la camara</label>
                  {
                    result.map((u) => (
                      <p  key={"p_user_id" + Math.random()}>
                        <Link to={`/members/${u.user_id}`} key={"user_id" + Math.random()}>
                          {u.user_name}
                        </Link>
                      </p>
                    ))
                  }
                </div>
              )
                : null

            }
            {
              result.length > 0  &&  alert.user_id && isUser.length==0? (
                <div className="form-group" key={"warning" + Math.random()}>
                <div className="alert alert-warning text-center">
                  <b>{`El usuario "${alert.user_name}" no fue reconocido por las camaras`}</b>
                </div>
              </div>
              )
                : null

            }

            {alert.start ? (
              <div className="form-group">
                <label className="form-label font-weight-bold">Fecha Inicio</label>
                <p>
                  {formatAlertDate(alert.start)}
                </p>
              </div>
            )
              : null
            }

            {alert.end ? (
              <div className="form-group">
                <label className="form-label font-weight-bold">Fecha Fin</label>
                <p>
                  {formatAlertDate(alert.end)}
                </p>
              </div>
            )
              : null
            }
          </>
        ) : <Spinner />}
      </div>
    </div>
  )
}

export default function AlertDetailsPage({ isEntry = false }) {
  const params = useParams()
  const navigate = useNavigate();
  const currentUser = useContext(CurrentUserContext)
  const CheckFeatures = useFeatureChecker()

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "DETAIL_SECURITY_ALERTS"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const AlertId = params.AlertId;
  const query = useQuery(`alertevents/${AlertId}`)
  const alert = query.isSuccess && query.data && query.data.result || null


  return (
    <Container>
      <div className="row">
        <Header title={`Detalle de ${isEntry ? "Acceso" : "alerta"}`} items={[
          { label: `Listado de ${isEntry ? "Acceso" : "alerta"}`, to: `${isEntry ? "/entries" : "/alerts"}` },
          { label: `Detalle de ${isEntry ? "Acceso" : "alerta"}` },
        ]}>
          <HeaderActions>
          </HeaderActions>
        </Header>
      </div>

      <div className="row">
      </div>

      <div className="row mb-2 pb-4">
        <div className="col-12 mb-2 col-lg-4">
          <DetailsCard alert={alert} isEntry={isEntry}  key={"detail"+Math.random()}/>
        </div>

        {
          <div className="col-12 mb-2 col-lg-8">
            
            <CheckFeatures feature='LIST_ALERTS_EVENTS'>
              <EventsCard events={alert ? alert.security_events : null} isEntry={isEntry} />
            </CheckFeatures>
            <CheckFeatures feature='LIST_ALERTS_MEDIA'>
              <EventsMediaCard events={alert ? alert.security_events_media : null} AlertId={AlertId} isEntry={isEntry} />
            </CheckFeatures>
          </div>
        }
      </div>
    </Container>
  )
}
