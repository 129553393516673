import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate,useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { TIME_ZONE, toQueryString, useFromQueryString } from '../../utils.js';
import { Container } from '../layout/AppLayout.js';
import Header from "../shared/Header.js"
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSadCry,
  faSadTear,
} from '@fortawesome/free-solid-svg-icons';

export default function ErrorDisplayPage() {
  const queryString = useFromQueryString()
  const navigate = useNavigate()

  const [currentFilters, setCurrentFilters] = useState({
    error: queryString.error || '404',
  });
  useEffect(()=>{
    setCurrentFilters(prevFilters => ({
      ...prevFilters
    }));
  },[queryString.error])


  useEffect(() => {
    navigate('?' + toQueryString(currentFilters), { replace: true })
  }, [ currentFilters ])


  return (
    <Container>
    <Row className="mt-2 text-secondary">
      <div
        className="d-flex flex-column justify-content-center align-items-center w-100"
        style={{
          height: "100vh"
        }}
      >
        <h1>
          { currentFilters.error == '403'?
             <> No tienes permisos </>
            :
            <> Página no encontrada</>
          }
          <FontAwesomeIcon  icon={faSadTear}/>
        </h1>

        {
          currentFilters.error=='403'?
            <p>
              No posee el permiso para acceder a este contenido, contacte a un
              administrador.
            </p>
          :
            <p>
              Puede que dicha página no exista, intente nuevamente.
            </p>
        }
        
      </div>
    </Row>
  </Container>

  );
}

