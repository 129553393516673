import React from 'react';

import { Link } from 'react-router-dom';


import SensorHealth from '../shared/SensorHealth.js';


import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';
import TableList from '../shared/TableList.js';

export default function SensorsTable({ sensors, mode }) {
  const CheckFeatures = useFeatureChecker()

  if (!sensors.length) {
    return (
      <div className="text-center text-muted py-4 my-4">No se encontraron dispositivos para los filtros especificados</div>
    )
  }

  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="Nombre" />
        <TableList.HeaderItem value="Puerta" width={150} />
        <TableList.HeaderItem value="Zona" width={150} />
        <TableList.HeaderItem value="Tipo" width={150} />
        <TableList.HeaderItem value="Device Id" width={350} />
        <TableList.HeaderItem value="Heartbeat" width={150} />
      </TableList.Header>

      <TableList.Body>
        {
          sensors.map(item => (
            <TableList.Row key={item.id}>
              <TableList.Column>
                <CheckFeatures feature="DETAIL_SENSORS">
                  <AllowedFeature>
                    {
                      item.name ?
                        <Link className="py-3" to={"/sensors/" + item.id}> <span>{item.name}</span> </Link>
                      :
                      <span>----</span>
                    }
                  </AllowedFeature>
                  <DeniedFeature>
                    <span>{item.name ? item.name : '----'}</span>
                  </DeniedFeature>
                </CheckFeatures>
              </TableList.Column>
              <TableList.Column>
                <CheckFeatures feature="DETAIL_DOOR">
                  <AllowedFeature>
                    {
                      item.door_name ?
                        <Link className="py-3" to={"/doors/" + item.door_id}> <span>{item.door_name}</span> </Link>
                      :
                        <span>----</span>
                    }
                  </AllowedFeature>
                  <DeniedFeature>
                    <span>{item.door_name ? item.door_name : '----'}</span>
                  </DeniedFeature>
                </CheckFeatures>
              </TableList.Column>

              <TableList.Column>
                <CheckFeatures feature="DETAIL_ZONE">
                  <AllowedFeature>
                    {
                       item.zone_name ?
                        <Link className="py-3" to={"/zones/" + item.zone_id}> <span> {item.zone_name} </span> </Link>
                      :
                        <span>----</span>
                    }
                  </AllowedFeature>
                  <DeniedFeature>
                    <span>{item.zone_name ? item.zone_name : '----'}</span>
                  </DeniedFeature>
                </CheckFeatures>
              </TableList.Column>

              <TableList.Column value={item.type_label} />

              <TableList.Column>
                <span style={{ fontFamily: 'monospace' }}>{item.device_id}</span>
              </TableList.Column>

              <TableList.Column>
                <SensorHealth sensor={item} />
              </TableList.Column>
            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}
