export default function(alertName) {
  switch (alertName) {
    case "Intruder":
      return "Intruso";
    case "Noise":
      return "Ruido";
    case "LostInternetConnection":
      return "Nodo Perdio Conectividad a Internet";
    case  "DiskUsage":
      return "Disco lleno"
    case "Unauthorized":
      return "Acceso sin autorización"
    case "OpeningRequest":
      return "Acceso autorizado"
    case "ExitAuthorized":
      return "Salida autorizada"
    case "NotbusinessDay":
      return "Apertura en día no hábil"
    case "NotbusinessTime":
      return "Apertura fuera del horario laboral"
    case "NotOpen":
      return "Sin apertura"
    case "ClosingRequestBle":
      return "Cerrado (Bluetooth)"
    case "OpeningRequestBle":
      return "Abierto (Bluetooth)"
    case "ActiveRequestBle":
      return "Alarma activada(Bluetooth)"
    case "DeactivateRequestBle":
      return "Alarma desactivada(Bluetooth)"
    case "limit8hour":
      return "Más de 8 horas abierto"
    case "qrDisable":
      return "QR deshabilitado por multiples usos"
    case "authorizedOpening":
      return "Apertura autorizada"
    case "unauthorizedOpening":
      return "Apertura no autorizada"
    default:
      return alertName;
  }
}
