import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import {  useMutation, useQueryClient } from "react-query";

import { CurrentCompanyContext, CurrentUserContext } from "../../App.js";

import { identityTypes } from "../../utils.js";

import { Container } from "../layout/AppLayout.js";
import { useMutationClient } from "../shared/hooks.js";
import { useNotifications } from "../shared/Notifications.js";
import Header from "../shared/Header.js";
import MemberDetailSubmenu from "./MemberDetailSubmenu.js";
import useFeatureChecker, {
  isAllowed,
  AllowedFeature,
  DeniedFeature,
} from "../shared/FeatureChecker.js";

function IdentityTypeSelector({
  defaultValue,
  placeholder,
  onChange,
  disabled,
}) {
  return (
    <select
      className="form-control"
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      value={defaultValue || ""}
    >
      <option value={0}>{placeholder || "Seleccione..."}</option>
      {identityTypes.map((item) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  );
}

function IdentityForm({ save, isLoading }) {
  const [type, setType] = useState("");
  const [identity, setIdentity] = useState("");

  function validate(e) {
    e.preventDefault();

    save({
      type,
      identity,
    });
  }

  return (
    <form onSubmit={validate}>
      <div className="form-group">
        <label>Tipo</label>

        <IdentityTypeSelector
          defaultValue={type}
          onChange={(value) => setType(value)}
          disabled={isLoading}
        />
      </div>

      <div className="form-group">
        <label>Identidad</label>
        <input
          type="text"
          className="form-control"
          onChange={(e) => setIdentity(e.target.value)}
          value={identity}
          disabled={isLoading}
        />
      </div>

      <div className="form-group">
        <button className="btn btn-primary" disabled={isLoading}>
          <span>Guardar</span>
          <div className="spinner-border" role="status"></div>
        </button>
      </div>
    </form>
  );
}

export default function NewMemberIdentityPage() {
  const params = useParams();
  const queryClient = useQueryClient();
  const CheckFeatures = useFeatureChecker();
  const currentCompany = useContext(CurrentCompanyContext);
  const mutationClient = useMutationClient();
  const navigate = useNavigate();
  const notifications = useNotifications();
  const currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser, every:["UPDATE_USER", "CREATE_IDENTITIES", "LIST_TYPE_IDENTITIES"]})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const createIdentity = useMutation(
    (data) =>
      mutationClient.post(
        `companies/${currentCompany.id}/users/${params.UserId}/identities`,
        data
      ),
    {
      onSuccess: (data, variables, context) => {
        if (data.status === 200) {
          notifications.success("Identidad creada correctamente");
          queryClient.invalidateQueries(`companies/${currentCompany.id}/users`);
          navigate(`/members/${params.UserId}/identities`);
        } else {
          notifications.danger("Hubo un error al crear la identidad");
        }
      },
    }
  );

  return (
    <Container>
      <div className="row">
        <Header
          title="Detalle de usuario"
          items={[
            { label: "Listado de usuarios", to: "/members" },
            { label: "Detalle de usuario", to: `/members/${params.UserId}` },
            {
              label: "Identidades de usuario",
              to: `/members/${params.UserId}/identities`,
            },
            { label: "Nueva identidad" },
          ]}
        />
      </div>

      <div className="row">
        <MemberDetailSubmenu UserId={params.UserId} />
      </div>

      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card shadow-sm">
            <div className="card-header">Nueva identidad</div>
            <div className="card-body">
              <IdentityForm
                save={(data) => createIdentity.mutate(data)}
                isLoading={createIdentity.isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
