import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom'
import { useFromQueryString, toQueryString } from '../../utils.js'
import { useQuery } from 'react-query'

import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Pagination from '../shared/Pagination.js'
import { FilterMenu } from '../shared/Filters.js';

import Spinner from '../shared/Spinner.js';
import Header, { HeaderActions } from "../shared/Header.js"
import useFeatureChecker, { isAllowed } from '../shared/FeatureChecker.js'
import { CurrentUserContext, CurrentCompanyContext } from '../../App.js';

import DoorsTable from './DoorsTable.js';

export default function DoorsListPage(){
  const queryString = useFromQueryString()
  const navigate = useNavigate()
  const CheckFeatures = useFeatureChecker()
  const currentUser = useContext(CurrentUserContext)
  const currentCompany = useContext(CurrentCompanyContext)

  const labelTitle = 'Puertas'
  const [ currentFilters, setCurrentFilters ] = useState({
    page: parseInt(queryString.page) || 1,
    search: queryString.search || '',
    FacilityId: queryString.FacilityId || '',
    ZoneId: queryString.ZoneId || '',
  })

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,some: ["LIST_DOORS" , "LIST_PERSONAL_DOORS"]})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const query = useQuery(`doors?${toQueryString(currentFilters)}`, {
    keepPreviousData: true,
    enabled: !!currentCompany
  })

  useEffect(()=>{
    setCurrentFilters(prevFilters => ({
      ...prevFilters,
      page: 1
    }));
  },[queryString.ZoneId,queryString.FacilityId])

  useEffect(() => {
    navigate('?' + toQueryString(currentFilters), { replace: true })
  }, [ currentFilters ])

  return (
    <Container>
      <Row>
        <Header title={labelTitle} items={[
          { label: `Listado de puertas` }
        ]}>
          <HeaderActions>
            <CheckFeatures feature="CREATE_DOOR">
              <Link to="/doors/new" className="btn btn-primary">Crear puerta</Link>
            </CheckFeatures>
          </HeaderActions>
        </Header>
      </Row>

      <Row className="mb-2">
        <FilterMenu
          currentFilters={currentFilters}
          onChange={filters => setCurrentFilters(prev => ({ ...prev, ...filters }))}
        />
      </Row>

      <Row>
        <Col className="col-12">
          {
            query.isLoading ?
              <div className="text-center my-4 py-4">
                <Spinner />
              </div>
            :
              <DoorsTable doors={query.data && query.data.result || []} />
          }
        </Col>
      </Row>

      <Row className="mt-2">
        <Pagination
          pagination={query.data && query.data.pagination}
          isLoading={query.isFetching}
          onChange={page => setCurrentFilters(prev => ({ ...prev, page }))}
        />
      </Row>
    </Container>
  )
}

