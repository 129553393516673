import React, { useEffect } from 'react';

import { Link } from 'react-router-dom'
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

//import './Header.css'

export function HeaderActions({ children }){
  return(
    <div>{children}</div>
  )
}

export default function Header({ title, items = [], children }){
  const headerActions = React.Children.toArray(children).find(x => x.type.name === HeaderActions.name)

  const breadCrumbs = items.map((item, idx) => {
    if( items.length - 1 === idx ){
      return (
        <li
          key={item.label}
          className="breadcrumb-item active"
        >{ item.label }</li>
      )
    }else{
      return (
        <li key={item.label} className="breadcrumb-item">
          <Link to={item.to}>{ item.label }</Link>
        </li>
      )
    }
  })

  {
    //history.length ?
      //<div className="d-md-none">
        //<button
          //className="btn btn-link collapsed"
          //onClick={e => dispatch(LayoutActions.openSidebar())}
        //>
          //<FontAwesomeIcon icon={faBars} />
        //</button>
      //</div>
    //: null
  }

  useEffect(() => {
    if(title){
      document.title = `${ title } - Octoberus`
    }else{
      document.title = `Octoberus`
    }
  }, [title])

  return (
    <div className="col-12">
      <div className="d-flex justify-content-between align-items-start mt-4">
        <h4 className="font-weight-bold">{title}</h4>

        <div>
          { headerActions }
        </div>
      </div>

      <nav className="d-none d-md-block">
        <ol className="breadcrumb p-0">
          { breadCrumbs }
        </ol>
      </nav>
    </div>
  )
}
