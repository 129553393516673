import React from 'react';
import { Link } from 'react-router-dom';

import { DestroyButton } from '../shared/Buttons.js';
import Spinner from '../shared/Spinner.js';
import TableList from '../shared/TableList.js';

import { formatDate } from '../../utils.js';
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';

export default function MembersTable({ users, refetch, isLoading, listType, onSort }) {
  const CheckFeatures = useFeatureChecker()

  if (isLoading) {
    return (
      <div className="container-lg py-4 p-0 text-center">
        <Spinner />
      </div>
    )
  }

  if(!users.length) {
    return <div className="py-4 my-4 text-center text-muted">No se encontraron usuarios para los filtros especificados</div>
  }

  return (
    <TableList onSort={onSort}>
      <TableList.Header>
        <TableList.HeaderItem value="Nombre" sortable sortField="name" />
        <TableList.HeaderItem value="Email" width={400} />
        <TableList.HeaderItem value="Rol" width={200} />

        {
          listType === "pending" ?
            <TableList.HeaderItem
              value="Fecha de invitación"
              width={200}
              sortable
              sortField="invited_at"
            />
            : null
        }

        <TableList.HeaderItem value="" width={100} />
      </TableList.Header>

      <TableList.Body>
        {
          users.map(item => (
            <TableList.Row key={item.id}>
              <TableList.Column>
                {
                  item.UsersCompanies.accepted_at ?
                  (
                    <CheckFeatures feature="DETAIL_USER">
                      <AllowedFeature>
                        <Link className="py-3" to={`${item.id}`}>
                          <span>
                            { item.name ? item.name : 'Sin nombre' }
                          </span>
                        </Link>
                      </AllowedFeature>
                      <DeniedFeature>
                        <span>{item.name ? item.name :'Sin nombre'}</span>
                      </DeniedFeature>
                    </CheckFeatures>
                  )
                  :
                  (
                    <span>
                      { item.name ? item.name : 'Sin nombre' }
                    </span>
                  )
                }

                {
                  item.Groups && item.Groups.length ?
                    item.Groups.map(
                      group => (
                        <CheckFeatures feature="DETAIL_GROUP">
                          <AllowedFeature>
                            <Link className="py-3" to={`groups/${group.id}`}>
                                <span
                                  className="badge badge-primary ml-2"
                                  key={group.id}
                                >
                                {group.name}
                              </span>
                            </Link>
                          </AllowedFeature>
                          <DeniedFeature>
                            <span
                              className="badge badge-primary ml-2"
                              key={group.id}
                            >
                              {group.name}
                            </span>
                          </DeniedFeature>
                        </CheckFeatures>
                      )
                    )
                  :
                    null
                }
              </TableList.Column>

              <TableList.Column value={item.email?item.email:'----'}/>


              <TableList.Column>
                <CheckFeatures feature="DETAIL_ROLE">
                    <AllowedFeature>
                      {
                        item.Role? <Link className="py-3" to={"/companies/"+item.Role.company_id+"/roles/"+ item.Role.id}> <span>{item.Role.name}</span> </Link> :  <span>----</span>
                      }
                    </AllowedFeature>
                    <DeniedFeature>
                      <span>{item.Role ? item.Role.name : '----'}</span>
                    </DeniedFeature>
                  </CheckFeatures>
              </TableList.Column>

              {
                listType === "pending" ?
                  <TableList.Column value={formatDate(item.UsersCompanies.created_at)}/>
                : null
              }

              <TableList.Column>
                <CheckFeatures feature="COMPANY_DELETE_USER">

                <DestroyButton
                  label="Eliminar"
                  confirmMessage={`¿Está seguro que desea eliminar el usuario "${item.name || item.email}"?`}
                  successMessage="Usuario eliminado correctamente"
                  deletePath={`companies/${item.UsersCompanies.company_id}/users/${item.id}`}
                  invalidate={`users`}
                  refetch={refetch}
                  size="sm"
                />
                </CheckFeatures>
              </TableList.Column>
            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}

