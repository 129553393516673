import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { useQuery } from "react-query";
import { isAllowed } from "../shared/FeatureChecker.js";
import { CurrentUserContext } from "../../App.js";
import { Container } from "../layout/AppLayout.js";

import { formatDate } from "../../utils.js";
import Header from "../shared/Header.js";
import Spinner from "../shared/Spinner.js";
import { useSensorType } from "../shared/hooks.js";

import DoorDetailSubmenu from "./DoorDetailSubmenu.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThermometerHalf,
  faChartLine,
  faVolumeUp,
  faWater,
  faFireAlt,
} from "@fortawesome/free-solid-svg-icons";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const DATA_PERIODS = {
  "24h": "24 horas",
  "12h": "12 horas",
  "6h": "6 horas",
  "3h": "3 horas",
  "2h": "2 horas",
  "1h": "1 hora",
};

function SensorAttributeChart({ sensor, attribute }) {
  const [filters, setFilters] = useState({
    period: "3h",
  });

  let factor=1;
  const query = useQuery(`sensors/${sensor.id}/data?period=${filters.period}`, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  if( query.data && query.data.result && query.data.result.metadata && query.data.result.metadata.factors){
    if (attribute.field in query.data.result.metadata.factors){
      factor=query.data.result.metadata.factors[attribute.field]
    }        
  }
  const rows =
    query.data &&
    query.data.result &&
    query.data.result.items &&
    query.data.result.items
      .map((item) => ({
        x: formatDate(item.date, "dd/MM HH:mm"),
        y: item[attribute.field]*factor,
      }))
      .reverse();

  
  const data = rows && {
    datasets: [
      {
        label: attribute.label,
        data: rows,
        backgroundColor: attribute.color,
        borderColor: attribute.color,
      },
    ],
  };

  if (query.isLoading) {
    return (
      <div className="col-12 py-4 my-4 text-center">
        <Spinner />
      </div>
    );
  }

  const options = {
    responsive: true,
    pointBackgroundColor: attribute.color,
    pointBorderColor: attribute.color,
    pointRadius: 2,
    pointHoverRadius: 6,

    //spanGaps: true,
    //stepped: true,

    interaction: {
      intersect: false,
      axis: "x",
    },

    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItems) => {
            const unit = attribute.unit || "";
            return `${tooltipItems.dataset.label}: ${tooltipItems.formattedValue} ${unit}`;
          },
        },
      },
    },

    scales: {
      x: {
        //min: data[1],
        ticks: {
          maxTicksLimit: 10,
        },
      },
    },
  };

  return (
    <Container>
      <div className="row">
        <div className="col-10">
          <div className="card">
            <div className="card-body">
              {!data ? (
                <div className="text-center text-muted py-4 my-4">
                  Sin datos disponibles para el período seleccionado
                </div>
              ) : (
                <Line options={options} data={data} height={100} />
              )}
            </div>
          </div>
        </div>

        <div className="col-2">
          <div className="card">
            <div className="card-body">
              <div>
                {Object.keys(DATA_PERIODS).map((period) => (
                  <button
                    key={period}
                    disabled={query.isFetching}
                    className={`btn btn-block ${
                      filters.period === period
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                    onClick={() =>
                      setFilters((currentFilters) => ({
                        ...currentFilters,
                        period,
                      }))
                    }
                  >
                    {DATA_PERIODS[period]}
                  </button>
                ))}

                <div className="text-muted text-center pt-3">
                  {query.isFetching ? "Actualizando..." : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

function SensorAttributeIcon({ attribute }) {
  let icon = faChartLine;

  switch (attribute.field) {
    case "temperature":
      icon = faThermometerHalf;
      break;

    case "pressure":
      break;

    case "humidity":
      icon = faWater;
      break;

    case "noise":
      icon = faVolumeUp;
      break;

    case "smoke":
      icon = faFireAlt;
      break;
  }

  return <FontAwesomeIcon icon={icon} size="3x" />;
}

function SensorSelectorItem({ sensor, selectedSensor, onSelected }) {
  const sensorType = useSensorType(sensor.sensor_type_id);
  const attributes = (sensorType && sensorType.variables) || [];

  function isSelected(sensor, field) {
    if (!selectedSensor) return;
    return (
      selectedSensor.id === sensor.id &&
      selectedSensor.attribute.field === field
    );
  }

  if (!attributes.length) {
    return null;
  }

  return (
    <>
      {attributes.map((item, idx) => (
        <div key={idx} className="d-inline-block">
          <div
            className={`${
              isSelected(sensor, item.field) ? "bg-dark text-white" : "bg-white"
            } border rounded p-3 shadow-sm mr-3 mb-3 d-flex justify-content-start align-items-center`}
            style={{ cursor: "pointer" }}
            onClick={() => onSelected({ id: sensor.id, attribute: item })}
          >
            <div className="p-2">
              <SensorAttributeIcon type={sensor.type} attribute={item} />
            </div>

            <div className="ml-3">
              <div className="font-weight-bold">{item.label}</div>
              <div className="text-muted">{sensor.name}</div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default function DoorSensorsPage() {
  const params = useParams();
  const currentUser = useContext(CurrentUserContext)
  const navigate = useNavigate()
  const [selectedSensor, setSelectedSensor] = useState(null);

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,every: ["LIST_SENSORS","DATA_SENSORS"]})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const query = useQuery(`sensors?DoorId=${params.DoorId}`, {
    keepPreviousData: true,
    //refetchOnWindowFocus: false,
    //refetchOnMount: false,
  });

  const sensors = (query.data && query.data.result) || [];

  return (
    <Container>
      <div className="row">
        <Header
          title="Dispositivos de puerta"
          items={[
            { label: `Listado de puertas`, to: "/doors" },
            { label: `Detalle de puerta`, to: `/doors/${params.DoorId}` },
            { label: `Dispositivos de puerta` },
          ]}
        />
      </div>

      <div className="row">
        <DoorDetailSubmenu DoorId={params.DoorId} />
      </div>

      {query.isLoading ? (
        <div className="text-center my-4 py-4">
          <Spinner />
        </div>
      ) : sensors.length ? (
        <>
          <div className="row pb-3">
            <div className="col-12">
              {sensors.map((item) => (
                <SensorSelectorItem
                  key={item.id}
                  sensor={item}
                  onSelected={(data) => setSelectedSensor(data)}
                  selectedSensor={selectedSensor}
                />
              ))}
            </div>
          </div>

          <div className="row mb-2 pb-4">
            {selectedSensor ? (
              <SensorAttributeChart
                attribute={selectedSensor.attribute}
                sensor={sensors.find((x) => x.id === selectedSensor.id)}
              />
            ) : (
              <div className="col-12 text-center text-muted py-4 my-4">
                Seleccione una variable para visualizar
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="text-center text-muted py-4 my-4">
          Esta puerta no tiene dispositivos asociados
        </div>
      )}
    </Container>
  );
}
