import React, { useEffect, useState } from 'react'
import { useFromQueryString } from '../../utils.js'

export function Pagination({ page, limit, totalItems, totalPages, onChange, ...props }){
  const [ currentPage, setCurrentPage ] = useState(page || 1)

  let pageNumbers = []

  useEffect(() => {
    onChange(currentPage)
  }, [currentPage, onChange])

  function changePage(e, selectedPage){
    e.preventDefault()

    if( selectedPage > totalPages || selectedPage < 1 ){
      return
    }

    setCurrentPage(selectedPage)
  }

  const windowSize = 10
  const halfWindow = windowSize / 2

  for(let i = 0; i < totalPages; i++){
    pageNumbers.push((
      <li
        key={i}
        className={ `page-item ${(i + 1) === currentPage ? 'active' : ''}` }
      >
        <button
          className="page-link"
          onClick={(e) => changePage(e, i + 1)}
        >{i + 1}</button>
      </li>
    ))
  }

  // TODO: Improve sliding window on edges
  if( totalPages > windowSize ){
    const currentIndex = currentPage - 1;
    let start = 0
    let end = totalPages

    if( currentIndex > halfWindow ){
      start = currentIndex - halfWindow
    }

    if( totalPages > currentIndex + halfWindow ){
      end = currentIndex + halfWindow
    }

    pageNumbers = pageNumbers.slice(start, end)
  }

  let styles = {}

  if(props.inline){
    styles.display = 'inline-block';
  }

  return (
    !isNaN(totalPages) && totalPages !== 0 && (
      <nav
        className="pagination-wrapper"
        style={styles}
      >
        <ul className="pagination pagination-sm mb-0">
          <li className="page-item">
            <button
              className="page-link"
              onClick={e => changePage(e, currentPage - 1)}
            >
              <span aria-hidden="true">&laquo;</span>
            </button>
          </li>

          { pageNumbers }

          <li className="page-item">
            <button
              className="page-link"
              onClick={e => changePage(e, currentPage + 1)}
            >
              <span aria-hidden="true">&raquo;</span>
            </button>
          </li>
        </ul>
      </nav>
    )
  )
}

export function usePagination(paginationInfo, onPageChange, props = {}){
  const query = useFromQueryString()

  function handlePageChange(selectedPage){
    if( parseInt(query.page || 1) !== selectedPage ){
      onPageChange(selectedPage)
    }
  }

  return (
    <Pagination
      onChange={handlePageChange}
      { ...paginationInfo }
      { ...props }
      page={parseInt(query.page) || 1}
    />
  )
}

export default function PaginationComponent({ pagination, isLoading, onChange }){
  if( !pagination || (pagination && pagination.totalPages <= 1) ){
    return null
  }

  const currentPage = pagination.page
  const totalPages = pagination.totalPages

  let windowSize = 10
  let pages = []
  let startPage = currentPage - windowSize / 2
  let endPage = currentPage + windowSize / 2

  if( startPage < 1 ){
    startPage = 1
  }

  if( endPage > totalPages ){
    endPage = totalPages
  }

  if( totalPages < windowSize ){
    windowSize = totalPages
    startPage = 1
  }

  if( totalPages == endPage ){
    startPage = endPage - windowSize + 1
  }

  for(let i = 0; i < windowSize; i++ ){
    pages.push(startPage + i)
  }

  //console.log({ currentPage, startPage, endPage, totalPages, windowSize });
  //console.log(pages);

  return (
    <div className="col-12">

      <nav>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button
              className="page-link"
              style={{ cursor: 'pointer' }}
              onClick={() => onChange(currentPage - 1)}
              disabled={currentPage === 1}
            >Anterior</button>
          </li>

          {
            pages.map(page => (
              <li
                key={page}
                className={`page-item ${currentPage === page ? 'active' : ''}`}
              >
                <a
                  className="page-link"
                  style={{ cursor: 'pointer' }}
                  onClick={() => onChange(page)}
                >{ page }</a>
              </li>
            ))
          }

          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button
              className="page-link"
              href="#"
              style={{ cursor: 'pointer' }}
              onClick={() => onChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >Siguiente</button>
          </li>
        </ul>
      </nav>

    </div>
  )
}

