import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { formatDate } from '../../utils.js'
import useFeatureChecker, { AllowedFeature, DeniedFeature} from '../shared/FeatureChecker.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVideo,
  faWalking,
  faBicycle,
  faDog,
  faCar,
  faPhotoVideo
} from '@fortawesome/free-solid-svg-icons';

import Table from 'react-bootstrap/Table'
import Spinner from '../shared/Spinner.js';
import TableList from '../shared/TableList.js';

import "./MediaTable.css"



function EventItem({ item }) {
  const [onError, setOnError] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const { innerWidth: width, innerHeight: height } = window
  const CheckFeatures = useFeatureChecker()

  const icons = []

  if( item.people_count ){
    icons.push(faWalking)
  }

/*   if( item.cars_count ){
    icons.push(faCar)
  }

  if( item.bicycle_count ){
    icons.push(faBicycle)
  }

  if( item.animal_count ){
    icons.push(faDog)
  } */

  if (item.mime_type === 'video/mp4') {
    icons.push(faVideo)
  } 
  if (item.mime_type === 'image/jpg') {
    icons.push(faPhotoVideo)
  } 

  if (onError) { return null }

  return (
    <div key={item.id} className="col mb-4 event-item">
      <div className={`card shadow-sm ${!isReady ? 'ph-item' : ''}`}>
        <div className={!isReady ? 'ph-picture' : ''}>
          <CheckFeatures feature ='DETAIL_MEDIA'>
            <AllowedFeature>
              <Link to={`/media/${item.id}`}>
                <img
                  src={item.media_thumb_url}
                  alt={item.created_at}
                  className={!isReady ? 'd-none' : ''}
                  onLoad={() => setIsReady(true)}
                  width="100%"
                  height={(height * 0.35) + 'rem'}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    setOnError(true)
                    if (item.mime_type !== 'video/mp4') {
                      currentTarget.src = item.media_url
                    }
                  }}
                />
              </Link>
            </AllowedFeature>
            <DeniedFeature>
              <img
                src={item.media_thumb_url}
                alt={item.created_at}
                className={!isReady ? 'd-none' : ''}
                onLoad={() => setIsReady(true)}
                width="100%"
                height={(height * 0.35) + 'rem'}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  setOnError(true)
                  if (item.mime_type !== 'video/mp4') {
                    currentTarget.src = item.media_url
                  }
                }}
              />
            </DeniedFeature>
          </CheckFeatures>
        </div>
        <div className="px-3 py-2 pb-3 event-item-detail">
          <div className="event-date d-flex justify-content-between">
            <div>
              {formatDate(item.ts)}
            </div>

            <div className="d-flex">
              {
                icons.map((icon, idx) => (
                  <div key={idx} className="px-1">
                    <FontAwesomeIcon icon={icon} />
                  </div>
                ))
              }
            </div> 

          </div>
          <div className="event-door">
            <CheckFeatures feature ='DETAIL_SENSORS'>
              <AllowedFeature>
                <Link to={`/sensors/${item.sensor_id}`}>{item.sensor_name}</Link>
              </AllowedFeature>
              <DeniedFeature>
                {item.sensor_name}
              </DeniedFeature>
            </CheckFeatures>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function EventsTable({ pages, items }) {
  if (pages && pages.length && pages.length && pages[0].pagination.totalItems === 0) {
    return (
      <div className="text-center text-muted py-4 my-4">
        Sin resultados para los filtros especificados
      </div>
    )
  }

  return (
    <div className="row row-cols-1 row-cols-md-3">
      {
        pages.map((group, i) => (
          <React.Fragment key={i}>
            {
              (group.result || []).map(item => (
                <EventItem key={item.id} item={item} />
              ))
            }
          </React.Fragment>
        ))

      }
    </div>
  )
}

