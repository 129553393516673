import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faDoorClosed, faVectorSquare, faImage } from '@fortawesome/free-solid-svg-icons';

import { formatDistanceStrict, parseISO, differenceInSeconds } from 'date-fns';
import { es } from 'date-fns/locale'
import { formatDate, toUTCString } from '../../utils.js';

import Spinner from '../shared/Spinner.js';
import useSortHeaders from '../shared/SortHeaders.js';
import TableList, { TableListBody } from '../shared/TableList.js';

import './LogsTable.css'
import { Table } from 'react-bootstrap';

export function EntryStateIndicator({ entry }){
  let state;
  let style = {
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'inline-block',
    height: '8px',
    width: '8px',
  }

  switch( entry.state ){
    case 0:
      state = 'Pendiente'
      style = {
        ...style,
        backgroundColor: '#888',
        boxShadow: '0px 0px 8px 2px #888',
      }
    break;

    case 1:
      state = 'Autorizado'

      style = {
        ...style,
        backgroundColor: 'green',
        boxShadow: '0px 0px 8px 2px green',
      }
    break;

    case 2:
      state = 'Forzado'

      style = {
        ...style,
        backgroundColor: 'red',
        boxShadow: '0 0 8px 2px red',
      }
    break;

    case 3:
      state = 'Expirado'

      style = {
        ...style,
        backgroundColor: '#FFA438',
        boxShadow: '0 0 8px 2px #FFA438',
      }
    break;

    case 4:
      state = 'No autorizado'

      style = {
        ...style,
        backgroundColor: 'red',
        boxShadow: '0 0 8px 2px red',
      }
    break;
  }

  return (
    <div className="d-flex justify-content-start align-items-center">
      <span className="mr-2" style={style}></span>
      <span>{ state }</span>
    </div>
  )
}
function LogsValue({item}){
  function showUserIdentity(item){
    let identity = ''

    if( !item.UserId ){
      if(item.metadata && item.metadata.identity){
        identity = ` (${item.metadata.identity})`
      }
    }

    if( item.metadata && item.metadata.action !== undefined ){
      let action = null

      if( item.metadata.action === 0 ){
        action = 'Entrada';
      }else if( item.metadata.action === 1 ){
        action = 'Salida';
      }

      if(action){
        identity = `${identity} - ${action}`
      }
    }

    return identity
  }


  return(
    <>
        <TableList.Column>
          <Link style={{ fontFamily: 'monospace' }} to={"/instructions/" + item.id}>#{item.id}</Link>
        </TableList.Column>
        <TableList.Column>
          {formatDate(item.entry_date)}
        </TableList.Column>
        <TableList.Column>
          {item.UserId ?
            <Link to={"/members/" + item.UserId}> {item.user_name} </Link>
          : 'No encontrado'}
        
        </TableList.Column>
        <TableList.Column>
          <Link to={"/doors/" + item.door_id}>{item.door_name}</Link>
        
        </TableList.Column>
        <TableList.Column>
          <Link to={"/zones/" + item.department_id}>{item.department_name}</Link>
        </TableList.Column>
    </>
  )
}

export default function LogsTable({ logs }) {
  if(logs && logs.length && logs.length && logs[0].pagination.totalItems === 0){
    return (
      <div className="text-center text-muted py-4 my-4">
        Sin resultados para los filtros especificados
      </div>
    )
  }

  function duration(item) {
    let startsAt = parseISO(item.entry_date)

    if (item.entry_date && item.closed_at) {
      const endsAt = parseISO(item.closed_at)
      return formatDistanceStrict(startsAt, endsAt, { locale: es })
    } else {
      const endsAt = parseISO(new Date().toISOString())
      return formatDistanceStrict(startsAt, endsAt, { locale: es }) + ' (En curso)'
    }
  }

  function recentItemStyle(item){
    const now = parseISO(toUTCString(new Date()))
    const diff = differenceInSeconds(now, parseISO(item.entry_date.replace('Z', '')))

    if(diff < 20){
      return {
        backgroundColor: '#FFF8E1',
        fontWeight: 'bold',
      }
    }
  }
  let flattenLogs=[]
  for(let group of logs){
    flattenLogs.push(...group.result)
  }
  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="#" />
        <TableList.HeaderItem value="Fecha"/>
        <TableList.HeaderItem value="Usuario" />
        <TableList.HeaderItem value="Puerta"/>
        <TableList.HeaderItem value="Zona"/>

      </TableList.Header>
      <TableList.Body>
        {
          (flattenLogs ).map(item=>(
            <TableList.Row key={item.id}>
              <LogsValue  item={item}/>
            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}
