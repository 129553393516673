import React, { useState } from 'react'


export default function RoleForm({save, isLoading,company_id }){
  const [ errors, setErrors ] = useState({})
  const [ name, setName ] = useState('')
  const [ level, setLevel ] = useState('')

  function validate(e){
    e.preventDefault()
 
    const validations = []

    if(!name){
      validations.push(['name', 'El nombre es requerido'])
    }
    if(!level){
      validations.push(['level', 'El nivel es requerido'])
    }
    if(level <1){
      validations.push (['level', 'El nivel debe ser mayor a 0'])
    }

    if( validations.length ){
      return setErrors(validations.reduce((acc, item) => ({...acc, [item[0]]: item[1]}), {}))
    }else{
      setErrors([])
      const data = {
        name,
        company_id,
        level
      }
      save(data)
    }
  }

  return (
    <form onSubmit={validate} noValidate>
      <div className="form-group">
        <label>Nombre</label>

        <input
          type="text"
          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
          onChange={(x) => setName(x.target.value)}
          value={name}
          autoFocus={true}
          disabled={isLoading}
        />
        <div className="invalid-feedback">{errors.name}</div>

        <label>Nivel </label>
        <input
          type='number'
          className={`form-control ${errors.level ? 'is-invalid' : ''}`}
          onChange={
            (x) => {
              let value = parseInt(x.target.value, 10);
              if (value < 0) {
                value = 1;
              }
              setLevel(value);
            }
          }
          value={level}
          autoFocus={true}
          disabled={isLoading}
        />
        <div className="invalid-feedback">{errors.level}</div>
      </div>
      <div className="form-group">
        <button className={`btn btn-primary ${isLoading ? 'loading' : ''}`} disabled={isLoading}>
          <span>Guardar</span>
          <div className='spinner-border' role='status'></div>
        </button>
      </div>
    </form>
  )
}

