import React, { useState, useEffect, useContext } from 'react';
import { CurrentCompanyContext } from '../../App.js'
import { useParams, useNavigate } from 'react-router-dom';

import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useNotifications } from '../shared/Notifications.js'

import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { useMutationClient } from '../shared/hooks.js'
import Spinner from '../shared/Spinner.js'
import Header from "../shared/Header.js"

import FacilityForm from './FacilityForm.js'

export default function EditFacilityPage() {
  const params = useParams()
  const queryClient = useQueryClient()
  const currentCompany = useContext(CurrentCompanyContext)
  const mutationClient = useMutationClient()
  const navigate = useNavigate()
  const notifications = useNotifications()

  const query = useQuery(`facilities/${params.FacilityId}`)

  const updateFacility = useMutation(
    data => mutationClient.put(`facilities/${params.FacilityId}`, data), {
    onSuccess: (data, variables, context) => {
      if( data.status === 200 ){
        notifications.success('Instalación actualizada correctamente')
        queryClient.invalidateQueries(`facilities`)
        navigate(`/facilities/${params.FacilityId}`)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al actualizar la instalación')
    }
  })

  return (
    <Container>
      <Row>
        <Header title="Editar instalación" items={[
          { label: `Listado de instalaciones`, to: "/facilities" },
          { label: `Detalle de instalación`, to: `/facilities/${params.FacilityId}` },
          { label: `Editar instalación` },
        ]} />
      </Row>

      <Row className="mb-2">
        <Col className="col-12 mb-2 col-lg-5">
          <Card className="mb-2 shadow-sm">
            <Card.Header>
              <span>Editar instalación</span>
            </Card.Header>

            <Card.Body>
              <FacilityForm
                facility={query.data && query.data.result || {}}
                currentCompany={currentCompany}
                save={data => updateFacility.mutate(data)}
                isLoading={updateFacility.isLoading}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
