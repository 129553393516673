import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useQuery } from 'react-query'
import { formatDistanceToNow, toDate } from 'date-fns'
import { formatDate } from '../../utils.js'
import { es } from 'date-fns/locale'

import TableList from '../shared/TableList.js';

import { useOpenDoor } from '../shared/Buttons.js'
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js'

function DoorHealth({ door }){
  const [ timestamp, setTimestamp ] = useState(null);

  const query = useQuery(`doors/${door.id}/health`, {
    enabled: !!door,
    refetchOnWindowFocus: false
  })

  if( !query.isLoading && query.data && query.data.result && query.data.result.ts ){
    const ts = toDate(query.data.result.ts)
    const elapsed = formatDistanceToNow(ts, { addSuffix: true, locale: es })
    const formatted = formatDate(query.data.result.ts, "dd/MM/yyyy HH:mm:ss")

    return (
      <span title={formatted}>Último dato {elapsed}</span>
    )
  }

  return null
}

export default function DoorsTable({ doors, isLoading, small = false, mode }) {
  const openDoor = useOpenDoor()
  const CheckFeatures = useFeatureChecker()

  if(!doors.length) {
    return (
      <div className="text-center text-muted py-4 my-4">No se encontraron puertas para los filtros especificados</div>
    )
  }

  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="Nombre" />
        <TableList.HeaderItem value="Zona" width={200} />
        <TableList.HeaderItem value="Dirección" width={300} />
        <TableList.HeaderItem width={150} />
      </TableList.Header>

      <TableList.Body>
        {
          doors.map(item => (
            <TableList.Row key={item.id}>
              <TableList.Column>
                <CheckFeatures feature = 'DETAIL_DOOR'>
                  <AllowedFeature>
                    <Link className="py-3" to={"/doors/" + item.id}>
                      <span>{item.name}</span>
                    </Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    {item.name}
                  </DeniedFeature>
                </CheckFeatures>
               
                <CheckFeatures feature='HEALTH_DOOR'>
                  <div style={{ fontSize: '.85em' }}>
                    <DoorHealth door={item} />
                  </div>
                </CheckFeatures>
              </TableList.Column>

              <TableList.Column>
                <CheckFeatures feature ='DETAIL_ZONE'>
                  <AllowedFeature>
                    <Link className="py-3" to={"/zones/" + item.zone_id}>
                      <span>{item.zone_name}</span>
                    </Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    {item.zone_name}
                  </DeniedFeature>
                </CheckFeatures>

              </TableList.Column>
              
              <TableList.Column value={item.address || item.facility_address}/>

              <TableList.Column>
                {
                  item.httpEnabled == true ? 
                    <CheckFeatures feature='OPEN_DOOR'>
                      <button
                        className="btn btn-sm btn-outline-secondary"
                        onClick={ () => openDoor.confirm({ door: item }) }
                      >Abrir Puerta</button>
                    </CheckFeatures>
                  : null
                }
              </TableList.Column>
            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}
