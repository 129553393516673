import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import TableList from '../shared/TableList.js';

import './AlertsTable.css'
import alertState from './alerts_states.js';
import { formatDistanceToNow, toDate } from 'date-fns'
import { es } from 'date-fns/locale'
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';{}

const formatEventDate = (date) => {
  const d = new Date(Date.parse(date))
  const region = "es-CL"
  return `${d.toLocaleDateString(region)} ${d.toLocaleTimeString(region)}`
}

function AlertHealth({ updated_at }){

  if( updated_at ){
    const ts = toDate(Date.parse(updated_at))
    const elapsed = formatDistanceToNow(ts, { addSuffix: true, locale: es })

    return (
      <span title={formatEventDate(updated_at)}> {elapsed}</span>
    )
  }

  return null
}
export default function AlertsTable({ alerts }) {
  const CheckFeature = useFeatureChecker()
  if (!alerts || !alerts.length) {
    return (
      <div className="text-center text-muted py-4 my-4">No se encontraron alertas para los filtros especificados</div>
    )
  }
  const ts = toDate(Date.parse(alerts[0].updated_at))
  const elapsed = formatDistanceToNow(ts, { addSuffix: true, locale: es })

  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="Nombre"/>
        <TableList.HeaderItem value="Estado"/>
        <TableList.HeaderItem value="Sensor"/>
        <TableList.HeaderItem value="Zona" />
        <TableList.HeaderItem value="Ultima actualización"/>
      </TableList.Header>

      <TableList.Body>
        {
          alerts.map(item => (
            <TableList.Row key={item.id}>
              <TableList.Column>
                <CheckFeature feature='DETAIL_SAFETY_ALERTS'>
                  <AllowedFeature>
                    <Link to={`/alerts/${item.id}/state`}>
                      { item.name }
                    </Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    { item.name }
                  </DeniedFeature>
                </CheckFeature>
              </TableList.Column>
              <TableList.Column>
                { alertState(item.variable,item.state) }
              </TableList.Column>
              <TableList.Column>
                <CheckFeature feature="DETAIL_SENSORS">
                  <AllowedFeature>
                    <Link to={`/sensors/${item.sensor_id}`}>
                      { item.sensor_name }
                    </Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    { item.sensor_name }
                  </DeniedFeature>
                </CheckFeature>
              </TableList.Column>

              <TableList.Column>
                <CheckFeature feature="DETAIL_ZONE">
                  <AllowedFeature>
                    <Link to={`/zones/${item.zone_id}`}>
                      { item.zone_name }
                    </Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    { item.zone_name }
                  </DeniedFeature>
                </CheckFeature>
              </TableList.Column>
              <TableList.Column>
                <AlertHealth updated_at={item.updated_at} />
              </TableList.Column>

            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}
