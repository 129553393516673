import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useQuery } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'

import Spinner from '../shared/Spinner.js';

function PlaceholderLoader(){
  const sizes = [2, 4, 6]
  const sizeOne = useMemo(() => sizes[Math.floor(Math.random()*sizes.length)], [])
  const sizeTwo = useMemo(() => sizes[Math.floor(Math.random()*sizes.length)], [])
  const sizeThree = useMemo(() => sizes[Math.floor(Math.random()*sizes.length)], [])

  return (
    <div className="ph-item py-3">
      <div className="ph-col-12">
        <div className="ph-row ">
          <div className={`ph-col-${sizeOne} big`}></div>
        </div>

        <div className="ph-row">
          <div className={`ph-col-${sizeTwo}`}></div>
        </div>

        <div className="ph-row">
          <div className={`ph-col-${sizeThree}`}></div>
        </div>
      </div>
    </div>
  )
}

export function StatWidget({ label, value }){
  return (
    <div className="py-3">
      <div className="value text-center">
        <div style={{ fontSize: '2.5em' }}>{value}</div>
      </div>
      <div className="label text-center text-uppercase text-muted" style={{ fontSize: '.8em' }}>{label}</div>
    </div>
  )
}

export function TableWidget({ title, headers, rows }){
  function renderCell(item){
    if( typeof(item) === "string" || typeof(item) === "number" || item === null ){
      return item
    }else{
      if( item.url ){
        return (
          <Link to={item.url}>{item.text}</Link>
        )
      }else{
        return "---"
      }
      return null
    }
  }

  return (
    <div>
      <div className="card-header">{title}</div>

      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <table className="table m-0 table-sm table-hover" style={{ fontSize: '.9em' }}>
          <thead>
            <tr>
              {
                headers.map(item => <th key={item}>{item}</th>)
              }
            </tr>
          </thead>
          <tbody>
            {
              rows.map((columns, idx) => (
                <tr key={idx}>
                  {
                    columns.map((item, i) => (
                      <td key={i}>
                        { renderCell(item) }
                      </td>
                    ))
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export function HTTPWidget({ metric }){
  //useInterval(() => {
    //dispatch(MetricsActions.fetchMetricData(metric.id))
  //}, 20000)

  const query = useQuery(`metrics/${metric.id}/data`)

  function renderWidget(data){
    switch( data.type ){
      case 'STAT':
        return <StatWidget label={metric.name} value={data.value} />

      case 'TABLE':
        return <TableWidget title={metric.name} { ...data.value } />

      default:
        return null
    }
  }

  return (
    <div className="bg-white border shadow-sm w-100 mb-3">
      {
        query.isLoading ? <PlaceholderLoader /> : renderWidget(query.data && query.data.result)
      }
    </div>
  )
}

