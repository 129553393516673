import { useEffect, useContext, useReducer, useMemo } from "react";
import { ActionsContext } from './App.js'
import { useNavigate } from 'react-router'

import * as uuid from 'uuid'

import { useQuery } from 'react-query'

const INITIAL_STATE = {
  isLoggedIn: false,
  currentSession: null,
  currentCompany: null,
  currentUser: null,
  notifications: {},
  scrollBottom: false,
}

export function useCurrentUser(){
  const actions = useContext(ActionsContext)
  const userQuery = useQuery('users/me', {
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })

  const currentUser = userQuery.data && userQuery.data.result
  const currentCompany = currentUser && currentUser.current_company

  const isLoading = useQuery.isLoading

  useEffect(() => {
    if(currentUser){
      actions.SetUser(currentUser)
      actions.SetCompany(currentUser.current_company)
    }
  }, [currentUser])

  return { currentUser, isLoading }
}

export function useAppReducer(){
  let currentSession, currentCompany
  const navigate = useNavigate()

  try{
    currentSession = JSON.parse(localStorage.getItem('sessionData'))
  }catch(e){
  }

  try{
    currentCompany = JSON.parse(localStorage.getItem('CurrentCompany'))
  }catch(e){
  }

  const [ state, dispatch ] = useReducer(
    (prevState, action) => {
      switch(action.type){
        case 'SET_SESSION':
          return {
            ...prevState,
            isLoggedIn: true,
            currentSession: action.session
          }

        case 'SET_USER':
          return {
            ...prevState,
            currentUser: action.user
          }

        case 'SET_COMPANY':
          return {
            ...prevState,
            currentCompany: action.company
          }

        case 'ADD_NOTIFICATION':
          const id = uuid.v4()

          return {
            ...prevState,
            notifications: {
              ...prevState.notifications,
              [id]: {
                id,
                ...action.notification,
                date: new Date()
              }
            }
          }

        case 'REMOVE_NOTIFICATION':
          const items = { ...prevState.notifications }
          delete(items[action.NotificationId])

          return {
            ...prevState,
            notifications: items
          }

        case 'SCROLL_CHANGE':
          return {
            ...prevState,
            scrollBottom: action.bottom
          }

        default:
          return prevState
      }
    },
    {
      ...INITIAL_STATE,
      isLoggedIn: !!currentSession,
      currentSession,
      currentCompany
    }
  )

  const actions = useMemo(() => ({
    SetSession: (session) => {
      localStorage.setItem('sessionData', JSON.stringify(session))
      dispatch({ type: 'SET_SESSION', session })
    },
    SetCompany: (company) => {
      localStorage.setItem('CurrentCompany', JSON.stringify(company))
      dispatch({ type: 'SET_COMPANY', company })
    },
    SetUser: (user) => {
      dispatch({ type: 'SET_USER', user })
    },
    SignOut: () => {
      localStorage.removeItem('sessionData')
      localStorage.removeItem('CurrentCompany')
      //navigate("/login")
      window.location.reload()
    },
    AddNotification: (notification) => {
      dispatch({ type: 'ADD_NOTIFICATION', notification })
    },
    DismissNotification: NotificationId => {
      dispatch({ type: 'REMOVE_NOTIFICATION', NotificationId })
    },
    ChangeScroll: (scroll) => {
      dispatch({ type: 'SCROLL_CHANGE', ...scroll })
    }
  }), [])

  return { state, actions }
}

