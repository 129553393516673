import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom'
import { useFromQueryString, toQueryString } from '../../utils.js'
import { useQuery } from 'react-query'
import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from '../shared/Pagination.js'
import { FilterMenu } from '../shared/Filters.js';
import Spinner from '../shared/Spinner.js';
import Header from "../shared/Header.js"
import { useNotifications } from '../shared/Notifications.js'
import useFeatureChecker, { AllowedFeature , DeniedFeature } from '../shared/FeatureChecker.js';
import DeviceInfoTable from './DeviceInfoTable.js';

export default function DeviceInfoListPage(){
  const queryString = useFromQueryString()
  const navigate = useNavigate()
  const notifications = useNotifications()
  const CheckFeatures= useFeatureChecker ()
  const labelTitle = 'Macs'
  const [ currentFilters, setCurrentFilters ] = useState({
    page: parseInt(queryString.page) || 1,
    search: queryString.search || ''
  })
  const query = useQuery(`deviceinfo?${toQueryString(currentFilters)}`, {
    keepPreviousData: true,
  })

  useEffect(()=>{
    setCurrentFilters(prevFilters => ({
      ...prevFilters,
      page: 1
    }));
  },[queryString.search])

  useEffect(() => {
    if( !query.isLoading && query.error  && query.error.response && query.error.response.status==403){
        //notifications.warning('Acceso no autorizado')
        navigate(`/error?error=403`)
    }
  }, [ query.isLoading])


  useEffect(() => {
    navigate('?' + toQueryString(currentFilters), { replace: true })
  }, [ currentFilters ])

  return (
    <Container>
      <Row>
        <Header title={labelTitle} items={[
          { label: `Listado de macs` }
        ]}>
        </Header>
      </Row>
      <Row className="mb-2">
        <FilterMenu
          currentFilters={currentFilters}
          onChange={filters => setCurrentFilters(prev => ({ ...prev, ...filters }))}
        />
      </Row>
      <CheckFeatures feature='LIST_DEVICE_INFO'>
        <AllowedFeature>
          <Row>
            <Col className="col-12">
              {
                query.isLoading ?
                  <div className="text-center my-4 py-4">
                    <Spinner />
                  </div>
                :
                <DeviceInfoTable deviceInfo={query.data && query.data.result || []} refetch={query.refetch}/>
              }
            </Col>
          </Row>

          <Row className="mt-2">
            <Pagination
              pagination={query.data && query.data.pagination}
              isLoading={query.isFetching}
              onChange={page => setCurrentFilters(prev => ({ ...prev, page }))}
            />
          </Row>
        </AllowedFeature>
        <DeniedFeature>
          <Navigate to={'/error?error=403'}/>
        </DeniedFeature>
      </CheckFeatures>
    </Container>
  )
}

