import React, { useState, useMemo, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";
import { UsersListSelector, DoorsListSelector, UsersGroupListSelector, ZoneListSelector } from '../shared/ListSelector.js'
import { AccessTypeSelector, DateSelector, TimeSelector } from '../shared/Selectors.js'
import { toUTCString, formatDate, parseDate } from '../../utils.js'
import { useNotifications } from '../shared/Notifications.js'
import './AccessForm.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';




export default function AccessFormBasic({ access, save, isLoading, remove, users, doors }) {
  const [accessTypeId, setAccessTypeId] = useState(access.access_type_id)
  const [description, setDescription] = useState(access.description || '')
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedDoors, setSelectedDoors] = useState([])
  const [errors, setErrors] = useState({})
  const [showReview, setShowReview] = useState(false)
  const [dateId, setDateId] = useState(0)
  const [timeId, setTimeId] = useState(0)
  const [key, setKey] = useState(0);
  const [key2, setKey2] = useState(0);
  const [key3, setKey3] = useState(0);


  const [selectedGroups, setSelectedGroups] = useState([])
  const [selectedZones, setSelectedZones] = useState([])
  const [startsAt, setStartsAt] = useState(parseDate(access.date_start))
  const [endsAt, setEndsAt] = useState(parseDate(access.date_end))

  const [validateState, setValidateState] = useState(true)




  const notifications = useNotifications()

  const data = useMemo(() => ({
    id: access && access.id ? access.id : null,
    date_start: startsAt ? startsAt : null,
    date_end: endsAt ? endsAt : null,
    access_type_id: accessTypeId,
    description: description,
    door_ids: selectedDoors ? selectedDoors.map(x => x[0]) : null,
    user_ids: selectedUsers ? selectedUsers.map(x => x[0]) : null,
    group_ids: selectedGroups ? selectedGroups.map(x => x[0]) : null,
    dateId: dateId ? dateId : null,
    timeId: timeId ? timeId : null,
    zone_ids: selectedZones ? selectedZones.map(x => x[0]) : null,
  }), [startsAt, endsAt, accessTypeId, description, selectedDoors, selectedUsers, selectedGroups, dateId, timeId, selectedZones])

  let warning;
  let warningText = '';


  if (warningText) {
    warning = (
      <div className="form-group">
        <div className="alert alert-primary text-center">
          <b>{warningText}</b>
        </div>
      </div>
    )
  }

  function validate() {
    const validations = []
    return validations
  }

  function handleSubmit(e) {
    if (!validate().length) {
      console.log(data.date_start)
      save({
        ...data,
        dataTmp: data.date_start,
        date_start: data.date_start ? toUTCString(data.date_start) : null,
        date_end: data.date_end ? toUTCString(data.date_end) : null,
        time_Zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        now: (new Date()).setHours(0, 0, 0, 0)
      })
    }
  }

  const DateTimeInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      className="form-control"
      value={value}
      onChange={onChange}
      onClick={onClick}
      ref={ref}
    />
  ));


  console.log(isLoading, validate())

  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <div className="card shadow-sm">
            <div className="card-header">Detalles del permiso</div>

            <div className="card-body">
              <div className="form-group">
                <label className="font-weight-bold">Tipo de permiso</label>
                <AccessTypeSelector
                  placeholder="Seleccione tipo de permiso"
                  onChange={value => setAccessTypeId(value)}
                  defaultValue={accessTypeId}
                  disabled={isLoading}
                />
              </div>

              <div className="form-group">
                <label className="font-weight-bold">Descripción</label>
                <textarea
                  className="form-control"
                  rows="3"
                  onChange={e => setDescription(e.target.value)}
                  value={description}
                  disabled={isLoading}
                ></textarea>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <div className="card shadow-sm">
            <div className="card-header">Detalle de la Fecha</div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-6">
                  <label className="font-weight-bold">Fecha de inicio</label>

                  <DatePicker
                    selected={startsAt}
                    onChange={(date) => setStartsAt(date)}
                    selectsStart
                    startDate={startsAt}
                    endDate={endsAt}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    dateFormat="dd/MM/yyyy HH:mm"
                    customInput={<DateTimeInput />}
                  />
                  {
                    errors.startsAt ?
                      <p className="alert alert-danger">{errors.startsAt}</p>
                      : null
                  }
                </div>

                <div className="col-6">
                  <label className="font-weight-bold">Fecha de término</label>

                  <DatePicker
                    selected={endsAt}
                    onChange={(date) => setEndsAt(date)}
                    selectsEnd
                    startDate={startsAt}
                    endDate={endsAt}
                    minDate={startsAt}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    dateFormat="dd/MM/yyyy HH:mm"
                    customInput={<DateTimeInput />}
                  />

                  {
                    errors.endsAt ?
                      <p className="alert alert-danger">{errors.endsAt}</p>
                      : null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 
      <Tabs
        id="controlled-tab-example"
        activeKey={key3}
        onSelect={(k) => setKey3(k)}
        className="mb-3"
        fill
      >
        <Tab eventKey={0} title="Basica">
          <div className="row mb-3">
            <div className="col-6">
              <label className="font-weight-bold"> Selecione un rango de fecha</label>
              <DateSelector
                placeholder="Seleccione rango de fecha"
                onChange={value => setDateId(value)}
                defaultValue={dateId}
                disabled={isLoading}
              />
            </div>
            <div className="col-6">
              <label className="font-weight-bold"> Selecione un rango de Horas</label>
              <TimeSelector
                placeholder="Seleccione rango de Horas"
                onChange={value => setTimeId(value)}
                defaultValue={timeId}
                disabled={isLoading}
              />
            </div>
          </div>
        </Tab>
        <Tab eventKey={1} title="Avanzada">
          <div className="row mb-3">
            <div className="col-6">
              <label className="font-weight-bold">Fecha de inicio</label>

              <DatePicker
                selected={startsAt}
                onChange={(date) => setStartsAt(date)}
                selectsStart
                startDate={startsAt}
                endDate={endsAt}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={30}
                dateFormat="dd/MM/yyyy HH:mm"
                customInput={<DateTimeInput />}
              />
              {
                errors.startsAt ?
                  <p className="alert alert-danger">{errors.startsAt}</p>
                  : null
              }
            </div>

            <div className="col-6">
              <label className="font-weight-bold">Fecha de término</label>

              <DatePicker
                selected={endsAt}
                onChange={(date) => setEndsAt(date)}
                selectsEnd
                startDate={startsAt}
                endDate={endsAt}
                minDate={startsAt}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={30}
                dateFormat="dd/MM/yyyy HH:mm"
                customInput={<DateTimeInput />}
              />

              {
                errors.endsAt ?
                  <p className="alert alert-danger">{errors.endsAt}</p>
                  : null
              }
            </div>
          </div>
        </Tab>
      </Tabs>
 */}

      <div className="row mb-3">
        <div className="col-12">
          <div className="card shadow-sm">
            <div className="card-header">Detalle de Puertas</div>
            <div className="card-body">
              <Tabs
                id="controlled-tab-example"
                activeKey={key2}
                onSelect={(k) => setKey2(k)}
                className="mb-3"
                fill
              >
                <Tab eventKey={0} title="Buscar por zona">
                  <div className="col">
                    <div className="row mb-3">
                      <ZoneListSelector
                        onChange={items => setSelectedZones(items)}
                      />

                    </div>
                  </div>
                </Tab>
                <Tab eventKey={1} title="Buscar por puerta">
                  <div className="col">
                    <div className="row mb-3">
                      <DoorsListSelector
                        onChange={items => setSelectedDoors(items)}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12">
          <div className="card shadow-sm">
            <div className="card-header">Detalle de usuarios</div>
            <div className="card-body">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
                fill
              >
                <Tab eventKey={0} title="Buscar por grupo">
                  <div className="col">
                    <div className="row mb-3">
                      <UsersGroupListSelector
                        onChange={items => setSelectedGroups(items)}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey={1} title="Buscar por nombre">
                  <div className="col">
                    <div className="row mb-3">
                      <UsersListSelector
                        onChange={items => setSelectedUsers(items)}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>





      <div className="row mb-3 pb-4">
        <div className="col-12 text-right">
          <button
            className={`btn btn-primary`}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            <span>Guardar permiso</span>
          </button>
        </div>
      </div>
    </>
  )

}

