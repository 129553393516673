import React, { useState, useContext, useEffect } from 'react';
import { CurrentCompanyContext } from '../../App.js'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom';

import { Container } from '../layout/AppLayout.js'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { isAllowed } from '../shared/FeatureChecker.js';
import { CurrentUserContext } from '../../App.js';

import { useMutationClient } from '../shared/hooks.js'
import { useNotifications } from '../shared/Notifications.js'

import Header from "../shared/Header.js";

import ZoneForm from './ZoneForm.js';

export default function NewZonePage() {
  const currentCompany = useContext(CurrentCompanyContext)
  const queryClient = useQueryClient()
  const mutationClient = useMutationClient()
  const navigate = useNavigate()
  const notifications = useNotifications()
  const currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "CREATE_ZONE"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])


  const createZone = useMutation(
    data => mutationClient.post(`zones`, { ...data, company_id: currentCompany && currentCompany.id }), {
    onSuccess: (data, variables, context) => {
      if( data.status === 200 ){
        notifications.success('Zona creada correctamente')
        navigate(`/zones`)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al crear la zona')
    }
  })

  return (
    <Container>
      <Row>
        <Header
          title="Nueva Zona"
          items={[
            { label: `Listado de zonas`, to: "/zones" },
            { label: `Nueva zona` },
          ]}
        />
      </Row>

      <Row className="mb-3">
        <Col className="col-12 mb-2 col-lg-6">
          <Card className="shadow-sm">
            <Card.Header>
              <span>Crear Zona</span>
            </Card.Header>
            <Card.Body>
              <ZoneForm
                zone={{}}
                save={data => createZone.mutate(data)}
                isLoading={createZone.isLoading}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
