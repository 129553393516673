import React, { useState, useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import { format, parseISO, startOfMinute, endOfMinute, startOfDay, endOfDay } from 'date-fns'

import DatePicker,{registerLocale} from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.min.css";
registerLocale("es",es);

const DateRangeInput = React.forwardRef(({ value, onClick, onChange }, ref) => (
  <button
    className={`btn btn-sm btn-outline-primary ${value ? 'pr-4' : ''}`}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
    { value ? value : 'Filtrar por fecha' }
  </button>
));

export default function DateRangeFilter({ start, end, onChange, disabled, ...props }){
  const [ dateRange, setDateRange ] = useState([
    start ? parseISO(start) : null,
    end ? parseISO(end) : null
  ])
  const [ startDate, endDate ] = dateRange

  useEffect(() => {
    if( startDate && endDate ){
      onChange({
	start: startOfDay(startDate).toISOString(),
	end: endOfDay(endDate).toISOString()
      })
    } else if( startDate === null && endDate === null ){
      onChange({
        start: null,
        end: null
      })
    }
  }, [ startDate, endDate ])

  return (
    <div className="d-block d-md-inline-block mb-1 mr-lg-1">
      <DatePicker
        className=""
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => setDateRange(update)}
        isClearable={true}
        customInput={<DateRangeInput />}
        locale="es"
      />
    </div>
  )
}

