import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

import Spinner from '../shared/Spinner.js';
import TableList from '../shared/TableList.js';
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';


export default function FacilitiesTable({ facilities }) {
  const CheckFeatures = useFeatureChecker()
  if (!facilities.length) {
    return <div className="text-center text-muted py-4 my-4">No se encontraron instalaciones para los filtros especificados</div>
  }

  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="Nombre" />
        <TableList.HeaderItem value="Tipo" width={400} />
        <TableList.HeaderItem value="Dirección" width={400} />
      </TableList.Header>

      <TableList.Body>
        {
          facilities.map(item => (
            <TableList.Row key={item.id}>
              <TableList.Column>
                <CheckFeatures feature ='DETAIL_FACILITY'>
                  <AllowedFeature>
                    <Link className="py-3" to={"/facilities/" + item.id}>{item.name}</Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    {item.name}
                  </DeniedFeature>
                </CheckFeatures>
              </TableList.Column>

              <TableList.Column value={item.facility_type_name}/>
              <TableList.Column value={item.address}/>
            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}
