import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'

import { Container } from '../layout/AppLayout.js';

import Spinner from '../shared/Spinner.js';
import Header, { HeaderActions } from '../shared/Header.js'

export default function ProfilePage(){
  const query = useQuery(`users/me`)
  const currentUser = query.data && query.data.result

  return (
    <Container>
      <div className="row">
        <Header
          title={`Mi cuenta`}
          items={[
            { label: "Mi perfil" },
          ]}
        >
          <HeaderActions>
            <Link to="/profile/edit" className="btn btn-primary">Editar perfil</Link>
          </HeaderActions>
        </Header>
      </div>

      <div className="row pb-4">

        <div className="col-12 col-md-6">
          {
            query.isLoading ?
              <div className="text-center my-4 py-4">
                <Spinner />
              </div>
            :
              <div className="card shadow-sm">
                <div className="card-header">Mis datos</div>

                <div className="card-body">
                  <div className="form-group">
                    <label className="font-weight-bold">Nombre</label>
                    <p>{ currentUser.name }</p>
                  </div>

                  <div className="form-group">
                    <label className="font-weight-bold">Nombre</label>
                    <p>{ currentUser.name }</p>
                  </div>



                </div>
              </div>
          }
        </div>

        <div className="col-12 col-md-6">
        </div>
      </div>
    </Container>
  )
}

