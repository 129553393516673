import React, { useState, useEffect, useContext, useMemo, forwardRef } from 'react';
import { useQuery } from 'react-query'
import { formatDistanceStrict, differenceInMinutes } from 'date-fns';
import { es } from 'date-fns/locale'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

import Spinner from '../shared/Spinner.js'
import { UsersListSelector, DoorsListSelector } from '../shared/ListSelector.js'
import { AccessTypeSelector } from '../shared/Selectors.js'
import { toUTCString, toQueryString, formatDate, parseDate } from '../../utils.js'
import { useNotifications } from '../shared/Notifications.js'

import './AccessForm.css'

function AccessTypeName({ AccessTypeId }){
  const query = useQuery(`doors/access/types?AccessTypeId=${AccessTypeId}`)

  if( query.isSuccess ){
    return query.data
      && query.data.result
      && query.data.result.length
      && query.data.result[0].name
      || null
  }

  return null
}

function AccessReview({ access, data, users, doors, onSave, onClose }){
  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col-12">
          <div className="card shadow-sm">
            <div className="card-header">Revisión del permiso</div>

            <div className="card-body">
              <div className="row form-group mb-0">
                <div className="col-6">
                  <label className="form-label font-weight-bold">Inicio</label>
                  <p>{ formatDate(data.date_start.toISOString()) }</p>
                </div>

                <div className="col-6">
                  <label className="form-label font-weight-bold">Término</label>
                  <p>{ formatDate(data.date_end.toISOString()) }</p>
                </div>
              </div>

              <div className="form-group">
                <label className="form-label font-weight-bold">Tipo</label>
                <p>
                  <AccessTypeName AccessTypeId={data.access_type_id} />
                </p>
              </div>

              <div className="form-group">
                <label className="form-label font-weight-bold">Descripción</label>
                <p>{ data.description }</p>
              </div>

              {
                !data.id ?
                  <React.Fragment>
                    <div className="form-group">
                      <label className="form-label font-weight-bold">Usuarios</label>

                      <p>
                        {
                          users.map(item => (
                            <span key={item[0]} className="mr-2 mb-2 p-2 badge rounded-pill bg-light border">{item[1]}</span>
                          ))
                        }
                      </p>
                    </div>

                    <div className="form-group">
                      <label className="form-label font-weight-bold">Puertas</label>

                      <p>
                        {
                          doors.map(item => (
                            <span key={item[0]} className="mr-2 mb-2 p-2 badge rounded-pill bg-light border">{item[1]}</span>
                          ))
                        }
                      </p>
                    </div>
                  </React.Fragment>
                :
                  <React.Fragment>
                    <div className="form-group">
                      <label className="form-label font-weight-bold">Usuario</label>
                      <p>
                        <span className="mr-2 mb-2 p-2 badge rounded-pill bg-light border">{ access.User.name || access.User.email }</span>
                      </p>
                    </div>

                    <div className="form-group">
                      <label className="form-label font-weight-bold">Puertas</label>

                      <p>
                        <span className="mr-2 mb-2 p-2 badge rounded-pill bg-light border">{ access.Door.name }</span>
                      </p>
                    </div>
                  </React.Fragment>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4 pb-4">
        <div className="col-12 text-right">
          <button
            className="btn btn-link mr-2"
            onClick={() => onClose()}
          >Volver</button>

          <button
            className="btn btn-primary"
            onClick={onSave}
          >Guardar permiso</button>
        </div>
      </div>
    </React.Fragment>
  )
}

const DateTimeInput = forwardRef(({ value, onClick, onChange }, ref) => (
  <input
    className="form-control"
    value={value}
    onChange={onChange}
    onClick={onClick}
    ref={ref}
  />
));

export default function AccessForm({ access, save, isLoading, remove, users, doors }) {
  const [startsAt, setStartsAt] = useState(parseDate(access.date_start))
  const [endsAt, setEndsAt] = useState(parseDate(access.date_end))
  const [accessTypeId, setAccessTypeId] = useState(access.access_type_id)
  const [description, setDescription] = useState(access.description || '')
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedDoors, setSelectedDoors] = useState([])
  const [errors, setErrors] = useState({})
  const [showReview, setShowReview] = useState(false)

  const notifications = useNotifications()

  const data = useMemo(() => ({
    id: access && access.id ? access.id : null,
    date_start: startsAt ? startsAt : null,
    date_end: endsAt ? endsAt : null,
    access_type_id: accessTypeId,
    description: description,
    door_ids: selectedDoors ? selectedDoors.map(x => x[0]) : null,
    user_ids: selectedUsers ? selectedUsers.map(x => x[0]) : null,
  }), [startsAt, endsAt, accessTypeId, description, selectedDoors, selectedUsers])

  let warning;
  let warningText = '';

  if (startsAt && endsAt) {
    const diff = differenceInMinutes(endsAt, startsAt)

    if (diff === 0) {
      warningText = 'No puedes asignar la misma fecha y hora para inicio y término'

    } else if (diff < 0) {
      warningText = 'Fecha de inicio es mayor que fecha de término'

    } else {
      warningText = 'Duración aproximada: ' + formatDistanceStrict(startsAt, endsAt, { locale: es })
    }
  }

  if (warningText) {
    warning = (
      <div className="form-group">
        <div className="alert alert-primary text-center">
          <b>{warningText}</b>
        </div>
      </div>
    )
  }

  function validate(){
    const validations = []

    if( !startsAt ){
      validations.push(['startsAt', 'Fecha de inicio es requerida'])
    }

    if( !endsAt ){
      validations.push(['endsAt', 'Fecha de término es requerida'])
    }

    if (differenceInMinutes(endsAt, startsAt) <= 0) {
      validations.push(['date', 'Fecha de término no puede ser menor a fecha de inicio'])
    }

    if( !access.id ){
      if( !selectedUsers.length ){
        validations.push(['users', 'Debes seleccionar al menos un usuario'])
      }

      if( !selectedDoors.length ){
        validations.push(['doors', 'Debes seleccionar al menos una puerta'])
      }
    }

    setErrors(validations.reduce((acc, item) => ({ ...acc, [item[0]]: item[1] }), {}))

    if( validations.length ){
      notifications.warning('Verifica la información ingresada, algunos datos son requeridos o están inválidos')
    }

    return validations
  }

  function handleSubmit(e) {
    if ( !validate().length ) {
      save({
        ...data,
        date_start: toUTCString(data.date_start),
        date_end: toUTCString(data.date_end),
      })
    }
  }

  if( showReview ){
    return <AccessReview
      access={access}
      data={data}
      doors={selectedDoors}
      users={selectedUsers}
      onClose={() => setShowReview(false)}
      onSave={handleSubmit}
    />
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <div className="card shadow-sm">
            <div className="card-header">Detalles del permiso</div>

            <div className="card-body">
              <div className="row form-group">
                <div className="col-6">
                  <label className="font-weight-bold">Fecha de inicio</label>

                  <DatePicker
                    selected={startsAt}
                    onChange={(date) => setStartsAt(date)}
                    selectsStart
                    startDate={startsAt}
                    endDate={endsAt}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    dateFormat="dd/MM/yyyy HH:mm"
                    customInput={<DateTimeInput />}
                  />
                  {
                    errors.startsAt ?
                      <p className="alert alert-danger">{ errors.startsAt }</p>
                    : null
                  }
                </div>

                <div className="col-6">
                  <label className="font-weight-bold">Fecha de término</label>

                  <DatePicker
                    selected={endsAt}
                    onChange={(date) => setEndsAt(date)}
                    selectsEnd
                    startDate={startsAt}
                    endDate={endsAt}
                    minDate={startsAt}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    dateFormat="dd/MM/yyyy HH:mm"
                    customInput={<DateTimeInput />}
                  />

                  {
                    errors.endsAt ?
                      <p className="alert alert-danger">{ errors.endsAt }</p>
                    : null
                  }
                </div>
              </div>

              {
                access.id ?
                  <>
                    <div className="form-group">
                      <label className="font-weight-bold">Usuario</label>
                      <input type="text" className="form-control" disabled={true} value={access.User.name} />
                    </div>

                    <div className="form-group">
                      <label className="font-weight-bold">Puerta</label>
                      <input type="text" className="form-control" disabled={true} value={access.Door.name} />
                    </div>
                  </>
                : null
              }

              <div className="form-group">
                <label className="font-weight-bold">Tipo de permiso</label>
                <AccessTypeSelector
                  placeholder="Seleccione tipo de permiso"
                  onChange={value => setAccessTypeId(value)}
                  defaultValue={accessTypeId}
                  disabled={isLoading}
                />
              </div>

              <div className="form-group">
                <label className="font-weight-bold">Descripción</label>
                <textarea
                  className="form-control"
                  rows="3"
                  onChange={e => setDescription(e.target.value)}
                  value={description}
                  disabled={isLoading}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        !access.id ?
          <div className="col">
            <div className="row mb-3">
              <UsersListSelector
                onChange={items => setSelectedUsers(items)}
              />
            </div>

            <div className="row mb-3">
              <DoorsListSelector
                onChange={items => setSelectedDoors(items)}
              />
            </div>
          </div>
        : null
      }

      <div className="row mb-3 pb-4">
        <div className="col-12 text-right">
          <button
            className={`btn btn-link mr-3`}
            disabled={isLoading}
            onClick={() => {
              if( !validate().length ){
                setShowReview(true)
              }
            }}
          >
            <span>Revisar y guardar</span>
          </button>

          <button
            className={`btn btn-primary`}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            <span>Guardar permiso</span>
          </button>
        </div>
      </div>
    </>
  )
}

