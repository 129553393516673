import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useNotifications } from '../shared/Notifications.js'

import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import { useMutationClient } from '../shared/hooks.js'
import Spinner from '../shared/Spinner.js'
import Header from "../shared/Header.js"

import AccessForm from './AccessForm.js'

export default function EditAccessPage(){
  const params = useParams()
  const queryClient = useQueryClient()
  const mutationClient = useMutationClient()
  const navigate = useNavigate()
  const notifications = useNotifications() 

  const query = useQuery(`doors/access?AccessId=${params.AccessId}`)
  const currentAccess = query.data
    && query.data.result
    && query.data.result.length
    && query.data.result[0]
    || {}

  const updateAccess = useMutation(
    data => mutationClient.put(`doors/access/${params.AccessId}`, data), {
    onSuccess: (data, variables, context) => {
      if( data.status === 200 ){
        notifications.success('Permiso actualizado exitosamente')
        navigate(`/permissions/${params.AccessId}`)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al actualizar el permiso')
    }
  })


  return (
    <Container>
      <Row>
        <Header title="Editar permiso" items={[
          { label: "Listado de permisos", to: "/permissions" },
          { label: "Detalle del permiso", to: `/permissions/${params.AccessId}` },
          { label: "Editar permiso" },
        ]} />
      </Row>

      {
        query.isLoading || !currentAccess ?
          <div className="row">
            <div className="col-12 text-center my-4 py-4">
              <Spinner />
            </div>
          </div>
        :
          <AccessForm
            access={currentAccess}
            save={data => updateAccess.mutate(data)}
            isLoading={updateAccess.isLoading || query.isLoading}
          />
      }
    </Container>
  )
}
