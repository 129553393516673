import React from 'react';
import { Link } from 'react-router-dom';
import SensorHealth from '../../../shared/SensorHealth.js';
import TableList from '../../../shared/TableList.js';
import useFeatureChecker, { AllowedFeature, DeniedFeature} from '../../../shared/FeatureChecker.js';
export default function SensorsTable({ sensors }) {
  const CheckFeatures = useFeatureChecker()

  if(!sensors.length) {
    return (
      <div className="text-center text-muted py-4 my-4">No se encontraron dispositivos para los filtros especificados</div>
    )
  }

  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="Nombre" />
        <TableList.HeaderItem value="Puerta" />
        <TableList.HeaderItem value="Tipo"/>
        <TableList.HeaderItem value="Device Id" />
        <TableList.HeaderItem value="Heartbeat"/>
      </TableList.Header>

      <TableList.Body>
        {
          sensors.map(item => (
            <TableList.Row key={item.id}>
              <TableList.Column>
                <CheckFeatures feature='DETAIL_SENSORS'>
                  <AllowedFeature>
                    <Link
                      className="py-3"
                      to={"/sensors/" + item.id}
                    >
                      <strong>{item.name ? item.name : 'Sin nombre'}</strong>
                    </Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    <strong>{item.name ? item.name : 'Sin nombre'}</strong>
                  </DeniedFeature>
                </CheckFeatures>

              </TableList.Column>
              <TableList.Column>
                <CheckFeatures feature='DETAIL_DOOR'>
                  <AllowedFeature>
                    <Link
                      className="py-3"
                      to={"/doors/"+item.door_id}
                    >
                      {item.door_name}
                    </Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    {item.door_name}
                  </DeniedFeature>
                </CheckFeatures>
                
              </TableList.Column>


              <TableList.Column value={item.type_label}/>

              <TableList.Column>
                <span style={{ fontFamily: 'monospace' }}>{item.device_id}</span>
              </TableList.Column>

              <TableList.Column>
                <SensorHealth sensor={item} />
              </TableList.Column>
            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}
