import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faTimes,
  faExclamationTriangle,
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';

import { Searchbox } from '../shared/Search.js'
import { toQueryString } from '../../utils.js'

function ListSelectorItem({ item, isSelected, onSelect }){
  const [value, label] = item

  return (
    <div
      className={`border-top-0 border-left-0 border-right-0 list-group-item justify-content-between align-items-center ${isSelected ? 'd-none' : 'd-flex'}`}
    >
      <span>{ label }</span>

      <button
        className="btn btn-sm py-0 px-2 btn-outline-dark"
        style={{ fontSize: '.9em' }}
        onClick={() => onSelect(item)}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  )
}

function ItemsCard({ items, selectedItems, onSelect, onSearch }){
  const selectedIds = selectedItems.map(x => x[0])
  const filteredItems = items.filter(x => !selectedIds.includes(x[0]))

  return (
    <React.Fragment>
      <div className="border-bottom py-2 px-2">
        <Searchbox
          onSearch={onSearch}
        />
      </div>

      <div className="list-group border-0" style={{ height: '300px', overflowY: 'auto', fontSize: '.9em' }}>
        {
          filteredItems.map(item => (
            <ListSelectorItem
              key={item[0]}
              item={item}
              onSelect={onSelect}
            />
          ))
        }
      </div>
    </React.Fragment>
  )
}

function SelectedItems({ items, onRemove }){
  return (
    <ul className="list-group border-0" style={{ height: '350px', overflowY: 'auto', fontSize: '.9em' }}>
      {
        !items.length ?
          <div className="text-center text-muted my-4 py-4">Sin elementos seleccionados</div>
        : null
      }

      {
        items.map(item => (
          <li key={item[0]} className={`border-top-0 border-left-0 border-right-0 list-group-item`}>
            <div className="d-flex justify-content-between align-items-center">
              <div>{ item[1] }</div>

              <div>
                <button
                  className="btn btn-sm py-0 px-2 btn-outline-danger"
                  style={{ fontSize: '.9em' }}
                  onClick={() => onRemove(item)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            </div>
          </li>
        ))
      }
    </ul>
  )
}

export function ListSelector({ items, listTitle, selectedTitle, onSearch, onChange }){
  const [ selectedItems, setSelectedItems ] = useState({})

  useEffect(() => {
    onChange(Object.values(selectedItems))
  }, [selectedItems])

  return (
    <>
      <div className="col-6 pl-0 pr-md-3">
        <div className="card shadow-sm">
          <div className="card-header">{listTitle || 'Para seleccionar'}</div>

          <ItemsCard
            items={items}
            onSearch={onSearch}
            selectedItems={Object.values(selectedItems)}
            onSelect={item => setSelectedItems(prev => ({ ...prev, [item[0]]: item }))}
          />
        </div>
      </div>

      <div className="col-6 p-0 pl-md-3">
        <div className="card shadow-sm">
          <div className="card-header d-flex justify-content-between align-items-center">
            <span>{selectedTitle || 'Seleccionados'}</span>
            {
              Object.keys(selectedItems).length ?
                <span className="badge badge-pill border" style={{ background: '#ddd' }}>{Object.keys(selectedItems).length}</span>
              : null
            }
          </div>

          <SelectedItems
            items={Object.values(selectedItems)}
            onRemove={item => setSelectedItems(prev => Object.values(prev).filter(x => x[0] !== item[0]))}
          />
        </div>
      </div>
    </>
  )
}

export function UsersGroupListSelector({ onChange }){
  const [ search, setSearch ] = useState(null)

  const query = useQuery(`groups?${toQueryString({ search })}`)

  const items = query.data
    && query.data.result
    && query.data.result.length
    && query.data.result.map(x => [x.id, x.name || x.email])
    || []

  return (
    <ListSelector
      listTitle="Seleccionar Grupo"
      selectedTitle="Grupos seleccionados"
      items={items}
      onSearch={q => setSearch(q)}
      onChange={onChange}
    />
  )
}

export function UsersListSelector({ onChange }){
  const [ search, setSearch ] = useState(null)

  const query = useQuery(`users?${toQueryString({ search })}&&limit=1000&&accepted=True`)

  const items = query.data
    && query.data.result
    && query.data.result.length
    && query.data.result.filter((u)=>u.accepted_at!=null).map(x => [x.id, x.name || x.email])
    || []

  return (
    <ListSelector
      listTitle="Seleccionar usuarios"
      selectedTitle="Usuarios seleccionados"
      items={items}
      onSearch={q => setSearch(q)}
      onChange={onChange}
    />
  )
}

export function DoorsListSelector({ onChange }){
  const [ search, setSearch ] = useState(null)

  const query = useQuery(`doors?${toQueryString({ search })}`)

  const items = query.data
    && query.data.result
    && query.data.result.length
    && query.data.result.map(x => (x.zone_name ? [x.id,x.name + " - " + x.zone_name]:[x.id,x.name]))
    || []  

  return (
    <ListSelector
      listTitle="Seleccionar puertas"
      selectedTitle="Puertas seleccionadas"
      items={items}
      onSearch={q => setSearch(q)}
      onChange={onChange}
    />
  )
}
export function ZoneListSelector({ onChange }){
  const [ search, setSearch ] = useState(null)

  const query = useQuery(`zones?${toQueryString({ search })}`)

  const items = query.data
    && query.data.result
    && query.data.result.length
    && query.data.result.map(x => (x.zone_name ? [x.id,x.name + " - " + x.zone_name]:[x.id,x.name]))
    || []  

  return (
    <ListSelector
      listTitle="Seleccionar Zona"
      selectedTitle="Zonas seleccionadas"
      items={items}
      onSearch={q => setSearch(q)}
      onChange={onChange}
    />
  )
}

export function DeviceListSelector({ onChange,ZoneId }){
  const [ search, setSearch ] = useState(null)
  const [ currentFilters, setCurrentFilters ] = useState({
    ZoneId: ZoneId || '',
  })
  const query = useQuery(`sensors?${toQueryString({ search })}&${toQueryString(currentFilters)}`)

  const items = query.data
    && query.data.result
    && query.data.result.length
    && query.data.result.map(x => [x.id, x.name])
    || []

  return (
    <ListSelector
      listTitle="Seleccionar dispositivos"
      selectedTitle="Dispositivos seleccionados"
      items={items}
      onSearch={q => setSearch(q)}
      onChange={onChange}
    />
  )
}

