import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import axios from 'axios';

export function useCurrentCompany(){
  const [ currentCompany, setCurrentCompany ] = useState(null)

  useEffect(() => {
    try{
      const data = JSON.parse(localStorage.getItem('CurrentCompany'))
      setCurrentCompany(data)
    }catch(e){
      console.log('No token found');
    }
  }, [])

  return currentCompany
}

export function useSensorType(SensorTypeId){
  const query = useQuery(`sensors/types?SensorTypeId=${SensorTypeId}`, {
    enabled: !!SensorTypeId,
    refetchOnWindowFocus: false
  })

  return query.data
    && query.data.result
    && query.data.result.length
    && query.data.result[0]
    || null
}

export function useMutationClient(){
  let sessionData;

  try{
    sessionData = JSON.parse(localStorage.getItem('sessionData'))
  }catch(e){
    console.log('No token found');
  }

  return axios.create({
    baseURL: window.config.API_URL,
    headers: {
      'Authorization': sessionData && sessionData.token ? `Bearer ${sessionData.token}` : undefined
    },
    //timeout: 1000,
  });
}

