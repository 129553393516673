export default function(alertType,alertState) {
    
    switch (alertType) {
      case "pm10":
        switch(alertState){
            case 0:
                return "No hay peligro alguno"
            case 1:
                return "Nivel moderado"
            case 2:
                return "Dañino para grupos sensibles"
            case 3:
                return "Dañino"
            case 4:
                return "Muy Dañino"
            case 5:
                return "Peligroso"
            default:
                return alertState
        }
      case "pm2.5":
        switch (alertState){
            case 0:
                return "No hay peligro alguno"
            case 1:
                return "Nivel moderado"
            case 2:
                return "Dañino para grupos sensibles"
            case 3:
                return "Dañino"
            case 4:
                return "Muy dañino"
            case 5:
                return "Peligroso"
            default: 
                return alertState
        }
      case "pm1.0":
        switch (alertState){
            case 0:
                return "No hay peligro alguno"
            case 1:
                return "Nivel moderado"
            case 2:
                return "Dañino para grupos senibles"
            case 3:
                return "Dañino"
            case 4:
                return "Muy dañino"
            case 5:
                return "Peligroso"
            default:
                return alertState
        }
      case  "no2":
        switch (alertState){
            case 0:
                return "No hay peligro alguno"
            case 1:
                return "Nivel moderado"
            case 2:
                return "Dañino para grupos sensibles"
            case 3:
                return "No saludable"
            case 4:
                return "Muy perjudicial para la salud"
            case 5:
                return "Peligroso"
            default:
                return alertState
        }
      case "ch2o":
        switch (alertState){
            case 0:
                return "No hay peligro alguno"
            case 1:
                return "Nivel moderado"
            case 2:
                return "Alto riesgo"
            case 3:
                return "Muy riesgoso"
            default:
                return alertState
        }
      case "voc":
        switch (alertState){
            case 0:
                return "No hay peligro alguno"
            case 1:
                return "Riesgo moderado"
            case 2:
                return "Alto riesgo"
            default:
                return alertState
        }
      case "o3":
        switch (alertState){
            case 0:
                return "No hay peligro alguno"
            case 1:
                return "Riesgo moderado"
            case 2:
                return "Dañino para grupos sensibles"
            case 3:
                return "Poco saludable"
            case 4:
                return "Muy riesgoso"
            default:
                return alertState
        }
      case "co2":
        switch (alertState){
            case 0:
                return "No hay peligro alguno"
            case 1:
                return "Quejas por somnolencia"
            case 2:
                return "Dolores de cabeza, somnolencia y falta de concentración"
            case 3:
                return "Falta grave de oxigeno"
            default:
                return alertState
        }
      case "co":
        switch (alertState){
            case 0:
                return "No hay peligro alguno"
            case 1:
                return "Riesgo moderado"
            case 2:
                return "Riesgo alto"
            case 3:
                return "Muy riegoso"
            default:
                return alertState
        }
      case "noise":
        switch (alertState){
            case 0:
                return "No hay peligro alguno"
            case 1:
                return "Riesgo auditivo 8 horas"
            case 2:
                return "Riesgo auditivo 2 horas"
            case 3:
                return "Riesgo auditivo 15 minutos"
            case 4:
                return "Riesgo auditivo inminente"
            default:
                return alertState
        }
      case "insolation":
        switch (alertState){
            case 0:
                return "Peligro de agotamiento por calor"
            case 1:
                return "No hay peligro de agotamiento por calor"
            default:
                return alertState
        }
      case "respiratory":
        switch (alertState){
            case 0:
                return "Peligro de problemas respiratorios"
            case 1:
                return "No hay peligro de problemas respiratorios"
            default:
                return alertState
        }
      case "cardiovascular":{
        switch(alertState){
            case 0:
                return "No hay peligro de problemas cardiovasculares"
            case 1:
                return "Peligro de problemas cardiovasculares"
            case 2:
                return "Peligro de problemas cardiovasculares"
            case 3:
                return "Peligro de problemas cardiovasculares"
            default:
                return alertState
        }
      }
      default:
        return alertState;
    }
  }
  