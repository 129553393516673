import React from 'react';
import { useQuery } from 'react-query'
import { Link, Navigate, useParams } from 'react-router-dom';

import { Container } from '../layout/AppLayout.js'
import { DestroyButton } from "../shared/Buttons.js"
import Header, { HeaderActions } from "../shared/Header.js";
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js'

import MembersListSubmenu from '../members/MembersListSubmenu.js';
import Spinner from '../shared/Spinner.js';

function DetailCard({ group, isLoading }){
  return (
    <div className="card shadow-sm">
      <div className="card-header">Detalle del grupo</div>
      <div className="card-body">
        {
          isLoading ?
            <div className="text-center py-4 my-4">
              <Spinner />
            </div>
          :
            group ?
              <React.Fragment>
                <div className="form-group">
                  <label className="font-weight-bold">Nombre</label>
                  <p>{ group.name }</p>
                </div>

                <div className="form-group">
                  <label className="font-weight-bold">Descripción</label>
                  <p>{ group.description ? group.description : '--' }</p>
                </div>
              </React.Fragment>
            : null
        }

      </div>
    </div>
  )

}
export default function GroupDetailPage() {
  const params = useParams()
  const CheckFeatures = useFeatureChecker()

  const query = useQuery(`groups/${params.GroupId}`)

  return (
    <Container>
      <div className="row">
        <Header title="Detalle de grupo" items={[
          { label: `Listado de grupos`, to: "/members/groups" },
          { label: `Detalle de grupo` },
        ]}>
          <HeaderActions>
            <CheckFeatures feature="UPDATE_GROUP">
              <Link className="btn btn-primary mr-3" to="edit">Editar grupo</Link>
            </CheckFeatures>

            <CheckFeatures feature="DESTROY_GROUP">
              <DestroyButton
                label="Eliminar grupo"
                confirmMessage="¿Está seguro que desea eliminar el grupo?"
                successMessage="Activo eliminado correctamente"
                deletePath={`groups/${params.GroupId}`}
                invalidate="groups"
                redirect={`/members/groups`}
              />
            </CheckFeatures>
          </HeaderActions>
        </Header>
      </div>

      <div className="row">
        <MembersListSubmenu />
      </div>

      <div className="row">
        <div className="col-6">
          <CheckFeatures feature="DETAIL_GROUP">
            <AllowedFeature>
              <DetailCard
              group={query.data && query.data.result}
              isLoading={query.isLoading}
            />
            </AllowedFeature>
            <DeniedFeature>
              <Navigate to={'/error?error=403'}/>
            </DeniedFeature>
          
          </CheckFeatures>
        </div>
      </div>
    </Container>
  )
}
