import React, { useState,useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom'
import { useFromQueryString, toQueryString, TIME_ZONE } from "../../utils.js";
import { CurrentUserContext } from '../../App';
import { isAllowed } from '../shared/FeatureChecker.js'

export default function AlertSubmenu({filters}){
  const currentUser = useContext(CurrentUserContext)
  const activeStyle = {
    border: '0',
    borderBottom: '4px solid #000'
  }

  const inactiveStyle = {
    //color: '#555',
    border: '0',
    borderBottom: '4px solid transparent'
  }

  let pages = [
    { to: `/alerts?${toQueryString(filters)}`, label: 'Security' },
    { to: `/alerts/state?${toQueryString(filters)}`, label: 'Safety', features: ['LIST_SAFETY_ALERTS'] },
    { to: `/alerts/connection?${toQueryString(filters)}`, label: 'Estado', features: ['LIST_STATE_ALERTS']}
  ]

  pages = pages.filter(item => {
    if(item.features && item.features.length){
      return currentUser && isAllowed({
        user: currentUser,
        every: item.features,
      })
    }
    return true
  })
  return (
    <div className="col-12 mb-3">
      <div className="bg-white rounded shadow-sm px-3 border">
        {
          pages.map((item, i) => (
            <NavLink
              key={i}
              to={item.to}
              className="btn px-3 pb-2 pt-3 rounded-0 mr-3 font-weight-bold"
              style={({ isActive }) => isActive ? activeStyle : inactiveStyle}
              end
              //activeStyle={activeStyle}
            >{item.label}</NavLink>
          ))
        }
      </div>
    </div>
  )
}

