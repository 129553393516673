import React from 'react';

import { formatDistanceToNow, toDate } from 'date-fns'
import { es } from 'date-fns/locale'

import { StateContext, ActionsContext } from '../../App.js'
import { formatDate } from '../../utils.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
  faInfoCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';

export function useNotifications(){
  const actions = React.useContext(ActionsContext)

  return React.useMemo(() => ({
    info: (body, opts = {}) => {
      const { title, autoClose = true } = opts
      actions.AddNotification({ title, body, autoClose, variant: 'info' })
    },

    warning: (body, opts = {}) => {
      const { title, autoClose = true } = opts
      actions.AddNotification({ title, body, autoClose, variant: 'warning' })
    },

    success: (body, opts = {}) => {
      const { title, autoClose = true } = opts
      actions.AddNotification({ title, body, autoClose, variant: 'success' })
    },

    danger: (body, opts = {}) => {
      const { title, autoClose = true } = opts
      actions.AddNotification({ title, body, autoClose, variant: 'danger' })
    }
  }), [])
}

function NotificationItem({ item, onRemove }){
  let icon = null

  React.useEffect(() => {
    if(item.autoClose){
      const timer = setTimeout(() => {
        onRemove(item.id)
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [item.autoClose])

  switch( item.variant ){
    case 'danger':
      icon = <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
    break;

    case 'warning':
      icon = <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning" />
    break;

    case 'success':
      icon = <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
    break;

    case 'info':
    default:
      icon = <FontAwesomeIcon icon={faInfoCircle} className="text-info" />
    break;
  }

  const dismissButton = (
    <button
      type="button"
      className="ml-2 mb-1 close"
      onClick={() => onRemove(item.id)}
    >
      <span aria-hidden="true">&times;</span>
    </button>
  )

  return (
    <div className="toast show shadow-sm">
      {
        item.title ?
          <div className="toast-header bg-light">
            <div className="mr-2">
              { icon }
            </div>
            <strong className="mr-auto">{item.title}</strong>
            {
              item.date ?
                <small title={formatDate(item.date.toISOString())}>{ formatDistanceToNow(item.date, { addSuffix: true, locale: es }) }</small>
              : null
            }
            { dismissButton }
          </div>
        : null
      }

      <div className="toast-body bg-white">
        <div className="d-flex justify-content-start align-items-center">
          {
            !item.title ?
              <div className="mr-2">
                { icon }
              </div>
            : null
          }
          <div>
            { item.body }
          </div>
          { item.title ? null : dismissButton }
        </div>
      </div>
    </div>
  )
}

export default function Notifications(){
  const { notifications } = React.useContext(StateContext)
  const { DismissNotification } = React.useContext(ActionsContext)

  return (
    <div className="position-fixed bottom-0 left-0 p-3" style={{ zIndex: 10000, left: 0, bottom: 0 }}>
      {
        Object.values(notifications).map((item, idx) => (
          <NotificationItem
            key={idx}
            item={item}
            onRemove={NotificationId => DismissNotification(NotificationId)}
          />
        ))
      }
    </div>
  )
};
