import React, { useEffect, useContext, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query'

import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import { formatDate } from '../../utils.js'
import Spinner from '../shared/Spinner.js'
import Header, { HeaderActions } from "../shared/Header.js"
import useFeatureChecker from '../shared/FeatureChecker.js'
import { useNotifications } from '../shared/Notifications.js'

import { OpenDoorButton, EnableButton, DisableButton } from '../shared/Buttons.js'

function DetailCard({ access, isLoading }){
  const CheckFeatures = useFeatureChecker()

  if( isLoading ){
    return (
      <div className="col-12 text-center">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="card shadow-sm mb-2">
      <div className="card-header">Detalles del permiso</div>

      <div className="card-body">

        <div className="row form-group">
          <div className="col-6">
            <label className="form-label font-weight-bold">Inicio</label>
            <p className="m-0">{ formatDate(access.date_start) }</p>
          </div>

          <div className="col-6">
            <label className="form-label font-weight-bold">Término</label>
            <p className="m-0">{ formatDate(access.date_end) }</p>
          </div>
        </div>

        <div className="form-group">
          <label className="form-label font-weight-bold">Tipo</label>

          <p>
            {
              access.AccessType && access.AccessType.name ?
                access.AccessType.name
              :
                '--'
            }
          </p>
        </div>

        <div className="form-group">
          <label className="form-label font-weight-bold">Descripción</label>
          <p>{ access.description ? access.description : 'Sin descripción'}</p>
        </div>

        <div className="form-group">
          <label className="form-label font-weight-bold">
            <span className="mr-2">Usuario</span>
          </label>

          <p>
            <Link to={`/members/${access.user_id}`}>{ access && access.User ? access.User.name : null }</Link>
          </p>
        </div>

        <div className="form-group">
          <label className="form-label font-weight-bold">
            <span className="mr-2">Puerta</span>
          </label>

          <p>
            <Link to={`/doors/${access.door_id}`}>{ access && access.Door ? access.Door.name : null }</Link>
          </p>
        </div>
        {console.log(access.Door.httpEnabled)}
        {
          access && access.Door && access.Door.httpEnabled ?
            <CheckFeatures feature="OPEN_DOOR">
              <div className="d-none d-lg-block">
                <OpenDoorButton access={access} />
              </div>
            </CheckFeatures>
          : null
        }
      </div>
    </div>
  )
}

function EntriesCard({ access }){
  const query = useQuery(`entries?AccessId=${access.id}&sort=created_at-desc&limit=10`, { enabled: !!access.id })
  const entries = query.data && query.data.result || []

  let content = (
    <div className="text-center my-4 py-4">
      <Spinner />
    </div>
  )

  if( !query.isLoading ){
    if( entries.length ){
      content = (
        <table className="table table-stripped table-hover m-0" style={{ fontSize: '0.9em' }}>
          <thead>
            <tr>
              <th scope="row" className="table-tr-th">#</th>
              <th scope="row" className="table-tr-th">Fecha y hora</th>
              <th scope="row" className="table-tr-th">Origen</th>
            </tr>
          </thead>
          <tbody>

            {
              entries.map(item => (
                <tr key={item.id}>
                  <td className="table-tr-td">
                    <Link style={{ fontFamily: 'monospace' }} to={"/entries/" + item.id}>#{item.id}</Link>
                  </td>

                  <td className="table-tr-td">
                    {formatDate(item.created_at)}
                  </td>

                  <td className="table-tr-td">
                    {item.access_type}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      )
    }else{
      content = (
        <div className="text-muted text-center py-4 my-4">No se encontraron usos para este permiso</div>
      )
    }
  }

  return (
    <div className="card shadow-sm mb-3">
      <div className="card-header">Últimos usos del permiso</div>
      { content }
    </div>
  )
}

function CommentItem({ comment }){
  const query = useQuery(`comments/${comment.id}/media`)

  let media = null;

  if( !query.isLoading && query.data && query.data.result && query.data.result.length ){
    media = (
      <div>
        {
          query.data.result.filter(x => !!x.media_url).map(item => (
            <div key={item.id} className="pt-2">
              <img width="50%" src={item.media_url} alt="" />
            </div>
          ))
        }
      </div>
    )
  }

  return (
    <div
      className="bg-light p-3 mb-2 border rounded"
      style={{ fontSize: '.9em' }}
    >
      <div className="d-flex justify-content-between">
        <strong>{comment.author_name}</strong>
        <span>{formatDate(comment.created_at)}</span>
      </div>
      <div>
        {
          media ? media : comment.body
        }
      </div>
    </div>
  )
}

function CommentsCard({ access }){
  const { isLoading, isSuccess, isError, data, error } = useQuery(`comments?AccessId=${access.id}&limit=5`, { enabled: !!access.id })

  let content

  if( isLoading ){
    content = (
      <div className="text-center my-4 py-4">
        <Spinner />
      </div>
    )
  }

  if( !isLoading ){
    if( data && data.result && data.result.length ){
      content = (
        <div className="card-body pb-2">
          {
            data.result.map(item => <CommentItem key={item.id} comment={item} />)
          }
        </div>
      )
    }else{
      content = (
        <div className="text-muted text-center py-4 my-4">No se encontraron observaciones para este permiso</div>
      )
    }
  }

  return (
    <div className="card shadow-sm mb-2">
      <div className="card-header">Observaciones</div>
      { content }
    </div>
  )
}

export default function AccessDetailPage(){
  const params = useParams()
  const CheckFeatures = useFeatureChecker()
  const navigate = useNavigate()
  const notifications = useNotifications()
  const [ position, setPosition ] = useState(null)

  const query = useQuery(`doors/access?AccessId=${params.AccessId}`)
  const currentAccess = query.data && query.data.result && query.data.result[0]

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setPosition({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          })
        },
        (error) => {
          //console.log(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 15000,
          maximumAge: 0
        }
      );
    }
  }, position)

  useEffect(() => {
    if( !query.isLoading && !currentAccess ){
      notifications.warning('El permiso no existe')
      navigate(`/permissions`)
    }
  }, [currentAccess, query.isLoading])

  return (
    <Container>
      <Row>
        <Header title="Detalle de permiso" items={[
          { label: "Listado de permisos", to: "/permissions" },
          { label: "Detalle de permiso" },
        ]}>
          <HeaderActions>
            <CheckFeatures feature="UPDATE_ACCESS">
              <Link
                to={`/permissions/${params.AccessId}/edit`}
                className="btn btn-primary mr-2"
              >Editar permiso</Link>
            </CheckFeatures>

            {
              currentAccess && currentAccess.id ?
                <CheckFeatures feature="UPDATE_ACCESS">
                  {
                    currentAccess.deleted_at ?
                      <EnableButton
                        label="Habilitar permiso"
                        confirmMessage="¿Está seguro que desea habilitar el permiso?"
                        successMessage="Permiso habilitado correctamente"
                        post={`doors/access/${params.AccessId}/enable`}
                        invalidate="doors/access"
                        refetch={query.refetch}
                      />
                    :
                      <DisableButton
                        label="Deshabilitar permiso"
                        confirmMessage="¿Está seguro que desea deshabilitar el permiso?"
                        successMessage="Permiso deshabilitado correctamente"
                        post={`doors/access/${params.AccessId}/disable`}
                        invalidate="doors/access"
                        refetch={query.refetch}
                      />
                  }
                </CheckFeatures>
              : null
            }
          </HeaderActions>
        </Header>
      </Row>

      <Row className="mb-4 pb-4">
        <div className="col-12 col-lg-6 pr-0">
          <DetailCard
            access={currentAccess || {}}
            isLoading={query.isLoading}
          />
        </div>

        <div className="col-12 col-lg-6">
          <EntriesCard access={currentAccess || {}} />
          <CommentsCard access={currentAccess || {}} />
        </div>
      </Row>

      <CheckFeatures feature="OPEN_DOOR">
        <Row className="d-block d-lg-none mb-4 pb-4">
          <div className="col-12">
            <OpenDoorButton access={currentAccess || {}} position={position}/>
          </div>
        </Row>
      </CheckFeatures>
    </Container>
  )
}

