import React, { useContext } from 'react';
import { CurrentUserContext } from '../../App.js'

export function isAllowed({ user, feature, every, some }){
  if( feature ){
    return user.features.includes(feature)
  }else if( every && every.length ){
    return every.every(item => user.features.includes(item))
  }else if( some && some.length ){
    return some.some(item => user.features.includes(item))
  }else{
    return false
  }
}

function CheckFeatures({ children, ...props }){
  const allowed = isAllowed(props);

  if( children.length ){
    return allowed ?
      children.find(x => x.type === AllowedFeature) :
      children.find(x => x.type === DeniedFeature)
  }else{
    return (
      <>
        { allowed ? children : null }
      </>
    )
  }
}

export function AllowedFeature({ children }){
  return children
}

export function DeniedFeature({ children }){
  return children
}

export default function useFeatureChecker(){
  const user = useContext(CurrentUserContext)

  if( !user ){
    return () => (<></>)
  }

  return function(props){
    return (
      <CheckFeatures user={user} {...props} />
    )
  }
}
