import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import Header, { HeaderActions } from "../shared/Header.js"
import Spinner from '../shared/Spinner.js';

import useFeatureChecker from '../shared/FeatureChecker.js'


export default function ManualDetailPage(){
  
  return (
    <Container>
      <Row>
        <Header title="Instrucciones de uso"
        >
         
        </Header>
      </Row>

      <Row className="mb-2">
       
      </Row>

     
    </Container>
  )
}

