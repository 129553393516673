import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useThrottleCallback } from '@react-hook/throttle'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export function Searchbox({ onSearch, ...props }){
  const [ searchTerm, setSearchTerm ] = useState(props.value || '')
  const placeholder = props.placeholder || 'Buscar...'

  useEffect(useThrottleCallback(() => onSearch(searchTerm), 2), [searchTerm])

  return (
    <div
      className={ `input-group ${props.className ? props.className : ''}` }
    >
      <span className="position-absolute d-inline-block fixed-top m-2" style={{ width: '20px' }}>
        <FontAwesomeIcon
          icon={faSearch}
          className="aform-control-feedback text-muted"
        />
      </span>

      <input
        className={`form-control ${props.error ? 'is-invalid': 'rounded'}`}
        style={{ paddingLeft: '30px', ...(props.inputStyle || {}) }}
        placeholder={ placeholder }
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyUp={(e) => {
          if( props.onKeyUp ){
            props.onKeyUp(e)
          }
        }}
      />
      <div className="invalid-feedback">{props.error}</div>
    </div> 
  )
}

export default function useSearch(onSearch, props = {}){
  return <Searchbox onSearch={onSearch} {...props} />
}

export function useAddressSearch({ defaultValue, onChange,defaultError }){
  const queryClient = useQueryClient()
  const [ address, setAddress ] = useState(defaultValue || '')

  const query = useQuery(
    ['addressSearch', address],
    async() => {
      const res = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`)
      if( res.ok){
        return res.json()
      }
    },
    { enabled: !!address, refetchOnWindowFocus: false }
  )

  useEffect(() => {
    if( query.data && query.data.length ){
      onChange({
        address,
        latitude: parseFloat(query.data[0].lat),
        longitude: parseFloat(query.data[0].lon),
      })
    }else{
      onChange(null)
    }
  }, [query.data])

  return useSearch(q => setAddress(q), {
    placeholder: 'Buscar dirección',
    value: address,
    error: defaultError.address
  })
}

