import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Container } from '../layout/AppLayout.js';

import { HTTPWidget } from '../shared/Widgets.js';

import { toQueryString } from '../../utils.js';
import useFeatureChecker, { isAllowed } from '../shared/FeatureChecker.js';
import Header from '../shared/Header.js';
import { CurrentUserContext } from '../../App.js';

import GafanaCard from './grafana.js';
import MapCard from './map/map.js';

function MetricsList({ metrics }) {
  metrics = metrics.sort((a, b) => (a.sort < b.sort) ? -1 : 1)

  return (
    <React.Fragment>
      {
        metrics.map(item => (
          <React.Fragment key={item.id}>
            {item.type === "HTTP" ? <HTTPWidget metric={item} /> : null}
          </React.Fragment>
        ))
      }
    </React.Fragment>
  )
}


function GrafanaList({ metrics }) {
  var leftMetrics = metrics.filter(x => x.column === 0)
  var rightMetrics = metrics.filter(x => x.column === 1)

  leftMetrics = leftMetrics.sort((a, b) => (a.sort < b.sort) ? -1 : 1)
  rightMetrics = rightMetrics.sort((a, b) => (a.sort < b.sort) ? -1 : 1)
  return (
    <React.Fragment>
      <div className="row pb-4">
        <div className="col-12 col-md-6">
          {
            leftMetrics.map(item => (
              <React.Fragment key={item.id}>
                {item.type === "Grafana" ? <GafanaCard metric={item} /> : null}
              </React.Fragment>
            ))
          }

        </div>

        <div className="col-12 col-md-6">
          {

            rightMetrics.map(item => (
              <React.Fragment key={item.id}>
                {item.type === "Grafana" ? <GafanaCard metric={item} /> : null}
              </React.Fragment>
            ))

          }
        </div>
      </div>
    </React.Fragment>
  )
}



export default function DashboardPage() {
  const CheckFeatures = useFeatureChecker()
  const [currentFilters, setCurrentFilters] = useState({})

  const query = useQuery(`metrics?${toQueryString(currentFilters)}`, {
    keepPreviousData: true,
  })
  const metrics = query.data && query.data.result || []
  const leftMetrics = metrics.filter(x => x.column === 0)
  const rightMetrics = metrics.filter(x => x.column === 1)
  const mapMetrics = metrics.filter(x => x.type === "Map") || null
  const grafanaMetrics = metrics.filter(x => x.type === "Grafana") || null
  const currentUser = useContext(CurrentUserContext)
  const navigate = useNavigate()

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "LIST_DASHBOARD"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])
  return (
    <Container>
      <div className="row">
        <Header
          title={`Dashboard`}
          items={[
            { label: "Resumen de general" },
          ]}
        />
      </div>

      <div className="row pb-4">
        <div className="col-12 col-md-6">
          <MetricsList metrics={leftMetrics} />
        </div>

        <div className="col-12 col-md-6">
          <MetricsList metrics={rightMetrics} />
        </div>
      </div>

      {
        mapMetrics.length == 0 ? null : <MapCard setting={mapMetrics[0].settings} />
      }

      {
        grafanaMetrics.length == 0 ? null : <GrafanaList metrics={grafanaMetrics} />
      }
    </Container>
  );
}

