import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { Container } from '../layout/AppLayout.js'

import { useMutationClient } from '../shared/hooks.js'
import { useNotifications } from '../shared/Notifications.js'

import Header from "../shared/Header.js";
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js'

import MembersListSubmenu from '../members/MembersListSubmenu.js';
import GroupForm from './GroupForm.js'
import Spinner from '../shared/Spinner.js';

export default function EditGroup() {
  const params = useParams()
  const mutationClient = useMutationClient()
  const navigate = useNavigate()
  const notifications = useNotifications()
  const queryClient = useQueryClient()
  const CheckFeatures = useFeatureChecker()

  const query = useQuery(`groups/${params.GroupId}`)
  const currentGroup = query.data && query.data.result

  const mutation = useMutation(
    data => mutationClient.put(`groups/${params.GroupId}`, data), {
    onSuccess: (data) => {
      if( data.status === 200 ){
        notifications.success('Grupo actualizado correctamente')
        navigate(`/members/groups/${params.GroupId}`)
        queryClient.invalidateQueries(`groups`)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al actualizar el grupo')
    }
  })

  return (
    <Container>
      <div className="row">
        <Header title="Detalle de grupo - Editar grupo" items={[
          { label: `Listado de grupos`, to: "/members/groups" },
          { label: `Detalle de grupo`, to: `/members/groups/${params.GroupId}` },
          { label: `Editar grupo` },
        ]} />
      </div>

      <div className="row">
        <MembersListSubmenu />
      </div>

      <div className="row">
        <div className="col-6">
          <div className="card shadow-sm">
            <div className="card-header">Editar grupo</div>
            <CheckFeatures feature="UPDATE_GROUP">
              <AllowedFeature>
                <div className="card-body">
                {
                  query.isLoading ?
                    <div className="text-center">
                      <Spinner />
                    </div>
                  :
                    <GroupForm
                      group={currentGroup || {}}
                      save={data => mutation.mutate(data)}
                      isLoading={mutation.isLoading}
                    />
                }
                </div>
              </AllowedFeature>
              <DeniedFeature>
                <Navigate to={'/error?error=403'}/>
              </DeniedFeature>
            
            </CheckFeatures>
          </div>
        </div>
      </div>
    </Container>
  )
}
