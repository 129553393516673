import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faVectorSquare } from '@fortawesome/free-solid-svg-icons';

import TableList from '../shared/TableList.js';

import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js'

export default function ZonesTable({ zones, small = false, onZoneSelection, mode }) {
  const CheckFeatures = useFeatureChecker()
  const navigate = useNavigate()

  if (!zones.length) {
    return <div className="text-center text-muted py-4 my-4">No se encontraron zonas para los filtros especificados</div>
  }

  if( mode === "CardList" ){
    return (
      <>
        {
          zones.map(item => (
            <div
              key={item.id}
              onClick={() => navigate(`/zones/${item.id}`)}
              className="card mb-2 shadow"
            >
              <div className="card-body d-flex w-100 justify-content-between align-items-center">
                <div>
                  <p className="mb-1">
                    <span className="d-inline-block mr-1" style={{ width: '20px' }}>
                      <FontAwesomeIcon icon={faVectorSquare} />
                    </span>
                    <span>{ item.name }</span>
                  </p>

                  <p className="m-0">
                    <span className="d-inline-block mr-1 text-center" style={{ width: '20px' }}>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </span>
                    <span className="text-muted">{ item.address }</span>
                  </p>
                </div>
              </div>
            </div>
          ))
        }
      </>
    )
  }else{
    return (
      <TableList>
        <TableList.Header>
          <TableList.HeaderItem value="Nombre" />
          <TableList.HeaderItem value="Instalación" width={300} />
          <TableList.HeaderItem value="Dirección" width={400} />
        </TableList.Header>

        <TableList.Body>
          {
            zones.map(item => (
              <TableList.Row key={item.id}>
                <TableList.Column>
                  <CheckFeatures feature='DETAIL_ZONE'>
                    <AllowedFeature>
                      <Link className="py-3" to={"/zones/" + item.id}>{item.name}</Link>
                    </AllowedFeature>
                    <DeniedFeature>
                      {item.name}
                    </DeniedFeature>
                  </CheckFeatures>
                </TableList.Column>

                <TableList.Column>
                  <CheckFeatures feature='DETAIL_FACILITY'>
                    <AllowedFeature>
                      <Link className="py-3" to={"/facilities/" + item.facility_id}>{item.facility_name}</Link>
                    </AllowedFeature>
                    <DeniedFeature>
                      {item.facility_name}
                    </DeniedFeature>
                  </CheckFeatures>
                </TableList.Column>

                <TableList.Column value={item.address}/>
              </TableList.Row>
            ))
          }
        </TableList.Body>
      </TableList>
    )
  }
}
