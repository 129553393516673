import React, { useContext } from 'react';
import { useMutation } from 'react-query'
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { CurrentCompanyContext } from '../../App.js'
import { Container } from '../layout/AppLayout.js'

import { useMutationClient } from '../shared/hooks.js'
import { useNotifications } from '../shared/Notifications.js'

import Header from "../shared/Header.js";

import MembersListSubmenu from '../members/MembersListSubmenu.js'
import GroupForm from './GroupForm.js'
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js'


export default function NewGroupPage() {
  const params = useParams()
  const mutationClient = useMutationClient()
  const navigate = useNavigate()
  const notifications = useNotifications()
  const currentCompany = useContext(CurrentCompanyContext)
  const CheckFeatures = useFeatureChecker()

  const mutation = useMutation(
    data => mutationClient.post(`groups`, { ...data, company_id: currentCompany && currentCompany.id }), {
    onSuccess: (data) => {
      if( data.status === 200 ){
        notifications.success('Grupo creado correctamente')
        navigate(`/members/groups`)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al crear el group')
    }
  })

  return (
    <Container>
      <div className="row">
        <Header title="Crear grupo" items={[
          { label: `Listado de grupos`, to: "/members/groups" },
          { label: `Crear grupo` },
        ]} />
      </div>

      <div className="row">
        <MembersListSubmenu />
      </div>

      <div className="row">
        <div className="col-6">
          <div className="card shadow-sm">
            <div className="card-header">Nuevo grupo</div>
            <CheckFeatures feature="CREATE_GROUP">
              <AllowedFeature>
                <div className="card-body">
                  <GroupForm
                    group={{}}
                    save={data => mutation.mutate(data)}
                    isLoading={mutation.isLoading}
                  />
                </div>
              </AllowedFeature>
              <DeniedFeature>
                <Navigate to={'/error?error=403'}/>
              </DeniedFeature>
            </CheckFeatures>
          </div>
        </div>
      </div>
    </Container>
  )
}
