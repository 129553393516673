import React, { useEffect, useState, useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { CurrentUserContext } from '../../App.js';

import useFeatureChecker, { isAllowed, AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';
import { SensorTypeSelector, ZoneSelector } from '../shared/Selectors.js';

function CustomFields({ currentSensor, defaultValue, isLoading, onChange }) {
  const [data, setData] = useState(defaultValue || {})
  const currentUser = useContext(CurrentUserContext)

  const query = useQuery(`sensors/types?SensorTypeId=${currentSensor.sensor_type_id}`, {
    enabled: (currentSensor && !!currentSensor.sensor_type_id)
  })
  const fields = query.data
    && query.data.result
    && query.data.result.length
    && query.data.result[0].custom_fields

  useEffect(() => {
    onChange(data)
  }, [data])

  if (fields && fields.length) {
    return (
      <div>
        {
          fields.map(field => (
            <div key={field.name} className={`form-group ${currentUser &&!isAllowed({user: currentUser,feature: "LIST_SENSORS_CUSTOM_FIELD" })?'d-none' : ''}`}>
              <label>{field.label}</label>

              <input
                className='form-control'
                type={field.type ? field.type : 'text'}
                min={field.min ? field.min : null}
                max={field.max ? field.max : null}
                disabled={isLoading || query.isLoading}
                value={data[field.name] ? data[field.name] : ''}
                onChange={e => {
                  const value = e.target.value
                  setData(prev => ({ ...prev, [field.name]: value }))
                }}
              />
            </div>
          ))
        }
      </div>
    )
  }

  return null
}

export default function SensorForm({ sensor, isLoading, save }) {
  const CheckFeatures = useFeatureChecker()
  const params = useParams()

  const [sensor_type_id, setSensorTypeId] = useState(sensor.sensor_type_id || '')
  const [device_id, setDeviceId] = useState(sensor.device_id || '')
  const [name, setName] = useState(sensor.name || '')
  const [zone_id, setZoneId] = useState(sensor.zone_id || params.ZoneId || '')
  const [errors, setErrors] = useState({})

  const [metadata, setMetadata] = useState(sensor.metadata || {});
  const currentUser = useContext(CurrentUserContext)

  function validate(e) {
    e.preventDefault()

    // TODO: Validate required data and format
    const validations = []

    if (!name) {
      validations.push(['name', 'Nombre es requerido'])
    }

    if (!device_id) {
      validations.push(['device_id', 'ID dispositivo es requerido'])
    }

    if (!sensor_type_id) {
      validations.push(['sensor_type_id', 'Tipo es requerido'])
    }

    if (validations.length > 0) {
      setErrors(validations.reduce((acc, item) => ({ ...acc, [item[0]]: item[1] }), {}))
      return
    } else {
      setErrors([])

      const data = {
        name,
        sensor_type_id: sensor_type_id === '' ? null : sensor_type_id,
        device_id: device_id === '' ? null : device_id,
        zone_id: zone_id === '' ? null : zone_id,
        metadata
      }

      if (sensor.id) {
        data.id = sensor.id
      }

      save(data)
    }
  }

  return (
    <form onSubmit={validate} noValidate>
      <div className="form-group">
        <label>Nombre</label>
        <input
          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
          disabled={isLoading}
        />
        <div className="invalid-feedback">{errors.name}</div>
      </div>

      <div className="form-group">
        <label>Tipo</label>
        <CheckFeatures every={["LIST_SENSORS_TYPE","UPDATE_SENSORS_TYPE"]}>
          <AllowedFeature>
            <SensorTypeSelector
              onChange={x => setSensorTypeId(x)}
              placeholder="Seleccione el tipo de sensor"
              defaultValue={sensor_type_id}
              disabled={isLoading}
              hasError={!!errors.sensor_type_id}
            />
          </AllowedFeature>
          <DeniedFeature>
            <SensorTypeSelector
              onChange={x => setSensorTypeId(x)}
              placeholder="Seleccione el tipo de sensor"
              defaultValue={sensor_type_id}
              disabled={true}
              hasError={!!errors.sensor_type_id}
            />
          </DeniedFeature>
        </CheckFeatures>
        <div className="invalid-feedback">{errors.sensor_type_id}</div>
      </div>

      <div className="form-group">
        <label>Zona</label>
        <CheckFeatures  every={["LIST_ZONES","UPDATE_SENSORS_ZONE"]}>
          <AllowedFeature>
            <ZoneSelector
              disabled={isLoading}
              onChange={zone_id => setZoneId(zone_id)}
              placeholder="Seleccione la zona"
              defaultValue={zone_id}
            />
          </AllowedFeature>
          <DeniedFeature>
            <ZoneSelector
              disabled={true}
              onChange={zone_id => setZoneId(zone_id)}
              placeholder="Seleccione la zona"
              defaultValue={zone_id}
            />
          </DeniedFeature>
        </CheckFeatures>
        <div className="invalid-feedback">{errors.zone}</div>
      </div>

      <div className="form-group">
        <label>Device Id</label>
            <input
              className={`form-control ${errors.device_id ? 'is-invalid' : ''} `}
              type="text"
              value={device_id}
              onChange={e => setDeviceId(e.target.value)}
              disabled={currentUser &&!isAllowed({user: currentUser,feature: "UPDATE_SENSORS_DEVICE_ID" })}
            />
         

        <div className="invalid-feedback">{errors.device_id}</div>
      </div>

      <CustomFields
        currentSensor={sensor}
        defaultValue={metadata}
        onChange={data => setMetadata(data)}
        isLoading={isLoading}
      />

      <div className="form-group pt-3" >
        <button
          className={`btn btn-primary  ${isLoading ? 'loading' : ''}`}
          size="lg"
          disabled={isLoading}
        >
          <span>Guardar</span>
          <div className='spinner-border' role='status'></div>
        </button>
      </div>
    </form>
  )
}
