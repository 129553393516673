import React, { useState, useEffect, useContext } from 'react';
import TableList from '../shared/TableList.js';
import { Link } from 'react-router-dom';
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';

import './EventsTable.css'
import translateEvent from './translate.js';

const formatEventDate = (date) => {
  const d = new Date(Date.parse(date))
  const region = "es-CL"
  return `${d.toLocaleDateString(region)} ${d.toLocaleTimeString(region)}`
}


export const EventLink = ({ event }) => {
  const CheckFeatures = useFeatureChecker()
  const DefaultLink = <> {formatEventDate(event.captured_at)} </>;
  const MediaLink = (
    <CheckFeatures feature='DETAIL_MEDIA'>
      <AllowedFeature>
        <Link to={`/media/${event.value.events_media_id}`}>
          {formatEventDate(event.captured_at)}
        </Link>
      </AllowedFeature>
      <DeniedFeature>
        {formatEventDate(event.captured_at)}
      </DeniedFeature>
    </CheckFeatures>
    
  );

  if (event.value.events_media_id) {
    return MediaLink;
  }
  else {
    return DefaultLink;
  }
}

const EventInfo = ({ event }) => {
  const CheckFeatures = useFeatureChecker()
  const Default = () => <></>;

  const SensorInfo = ({ value, door }) => {
    return (
      <>
        <CheckFeatures feature='DETAIL_DOOR'>
          <AllowedFeature>
            {value} - <Link to={`/doors/${door.id}`}> {door.name} </Link>
          </AllowedFeature>
          <DeniedFeature>
            {value} - {door.name}
          </DeniedFeature>
        </CheckFeatures>
      </>
    );
  };

  const SensorLink = ({ zone_name, sensor }) => {
    return (
      <CheckFeatures feature ='DETAIL_SENSORS'>
        <AllowedFeature>
          <Link to={`/sensors/${sensor.id}`}>
            {sensor.name} - {zone_name}
          </Link>
        </AllowedFeature>
        <DeniedFeature>
          {sensor.name} - {zone_name}
        </DeniedFeature>
      </CheckFeatures>
     
    );
  }

  const DoorLink = ({ door }) => {
    return (
      <CheckFeatures feature='DETAIL_DOOR'>
        <AllowedFeature>
          <Link to={`/doors/${door.id}`}>
            {door.name}
          </Link>
        </AllowedFeature>
        <DeniedFeature>
          {door.name}
        </DeniedFeature>
      </CheckFeatures>
     
    );
  };

  if (! "type" in event) {
    return <Default />;
  }
  switch (event.type) {
    case "DiskUsageChanged":
      return event.value.door
        ? (
          <> {event.value.disk_usage} % - <DoorLink door={event.value.door} /> </>
        )
        : <Default />;

    case "MagneticOpen":
    case "MagneticClosed":
    case "SmokeStarted":
    case "SmokeEnded":
    case "NoiseStarted":
    case "NoiseEnded":
    case "RecoveredEnergy":
    case "LostEnergy":
      return event.value.door
        ? <DoorLink door={event.value.door} />
        : <Default />;

    case "PersonDetected":
    case "CameraMotion":
    case "CarDetected":
      return event.value.sensor ? <SensorLink zone_name={event.zone_name} sensor={event.value.sensor} /> : <Default />;

    case "DiskUsageChanged":
      const usage = event.value.disk_usage;
      return usage ? `${usage} %` : <Default />;

    case "HumidityChanged":
      return event.value.humidity && event.value.door
        ? (<SensorInfo
          value={`${event.value.humidity.toFixed(2)} %`}
          door={event.value.door} />)
        : <Default />

    case "TemperatureChanged":
      return event.value.temperature && event.value.door
        ? (<SensorInfo
          value={`${event.value.temperature.toFixed(2)} C`}
          door={event.value.door} />)
        : <Default />

    case "PressureChanged":
      return event.value.pressure && event.value.door
        ? (<SensorInfo
          value={`${event.value.pressure.toFixed(2)} hPa`}
          door={event.value.door} />)
        : <Default />

    case "RecoveredInternetConnection":

    case "LostInternetConnection":
      if (event.value.door) {
        return <DoorLink door={event.value.door} />
      }

      if (event.value.sensor) {
        return <SensorLink sensor={event.value.sensor} />;
      }

      return <Default />;

    case "DoorActionExit":
      if (event.value.door) {
        return <DoorLink door={event.value.door} />
      }
    case "DoorActionEnter":
      if (event.value.door) {
        return <DoorLink door={event.value.door} />
      }

    case "DoorActionNoAction":
      if (event.value.door) {
        return <DoorLink door={event.value.door} />
      }

    case "AuthorizedRfid":
      if (event.value.door) {
        return (
          <> {event.value.data} <DoorLink door={event.value.door} /> </>
        )
      }
    case "AuthorizedHttp":
      if (event.value.door) {
        return (
          <> {event.value.data} <DoorLink door={event.value.door} /> </>
        )
      }
    case "AuthorizedBle":
      if (event.value.door) {
        return (
          <>{event.value.data} <DoorLink door={event.value.door} /> </>
        )
      }
    case "AuthorizedQr":
      if (event.value.door) {
        return (
          <> {event.value.data} <DoorLink door={event.value.door} /> </>
        )
      }
    case "UnauthorizedQr":
      if (event.value.door) {
        return (
          <> {event.value.data} <DoorLink door={event.value.door} /> </>
        )
      }
    case "UnauthorizedBle":
      if (event.value.door) {
        return (
          <> {event.value.data} <DoorLink door={event.value.door} /> </>
        )
      }
    case "UnauthorizedHttp":
      if (event.value.door) {
        return (
          <> {event.value.data} <DoorLink door={event.value.door} /> </>
        )
      }
    case "UnauthorizedRfid":
      if (event.value.door) {
        return (
          <> {event.value.data} <DoorLink door={event.value.door} /> </>
        )
      }
    case "ActiveElectromagnet":
      if (event.value.door) {
        return (
          <> {event.value.electromagnet} <DoorLink door={event.value.door} /> </>
        )
      }
    case "DeactivateElectromagnet":
      if (event.value.door) {
        return (
          <> {event.value.electromagnet} <DoorLink door={event.value.door} /> </>
        )
      }
    case "AuthorizedButton":
      if (event.value.door) {
        return (
          <> {event.value.data} <DoorLink door={event.value.door} /> </>
        )
      }
    default:
      return <Default />;
  }
};


export default function EventsTable({ events }) {
  const CheckFeatures = useFeatureChecker()

  if (events && events.length < 1) {
    return (
      <div className="text-center text-muted py-4 my-4">
        Sin resultados para los filtros especificados
      </div>
    )
  }

  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="Fecha" />
        <TableList.HeaderItem value="Tipo" />
        <TableList.HeaderItem value="Zona" />
        <TableList.HeaderItem />
      </TableList.Header>

      <TableList.Body>
        {events
          ? (events.map(item => (
            <TableList.Row key={item.id}>
              <TableList.Column>
                <EventLink event={item} />
              </TableList.Column>

              <TableList.Column>
                {translateEvent(item.type)}
              </TableList.Column>

              <TableList.Column>
                <CheckFeatures feature='DETAIL_ZONE'>
                  <AllowedFeature>
                    <Link to={`/zones/${item.zone_id}`}>
                      {item.zone_name}
                    </Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    {item.zone_name}
                  </DeniedFeature>
                </CheckFeatures>
              </TableList.Column>

              <TableList.Column>
                <EventInfo event={item}>
                </EventInfo>
              </TableList.Column>
            </TableList.Row>
          ))
          )
          : <></>

        }
      </TableList.Body>
    </TableList>
  )
}
