import React from 'react';
import { useQuery } from 'react-query';
import { toQueryString } from '../../utils.js';

import Multiselect from 'react-widgets/Multiselect';
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';

export function BaseSelector({ defaultValue, items, onChange, disabled, placeholder, hasError }){
  disabled = disabled || false

  return (
    <select
      className={`form-control ${hasError ? 'is-invalid' : ''}`}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      value={ defaultValue || '' }
    >
      <option value="">{ placeholder || 'Seleccione...' }</option>

      {
        items.map(item => (
          <option key={item[0]} value={item[0]}>{ item[1] }</option>
        ))
      }
    </select>
  )
}

export function ZoneSelector(props){
  const query = useQuery(`zones`)

  const items = query.data
    && query.data.result
    && query.data.result.map(item => ([item.id, item.name]))
    || []

  return (
    <BaseSelector
      {...props}
      items={items}
      onChange={id => props.onChange(parseInt(id))}
      disabled={query.isLoading || !!props.disabled}
    />
  )
}

export function SensorTypeSelector(props){
  const query = useQuery(`sensors/types`)

  const items = query.data
    && query.data.result
    && query.data.result.map(item => ([item.id, item.label]))
    || []

  return (
    <BaseSelector
      {...props}
      items={items}
      onChange={id => props.onChange(parseInt(id))}
      disabled={query.isLoading || !!props.disabled}
    />
  )
}

export function DoorTypeSelector(props){
  const query = useQuery(`doors/types`)

  const items = query.data
    && query.data.result
    && query.data.result.map(item => ([item.id, item.name]))
    || []

  return (
    <BaseSelector
      {...props}
      items={items}
      onChange={id => props.onChange(parseInt(id))}
      disabled={query.isLoading || !!props.disabled}
    />
  )
}

export function FacilityTypeSelector({ filters = {}, ...props }){
  const query = useQuery(`facilities/types?${toQueryString(filters)}`)

  const items = query.data
    && query.data.result
    && query.data.result.map(item => ([item.id, item.name]))
    || []

  return (
    <BaseSelector
      {...props}
      items={items}
      onChange={id => props.onChange(parseInt(id))}
      disabled={query.isLoading || !!props.disabled}
    />
  )
}

export function FacilitySelector(props){
  const query = useQuery(`facilities`)

  const items = query.data
    && query.data.result
    && query.data.result.map(item => ([item.id, item.name]))
    || []

  return (
    <BaseSelector
      {...props}
      items={items}
      onChange={id => props.onChange(parseInt(id))}
      disabled={query.isLoading || !!props.disabled}
    />
  )
}

export function IndustryTypeSelector(props){
  const query = useQuery(`industries`)

  const items = query.data
    && query.data.result
    && query.data.result.map(item => ([item.id, item.name]))
    || []

  return (
    <BaseSelector
      {...props}
      items={items}
      onChange={id => props.onChange(parseInt(id))}
      disabled={query.isLoading || !!props.disabled}
    />
  )
}

export function DateSelector(props){
  const query = useQuery(`doors/access/day`)

  const items = query.data
    && query.data.result
    && query.data.result.map(item => ([item.id, item.name]))
    || []

  return (
    <BaseSelector
      {...props}
      items={items}
      onChange={id => props.onChange(parseInt(id))}
      disabled={!!props.disabled}
    />
  )
}

export function TimeSelector(props){
  const query = useQuery(`doors/access/time`)

  const items = query.data
    && query.data.result
    && query.data.result.map(item => ([item.id, item.name]))
    || []

  return (
    <BaseSelector
      {...props}
      items={items}
      onChange={id => props.onChange(parseInt(id))}
      disabled={!!props.disabled}
    />
  )
}

export function AccessTypeSelector(props){
  const query = useQuery(`doors/access/types`)

  const items = query.data
    && query.data.result
    && query.data.result.map(item => ([item.id, item.name]))
    || []
  return (
    <BaseSelector
      {...props}
      items={items}
      onChange={id => props.onChange(parseInt(id))}
      disabled={query.isLoading || !!props.disabled}
    />
  )
}

export function RoleSelector(props){
  const CheckFeatures = useFeatureChecker()
  const query = useQuery(`roles`)

  const items = query.data
    && query.data.result
    && query.data.result.map(item => ([item.id, item.name]))
    || []

  return (
    <CheckFeatures feature="LIST_ROLES">
      <AllowedFeature>
        <BaseSelector
          {...props}
          placeholder="Seleccione un rol"
          items={items}
          onChange={id => props.onChange(parseInt(id))}
          disabled={query.isLoading || !!props.disabled}
        />
      </AllowedFeature>
      <DeniedFeature>
        <BaseSelector
          {...props}
          placeholder="Seleccione un rol"
          items={items}
          onChange={id => props.onChange(parseInt(id))}
          disabled={true}
        />
      </DeniedFeature>
    </CheckFeatures>

    


  )
}

export function SectionSelector({ filters = {}, ...props }){
  const query = useQuery(`sections?${toQueryString(filters)}`)

  const items = query.data
    && query.data.result
    && query.data.result.map(item => ([item.id, item.name]))
    || []

  return (
    <BaseSelector
      {...props}
      items={items}
      onChange={id => props.onChange(parseInt(id))}
      disabled={query.isLoading || !!props.disabled}
    />
  )
}

export function MaintanceSelector (props){
  const query = useQuery(`devicereport/types?type=maintance`)
  const items = query.data
    && query.data.result
    && query.data.result.map(item => ([item.id, item.name]))
    || []

  return (
    <BaseSelector
      {...props}
      items={items}
      onChange={id => props.onChange(parseInt(id))}
      disabled={query.isLoading || !!props.disabled}
    />
  )
}

export function FailureSelector (props){
  const query = useQuery(`devicereport/types?type=failure`)
  const items = query.data
    && query.data.result
    && query.data.result.map(item => ([item.id, item.name]))
    || []

  return (
    <BaseSelector
      {...props}
      items={items}
      onChange={id => props.onChange(parseInt(id))}
      disabled={query.isLoading || !!props.disabled}
    />
  )
}


export function AssetsTagsMultiSelector({ filters = {}, ...props }){
  const query = useQuery(`assets/tags?${toQueryString(filters)}`)

  const items = query.data
    && query.data.result
    && query.data.result.map(item => ({id: item.id, label: item.name}))
    || []

  return (
    <Multiselect
      defaultValue={props.defaultValue}
      dataKey="id"
      textField="label"
      data={items}
      onChange={value => props.onChange(value.map(x => x.id))}
    />
  )
}

