import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router'
import { CurrentCompanyContext } from '../../App.js'

import { useMutation, useQueryClient } from 'react-query'
import { useNotifications } from '../shared/Notifications.js'

import { useMutationClient } from '../shared/hooks.js'

import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import DoorForm from "./DoorForm.js";

import Header from "../shared/Header.js"
import { CurrentUserContext } from '../../App.js';
import { isAllowed } from '../shared/FeatureChecker.js';

export default function NewSensorPage() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const mutationClient = useMutationClient()
  const currentCompany = useContext(CurrentCompanyContext)
  const notifications = useNotifications()
  const currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "CREATE_DOOR"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const createDoor = useMutation(
    data => mutationClient.post(`doors`, { ...data, company_id: currentCompany && currentCompany.id }), {
    onSuccess: (data, variables, context) => {
      if( data.status === 200 ){
        notifications.success('Puerta creada correctamente')
        navigate(`/doors/${data.data.result.id}`)
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al crear sensor')
    }
  })

  return (
    <Container>
      <Row>
        <Header title="Crear puerta" items={[
          { label: `Listado de puertas`, to:"/doors"},
          { label: `Crear puerta` },
        ]} />
      </Row>

      <Row className="mb-3">
        <div className="col-12 col-lg-6">
          <Card className="mb-2 shadow-sm">
            <Card.Header>
              <span>Nueva puerta</span>
            </Card.Header>

            <Card.Body>
              <DoorForm
                currentDoor={{}}
                save={data => createDoor.mutate(data)}
                isLoading={createDoor.isLoading}
              />
            </Card.Body>
          </Card>
        </div>
      </Row>
    </Container>
  )
}
