import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {  useNavigate, useParams } from 'react-router-dom';
import { CurrentCompanyContext } from '../../App.js';
import { useMutationClient } from '../shared/hooks.js';

import Header from '../shared/Header.js';
import { useNotifications } from '../shared/Notifications.js';
import Spinner from '../shared/Spinner.js';

import { CurrentUserContext } from '../../App';
import { Container } from '../layout/AppLayout.js';
import useFeatureChecker, { isAllowed, AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';
import { RoleSelector } from '../shared/Selectors.js';
import MemberDetailSubmenu from './MemberDetailSubmenu.js';

function MemberDetailsForm({ member, save, isLoading }){
  const [ currentAreaId, setCurrentAreaId ] = useState(member && member.UsersCompanies && member.UsersCompanies.section_id)
  const [ currentUnitId, setCurrentUnitId ] = useState(member && member.UsersCompanies && member.UsersCompanies.child_section_id)
  const [ currentRoleId, setCurrentRoleId ] = useState(member && member.Role && member.Role.id)
  const [ currentName, setCurrentName ] = useState(member && member.name)

  useEffect(() => {
    if( currentAreaId === null ){
      setCurrentUnitId(null)
    }
  }, [currentAreaId])

  let content

  function validate(e){
    e.preventDefault()

    save({
      id: member.id,
      section_id: currentAreaId,
      child_section_id: currentUnitId,
      role_id: currentRoleId,
      name: currentName
    })
  }

  content = (
    <form onSubmit={validate} noValidate>
      <div className="form-group">
        <label>Nombre completo</label>

        <input
          type="text"
          className="form-control"
          value={currentName}
          onChange={value=>setCurrentName(value.target.value)}
          disabled={isLoading}
          //disabled={true}
        />
      </div>

      <div className="form-group">
        <label>Correo principal</label>

        <input
          type="text"
          className="form-control"
          value={member.email}
          disabled={true}
        />
      </div>

      <div className="form-group">
        <label>Rol</label>

        <RoleSelector
          disabled={isLoading}
          onChange={value => setCurrentRoleId(value)}
          defaultValue={currentRoleId}
        />
      </div>

{/*       <div className="form-group">
        <label>Área2</label>

        <SectionSelector
          placeholder="Seleccione el área"
          defaultValue={currentAreaId}
          disabled={isLoading}
          onChange={AreaId => setCurrentAreaId(AreaId)}
          filters={{ ParentId: 0 }}
        />
      </div>

      <div className="form-group">
        <label>Unidad</label>

        <SectionSelector
          onChange={UnitId => setCurrentUnitId(UnitId) }
          placeholder="Seleccione la unidad"
          defaultValue={currentUnitId}
          disabled={isLoading || !currentAreaId}
          filters={{ ParentId: currentAreaId }}
        />
      </div> */}

      <div className="form-group">
        <button
          className="btn btn-primary"
          disabled={isLoading}
        >
          <span>Guardar</span>
        </button>
      </div>
    </form>
  )

  return (
    <div className="card shadow-sm">
      <div className="card-header">Editar usuario de la compañía</div>
      <div className="card-body">
        { content }
      </div>
    </div>
  )
}

function PasswordDetailForm({currentMember }){
  
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('')
  const mutationClient = useMutationClient()

  const mutation = useMutation(
    data => mutationClient.put(`users/${currentMember.id}/changePassword`, data),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRepeatPassword("")
          setPassword("")
          setSuccessMessage('La contraseña se ha modificado correctamente')
        }
      },
    }
  )
  


  let content
  function handleSubmit(e){
    e.preventDefault()
    setErrorMessage('')

    if( password === '' || repeatPassword === '' ){
      setErrorMessage('La contraseña no puede estar vacía')
      setSuccessMessage('')
      return
    }

    if( password !== repeatPassword ){
      setErrorMessage('Las contraseñas no coinciden')
      setSuccessMessage('')
      return
    }

    mutation.reset()
    mutation.mutate({ password, repeatPassword})
  }

  content = (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label className="font-weight-bold">Nueva contraseña</label>

        <input
          className="form-control"
          type="password"
          onChange={e => setPassword(e.target.value)}
          value={password}
          disabled={mutation.isLoading}
        />
      </div>

      <div className="form-group">
        <label className="font-weight-bold">Confirmar nueva contraseña</label>

        <input
          className="form-control"
          type="password"
          onChange={e => setRepeatPassword(e.target.value)}
          value={repeatPassword}
          disabled={mutation.isLoading}
        />
      </div>

      {
        mutation.isError ?
          <p className="text-danger text-center">Hubo un error al cambiar la contraseña</p>
        : null
      }

      {
        errorMessage !== '' ?
          <p className="text-danger text-center">{errorMessage}</p>
        : null
      }
      {
        successMessage !== '' ?
        <p className="text-center">{successMessage}</p>
        :null
      }
      <div className="form-group text-center">
        <input
          type="submit"
          className="btn btn-primary btn-block"
          value="Cambiar"
          disabled={mutation.isLoading}
        />
      </div>
        
    </form>
  )

  return (
    <div className="card shadow-sm">
      <div className="card-header">Cambio de contraseña</div>
      <div className="card-body">
        { content }
      </div>
    </div>
  )
}


function MemberGroupItem({ currentMember, item }){
  const CheckFeatures = useFeatureChecker()
  const mutationClient = useMutationClient()
  const notifications = useNotifications()
  const queryClient = useQueryClient()

  const [ isSelected, setIsSelected ] = useState(
    !!currentMember.Groups.find(x => x.id === item.id)
  )

  const { company_id } = currentMember.UsersCompanies

  const addGroup = useMutation(
    data => mutationClient.post(`companies/${company_id}/users/${currentMember.id}/groups`, data),
    {
      onSuccess: () => {
        notifications.success('Grupo asociado exitosamente')
        queryClient.invalidateQueries(`companies/${company_id}/users?UserId=${currentMember.id}`)
        setIsSelected(true)
      }
    }
  )

  const removeGroup = useMutation(
    GroupId => mutationClient.delete(`companies/${company_id}/users/${currentMember.id}/groups/${GroupId}`),
    {
      onSuccess: () => {
        notifications.success('Usuario eliminado del grupo exitosamente')
        queryClient.invalidateQueries(`companies/${company_id}/users?UserId=${currentMember.id}`)
        setIsSelected(false)
      }
    }
  )

  return (
    <div key={item.id} className="list-group-item">
      <label className="m-0" style={{ cursor: 'pointer' }}>
          <CheckFeatures every={["LIST_ROLES","UPDATE_USER","UPDATE_USER_GROUP"]}>
          <AllowedFeature>
            <input
              type="checkbox"
              disabled={addGroup.isLoading || removeGroup.isLoading}
              checked={isSelected}
              onChange={(e) => {
                if( e.target.checked ){
                  addGroup.mutate({ GroupId: item.id })
                }else{
                  removeGroup.mutate(item.id)
                }
              }}
            />
          </AllowedFeature>
          <DeniedFeature>
            <input
              type="checkbox"
              disabled={true}
              checked={isSelected}
            />
          </DeniedFeature>
        </CheckFeatures>        
        <span className="ml-2">{item.name}</span>
      </label>
    </div>
  )
}

function MemberGroups({ currentMember }){
  const query = useQuery(`groups`)
  const groups = query.data && query.data.result || []

  return (
    <div className="card shadow-sm">
      <div className="card-header">Grupos</div>
      <div className="">
        {
          query.isLoading ?
            <div className="text-center py-4">
              <Spinner />
            </div>
          :
            <div className="list-group list-group-flush">
              {
                groups.map(item => (
                  <MemberGroupItem
                    key={item.id}
                    currentMember={currentMember}
                    item={item}
                  />
                ))
              }
            </div>
        }
      </div>
    </div>
  )
}

export default function MemberEditPage() {
  const CheckFeatures = useFeatureChecker()
  const params = useParams()
  const queryClient = useQueryClient()
  const currentCompany = useContext(CurrentCompanyContext)
  const navigate = useNavigate()
  const mutationClient = useMutationClient()
  const notifications = useNotifications()
  const user = useContext(CurrentUserContext)

  useEffect(() => {
    if(user){
      const allowed= isAllowed({user: user, feature:"UPDATE_USER"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[user])

  const query = useQuery(`companies/${currentCompany.id}/users?UserId=${params.UserId}`, {
    enabled: !!(currentCompany && currentCompany.id)
  })

  const currentMember = query.data
    && query.data.result
    && query.data.result.length
    && query.data.result[0]

  const memberMutation = useMutation(data => mutationClient.put(`companies/${currentCompany.id}/users/${data.id}`, data), {
    onSuccess: (data, variables, context) => {
      notifications.success('Usuario actualizado correctamente')
      queryClient.invalidateQueries(`companies/${currentCompany.id}/users`)
      navigate(`/members/${variables.id}/`)
    }
  })

  return (
    <Container>
      <div className="row">
        <Header
          title="Detalle de usuario"
          items={[
            { label: "Listado de usuarios", to: "/members" },
            { label: "Detalle de usuario", to: `/members/${params.UserId}` },
            { label: "Editar usuario" },
          ]}
        />
      </div>

      <div className="row">
        <MemberDetailSubmenu UserId={params.UserId}/>
      </div>

      <div className="row">
       
        <div className="col-12 col-md-6">
        {
          query.isLoading ?
            <div className="text-center my-5 py-4">
              <Spinner />
            </div>
          :
            <MemberDetailsForm
              member={currentMember}
              save={data => memberMutation.mutate(data)}
              isLoading={memberMutation.isLoading}
            />
        }
        </div>

        <div className="col-12 col-md-6">
          {
            currentMember ?
            <CheckFeatures feature="LIST_GROUPS">
                <MemberGroups currentMember={currentMember} />
            </CheckFeatures>
            : null
          }
        </div>
        <div className="col-12 col-md-6 mt-3">
          {
            currentMember && user && currentMember.id === user.id ?
            <CheckFeatures feature="CHANGE_PASSWORD">
              <PasswordDetailForm currentMember = {currentMember}/>
            </CheckFeatures>
            : null
          }
        </div>
      </div>
    </Container>
  )
}

