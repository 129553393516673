import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate, Navigate } from 'react-router-dom';
import { useQuery } from 'react-query'
import { CurrentUserContext, CurrentCompanyContext } from '../../App.js'

import { Container } from '../layout/AppLayout.js';

import Header, { HeaderActions } from "../shared/Header.js"
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js'
import Spinner from '../shared/Spinner.js';
import { DestroyButton } from '../shared/Buttons.js';

import CompanyDetailSubmenu from './CompanyDetailSubmenu.js'

function Loading(){
  return (
    <div className="text-center my-4 py-4">
      <Spinner />
    </div>
  )
}

function DetailsCard({ company }){
  return (
    <div className="card shadow-sm mb-3">
      <div className="card-header">Detalle de la compañía</div>

      <div className="card-body">
        <div className="form-group">
          <label className="form-label font-weight-bold">Nombre</label>
          <p>{ company.name }</p>
        </div>

        <div className="form-group">
          <label className="form-label font-weight-bold">Industria</label>
          <p>{ company.industry_name ? company.industry_name : '--' }</p>
        </div>
      </div>
    </div>
  )
}

export default function FacilityDetailsPage() {
  const params = useParams()
  const CheckFeatures = useFeatureChecker()
  const currentUser = useContext(CurrentUserContext)
  const currentCompany = useContext(CurrentCompanyContext)
  const navigate = useNavigate()

  const query = useQuery(`companies/${params.CompanyId}`)


  useEffect(() => {
    if( currentUser && currentUser.role_level>0){ 
      if(params.CompanyId != currentCompany.id){
        navigate(`/companies/${currentCompany.id}`)
      }
    }
  }, [currentUser,query])
  

  return (
    <Container>
      <div className="row">
        <Header title="Detalle de compañía" items={[
          { label: `Listado de compañías`, to: "/companies" },
          { label: `Detalle de compañía` },
        ]}>
          <HeaderActions>
            <CheckFeatures feature="UPDATE_COMPANY">
              <Link
                to={`/companies/${params.CompanyId}/edit`}
                className="btn btn-primary mr-2"
              >Editar compañía</Link>
            </CheckFeatures>

            <CheckFeatures feature="DESTROY_COMPANY">
              <DestroyButton
                label="Eliminar instalación"
                confirmMessage="¿Está seguro que desea eliminar la instalación?"
                successMessage="Instalación eliminada correctamente"
                deletePath={`facilities/${params.FacilityId}`}
                invalidate="facilities"
                redirect="/facilities"
              />
            </CheckFeatures>
          </HeaderActions>
        </Header>
      </div>

      <CheckFeatures feature='LIST_ROLES'>
        <div className="row">
          <CompanyDetailSubmenu CompanyId={params.CompanyId} />
        </div>
      </CheckFeatures>
      
      <CheckFeatures feature="DETAIL_COMPANY">
        <AllowedFeature>
          <div className="row pb-4">
            <div className="col-12 mb-2 col-lg-6">
              {
                query.isLoading ?
                  <Loading />
                :
                  <DetailsCard company={query.data && query.data.result || {}} />
              }
            </div>
          </div>
        </AllowedFeature>
        <DeniedFeature>
          <Navigate to={'/error?error=403'}/>
        </DeniedFeature>
      </CheckFeatures>
    </Container>
  )
}

