import React, { useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { Container } from '../layout/AppLayout.js'
import Header, { HeaderActions } from "../shared/Header.js"
import useFeatureChecker, { isAllowed ,AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js'
import Spinner from '../shared/Spinner.js';
import TableList from '../shared/TableList.js';
import { CurrentUserContext } from '../../App.js';
import ZoneDetailSubmenu from '../zones/ZoneDetailSubmenu.js';
import { useQuery } from 'react-query';

function AssetsTable({ items }){
  const CheckFeatures = useFeatureChecker()
  if (!items.length) {
    return <div className="text-center text-muted py-4 my-4">No hay activos dentro de esta zona</div>
  }

  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="Nombre" />
        <TableList.HeaderItem value="Tags" width={400} />
      </TableList.Header>

      <TableList.Body>
        {
          items.map(item => (
            <TableList.Row key={item.id}>
              <TableList.Column>
                <CheckFeatures feature='DETAIL_ASSETS'>
                  <AllowedFeature>
                    <Link className="py-3" to={`${item.id}`}>{item.name}</Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    {item.name}
                  </DeniedFeature>
                </CheckFeatures>
              </TableList.Column>

              <TableList.Column>
                {
                  item.tags.map(item => (
                    <div key={item.id} className="badge badge-info py-1 px-2 mr-2" style={{ fontSize: '.9em' }}>{ item.name }</div>
                  ))
                }
              </TableList.Column>
            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}


export default function AssetsListPage() {
  const params = useParams()
  const CheckFeatures = useFeatureChecker()
  const navigate = useNavigate()
  const currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "LIST_ASSETS"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  const query = useQuery(`assets?ZoneId=${params.ZoneId}`, {
    keepPreviousData: true
  })

  const assets = query.data && query.data.result || []

  return (
    <Container>
      <div className="row">
        <Header title="Detalle de zona - Activos" items={[
          { label: `Listado de zonas`, to: "/zones" },
          { label: `Detalle de zona`, to: `/zones/${params.ZoneId}` },
          { label: `Activos` },
        ]}>
          <HeaderActions>
            <CheckFeatures feature="CREATE_ASSETS">
              <Link to="new" className="btn btn-primary">Agregar activo</Link>
            </CheckFeatures>
          </HeaderActions>
        </Header>
      </div>

      <div className="row">
        <ZoneDetailSubmenu ZoneId={params.ZoneId}/>
      </div>

      <div className="row mb-2 pb-4">
        <div className="col-12">
          {
            query.isLoading ?
              <div className="text-center my-4 py-4">
                <Spinner />
              </div>
            :
              <AssetsTable items={assets} />
          }
        </div>
      </div>
    </Container>
  )
}
