import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

import './TableList.css'

export function TableListHeader({ children, onSort }){
  const arrayChildren = React.Children
    .toArray(children)
    .map(x => React.cloneElement(x, { onSort }))

  return (
    <div
      className="d-flex align-items-center"
    >
      { arrayChildren }
    </div>
  )
}

export function TableListBody({ children, columnWidths }){
  children = React.Children.toArray(children)

  const items = children.map(item => (
    React.cloneElement(item, { columnWidths })
  ))

  return (
    <div>{items}</div>
  )
}

export function TableListHeaderItem({ children, value, width, ...props }){
  const sortable = props.sortable && props.sortField || false;
  const [sortOrder, setSortOrder] = useState(0)

  let style = {}

  if( width ){
    style.flex = `0 0 ${width}px`
  }else{
    style.flex = '1 1 100%'
  }

  if(sortable){
    style["cursor"] = "pointer"
  }

  useEffect(() => {
    if(sortable && sortOrder !== 0){
      props.onSort([props.sortField, sortOrder === 1 ? 'asc': 'desc'])
    }
  }, [sortOrder])

  return (
    <div
      className="text-uppercase text-muted p-3"
      style={style}
      onClick={() => {
        if( sortable ){
          setSortOrder(prev => prev === 0 ? 1 : (prev === 1 ? -1 : 1) )
        }
      }}
    >
      {children ? children : value }

      {
        sortable && sortOrder !== 0 ?
          <FontAwesomeIcon
            icon={sortOrder > 0 ? faCaretUp : faCaretDown}
            className="ml-1"
          />
        : null
      }
    </div>
  )
}

export function TableListRow({ children, value, columnWidths }){
  children = React.Children.toArray(children)

  const items = children.filter(x => x !== null).map((item, idx) => (
    React.cloneElement(item, { key: `column-${idx}`, width: columnWidths[idx] })
  ))

  return (
    <div className="TableListRow shadow-sm mb-2 bg-white rounded d-flex align-items-center">
      {items}
    </div>
  )
}

export function TableListColumn({ children, value, width }){
  const style = {}

  if( width ){
    style.flex = `0 0 ${width}px`
  }else{
    style.flex = '1 1 100%'
  }

  return (
    <div className="p-3" style={style}>
      { value ? value : children }
    </div>
  )
}

export default function TableList({ children, onSort }){
  const arrayChildren = React.Children.toArray(children)
  let header = arrayChildren.find(x => x.type.name === TableListHeader.name)
  let body = arrayChildren.find(x => x.type.name === TableListBody.name)

  header = React.cloneElement(header, { onSort })

  const columnWidths = React.Children
    .toArray(header.props.children)
    .map(item => item ? item.props.width : undefined)

  body = React.cloneElement(body, { columnWidths: columnWidths })

  return (
    <div className="TableList">
      {header}
      {body}
    </div>
  )
}

TableList.Header = TableListHeader;
TableList.HeaderItem = TableListHeaderItem;
TableList.Body = TableListBody;
TableList.Row = TableListRow;
TableList.Column = TableListColumn;

