import React, { useState } from 'react';
import { useQuery } from 'react-query'

import { formatDistanceToNow, toDate } from 'date-fns'
import { formatDate } from '../../utils.js'
import { es } from 'date-fns/locale'

export default function SensorHealth({ sensor }){
  const [ timestamp, setTimestamp ] = useState(null);

  const query = useQuery(`sensors/${sensor.id}/health`, {
    enabled: !!sensor,
    refetchOnWindowFocus: false
  })

  if( query.data && query.data.result && query.data.result.ts ){
    const ts = toDate(query.data.result.ts)
    const elapsed = formatDistanceToNow(ts, { addSuffix: true, locale: es })
    const formatted = formatDate(query.data.result.ts, "dd/MM/yyyy HH:mm:ss")

    return (
      <span title={formatted}>{elapsed}</span>
    )
  }

  return null
}
