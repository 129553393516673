import React, { useState, useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import Multiselect from 'multiselect-react-dropdown'
import './Filters.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faDoorClosed,
  faVectorSquare,
  faFilter,
  faObjectUngroup,
  faCamera,
  faMicrochip,
  faIndustry,
  faEye,
  faUsers
} from '@fortawesome/free-solid-svg-icons';


import DateRangeFilter from "./DateRangeFilter.js";
import OptionSelector from './OptionSelector.js'
import Spinner from './Spinner.js'
import { Searchbox } from './Search.js'
import { toQueryString } from '../../utils.js'

import translateEvent from '../events/translate.js'
import translateAlert from '../alerts/translate.js';


function DropdownSelectorItem({ item, isActive, onClick }){
  return (
    <li
      key={item.value}
      className={`list-group-item list-group-item-action px-2 py-2 ${isActive ? 'active' : ''}`}
      tabIndex={1}
      onClick={e => onClick(item)}
      style={{
        tabIndex: 1,
        cursor: 'pointer'
      }}
    >
      <span className="d-block text-truncate">{item.label}</span>
    </li>
  )
}

export function MultipleDropdownSelector({
  items = [],
  disabled ,
  defaultLabel,
  defaultValue,
  onChange,
  onSearch,
  ...props
}){
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isActive, setIsActive ] = useState(false)
  const [ label, setLabel ] = useState(defaultLabel)
  const [selectedItems, setSelectedItems] = useState([]);

  //const listRef = useRef(null)
  const menuRef = useRef(null)
  const wrapperRef = useRef(null)
  useEffect(() => {
    if( isOpen ){
      if(onSearch){
        onSearch('')
      }

      function handleClick(e){
        if( !wrapperRef.current.contains(e.target) ){
          setIsOpen(false)
        }
      }

      //menuRef.current.children[0].children[1].focus()
      document.addEventListener('click', handleClick)
      return () => {
        document.removeEventListener('click', handleClick)
      }
    }
  }, [isOpen])
  useEffect(()=>{
    if(defaultValue.length>0){
      setLabel(defaultValue.length +" opciones")
    }
  },[defaultValue])
const handleSelect = (selectedList, selectedItem) => {

  setSelectedItems(selectedList);
  const selectedValue_f = selectedList.map(item => item.value); 
  onChange(selectedValue_f)
  setIsOpen(false)
  setIsActive(true)
}
const handleRemove = (selectedList, removedItem) => { 
  setSelectedItems(selectedList);
  if (selectedList.length === 0) {
    onChange(null)
    setLabel(null)
    setIsOpen(false)
    setIsActive(false)
    setLabel(null)
    setLabel(defaultLabel)
  }else{
    const selectedValue = selectedList.map(item => item.value);    
    onChange(selectedValue)
    setIsOpen(false)
    setIsActive(true)
  }
};
const sortItems = (options, selectedItems) => {
  const selectedValues = selectedItems.map(item => item.value);
  const unselectedItems = options.filter(item => !selectedValues.includes(item.value));
  return [...selectedItems, ...unselectedItems];
};

  return (
    <div
      className={`d-block d-md-inline-block ${props.className}`}
      ref={wrapperRef}
    >
            <Multiselect
              displayValue="label"
              hideSelectedList
              onKeyPressFn={function noRefCheck(){}}
              onRemove={handleRemove}
              onSearch={function noRefCheck(){}}
              onSelect={handleSelect}
              options={sortItems(items, selectedItems)}
              showCheckbox
              placeholder={label} 
              selectedValues={defaultValue}
              style={{
                multiselectContainer: {
                  width: '140px'
                }
              }}
            />
        </div>
  )
}  

export function DropdownSelector({
  items = [],
  disabled ,
  defaultItem,
  defaultLabel,
  onChange,
  onSearch,
  ...props
}){
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isActive, setIsActive ] = useState(false)
  const [ label, setLabel ] = useState(defaultLabel)

  //const listRef = useRef(null)
  const menuRef = useRef(null)
  const wrapperRef = useRef(null)

  const results = []

  useEffect(() => {
    if(defaultItem && defaultItem.label){
      setLabel(defaultItem.label)
      setIsActive(true)
    }else{
      setLabel(defaultLabel)
      setIsActive(false)
    }
  }, [defaultItem])

  useEffect(() => {
    if( isOpen ){
      if(onSearch){
        onSearch('')
      }

      function handleClick(e){
        if( !wrapperRef.current.contains(e.target) ){
          setIsOpen(false)
        }
      }

      //menuRef.current.children[0].children[1].focus()
      document.addEventListener('click', handleClick)
      return () => {
        document.removeEventListener('click', handleClick)
      }
    }
  }, [isOpen])

  function handleItemSelection(item){
    onChange(item)
    setLabel(item.label)
    setIsOpen(false)
    setIsActive(true)
  }

  function clearFilter(e){
    onChange(null)
    setLabel(null)
    setIsOpen(false)
    setIsActive(false)
    setLabel(defaultLabel)
  }

  return (
    <div
      className={`d-block d-md-inline-block ${props.className}`}
      ref={wrapperRef}
    >
      <div className={ `dropdown ${isOpen ? 'show': ''}` }>
        <button
          className={`btn btn-outline-primary container-lg d-block dropdown-toggle  ${isActive ? 'active' : ''}`}
          onClick={() => setIsOpen(prev => !prev)}
          disabled={disabled}
        >
          { props.icon ? <FontAwesomeIcon icon={props.icon} className="mr-2" /> : '' }
          <span>{label}</span>
        </button>

        <div
          ref={menuRef}
          className={ `dropdown-menu dropdown-menu-right shadow-sm p-0 ${isOpen ? 'show' : ''}` }
          style={{ width: '250px' }}
        >
          {
            onSearch ?
              <div className="p-2">
                <Searchbox onSearch={q => onSearch(q)} />
              </div>
            : null
          }

          <ul
            className={`list-group list-group-flush ${onSearch ? 'border-top' : ''} ${isActive ? 'border-bottom' : ''}`}
            style={items.length > 10 ? { height: '300px', overflowY: 'scroll' } : {}}
          >
            {
              items.map((item, idx) => (
                <DropdownSelectorItem
                  key={idx}
                  item={item}
                  onClick={handleItemSelection}
                  isActive={defaultItem && defaultItem.value && defaultItem.value === item.value}
                />
              ))
            }
          </ul>

          {
            isActive ?
              <button
                className="btn btn-sm btn-link btn-block btn-clear"
                onClick={clearFilter}
              >Borrar selección</button>
            : null
          }
        </div>
      </div>
    </div>
  )
}

export function UserMediaFilter({ defaultValue, onChange, disabled, ...props }){
  const [ filters, setFilters ] = useState({ status: 'enabled',limit:2000 })

  const query = useQuery(`users/mediaTags?${toQueryString(filters)}`, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const users = (!query.isLoading && query.data && query.data.result || [])
    .map(x => ({ value: x.id, label: x.name || x.email }))
  
  let selectedValues=[]
  if (typeof defaultValue === 'string') {
    selectedValues = defaultValue.split(',').map(Number);
  } else if (Array.isArray(defaultValue)) {
    selectedValues = defaultValue.flatMap(item => {
      if (typeof item === 'string') {
        return item.split(',').map(Number);
      } else {
        return item;
      }
    });
  }
  const resultados = users.filter(objeto => selectedValues.includes(objeto.value));

  return (
    <MultipleDropdownSelector
      className={props.className}
      defaultLabel={"Usuario"}
      defaultValue={resultados}
      icon={faUser}
      items={users}
      isLoading={query.isLoading}
      onChange={selected => onChange(selected  || null)}
      onSearch={search => setFilters(prev => ({ ...prev, search }))}
      disabled={disabled}
    />
  )
}

export function UserFilter({ defaultValue, onChange, disabled, ...props }){
  const [ filters, setFilters ] = useState({ status: 'enabled',limit:2000 })

  const query = useQuery(`users?${toQueryString(filters)}`, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const selectedQuery = useQuery(`users?UserId=${defaultValue}`, {
    enabled: !!defaultValue,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const users = (!query.isLoading && query.data && query.data.result || [])
    .map(x => ({ value: x.id, label: x.name || x.email }))

  let selectedValues=[]
    if (typeof defaultValue === 'string') {
      selectedValues = defaultValue.split(',').map(Number);
    } else if (Array.isArray(defaultValue)) {
      selectedValues = defaultValue.flatMap(item => {
        if (typeof item === 'string') {
          return item.split(',').map(Number);
        } else {
          return item;
        }
      });
    }
    const resultados = users.filter(objeto => selectedValues.includes(objeto.value));
  
  const selectedUser = !selectedQuery.isLoading
    && selectedQuery.data
    && selectedQuery.data.result
    && selectedQuery.data.result.length
    && selectedQuery.data.result[0]
    || {}

  return (
    <MultipleDropdownSelector
      className={props.className}
      defaultLabel={"Usuario"}
      defaultValue={resultados}
      icon={faUser}
      items={users}
      isLoading={query.isLoading}
      onChange={selected => onChange(selected  || null)}
      onSearch={search => setFilters(prev => ({ ...prev, search }))}
      disabled={disabled}
    />
  )
}

export function ObjectFilter({ defaultValue, onChange, disabled, ...props }){
  const [ filters, setFilters ] = useState({})

  const query = useQuery(`media/tags?${toQueryString(filters)}`, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const selectedQuery = useQuery(`media/tags?MediaTagId=${defaultValue}`, {
    enabled: !!defaultValue,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const items = (!query.isLoading && query.data && query.data.result || [])
    .map(x => ({ value: x.id, label: x.human_name || x.name }))

  let selectedValues=[]
  if (typeof defaultValue === 'string') {
    selectedValues = defaultValue.split(',').map(Number);
  } else if (Array.isArray(defaultValue)) {
    selectedValues = defaultValue.flatMap(item => {
      if (typeof item === 'string') {
        return item.split(',').map(Number);
      } else {
        return item;
      }
    });
  }
  const resultados = items.filter(objeto => selectedValues.includes(objeto.value));


  const selectedUser = !selectedQuery.isLoading
    && selectedQuery.data
    && selectedQuery.data.result
    && selectedQuery.data.result.length
    && selectedQuery.data.result[0]
    || {}

  return (
    <MultipleDropdownSelector
      className={props.className}
      defaultLabel={"Objeto"}
      defaultValue={resultados}
      icon={faObjectUngroup}
      items={items}
      isLoading={query.isLoading}
      onChange={selected => onChange(selected  || null)}
      onSearch={search => setFilters(prev => ({ ...prev, search }))}
      disabled={disabled}
    />
  )
}

export function DoorFilter({ defaultValue,extraFilters, onChange, disabled, ...props }){
  const [ filters, setFilters ] = useState({})
  const [selectedValue,setSelectedValue] = useState(defaultValue)
  const extraFiltersRef = useRef(null);
  if(!extraFiltersRef.current){
    extraFiltersRef.current=extraFilters
  }
  const query = useQuery(`doors?${toQueryString(filters)}`, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
  const selectedQuery = useQuery(`doors?DoorId=${selectedValue}`, {
    enabled: !!defaultValue,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  useEffect(() => {
    if(extraFilters){
      setFilters(extraFilters)
      query.refetch({ ...filters })
    }else{
      setFilters({})
      query.refetch({ ...filters })
    }
    if(extraFiltersRef.current.ZoneId!==extraFilters.ZoneId){
      extraFiltersRef.current=extraFilters
      setSelectedValue()
    }
  }, [extraFilters])

  const items = (!query.isLoading && query.data && query.data.result || [])
    .map(x => ({ value: x.id, label: (x.name+"-"+x.zone_name )}))

  let selectedValues=[]
  if (typeof defaultValue === 'string') {
    selectedValues = defaultValue.split(',').map(Number);
  } else if (Array.isArray(defaultValue)) {
    selectedValues = defaultValue.flatMap(item => {
      if (typeof item === 'string') {
        return item.split(',').map(Number);
      } else {
        return item;
      }
    });
  }
  const resultados = items.filter(objeto => selectedValues.includes(objeto.value));

  const selectedItem = !selectedQuery.isLoading
    && selectedQuery.data
    && selectedQuery.data.result
    && selectedQuery.data.result.length
    && selectedQuery.data.result[0]
    || {}

  return (
    <MultipleDropdownSelector
      className={props.className}
      defaultLabel={"Puerta"}
      defaultValue={resultados}
      icon={faDoorClosed}
      items={items}
      isLoading={query.isLoading}
      onChange={selected => {
        onChange(selected  || null)
        setSelectedValue(selected  || null)
      }}
      onSearch={search => setFilters(prev => ({ ...prev, search }))}
      disabled={disabled}
    />
  )
}

export function CameraFilter({ defaultValue, extraFilters, onChange, disabled, ...props }){
  const [ filters, setFilters ] = useState({ SensorTypeName: 'camera' })
  const [selectedValue,setSelectedValue] = useState(defaultValue)
  const extraFiltersRef = useRef(null);
  if(!extraFiltersRef.current){
    extraFiltersRef.current=extraFilters
  }

  const query = useQuery(`sensors?${toQueryString(filters)}`, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const selectedQuery = useQuery(`sensors?SensorId=${selectedValue}`, {
    enabled: !!defaultValue,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
  useEffect(() => {
    if(extraFilters){
      setFilters(prev=>({...prev,...extraFilters}))
      query.refetch({ ...filters })
    }else{
      setFilters({SensorTypeName: 'camera'})
      query.refetch({ ...filters })
    }
    if(extraFiltersRef.current.DoorId!==extraFilters.DoorId){
      extraFiltersRef.current=extraFilters
      setSelectedValue()
    }
  }, [extraFilters])

  const items = (!query.isLoading && query.data && query.data.result || [])
    .map(x => ({ value: x.id, label: x.name }))

  let selectedValues=[]
  if (typeof defaultValue === 'string') {
    selectedValues = defaultValue.split(',').map(Number);
  } else if (Array.isArray(defaultValue)) {
    selectedValues = defaultValue.flatMap(item => {
      if (typeof item === 'string') {
        return item.split(',').map(Number);
      } else {
        return item;
      }
    });
  }
  const resultados = items.filter(objeto => selectedValues.includes(objeto.value));

  const selectedItem = !selectedQuery.isLoading
    && selectedQuery.data
    && selectedQuery.data.result
    && selectedQuery.data.result.length
    && selectedQuery.data.result[0]
    || {}

  return (
    <MultipleDropdownSelector
      className={props.className}
      defaultLabel={"Cámara"}
      defaultValue={resultados}
      icon={faCamera}
      items={items}
      isLoading={query.isLoading}
      onChange={selected => {
        onChange(selected  || null)
        setSelectedValue(selected  || null)
      }}
      onSearch={search => setFilters(prev => ({ ...prev, search }))}
      disabled={disabled}
    />
  )
}

export function GroupFilter({ defaultValue, onChange,  disabled,...props }){
  const [ filters, setFilters ] = useState({})
  const [selectedValue,setSelectedValue] = useState(defaultValue)
  
  const query = useQuery(`groups?${toQueryString({...filters})}`, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
  const selectedQuery = useQuery(`groups?GroupId=${selectedValue}`, {
    enabled: !!defaultValue,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
 
  const items = (!query.isLoading && query.data && query.data.result || [])
    .map(x => ({ value: x.id, label: x.name }))

  let selectedValues=[]
  if (typeof defaultValue === 'string') {
    selectedValues = defaultValue.split(',').map(Number);
  } else if (Array.isArray(defaultValue)) {
    selectedValues = defaultValue.flatMap(item => {
      if (typeof item === 'string') {
        return item.split(',').map(Number);
      } else {
        return item;
      }
    });
  }
  const resultados = items.filter(objeto => selectedValues.includes(objeto.value));

  const selectedItem = !selectedQuery.isLoading
    && selectedQuery.data
    && selectedQuery.data.result
    && selectedQuery.data.result.length
    && selectedQuery.data.result[0]
    || {}

  return (
    <MultipleDropdownSelector
      className={props.className}
      defaultLabel={"Grupo"}
      defaultValue={resultados}
      icon={faUsers}
      items={items}
      isLoading={query.isLoading}
      onChange={selected => {
        onChange(selected  || null)
        setSelectedValue(selected  || null)
      }}
      onSearch={search => setFilters(prev => ({ ...prev, search }))}
      disabled={disabled}
    />
  )
}
export function ZoneFilter({ defaultValue, extraFilters, onChange,  disabled,...props }){
  const [ filters, setFilters ] = useState({})
  const [selectedValue,setSelectedValue] = useState(defaultValue)
  const extraFiltersRef = useRef(null);
  if(!extraFiltersRef.current){
    extraFiltersRef.current=extraFilters
  }
  const query = useQuery(`zones?${toQueryString({...filters})}`, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
  const selectedQuery = useQuery(`zones?ZoneId=${selectedValue}`, {
    enabled: !!defaultValue,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
  useEffect(() => {
    if(extraFilters){
      setFilters(extraFilters)
      query.refetch({ ...filters })
    }else{
      setFilters({})
      query.refetch({ ...filters })
    }
    if(extraFiltersRef.current.FacilityId!==extraFilters.FacilityId){
      extraFiltersRef.current=extraFilters
      setSelectedValue()
    }
  }, [extraFilters])


  const items = (!query.isLoading && query.data && query.data.result || [])
    .map(x => ({ value: x.id, label: x.name }))

  let selectedValues=[]
  if (typeof defaultValue === 'string') {
    selectedValues = defaultValue.split(',').map(Number);
  } else if (Array.isArray(defaultValue)) {
    selectedValues = defaultValue.flatMap(item => {
      if (typeof item === 'string') {
        return item.split(',').map(Number);
      } else {
        return item;
      }
    });
  }
  const resultados = items.filter(objeto => selectedValues.includes(objeto.value));

  const selectedItem = !selectedQuery.isLoading
    && selectedQuery.data
    && selectedQuery.data.result
    && selectedQuery.data.result.length
    && selectedQuery.data.result[0]
    || {}

  return (
    <MultipleDropdownSelector
      className={props.className}
      defaultLabel={"Zona"}
      defaultValue={resultados}
      icon={faVectorSquare}
      items={items}
      isLoading={query.isLoading}
      onChange={selected => {
        onChange(selected  || null)
        setSelectedValue(selected  || null)
      }}
      onSearch={search => setFilters(prev => ({ ...prev, search }))}
      disabled={disabled}
    />
  )
}

export function SensorTypeFilter({ defaultValue, onChange, disabled, ...props }){
  const [ filters, setFilters ] = useState({})

  const query = useQuery(`sensors/types?${toQueryString(filters)}`, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const selectedQuery = useQuery(`sensors/types?SensorTypeId=${defaultValue}`, {
    enabled: !!defaultValue,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const items = (!query.isLoading && query.data && query.data.result || [])
    .map(x => ({ value: x.id, label: x.label }))

  let selectedValues=[]
  if (typeof defaultValue === 'string') {
    selectedValues = defaultValue.split(',').map(Number);
  } else if (Array.isArray(defaultValue)) {
    selectedValues = defaultValue.flatMap(item => {
      if (typeof item === 'string') {
        return item.split(',').map(Number);
      } else {
        return item;
      }
    });
  }
  const resultados = items.filter(objeto => selectedValues.includes(objeto.value));

  const selectedItem = !selectedQuery.isLoading
    && selectedQuery.data
    && selectedQuery.data.result
    && selectedQuery.data.result.length
    && selectedQuery.data.result[0]
    || {}

  return (
    <MultipleDropdownSelector
      className={props.className}
      defaultLabel={"Tipo de sensor"}
      defaultValue={resultados}
      icon={faMicrochip}
      items={items}
      isLoading={query.isLoading}
      onChange={selected => onChange(selected || null)}
      onSearch={search => setFilters(prev => ({ ...prev, search }))}
      disabled={disabled}
    />
  )
}

export function FacilityFilter({ defaultValue, onChange, disabled, ...props }){
  const [ filters, setFilters ] = useState({})

  const query = useQuery(`facilities?${toQueryString(filters)}`, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const selectedQuery = useQuery(`facilities/${defaultValue}`, {
    enabled: !!defaultValue,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const items = (!query.isLoading && query.data && query.data.result || [])
    .map(x => ({ value: x.id, label: x.name }))

  let selectedValues=[]
  if (typeof defaultValue === 'string') {
    selectedValues = defaultValue.split(',').map(Number);
  } else if (Array.isArray(defaultValue)) {
    selectedValues = defaultValue.flatMap(item => {
      if (typeof item === 'string') {
        return item.split(',').map(Number);
      } else {
        return item;
      }
    });
  }
  const resultados = items.filter(objeto => selectedValues.includes(objeto.value));

  const selectedItem = !selectedQuery.isLoading
    && selectedQuery.data
    && selectedQuery.data.result
    || {}

  return (
    <MultipleDropdownSelector
      className={props.className}
      defaultLabel={"Instalación"}
      defaultValue={resultados}
      icon={faIndustry}
      items={items}
      isLoading={query.isLoading}
      onChange={selected => onChange(selected  || null)}
      onSearch={search => setFilters(prev => ({ ...prev, search }))}
      disabled={disabled}
    />
  )
}

export function AccessTypeOptionFilter({ defaultValue, onChange }){
  const options = [
    { label: 'Todos', value: 'all', description: 'Permisos en cualquier estado' },
    { label: 'En curso', value: 'current', description: 'Permisos en curso' },
    { label: 'Próximos', value: 'upcoming', description: 'Permisos próximos a iniciar' },
    { label: 'Pasados', value: 'past', description: 'Permisos caducados' },
    { label: 'Revocados', value: 'revoked', description: 'Permisos manualmente desactivados' }
  ]

  return (
    <OptionSelector
      className="mr-lg-1"
      type='dropdown'
      options={options}
      selected={defaultValue}
      onChange={type => onChange(type)}
    />
  )
}

export function MimeTypeOptionFilter({defaultValue, onChange, disabled, ...props}){
  const [ mimeType,setMimeType ] = useState({})
  const options = [
    { label: 'Fotos', value: 0},
    { label: 'Videos', value: 1 }
  
  ]
  let selectedValues=[]
  if (typeof defaultValue === 'string' && defaultValue!=='' ) {
    selectedValues = defaultValue.split(',').map(Number);
  } else if (Array.isArray(defaultValue)) {
    selectedValues = defaultValue.flatMap(item => {
      if (typeof item === 'string') {
        return item.split(',').map(Number);
      } else {
        return item;
      }
    });
  }
  const resultados = options.filter(objeto => selectedValues.includes(objeto.value));
  return (
    <MultipleDropdownSelector
      className={props.className}
      defaultLabel={"Tipo media"}
      defaultValue={resultados}
      icon={faIndustry}
      items={options}
      onChange={selected => onChange(selected  || null)}
      onSearch={search => setMimeType(prev => ({ ...prev, search }))}
      disabled={disabled}
    />
  )
}

export function MemberStatusFilter({ defaultValue, onChange }){
    const options = [
    { label: 'Activos', value: 'enabled' },
    { label: 'Invitación pendiente', value: 'pending' },
  ]

  return (
    <OptionSelector
      className=" d-lg-inline-block p-0"
      type='radio'
      options={options}
      selected={defaultValue}
      onChange={value => onChange(value)}
    />
  )
}

export function AlertTypeFilter({ defaultValue, onChange, disabled, ...props }) {
  const [ filters, setFilters ] = useState({})

  const query = useQuery(`alertevents/types?${toQueryString(filters)}`)

  const alert_types = (!query.isLoading && query.data && query.data.result || [])
    .map(alert => ({value: alert.name, label: translateAlert(alert.name)}))

  
  let selectedValues=[]
  if (typeof defaultValue === 'string') {
    selectedValues = defaultValue.split(',').map(Number);
  } else if (Array.isArray(defaultValue)) {
    selectedValues = defaultValue.flatMap(item => {
      if (typeof item === 'string') {
        return item.split(',').map(Number);
      } else {
        return item;
      }
    });
  }
  const resultados = alert_types.filter(objeto => selectedValues.includes(objeto.value));

  const selectedItem = defaultValue || null

  const dropdown =
    <MultipleDropdownSelector
      className={props.className}
      defaultLabel={"Alertas"}
      defaultValue={resultados}
      icon={faEye}
      items={alert_types}
      isLoading={query.isLoading}
      onChange={selected => onChange(selected  || null)}
      onSearch={search => setFilters(prev => ({ ...prev, search }))}
      disabled={disabled}
    />

  return dropdown
}

export function MaintanceTypeFilter({ defaultValue, onChange, disabled, ...props }) {
  const [ filters, setFilters ] = useState({})

  const query = useQuery(`devicereport/types?type=maintance&${toQueryString(filters)}`)


  const maintance_types = (!query.isLoading && query.data && query.data.result || [])
    .map(types => ({value: types.id, label: (types.name)}))

  
  let selectedValues=[]
  if (typeof defaultValue === 'string') {
    selectedValues = defaultValue.split(',').map(Number);
  } else if (Array.isArray(defaultValue)) {
    selectedValues = defaultValue.flatMap(item => {
      if (typeof item === 'string') {
        return item.split(',').map(Number);
      } else {
        return item;
      }
    });
  }
  const resultados = maintance_types.filter(objeto => selectedValues.includes(objeto.value));


  const dropdown =
    <MultipleDropdownSelector
      className={props.className}
      defaultLabel={"Tipos"}
      defaultValue={resultados}
      icon={faEye}
      items={maintance_types}
      isLoading={query.isLoading}
      onChange={selected => onChange(selected  || null)}
      onSearch={search => setFilters(prev => ({ ...prev, search }))}
      disabled={disabled}
    />

  return dropdown
}
export function FailureTypeFilter({ defaultValue, onChange, disabled, ...props }) {
  const [ filters, setFilters ] = useState({})

  const query = useQuery(`devicereport/types?type=failure&${toQueryString(filters)}`)


  const failure_types = (!query.isLoading && query.data && query.data.result || [])
    .map(types => ({value: types.id, label: (types.name)}))

  
  let selectedValues=[]
  if (typeof defaultValue === 'string') {
    selectedValues = defaultValue.split(',').map(Number);
  } else if (Array.isArray(defaultValue)) {
    selectedValues = defaultValue.flatMap(item => {
      if (typeof item === 'string') {
        return item.split(',').map(Number);
      } else {
        return item;
      }
    });
  }
  const resultados = failure_types.filter(objeto => selectedValues.includes(objeto.value));


  const dropdown =
    <MultipleDropdownSelector
      className={props.className}
      defaultLabel={"Tipos"}
      defaultValue={resultados}
      icon={faEye}
      items={failure_types}
      isLoading={query.isLoading}
      onChange={selected => onChange(selected  || null)}
      onSearch={search => setFilters(prev => ({ ...prev, search }))}
      disabled={disabled}
    />

  return dropdown
}
export function AlertStateFilters({ defaultValue, onChange, disabled, ...props }) {
  const [ filters, setFilters ] = useState({})

  const query = useQuery(`alertstate/types?${toQueryString(filters)}`)

  const alert_types = (!query.isLoading && query.data && query.data.result || [])
    .map(alert => ({value: alert.name, label: translateAlert(alert.name)}))

  let selectedValues=[]
  if (typeof defaultValue === 'string') {
    selectedValues = defaultValue.split(',').map(Number);
  } else if (Array.isArray(defaultValue)) {
    selectedValues = defaultValue.flatMap(item => {
      if (typeof item === 'string') {
        return item.split(',').map(Number);
      } else {
        return item;
      }
    });
  }
  const resultados = alert_types.filter(objeto => selectedValues.includes(objeto.value));

  const selectedItem = defaultValue || null

  const dropdown =
    <MultipleDropdownSelector
      className={props.className}
      defaultLabel={"Alertas"}
      defaultValue={resultados}
      icon={faEye}
      items={alert_types}
      isLoading={query.isLoading}
      onChange={selected => onChange(selected || null)}
      onSearch={search => setFilters(prev => ({ ...prev, search }))}
      disabled={disabled}
    />

  return dropdown
}

export function LiveStatusFilter({defaultValue, onChange, disabled, ...props}){
  const [ filters, setFilters ] = useState({})

  const options = [
    { label: 'Online', value: 0 },
    { label: 'Interminecia', value: 1},
    { label: 'Offline', value: 2},
    { label: 'Bajo (< 10V)', value: 3 },
    { label: 'Media', value: 4},
    { label: 'Alto (>11 V)', value: 5}
  ]

  let selectedValues=[]
  if (typeof defaultValue === 'string' && defaultValue!=='') {
    selectedValues = defaultValue.split(',').map(Number);
  } else if (Array.isArray(defaultValue)) {
    selectedValues = defaultValue.flatMap(item => {
      if (typeof item === 'string') {
        return item.split(',').map(Number);
      } else {
        return item;
      }
    });
  }
  const resultados = options.filter(objeto => selectedValues.includes(objeto.value));
  const dropdown =
    <MultipleDropdownSelector
      className={props.className}
      defaultLabel={"Estado"}
      defaultValue={resultados}
      icon={faEye}
      items={options}
      onChange={selected => onChange(selected || null)}
      onSearch={search => setFilters(prev => ({ ...prev, search }))}
      disabled={disabled}
    />

return dropdown
}

export function EventTypeFilter({ defaultValue, onChange, disabled, ...props }) {
  const [ filters, setFilters ] = useState({})

  const query = useQuery(`events/types?${toQueryString(filters)}`)

  const event_types = (!query.isLoading && query.data && query.data.result || [])
    .map(event_type => ({value: event_type, label: translateEvent(event_type)}))

  let selectedValues=[]
  if (typeof defaultValue === 'string') {
    selectedValues = defaultValue.split(',').map(Number);
  } else if (Array.isArray(defaultValue)) {
    selectedValues = defaultValue.flatMap(item => {
      if (typeof item === 'string') {
        return item.split(',').map(Number);
      } else {
        return item;
      }
    });
  }
  const resultados = event_types.filter(objeto => selectedValues.includes(objeto.value));

  const selectedItem = defaultValue || null

  const dropdown =
    <MultipleDropdownSelector
      className={props.className}
      defaultLabel={"Eventos"}
      defaultValue={resultados}
      icon={faEye}
      items={event_types}
      isLoading={query.isLoading}
      onChange={selected => onChange(selected  || null)}
      onSearch={search => setFilters(prev => ({ ...prev, search }))}
      disabled={disabled}
    />

  return dropdown
}

export function FilterMenuLeft({ children }){
  return children
}

export function FilterMenu({ currentFilters, onChange, isLoading, children, ...props }){
  const leftSide = React.Children.toArray(children).find(x => x.type.name === FilterMenuLeft.name)
  return (
    <div className="col-12">
      <div className="p-2 bg-white rounded border shadow-sm d-flex justify-content-between align-items-center">
        <div>
          {
            currentFilters.search !== undefined ?
              <Searchbox
                value={currentFilters.search}
                onSearch={search => onChange({ search })}
              />
            : null
          }

          {
            leftSide ? leftSide : null
          }
        </div>

        <div>
          {
            props.accessTypeFilter ?
              <AccessTypeOptionFilter
                className="mr-lg-1"
                defaultValue={currentFilters.type}
                onChange={type => onChange({ type })}
                disabled={isLoading}
              />
            : null
          }

          {
            currentFilters.mimeType !== undefined ?
              <MimeTypeOptionFilter
                className="mr-lg-1"
                defaultValue={currentFilters.mimeType}
                onChange={mimeType => onChange({ mimeType })}
                disabled={isLoading}
              />
            : null
          }

          {
            currentFilters.UserId !== undefined ?
              <UserFilter
                className="mr-lg-1"
                defaultValue={currentFilters.UserId}
                onChange={UserId => onChange({ UserId })}
                disabled={isLoading}
              />
            : null
          }
          
          {
            currentFilters.UserIdMedia !== undefined ?
              <UserMediaFilter
                className="mr-lg-1"
                defaultValue={currentFilters.UserIdMedia}
                onChange={UserIdMedia => onChange({ UserIdMedia })}
                disabled={isLoading}
              />
            : null
          }

          {
            currentFilters.MediaTagId !== undefined ?
              <ObjectFilter
                className="mr-lg-1"
                defaultValue={currentFilters.MediaTagId}
                onChange={MediaTagId => onChange({ MediaTagId })}
                disabled={isLoading}
              />
            : null
          }
          {
            currentFilters.SensorTypeId !== undefined ?
              <SensorTypeFilter
                className="mr-lg-1"
                defaultValue={currentFilters.SensorTypeId}
                onChange={SensorTypeId => onChange({ SensorTypeId })}
                disabled={isLoading}
              />
            : null
          }

          {
            currentFilters.FacilityId !== undefined ?
              <FacilityFilter
                className="mr-lg-1"
                defaultValue={currentFilters.FacilityId}
                onChange={FacilityId => onChange({ FacilityId })}
                disabled={isLoading}
              />
            : null
          }

          {
            currentFilters.ZoneId !== undefined ?
              <ZoneFilter
                className="mr-lg-1"
                defaultValue={currentFilters.ZoneId}
                onChange={ZoneId => onChange({ ZoneId })}
                disabled={isLoading}
                extraFilters={{FacilityId:currentFilters.FacilityId}}
              />
            : null
          }

          {currentFilters.EventTypeFilter !== undefined ? (
            <EventTypeFilter
              className="mr-lg-1"
              defaultValue={translateEvent(currentFilters.EventTypeName)}
              onChange={EventTypeName => onChange({ EventTypeName })}
              disabled={isLoading}
            />
          ) : null}

          {currentFilters.AlertTypeFilter !== undefined ? (
            <AlertTypeFilter
              className="mr-lg-1"
              defaultValue={currentFilters.AlertTypeName}
              onChange={AlertTypeName => onChange({ AlertTypeName })}
              disabled={isLoading}
            />
          ) : null}
          {
            
            currentFilters.AlertStateFilters !== undefined ?(
              <AlertStateFilters
                className="mr-lg-1"
                defaultValue={currentFilters.AlertStateName}
                onChange={AlertStateName => onChange({ AlertStateName })}
                disabled={isLoading}
              />
            ) : null}

          {
            currentFilters.DoorId !== undefined ?
              <DoorFilter
                className="mr-lg-1"
                defaultValue={currentFilters.DoorId}
                onChange={DoorId => onChange({ DoorId })}
                disabled={isLoading}
                extraFilters={{FacilityId:currentFilters.FacilityId, ZoneId: currentFilters.ZoneId}}
              />
            : null
          }
          {
            currentFilters.CameraId !== undefined ?
              <CameraFilter
                className="mr-lg-1"
                defaultValue={currentFilters.CameraId}
                onChange={CameraId => onChange({ CameraId })}
                disabled={isLoading}
                extraFilters={{FacilityId: currentFilters.FacilityId, ZoneId: currentFilters.ZoneId, DoorId: currentFilters.DoorId}}
              />
            : null
          }
          {
            currentFilters.GroupId !== undefined ? 
              <GroupFilter
                className="mr-lg-1"
                defaultValue={currentFilters.GroupId}
                onChange={GroupId => onChange({ GroupId })}
                disabled={isLoading}
              />
            : null
          }
          {
            currentFilters.LiveStatus !== undefined ?
              <LiveStatusFilter
                defaultValue={currentFilters.LiveStatus}
                onChange={LiveStatus => onChange({ LiveStatus })}
                disabled={isLoading}
              />
            : null
          }
          {
            props.memberStatusFilter ?
              <MemberStatusFilter
                className="mr-lg-1"
                defaultValue={currentFilters.status}
                onChange={status => onChange({ status })}
                disabled={isLoading}
              />
            : null
          }
          {
            currentFilters.MaintanceTypeId !== undefined ?
              <MaintanceTypeFilter
                className="mr-lg-1"
                defaultValue={currentFilters.MaintanceTypeId}
                onChange={MaintanceTypeId => onChange({ MaintanceTypeId })}
                disabled={isLoading}
              />
            : null
          }
          {
            currentFilters.FailureTypeId !== undefined ?
              <FailureTypeFilter
                className="mr-lg-1"
                defaultValue={currentFilters.FailureTypeId}
                onChange={FailureTypeId => onChange({ FailureTypeId })}
                disabled={isLoading}
              />
            : null
          }

          {
            props.dateRangeFilter ?
              <DateRangeFilter
                start={currentFilters.start_date}
                end={currentFilters.end_date}
                onChange={range => onChange({ start_date: range.start, end_date: range.end })}
              />
            : null
          }
        </div>
      </div>
    </div>
  )
}

FilterMenu.Left = FilterMenuLeft
