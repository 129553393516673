import React, { useRef, useEffect, useState, useContext } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Container } from '../layout/AppLayout.js'
import Spinner from '../shared/Spinner.js';
import Header from "../shared/Header.js"
import { formatDate } from '../../utils.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faWalking} from '@fortawesome/free-solid-svg-icons';
import videojs from "video.js";
import 'video.js/dist/video-js.css';
import { isAllowed } from '../shared/FeatureChecker.js';
import { CurrentUserContext } from '../../App.js';

function VideoPlayer({ thumb_url, url }) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const options = {
        //width: '100%',
        autoplay: false,
        preload: 'auto',
        controls: true,
        responsive: true,
        fluid: true,
        poster: thumb_url,
        sources: [
          {
            src: url,
            type: 'video/mp4'
          }
        ]
      }

      const player = playerRef.current = videojs(videoElement, options, () => {
      });
    } else {
      const player = playerRef.current;
      player.autoplay(true);
      player.src(url);
      player.poster(thumb_url);
    }
  }, [url]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-fill vjs-big-play-centered" />
    </div>
  )
}

function MediaPreview({ currentMedia }) {
  let icons = []
  let people_count = currentMedia.tags.filter((tags) => tags.name === "Person").length
  if (people_count > 0) {
    for (let index = 0; index < people_count; index++) {
      icons.push(faWalking)
    }
  }
  return (
    <div >
    {
      currentMedia.mime_type === 'video/mp4'?(
          <VideoPlayer url={currentMedia.media_url} thumb_url={currentMedia.media_thumb_url} />
      ):
      (
        <img
          src={currentMedia.media_url}
          alt={currentMedia.url}
          width="100%"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "https://dinahosting.com/blog/upload/2020/07/eror-404.jpg";
          }}
        />
      )

    }

      <div
        className={`card-body p-2`}
        style={{ fontSize: '.85em' }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <div className="d-flex justify-content-between mt-3" style={{ fontSize: '0.9em' }}>
              <div className="d-flex justify-content-start">
                {
                  currentMedia.tags.map((e) =>
                    <div className="bg-light mr-2 border rounded d-flex justify-content-start align-items-center">
                      <div className="py-1 px-2 border-right">
                        <FontAwesomeIcon icon={faWalking} />
                      </div>
                      <div className="px-2">
                        <Link to={`/members/${e.user_id}`}>
                          {e.user_name}
                        </Link>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}



export default function AlertMediaDetailPage(isEntry=false) {
  const params = useParams()
  const mediaQuery = useQuery(`media/${params.MediaId}/tags`)
  const currentMedia = mediaQuery.data && mediaQuery.data.result || {}
  const currentUser = useContext(CurrentUserContext)
  const navigate = useNavigate()

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "DETAIL_ALERT_MEDIA"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  if (mediaQuery.isLoading) {
    return (
      <div className="my-4 py-4 text-center">
        <Spinner />
      </div>
    )
  }

  return (

    <Container>
      <div className="row">
        <Header title="Detalle de alerta" items={[
          { label: `Listado de alertas`, to: "/alerts" },
          { label: `Alerta ${formatDate(currentMedia.ts, 'dd/MM/YYY')}`, to: `/alerts/${params.AlertId}` },
          { label: `${formatDate(currentMedia.ts, 'HH:mm:ss')}` },
        ]}>

        </Header>
      </div>
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="card shadow-sm">
            <div className="card-body">
              <MediaPreview currentMedia={currentMedia} />
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

