import React, { useContext, useState } from 'react'
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js'

import { Link } from 'react-router-dom'
import { formatDate } from '../../utils.js'

import TableList from '../shared/TableList.js';
import Spinner from '../shared/Spinner.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faDoorClosed, faVectorSquare, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';

export default function AccessTable({ accesses }) {
  const CheckFeatures = useFeatureChecker()

  if(!accesses.length) {
    return <div className="text-center my-4 py-4 text-muted">No se encontraron permisos para los filtros especificados</div>
  }

  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="#" width={80} />
        <TableList.HeaderItem value="Fecha"  />
        <TableList.HeaderItem value="Usuario" width={200} />
        <TableList.HeaderItem value="Puerta" width={200} />
        <TableList.HeaderItem value="Zona" width={200} />
      </TableList.Header>

      <TableList.Body>
        {
          accesses.map(item => (
            <TableList.Row key={item.id}>

              <TableList.Column>
                <CheckFeatures feature='DETAIL_ACCESS'>
                  <AllowedFeature>
                    <Link style={{ fontFamily: 'monospace' }} to={`/permissions/${item.id}`}>
                      <span>#{item.id}</span>
                    </Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    <span>#{item.id}</span>
                  </DeniedFeature>
                </CheckFeatures>
              </TableList.Column>

              <TableList.Column>
                <div style={{ fontFamily: 'monospace' }}>
                  <div>Inicio: &nbsp;{formatDate(item.date_start)}</div>
                  <div>Término: {formatDate(item.date_end)}</div>

                  {
                    //<span>{formatDate(item.date_start)}</span>
                    //<span className="mx-2">hasta</span>
                    //<span>{formatDate(item.date_end)}</span>
                  }
                </div>
              </TableList.Column>

              <TableList.Column>
                <CheckFeatures feature='DETAIL_USER'>
                  <AllowedFeature>
                    <Link to={`/members/${item.User.id}`}>{item.User.name}</Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    {item.User.name}
                  </DeniedFeature>
                </CheckFeatures>
              </TableList.Column>

              <TableList.Column>
                <CheckFeatures feature= 'DETAIL_DOOR'>
                  <AllowedFeature>
                    <Link to={`/doors/${item.Door.id}`}>{item.Door.name}</Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    {item.Door.name}
                  </DeniedFeature>
                </CheckFeatures>
              </TableList.Column>

              <TableList.Column>
                <CheckFeatures feature='DETAIL_ZONE'>
                  <AllowedFeature>
                    <Link to={`/zones/${item.Door.zone_id}`}>{item.Door.zone_name}</Link>
                  </AllowedFeature>
                  <DeniedFeature>
                    {item.Door.zone_name}
                  </DeniedFeature>
                </CheckFeatures>
              </TableList.Column>
            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}
