import React, { useState, useCallback } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useDropzone } from 'react-dropzone'
import { useParams } from 'react-router-dom';
import { DestroyButton } from '../../shared/Buttons.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { BaseModal } from '../../shared/Modals.js'
import { DeviceListSelector } from '../../shared/ListSelector.js'
import FileUploaderModal from '../../shared/FileUploaderModal.js'
import { useMutationClient } from '../../shared/hooks.js';
import RiskSelector, {RiskSelect} from './RiskSelector.js'

const MITIGATION_TYPES = {
  DOCUMENT: 0,
  TECHNOLOGY: 1,
  POLICIES: 2,
  PHYSICAL: 3,
  HUMAN: 4,
}
export function TechnologyMitigationModal({ risk, onClose }){
  const params = useParams()
  const query = useQuery(`sva/${params.SvaId}/threats?SvaThreatId=${risk.sva_threat_id}`)
  const zoneId= query.data && query.data.result && query.data.result[0].zone_id || ''
  const openInNewTab = ZoneId => {
    window.open(`/sensors/new/${ZoneId}`, '_blank', 'noopener,noreferrer');
  };
  
  return (
    <BaseModal
      title={`Mitigación - Technology deployment`}
      size="lg"
      onClose={() => onClose()}
    >
      <BaseModal.Body>
        <p className="alert alert-info">Seleccione los dispositivos que mitiguen el riesgo <b>{risk.name}</b></p>
        <div className="col-12">
          <div className='row'>
            {
              query.isSuccess?
              <RiskSelector 
                ZoneId={zoneId}
                Risk={risk}
                Type={1}
              />
              : null
            }  
          </div>
        </div>
      </BaseModal.Body>

      <BaseModal.Footer>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div>
            <button className="btn btn-primary ml-2" onClick={() => openInNewTab(zoneId)}>Crear dispositivo</button>
            {
              //<button className="btn btn-primary ml-2" onClick={()=>query.refetch()}>Refrescar</button>
            }
          </div>

          <button className="btn btn-outline-secondary" onClick={() => onClose()}>Cerrar</button>
        </div>
      </BaseModal.Footer>
    </BaseModal>
  )
}

export function PoliciesMitigationModal({ risk, onClose }){
  //const [ files, setFiles ] = useState([])
  const mutationClient = useMutationClient()
  const query = useQuery(`mitigations?SvaRiskId=${risk.id}&type=2`)

  const documentsMutation = useMutation(
    data => mutationClient.post(`mitigations`, data),
    {
      onSuccess: () => {
        query.refetch()
      }
    }
  )

  return(
    <FileUploaderModal
    title="Mitigación - Policies and procedures"
    description={` Puede subir la documentación relacionada a políticas y/o
    procedimientos establecidos que ayuden a la mitigación del riesgo ${risk.name}`}
    onClose={() => onClose()}
    onSuccess={files => {
      documentsMutation.mutate({
        type: MITIGATION_TYPES.POLICIES,
        SvaRiskId: risk.id,
        files: files.map(x => ({
          id: x.id,
          name: x.name,
          size: x.size,
          type: x.type,
          file_key: x.file_key
        }))
      })
    }}
  />
  )
}
export function PoliciesMitigationFileModal({risk}){
  const query = useQuery(`mitigations?SvaRiskId=${risk.id}&type=2`)
  const mitigations = query.data && query.data.result || []
  return( 
    <div>
      {
        mitigations.map(items => (
          <div
            key={items.id}
            className="d-flex justify-content-between bg-light border rounded mb-2 p-2"
            style={{ fontSize: '.9em' }}
          >
            <a href={items.file_url} target="_blank">{items.metadata.file_name}</a>
            <DestroyButton
              confirmMessage="¿Está seguro que desea eliminar este documento?"
              successMessage="Documento eliminado correctamente"
              deletePath={`mitigations/${items.id}`}
              invalidate={`mitigations?SvaRiskId=${risk.id}&type=2`}
              size="sm"
            >
              <FontAwesomeIcon icon={faTrash} />
            </DestroyButton>
          </div>
        ))
        
      }
    </div>
  )

}

export function PhysicalMitigationModal({ risk, onClose }){
  const params = useParams()
  const query = useQuery(`sva/${params.SvaId}/threats?SvaThreatId=${risk.sva_threat_id}`)
  const zoneId= query.data && query.data.result && query.data.result[0].zone_id || ''

  const openInNewTab = ZoneId => {
    window.open(`/sensors/new/${ZoneId}`, '_blank', 'noopener,noreferrer');
  };
  return (
    <BaseModal
      title={`Mitigación - Physical deployment`}
      size="lg"
      onClose={() => onClose()}
    >
      <BaseModal.Body>
        <p className="alert alert-info">Seleccione los elementos físicos que mitiguen el riesgo <b>{risk.name}</b></p>
        <div className="col-12">
          <div className='row'>
            { query.isSuccess?
                <RiskSelector 
                ZoneId={zoneId}
                Risk={risk}
                Type={3}
              />
              :null
            }
          </div>
        </div>
      </BaseModal.Body>

      <BaseModal.Footer>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div>
            <button className="btn btn-primary ml-2" onClick={() => openInNewTab(zoneId)}>Crear dispositivo</button>
            {
              //<button className="btn btn-primary ml-2" onClick={()=>query.refetch()}>Refrescar</button>
            }
          </div>

          <button className="btn btn-outline-secondary" onClick={() => onClose()}>Cerrar</button>
        </div>
      </BaseModal.Footer>
    </BaseModal>
  )
}

export function HumanMitigationModal({ risk, onClose }){
  //const [ files, setFiles ] = useState([])
  const mutationClient = useMutationClient()
  const query = useQuery(`mitigations?SvaRiskId=${risk.id}&type=4`)

  const documentsMutation = useMutation(
    data => mutationClient.post(`mitigations`, data),
    {
      onSuccess: () => {
        query.refetch()
      }
    }
  )

  return(
    <FileUploaderModal
    title="Mitigación - Human deployment"
    description={`Suba archivos que tengan relación con la documentación de contrataciones de personal que ejecute actividades de seguridad que mitiguen el riesgo ${risk.name}`}
    onClose={() => onClose()}
    onSuccess={files => {
      documentsMutation.mutate({
        type: MITIGATION_TYPES.HUMAN,
        SvaRiskId: risk.id,
        files: files.map(x => ({
          id: x.id,
          name: x.name,
          size: x.size,
          type: x.type,
          file_key: x.file_key
        }))
      })
    }}
  />
  )
}
export function HumanMitigationFileModal({risk}){
  const query = useQuery(`mitigations?SvaRiskId=${risk.id}&type=4`)
  const mitigations = query.data && query.data.result || []
  return( 
    <div>
      {
        mitigations.map(items => (
          <div
            key={items.id}
            className="d-flex justify-content-between bg-light border rounded mb-2 p-2"
            style={{ fontSize: '.9em' }}
          >
            <a href={items.file_url} target="_blank">{items.metadata.file_name}</a>
            <DestroyButton
              confirmMessage="¿Está seguro que desea eliminar este documento?"
              successMessage="Documento eliminado correctamente"
              deletePath={`mitigations/${items.id}`}
              invalidate={`mitigations?SvaRiskId=${risk.id}&type=4`}
              size="sm"
            >
              <FontAwesomeIcon icon={faTrash} />
            </DestroyButton>
          </div>
        ))
        
      }
    </div>
  )

}
export function SensorCountModal({risk,type}){
  const query = useQuery(`mitigations?SvaRiskId=${risk.id}&type=${type}`)

  const sensorCount=query.data && query.data.result || []

  return (
    <>
      {
        query.isSuccess?
            sensorCount.length>0?
              <div>Hay {sensorCount.length} sensores</div>
            :null
        :null

      }
    </>
  )
}
