import React, { useState } from 'react';
import { useMutation } from 'react-query'
import { Link, useNavigate } from "react-router-dom";

import { useMutationClient } from '../shared/hooks.js'
import { ActionsContext } from '../../App.js'

export default function LoginPage() {
  const actions = React.useContext(ActionsContext)
  const mutationClient = useMutationClient()

  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const navigate = useNavigate()

  const loginMutation = useMutation(
    data => mutationClient.post('login', data), {
    onSuccess: (data) => {
      if( data.status === 200 ){
        actions.SetSession(data.data && data.data.result)
        navigate('/doors')
      }
    },
  })

  function login(e){
    e.preventDefault()
    loginMutation.reset()
    loginMutation.mutate({ email, password })
  }

  return (
    <div className="col-12 col-md-6 col-lg-3 mx-auto">
      <div className="card shadow-sm">
        <div className="card-body">

          <h4 className="py-2 text-center text-muted">Octoberus - Login</h4>

          <form onSubmit={login}>
            <div className="form-group">
              <label>Usuario</label>
              <input
                className="form-control"
                type="text"
                value={email}
                disabled={loginMutation.isLoading}
                onChange={e => setEmail(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>Contraseña</label>
              <input
                className="form-control"
                type="password"
                value={password}
                disabled={loginMutation.isLoading}
                onChange={e => setPassword(e.target.value)}
              />
            </div>

            {
              loginMutation.error ?
                <p className="text-danger text-center">Usuario o contraseña incorrectos</p>
              : null
            }

            <div className="form-group text-center">
              <input
                type="submit"
                className="btn btn-primary btn-block"
                value={`${ loginMutation.isLoading ? 'Iniciando sesión...' : 'Iniciar sesión' }`}
                disabled={loginMutation.isLoading}
              />
            </div>

            <div className="form-group text-center">
              <Link to="/password/recover">Olvidé mi contraseña</Link>
            </div>
          </form>

        </div>
      </div>
    </div>
  )
}

