import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from 'react-query'
//import { useDropzone } from 'react-dropzone'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import Spinner from '../../shared/Spinner.js'
import { RISK_ACTIONS } from './RiskAssestment.js';
import { DestroyButton } from '../../shared/Buttons.js'

import {
  TechnologyMitigationModal,
  PoliciesMitigationModal,
  PhysicalMitigationModal,
  HumanMitigationModal,
  PoliciesMitigationFileModal,
  HumanMitigationFileModal,
  SensorCountModal
} from './RiskMitigationModals.js'

import FileUploaderModal from '../../shared/FileUploaderModal.js'

import { useMutationClient } from '../../shared/hooks.js';

const MITIGATION_TYPES = {
  DOCUMENT: 0,
  TECHNOLOGY: 1,
  POLICIES: 2,
  PHYSICAL: 3,
  HUMAN: 4,
}

function TabItem({ children, onNewItem }){
  return (
    <div
      //className={`${isActive ? 'px-3 mt-3 pb-3 rounded-top' : 'p-3' }`}
      //className={`${isActive ? 'px-3 mt-3 pb-3 rounded-top' : 'my-2' }`}
      //className={`${ isActive ? 'py-2 px-2' : 'my-2' }`}
      className="my-2"
    >
      { children }

      <button
        className="btn btn-sm btn-block btn-light"
        onClick={() => onNewItem() }
      >+ Agregar</button>
    </div>
  )
}

function RiskMitigationItem({ item }){
  const [ currentForm, setCurrentForm ] = useState(null)
  let modal;

  switch (currentForm) {
    case MITIGATION_TYPES.TECHNOLOGY:
      modal = (
        <TechnologyMitigationModal
          risk={item}
          onClose={() => setCurrentForm(null)}
        />
      )
    break;

    case MITIGATION_TYPES.POLICIES:
      modal = (
        <PoliciesMitigationModal
          risk={item}
          onClose={() => setCurrentForm(null)}
        />
      )
    break;

    case MITIGATION_TYPES.HUMAN:
      modal = (
        <HumanMitigationModal
          risk={item}
          onClose={() => setCurrentForm(null)}
        />
      )
    break;

    case MITIGATION_TYPES.PHYSICAL:
      modal = (
        <PhysicalMitigationModal
          risk={item}
          onClose={() => setCurrentForm(null)}
        />
      )
    break;
  }

  return (
    <div key={item.id} className="row mb-2">
      <div className="col-12">
        <div className="card">
          <div className="row">
            <div className="col-2">
              <div className="p-3">{ item.name }</div>
            </div>

            <div className="col">
              <SensorCountModal
                risk={item}
                type={1}
              />
              <TabItem
                onNewItem={() => setCurrentForm(MITIGATION_TYPES.TECHNOLOGY)}
              >
              </TabItem>
            </div>

            <div className="col">
              <PoliciesMitigationFileModal
                risk={item}
              />
              <TabItem
                onNewItem={() => setCurrentForm(MITIGATION_TYPES.POLICIES)}
              >
              </TabItem>
            </div>

            <div className="col">
              <SensorCountModal
                risk={item}
                type={3}
              />
              <TabItem
                onNewItem={() => setCurrentForm(MITIGATION_TYPES.PHYSICAL)}
              >
              </TabItem>
            </div>

            <div className="col mr-3">
              <HumanMitigationFileModal
                risk={item}
              />
              <TabItem
                onNewItem={() => setCurrentForm(MITIGATION_TYPES.HUMAN)}
              >
              </TabItem>
            </div>
          </div>
        </div>
      </div>

      { modal }
    </div>
  )
}

function RiskRemarks({ risk, isLoading, isSuccess, onSave }){
  const [ showForm, setShowForm ] = useState(false)
  const [ comment, setComment ]= useState(risk.comments || '')

  useEffect(() => {
    if( isSuccess ){
      setShowForm(false)
    }
  }, [isSuccess])

  function submitForm(e){
    if(e) e.preventDefault()
    setShowForm(false)
    onSave({ comments: comment })
  }

  return (
    <div className="py-3">
      {
        showForm ?
          <form onSubmit={submitForm}>
            <label className="font-weight-bold">Observaciones</label>
            <textarea
              className="form-control"
              autoFocus={true}
              value={comment}
              onChange={e => setComment(e.target.value)}
              onKeyUp={e => {
                if(e.keyCode){
                  if(e.keyCode === 13 && e.ctrlKey){
                    submitForm()
                  }
                }else{
                  if(e.code === 'Enter' && e.ctrlKey){
                    submitForm()
                  }
                }
              }}
              disabled={isLoading}
              rows={4}
            ></textarea>

            <div className="mt-3 d-flex justify-content-between">
              <button
                className="btn btn-sm btn-outline-primary"
                disabled={isLoading}
              >Guardar</button>

              <button
                className="btn btn-sm btn-link"
                disabled={isLoading}
              >Cancelar</button>
            </div>
          </form>
        :
          risk.comments ?
            <p className="text-justify">
              {risk.comments}

              <a
                href="#"
                className="ml-2"
                onClick={(e) => {
                  e.preventDefault()
                  setShowForm(true)
                }}
              >Editar</a>
            </p>
          :            <button
              className="btn btn-sm btn-light"
              onClick={() => setShowForm(true)}
            >+ Agregar observaciones</button>
      }
    </div>
  )
}

function RiskDocuments({ risk }){
  const mutationClient = useMutationClient()
  const [ showForm, setShowForm ] = useState(false)

  const query = useQuery(`mitigations?SvaRiskId=${risk.id}`)
  const mitigations = query.data && query.data.result || []
  const documentsMutation = useMutation(
    data => mutationClient.post(`mitigations`, data),
    {
      onSuccess: () => {
        query.refetch()
      }
    }
  )

  return (
    <div className="py-3">
      <div className="mr-3">
        {
          mitigations.map(item => (
            <div
              key={item.id}
              className="d-flex justify-content-between bg-light border rounded mb-2 p-2"
              style={{ fontSize: '.9em' }}
            >
              <a href={item.file_url} target="_blank">{item.metadata.file_name}</a>
              <DestroyButton
                confirmMessage="¿Está seguro que desea eliminar este documento?"
                successMessage="Documento eliminado correctamente"
                deletePath={`mitigations/${item.id}`}
                invalidate={`mitigations?SvaRiskId=${risk.id}`}
                size="sm"
              >
                <FontAwesomeIcon icon={faTrash} />
              </DestroyButton>
            </div>
          ))
        }
      </div>

      <button
        className="btn btn-sm btn-light"
        onClick={() => setShowForm(true)}
      >+ Subir documentación </button>

      {
        showForm ?
          <FileUploaderModal
            title="Subir documentos"
            onClose={() => setShowForm(false)}
            onSuccess={files => {
              documentsMutation.mutate({
                type: MITIGATION_TYPES.DOCUMENT,
                SvaRiskId: risk.id,
                files: files.map(x => ({
                  id: x.id,
                  name: x.name,
                  size: x.size,
                  type: x.type,
                  file_key: x.file_key
                }))
              })
            }}
          />
        : null
      }
    </div>
  )
}

function RisksItem({ item, refetch, SvaId }){
  const mutationClient = useMutationClient()

  const updateRisk = useMutation(
    data => mutationClient.put(`sva/${SvaId}/threats/${item.sva_threat_id}/risks/${item.id}`, data),
    {
      onSuccess: () => {
        refetch()
      }
    }
  )

  return (
    <div key={item.id} className="row mb-2">
      <div className="col-12">
        <div className="card">
          <div className="row">
            <div className="col-2">
              <div className="p-3">{ item.name }</div>
            </div>

            <div className="col-5">
              <RiskRemarks
                risk={item}
                isLoading={updateRisk.isLoading}
                isSuccess={updateRisk.isSuccess}
                onSave={data => {
                  updateRisk.reset()
                  updateRisk.mutate(data)
                }}
              />
            </div>

            <div className="col-5">
              <RiskDocuments risk={item} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function RisksList({ title, items, refetch, SvaId }){
  return (
    <React.Fragment>
      <div className="row">
        <div className='col-auto'>
          <div
            className='my-3 text-uppercase'
            style={{
              fontWeight: 'bold',
              color: '#222',
              fontSize: '.9em',
              letterSpacing: 1.2
            }}
          >{ title }</div>
        </div>
      </div>

      <div className='row'>
        <div className="col-12">
          {
            items.map(item =>(
              <RisksItem
                key={item.id}
                item={item}
                refetch={refetch}
                SvaId={SvaId}
              />
            ))
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default function RisksMitigation({ currentSva }){
  const query = useQuery(`sva/${currentSva.id}/risks`)
  const currentRisks = query.data && query.data.result || []

  const risks = currentRisks.reduce((acc, item) => {
    if(item.action){
      acc[item.action].push(item)
    }
    return acc
  }, {
    [RISK_ACTIONS.ASSUME]: [],
    [RISK_ACTIONS.AVOID]: [],
    [RISK_ACTIONS.TRANSFER]: [],
    [RISK_ACTIONS.MITIGATE]: [],
  })

  if( query.isLoading ){
    return (
      <div className="text-center my-4 py-4">
        <Spinner />
      </div>
    )
  }

  return(
    <div className="row">
      <div className="col-12">

        {
          risks[RISK_ACTIONS.MITIGATE].length ?
            <React.Fragment>
              <div className="row">
                <div className='col-auto'>
                  <div
                    className='my-3 text-uppercase'
                    style={{
                      fontWeight: 'bold',
                      color: '#222',
                      fontSize: '.9em',
                      letterSpacing: 1.2
                    }}
                  >Riesgos a mitigar</div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="row p-3 text-uppercase text-muted" style={{ fontSize: '.9em' }}>
                    <div className="col-2">Riesgo</div>
                    <div className="col text-center">Technology deployment</div>
                    <div className="col text-center">Policies and procedures</div>
                    <div className="col text-center">Physical deployment</div>
                    <div className="col text-center">Human deployment</div>
                  </div>
                </div>
              </div>

              {
                risks[RISK_ACTIONS.MITIGATE].map(item =>
                  <RiskMitigationItem
                    key={item.id}
                    item={item}
                    refetch={query.refetch}
                    SvaId={currentSva.id}
                />)
              }
            </React.Fragment>
          : null
        }

        {
          risks[RISK_ACTIONS.TRANSFER].length ?
            <RisksList
              title="Riesgos a transferir"
              items={risks[RISK_ACTIONS.TRANSFER]}
              refetch={query.refetch}
              SvaId={currentSva.id}
            />
          : null
        }

        {
          risks[RISK_ACTIONS.ASSUME].length ?
            <RisksList
              title="Riesgos a asumir"
              items={risks[RISK_ACTIONS.ASSUME]}
              refetch={query.refetch}
              SvaId={currentSva.id}
            />
          : null
        }

        {
          risks[RISK_ACTIONS.AVOID].length ?
            <RisksList
              title="Riesgos a evitar"
              items={risks[RISK_ACTIONS.AVOID]}
              refetch={query.refetch}
              SvaId={currentSva.id}
            />
         : null
        }
      </div>
    </div>
  );
}
