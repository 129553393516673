export default function (change) {
  switch (change) {
    case "Create":
      return "Creación";
    case "Updated":
      return "Actualización";
    case "Update":
      return "Actualización";
    case "Disable":
      return "Deshabilitación";
    case "Delete":
      return "Eliminación";     
    default:
      return change;
  }
}
