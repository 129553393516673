import React, { useRef, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Container } from '../layout/AppLayout.js'
import Spinner from '../shared/Spinner.js';
import Header from "../shared/Header.js"
import { formatDate } from '../../utils.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faWalking} from '@fortawesome/free-solid-svg-icons';
import videojs from "video.js";
import 'video.js/dist/video-js.css';

import translateAlert from "../alerts/translate";
function VideoPlayer({ thumb_url, url }) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const options = {
        //width: '100%',
        autoplay: false,
        preload: 'auto',
        controls: true,
        responsive: true,
        fluid: true,
        poster: thumb_url,
        sources: [
          {
            src: url,
            type: 'video/mp4'
          }
        ]
      }

      const player = playerRef.current = videojs(videoElement, options, () => {
      });
    } else {
      const player = playerRef.current;
      player.autoplay(true);
      player.src(url);
      player.poster(thumb_url);
    }
  }, [url]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-fill vjs-big-play-centered" />
    </div>
  )
}

function MediaPreview({ currentMedia }) {
  let icons = []
  let people_count = currentMedia.tags.filter((tags) => tags.name === "Person").length
  if (people_count > 0) {
    for (let index = 0; index < people_count; index++) {
      icons.push(faWalking)
    }
  }
  return (
    <div >
    {
      currentMedia.mime_type === 'video/mp4'?(
          <VideoPlayer url={currentMedia.media_url} thumb_url={currentMedia.media_thumb_url} />
      ):
      (
        <img
          src={currentMedia.media_url}
          alt={currentMedia.url}
          width="100%"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "https://dinahosting.com/blog/upload/2020/07/eror-404.jpg";
          }}
        />
      )

    }

      <div
        className={`card-body p-2`}
        style={{ fontSize: '.85em' }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <div className="d-flex justify-content-between mt-3" style={{ fontSize: '0.9em' }}>
              <div className="d-flex justify-content-start">
                {
                  currentMedia.tags.map((e) =>
                    <div className="bg-light mr-2 border rounded d-flex justify-content-start align-items-center">
                      <div className="py-1 px-2 border-right">
                        <FontAwesomeIcon icon={faWalking} />
                      </div>
                      <div className="px-2">
                        <Link to={`/members/${e.user_id}`}>
                          {e.user_name}
                        </Link>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function DetailCardLoadSensor({ currentEvent }) {
  const mediaQuery = useQuery(`sensors?SensorId=${currentEvent.sensor_id}`)
  const currentSensor = mediaQuery.data && mediaQuery.data.result && mediaQuery.data.result[0] || null;
  const isLoadingSensor = mediaQuery.isLoading;
  if (isLoadingSensor || currentSensor == null) {
    return (<div className="text-center py-4 my-4">
      <Spinner />
    </div>)
  }
  return (
    <table className="table m-0">
      <tbody>
        <tr>
          <th className="border-0">Fecha</th>
            <td>
              <div className='row'>
                <span className="text-left">
                {formatDate(currentEvent.ts, "cccc d 'de' MMMM yyy")}
                </span>
              </div>    
            </td>
        </tr>

        <tr>
          <th>Hora</th>
            <td>
              <div className='row'>
                <span className="text-left">
                  {formatDate(currentEvent.ts, "HH:mm:ss")}
                </span>
              </div>    
            </td>
        </tr>

        <tr>
          <th>Dispositivo</th>
          <td>
            <div className='row'>
              <span className="text-left">
                <Link to={`/sensors/${currentSensor.id}`}>{currentSensor.name}</Link>
              </span>
            </div>
          </td>
        </tr>

        <tr>
          <th>Acceso/Alerta</th>
          <td>
          {
              currentEvent.alert.length>0?
                (
                  currentEvent.alert.map((a)=>{
                    return (
                      <div className='row'>
                        <span className="text-left">
                          <Link key={a.id} to={`/alerts/${a.id}`}>
                            {translateAlert(a.name)}
                          </Link>
                        </span>
                      </div>
                    )
                  })
                  
                )
                : <span className="badge rounded-pill bg-light border p-2">Sin asignar</span>
            }
          </td>
        </tr>

        <tr>
          <th>Puerta</th>
          <td>
            <div className='row'>
              <span className="text-left">
              {
                (currentSensor.door_ids && currentSensor.door_ids.length > 0) ?
                  (
                    <Link key={currentSensor.door_ids[0]} to={`/doors/${currentSensor.door_ids[0]}`}>
                      {currentSensor.door_name}
                    </Link>
                  )
                  : <span className="badge rounded-pill bg-light border p-2">Sin asignar</span>
              }
              </span>
            </div>    
          </td>
        </tr>

        <tr>
          <th>Zona</th>
          <td>
            <div className='row'>
              <span className="text-left">
              {
                currentSensor.zone_id ?
                  (
                    <Link key={currentSensor.zone_id} to={`/zones/${currentSensor.zone_id}`}>
                      {currentSensor.zone_name}
                    </Link>
                  )
                  : <span className="badge rounded-pill bg-light border p-2">Sin asignar</span>
              }
              </span>
            </div>    
          </td>
        </tr>

        <tr>
          <th>Instalación</th>
          <td>
            <div className='row'>
              <span className="text-left">
              {
                currentSensor.facility_id ?
                  (
                    <Link key={currentSensor.facility_id} to={`/facilities/${currentSensor.facility_id}`}>
                      {currentSensor.facility_name}
                    </Link>
                  )
                  : <span className="badge rounded-pill bg-light border p-2">Sin asignar</span>
              }
            
              </span>
            </div>    
          </td>
        </tr>

      </tbody>
    </table>
  )
}

function DetailCard({ currentEvent, isLoading }) {

  return (
    <div className="card shadow-sm" style={{ fontSize: '.9em' }}>
      <div className="card-header">
        Detalle
      </div>
      {
        isLoading ? (
          <div className="text-center py-4 my-4">
            <Spinner />
          </div>
        ) : <DetailCardLoadSensor currentEvent={currentEvent} isLoading={isLoading} />
      }

    </div>
  )
}


export default function MemebersIdentificationMediaPage() {
  const params = useParams()
  const mediaQuery = useQuery(`media/${params.MediaId}/tags`)
  const currentMedia = mediaQuery.data && mediaQuery.data.result || {}



  if (mediaQuery.isLoading) {
    return (
      <div className="my-4 py-4 text-center">
        <Spinner />
      </div>
    )
  }

  return (

    <Container>
      <div className="row">
        <Header title="Detalle de media"
          items={[
            { label: "Listado de usuarios", to: "/members" },
            { label: "Detalle de usuario", to: `/members/${params.UserId}` },
            { label: "Identidades de usuario", to: `/members/${params.UserId}/identities` },
            { label: "Identificaciones de usuario", to: `/members/${params.UserId}/identification` },
            { label: "Media"}
          ]}
        >

        </Header>
      </div>
      <div className="row justify-content-center">
        <div className="col-8">
          <div className="card shadow-sm">
            <div className="card-body">
              <MediaPreview currentMedia={currentMedia} />
            </div>
          </div>
        </div>
        <div className="col-4">
            <DetailCard  currentEvent={currentMedia} isLoading={mediaQuery.isLoading}/>      
        </div> 
      </div>
    </Container>
  )
}

