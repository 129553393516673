import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query'

import { formatDistanceStrict, parseISO, subMinutes } from 'date-fns'
import { es } from 'date-fns/locale'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWalking,
  faBicycle,
  faDog,
  faCar,
  faImage,
  faVideo
} from '@fortawesome/free-solid-svg-icons';

import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import { formatDate, TIME_ZONE } from '../../utils.js'
import { useNotifications } from '../shared/Notifications.js'
import Spinner from '../shared/Spinner.js';
import Header from "../shared/Header.js";
import { useSensorType } from '../shared/hooks.js'
import { formatDistanceToNow } from 'date-fns'

import { EntryStateIndicator } from './LogsTable.js'

import { MapContainer, TileLayer, useMap, Marker, Popup, useMapEvents, Circle, FeatureGroup } from 'react-leaflet'

function MapCard({ entry }) {
  const query = useQuery(`doors/access?DoorId=${entry.door_id}`)

  const items = query.data && query.data.result && query.data.result[0] && query.data.result[0].Door || []
  if (items == []) { return null; }
  if (items.latitude == null || items.longitude == null) {
    return <Spinner />;
  }
  try {
    const door = [items.latitude, items.longitude]

    var access = []
    if (entry.latitude && entry.longitude) {
      access = [entry.latitude, entry.longitude]
    }

    return (
      <MapContainer center={door} zoom={30} scrollWheelZoom={false} style={{ height: '50vh' }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={door}>
          <Popup>
            {entry.door_name}
          </Popup>
        </Marker>
        {
          access.length == 0 ? null : (<Marker position={access} >
            <Popup>
              acceso
            </Popup>
          </Marker>)
        }

        <FeatureGroup pathOptions={{ color: 'red' }}>
          <Popup>radio de 75m</Popup>
          <Circle center={door} radius={75} />
        </FeatureGroup>
        <FeatureGroup pathOptions={{ color: 'purple' }}>
          <Popup>radio de 50m</Popup>
          <Circle center={door} radius={50} />
        </FeatureGroup>
        <FeatureGroup pathOptions={{ color: 'blue' }}>
          <Popup>radio de 25m</Popup>
          <Circle center={door} radius={25} />
        </FeatureGroup>
        <FeatureGroup pathOptions={{ color: 'orange' }}>
          <Popup>radio de 10m</Popup>
          <Circle center={door} radius={10} />
        </FeatureGroup>
        <FeatureGroup pathOptions={{ color: 'green' }}>
          <Popup>radio de 5m</Popup>
          <Circle center={door} radius={5} />
        </FeatureGroup>


      </MapContainer>
    );

  } catch (error) {
    return <span>Sin Ubicación registrados para este evento</span>;
  }
}

function DetailsCard({ entry, isLoading }) {
  if (isLoading || !entry) {
    return (
      <div className="text-center my-4 py-4">
        <Spinner />
      </div>
    )
  }

  const elapsedTime = formatDistanceStrict(parseISO(entry.created_at), new Date(), { locale: es })

  let duration

  if (entry.closed_at) {
    duration = formatDistanceStrict(parseISO(entry.created_at), parseISO(entry.closed_at), { locale: es })
  }

  return (
    <div className="card shadow-sm">
      <div className="card-header">Detalle del evento</div>
      <div className="card-body">
        <div className="form-group">
          <label className="form-label font-weight-bold">Tipo de evento</label>

          <div>
            <EntryStateIndicator entry={entry} />
          </div>
        </div>

        <div className="form-group">
          <label className="form-label font-weight-bold">Fecha de creación</label>
          <p>{formatDate(entry.created_at, 'dd/MM/yyyy HH:mm:ss')} - Hace {elapsedTime}</p>
        </div>

        <div className="form-group">
          <label className="form-label font-weight-bold">Fecha y hora de apertura</label>
          <p>
            {
              entry.opened_at ?
                `${formatDate(entry.opened_at, 'dd/MM/yyyy HH:mm:ss')}`
                : 'Sin apertura confirmada'
            }
          </p>
        </div>

        <div className="form-group">
          <label className="form-label font-weight-bold">Fecha y hora de cierre</label>
          <p>
            {
              entry.closed_at ?
                `${formatDate(entry.closed_at, 'dd/MM/yyyy HH:mm:ss')}`
                : 'Sin cierre confirmado'
            }
          </p>
        </div>

        {
          entry.access_id ?
            <div className="form-group">
              <label className="form-label font-weight-bold">Permiso</label>
              <div>
                <Link to={`/permissions/${entry.access_id}`}>
                  <span style={{ fontFamily: 'monospace' }} className="mr-2">#{entry.access_id}</span>
                  {
                    entry.Access ?
                      <span className="mr-2">{entry.Access.name}</span>
                      : null
                  }
                </Link>

                {
                  entry.Access && entry.Access.description ?
                    <p className="mt-2 text-muted">
                      <span className="mr-2">{entry.Access.description}</span>
                    </p>
                    : null
                }
              </div>
            </div>
            : null
        }

        <div className="form-group">
          <label className="form-label font-weight-bold">Puerta y Zona</label>
          <p>
            <Link to={`/doors/${entry.door_id}`}>{entry.door_name}</Link>
            <span> - </span>
            {
              entry.department_name ?
                <Link to={`/zones/${entry.department_id}`}>{entry.department_name}</Link>
                : 'Sin zona'
            }
          </p>
        </div>
        {
          entry.state == 2 ? null :
            (
              <div className="form-group">
                <label className="form-label font-weight-bold">Usuario</label>
                <p>
                  {
                    entry.UserId ?
                      <Link to={`/members/${entry.UserId}`}>{entry.user_name}</Link>
                      : '--'
                  }
                </p>
              </div>
            )
        }

        {
          entry.longitude && entry.latitude ?
            <div className="form-group m-0">
              <label className="form-label font-weight-bold">Ubicación</label>

              <p>
                <a
                  href={`https://www.google.com/maps?q=${entry.latitude},${entry.longitude}`}
                  target="_blank"
                  style={{ fontFamily: 'monospace' }}
                >{entry.latitude}, {entry.longitude}</a>
              </p>
            </div>
            : null
        }
      </div>
    </div>
  )
}

function MediaCard({ EntryId }) {
  const query = useQuery(`media?EntryId=${EntryId}`)
  const media = query.data && query.data.result || []

  let content = null

  if (query.isLoading) {
    content = (
      <div className="text-center py-4 my-4">
        <Spinner />
      </div>
    )
  } else {
    if (media.length) {
      content = (
        media.map(item => (
          <div key={item.id} className="py-2 pl-2 border-bottom d-flex justify-content-start align-items-top">
            <div className="mr-3">
              <Link to={`/media/${item.id}`}>
                <img src={item.thumb_url} width="200" alt="" />
              </Link>
            </div>

            <div>
              <div>
                <Link to={`/media/${item.id}`} style={{ fontFamily: 'monospace', fontSize: '.9em' }}>{formatDate(item.ts, "HH:mm:ss")}</Link>
              </div>

              <div className="d-flex justify-content-start mt-2" style={{ fontSize: '.9em' }}>
                <div className="bg-light mr-2 border rounded">
                  <div className="px-2 py-1">
                    {
                      item.video_url ? <FontAwesomeIcon icon={faVideo} /> : <FontAwesomeIcon icon={faImage} />
                    }

                  </div>
                </div>

                {
                  item.people_count ?
                    <div className="bg-light mr-2 border rounded">
                      <div className="px-2 py-1">
                        <FontAwesomeIcon icon={faWalking} />
                      </div>
                    </div>
                    : null
                }

                {
                  item.animal_count ?
                    <div className="bg-light mr-2 border rounded">
                      <div className="px-2 py-1">
                        <FontAwesomeIcon icon={faDog} />
                      </div>
                    </div>
                    : null
                }

                {
                  item.bicycle_count ?
                    <div className="bg-light mr-2 border rounded">
                      <div className="px-2 py-1">
                        <FontAwesomeIcon icon={faBicycle} />
                      </div>
                    </div>
                    : null
                }

                {
                  item.cars_count ?
                    <div className="bg-light mr-2 border rounded">
                      <div className="px-2 py-1">
                        <FontAwesomeIcon icon={faCar} />
                      </div>
                    </div>
                    : null
                }

              </div>

            </div>
          </div>
        ))
      )
    } else {
      content = (
        <div className="text-muted text-center py-4 my-4">Sin imágenes o videos registrados para este evento</div>
      )
    }
  }

  return (
    <div className="card shadow-sm">
      <div className="card-header">Media</div>
      {content}
    </div>
  )
}

function SensorItem({ sensor, limit, time }) {
  const query = useQuery(`sensors/${sensor.id}/data?limit=${limit}&dateStart=${time}`)
  const sensorType = useSensorType(sensor.sensor_type_id)
  const attributes = sensorType && sensorType.variables || []


  function variableValue(data, attribute) {
    let value = '';

    if (data && data.items && data.items.length) {
      value = data.items[0][attribute.field]
      if (value === undefined) {
        return 'Sin datos encontrados'
      }

      if (attribute.values) {
        value = attribute.values[value] || ''
      } else {
        if (!Number.isInteger(value)) {
          try {
            value = value.toFixed(2)
          } catch (e) {
          }
        }

        if (attribute.unit) {
          value = `${value} ${attribute.unit}`
        }
      }
    }

    return value
  }

  if (attributes.length == 0) {
    return null;
  }

  return (
    <>
      {
        attributes.map(item => (
          <tr key={item.field}>
            <td>
              {item.label}
            </td>

            <td
              style={{
                opacity: (query.isFetching || query.isLoading) ? 0.5 : 1
              }}
            >
              {
                query.isLoading ?
                  'cargando...'
                  :
                  variableValue(query.data && query.data.result, item)
              }
            </td>
          </tr>
        ))
      }
    </>
  )
}

function SensorsDataCard({ DoorId, limit, time }) {
  const query = useQuery(`sensors?DoorId=${DoorId}`, { enabled: !!DoorId })
  const devices = query.isSuccess && query.data && query.data.result || []
  if (query.isLoading) {
    return <Spinner />
  } else {
    if (query.isSuccess && !devices.length) {
      return (
        <div className="text-muted text-center my-4 py-4">No se encontraron dispositivos asociados</div>
      )
    }

    return (
      <table className="table mb-0" style={{ fontSize: '0.9em' }}>
        <tbody>
          {
            devices.map(item =>
              <SensorItem key={item.id} sensor={item} limit={limit} time={time} />
            )
          }
        </tbody>
      </table>
    )
  }
}

function SensorCard({ entry, isLoading, limit }) {
  if (isLoading || !entry) {
    return (
      <div className="text-center my-4 py-4">
        <Spinner />
      </div>
    )
  }
  return (
    <Card className="shadow-sm mb-3 mt-2">
      <Card.Header>Información de los dispositivos</Card.Header>
      <Card.Body className="p-0">
        {
          <SensorsDataCard DoorId={entry.door_id} limit={limit} time={entry.created_at} />
        }
      </Card.Body>
    </Card>
  )
}


export default function EntryDetailPage({isInstruction=false}) {
  const params = useParams()
  const navigate = useNavigate()
  const notifications = useNotifications()

  const query = useQuery(`entries?EntryId=${params.EntryId}`)
  const currentEntry = query.data
    && query.data.result
    && query.data.result.length
    && query.data.result[0]

  useEffect(() => {
    if (query.isSuccess && !currentEntry) {
      navigate('/entries')
      notifications.warning('Acceso no encontrado')
    }
  }, [query.isLoading, currentEntry])

  return (
    <Container>
      <Row>
        <Header title="Detalle de acceso" items={[
          { label: isInstruction?"Listado de instrucción de apertura":"Listado de accesos", to: isInstruction?"/instructions":"/entries" },
          { label: "Detalle de acceso" },
        ]} />
      </Row>

      <Row className="mb-4 pb-4">

        <div className="col-12 mb-2 col-lg-6">
          <DetailsCard entry={currentEntry} isLoading={query.isLoading} />
          <SensorCard entry={currentEntry} isLoading={query.isLoading} limit={1} />
        </div>

        <div className="col-12 mb-2 col-lg-6">
          <MediaCard EntryId={params.EntryId} />
          <div className="card shadow-sm mb-2 mt-2">
            <div className="card-header">Ubicación</div>
            <div className="card-body ">
              {
                query.isLoading ? <Spinner /> : <MapCard entry={currentEntry} />
              }
            </div>
          </div>

        </div>

      </Row>
    </Container>
  )
}