import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from 'react-query'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { useMutationClient } from '../../shared/hooks.js'
import { useNotifications } from '../../shared/Notifications.js'
import { Searchbox } from '../../shared/Search.js'
import { DestroyButton } from '../../shared/Buttons.js'
import { DropdownSelector } from '../../shared/Filters'
import Spinner from '../../shared/Spinner.js'

import { ThreatLevelIndicator } from './ThreatAssestment.js'

export const RISK_ACTIONS = {
  ASSUME: "assume",
  AVOID: "avoid",
  TRANSFER: "transfer",
  MITIGATE: "mitigate",
}

export const RISK_ACTIONS_LABELS = {
  [RISK_ACTIONS.ASSUME]: 'Asumir',
  [RISK_ACTIONS.AVOID]: "Evitar",
  [RISK_ACTIONS.TRANSFER]: "Transferir",
  [RISK_ACTIONS.MITIGATE]: "Mitigar",
}

function RiskFormItem({ item, currentSva, currentThreat, onChange }){
  const mutationClient = useMutationClient()
  const notifications = useNotifications()

  const addRisk = useMutation(
    data => mutationClient.post(`sva/${currentSva.id}/threats/${currentThreat.id}/risks`, data), {
    onSuccess: (data) => {
      if( data.status === 200 ){
        notifications.success('Riesgo agregado')
        onChange()
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al agregar el riesgo')
    }
  })

  return (
    <div className="list-group-item list-group-item-action d-flex align-items-center">
      <div className="mr-3">
        <button
          className="btn btn-sm btn-outline-info"
          onClick={() => addRisk.mutate({ risk_id: item.id })}
          disabled={addRisk.isLoading}
        >
          <FontAwesomeIcon icon={faPlusCircle} />
        </button>
      </div>

      <div>
        { item.name }
      </div>
    </div>
  )
}

function AddRiskForm({ currentSva, currentThreat, onChange }){
  const [ search, setSearch ] = useState('')

  const query = useQuery(
    `risks?ExcludeSvaId=${currentSva.id}&ExcludeThreatId=${currentThreat.id}&search=${search}&limit=5`,
    { keepPreviousData: true }
  )

  const risks = query.data && query.data.result || []

  return (
    <div className="m-3">
      <div className="w-25">
        <Searchbox
          placeholder="Filtrar riesgos"
          onSearch={q => setSearch(q)}
          autoFocus={true}
        />
      </div>

      <div className="list-group mt-3 shadow-sm">
        {
          risks.map(item => (
            <RiskFormItem
              key={item.id}
              item={item}
              currentSva={currentSva}
              currentThreat={currentThreat}
              onChange={() => {
                query.refetch()
                onChange()
              }}
            />
          ))
        }
      </div>
    </div>
  )
}

function ImpactLevelSelector({ value, disabled, onChange }){
  const items = [
    { value: 1, label: 'Impacto insignificante' },
    { value: 2, label: 'Impacto menor' },
    { value: 3, label: 'Impacto moderado' },
    { value: 4, label: 'Impacto significante' },
    { value: 5, label: 'Impacto severo' },
  ]

  const defaultItem = items.find(x => x.value === value)

  return (
    <DropdownSelector
      defaultLabel="Nivel de impacto"
      items={items}
      defaultItem={defaultItem}
      onChange={selection => selection ? onChange(selection.value) : onChange(null)}
      disabled={disabled}
    />
  )
}

function RiskActionSelector({ value, disabled, onChange }){
  const items = Object.keys(RISK_ACTIONS_LABELS).map(x => ({
    value: x,
    label: RISK_ACTIONS_LABELS[x]
  }))

  const defaultItem = items.find(x => x.value === value)

  return (
    <DropdownSelector
      defaultLabel="Acción"
      items={items}
      defaultItem={defaultItem}
      onChange={selection => selection ? onChange(selection.value) : onChange(null)}
      disabled={disabled}
    />
  )
}

function RiskItem({ item, currentSva, currentThreat, refetch }){
  const [ impact, setImpact ] = useState(item.value)
  const [ action, setAction ] = useState(item.action)

  const mutationClient = useMutationClient()
  const notifications = useNotifications()

  const update = useMutation(
    data => mutationClient.put(`sva/${currentSva.id}/threats/${currentThreat.id}/risks/${item.id}`, data), {
    onSuccess: (data) => {
      if( data.status === 200 ){
        notifications.success('Riesgo actualizado')
        refetch()
      }
    },
    onError: () => {
      notifications.danger('Ha ocurrido un error al actualizar el riesgo')
    }
  })

  useEffect(() => {
    if( item.value !== impact ){
      update.mutate({ value: impact })
    }
  }, [impact])

  useEffect(() => {
    if( item.action !== action ){
      update.mutate({ action })
    }
  }, [action])

  return (
    <div className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
      <div>
        <div className="mr-4">{ item.risk_name }</div>
      </div>

      <div className="d-flex align-items-center">
        <div className="mr-2">
          <ImpactLevelSelector
            value={impact}
            onChange={value => setImpact(value)}
            disabled={update.isLoading}
          />
        </div>

        <div className="mr-2">
          <RiskActionSelector
            value={action}
            onChange={value => setAction(value)}
            disabled={update.isLoading}
          />
        </div>

        {
          <DestroyButton
            confirmMessage="¿Está seguro que desea eliminar este riesgo?"
            successMessage="Riesgo eliminado correctamente"
            deletePath={`sva/${currentSva.id}/threats/${currentThreat.id}/risks/${item.id}`}
            invalidate={`sva/${currentSva.id}/threats/${currentThreat.id}/risks`}
            size="sm"
            refetch={refetch}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </DestroyButton>
        }
      </div>
    </div>
  )
}

function RisksList({ threat, currentSva, showRiskForm }){
  const query = useQuery(`sva/${currentSva.id}/threats/${threat.id}/risks`)
  const risks = query.data && query.data.result || []

  if( query.isLoading){
    return (
      <div className="text-center py-4 my-4">
        <Spinner />
      </div>
    )
  }

  return (
    <React.Fragment>
      {
        showRiskForm ?
          <AddRiskForm
            currentSva={currentSva}
            currentThreat={threat}
            onChange={() => query.refetch()}
          />
        : null
      }

      {
        risks.length ?
          <div className="m-3">
            <div className="list-group">
              {
                risks.map(item => (
                  <RiskItem
                    key={item.id}
                    item={item}
                    currentSva={currentSva}
                    currentThreat={threat}
                    refetch={query.refetch}
                  />
                ))
              }
            </div>
          </div>
        :
          <div className="text-muted text-center py-4">
            Sin riesgos identificados
          </div>
      }
    </React.Fragment>
  )
}

function ThreatItem({ threat, currentSva }){
  const [ showRiskForm, setShowRiskForm ] = useState(false)

  return (
    <div className="card shadow-sm">
      <div className="card-header d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div className="mr-2">{ threat.name }</div>
          <ThreatLevelIndicator {...threat} />
        </div>
        <div>
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={() => setShowRiskForm(prev => !prev)}
          >
            { showRiskForm ? 'Cancelar' : '+ Agregar riesgo' }
          </button>
        </div>
      </div>

      <RisksList
        threat={threat}
        currentSva={currentSva}
        showRiskForm={showRiskForm}
      />
    </div>
  )
}

export default function RiskAssestment({ currentSva }){
  const query = useQuery(`sva/${currentSva.id}/threats`)
  const currentThreats = query.data && query.data.result || []

  return(
    <React.Fragment>
      {
        query.isLoading ?
          <div className="text-center my-4 py-4">
            <Spinner />
          </div>
        :
          !currentThreats.length ?
            <div className="p-3 my-4 py-4 text-center text-muted">
              Sin amenazas identificadas
            </div>
          :
            currentThreats.map(item => (
              <div className="row mb-3" key={item.id}>
                <div className="col-12">
                  <ThreatItem threat={item} currentSva={currentSva} />
                </div>
              </div>
            ))
      }
    </React.Fragment>
  );
}