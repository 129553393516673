import React from 'react';
import Submenu from "../shared/Submenu.js";

export default function CompanyDetailSubmenu({ CompanyId }){
  return (
    <Submenu items={[
      { to: `/companies/${CompanyId}`, label: 'General', end: true },
      { to: `/companies/${CompanyId}/roles`, label: 'Roles', features: ['LIST_ROLES'] },
    ]}/>
  )
}

