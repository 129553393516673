import React, { useEffect, useState,useContext } from "react";
import { useQuery, useMutation } from "react-query";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { CurrentCompanyContext } from '../../../App.js'
import TableList, { TableListColumn } from '../../shared/TableList.js';

import { useMutationClient } from "../../shared/hooks.js";
import { useNotifications } from "../../shared/Notifications.js";
//import { DropdownSelector } from '../../shared/Filters'
//import { Searchbox } from '../../shared/Search.js'
import { DestroyButton } from '../../shared/Buttons.js'
import Spinner from "../../shared/Spinner.js";
import { BaseModal } from "../../shared/Modals.js";
import ZoneForm from "../../zones/ZoneForm.js";
import AssetForm from "../../assets/AssetForm.js";

function NewZoneModal({ FacilityId, company_id, refetch, onClose }) {
  const mutationClient = useMutationClient();
  const notifications = useNotifications();
  const mutation = useMutation((data) => 
  mutationClient.post(`zones`,{ ...data,company_id:company_id}), {
    onSuccess: (data) => {
      if (data.status === 200) {
        notifications.success("Zona creada");
        refetch();
        onClose();
      }
    },
  });

  return (
    <BaseModal title="Nueva zona" onClose={onClose}>
      <BaseModal.Body>
        <ZoneForm
          zone={{}}
          FacilityId={FacilityId}
          save={(data) => mutation.mutate(data)}
          isLoading={mutation.isLoading}
        />
      </BaseModal.Body>
    </BaseModal>
  );
}
function NewAssetModal({zone_id,refetch,onClose}){
  const mutationClient = useMutationClient();
  const notifications = useNotifications();
  const mutation = useMutation((data) => 
  mutationClient.post(`assets`,{...data,zone_id:zone_id}), {
    onSuccess: (data) => {
      if (data.status === 200) {
        notifications.success("Activo creado");
        refetch();
        onClose();
      }
    },
  });
  return (
    <BaseModal title="Nuevo Activo" onClose={onClose}>
      <BaseModal.Body>
        <AssetForm
          asset={{}}
          save={(data) => mutation.mutate(data)}
          isLoading={mutation.isLoading}
        />
      </BaseModal.Body>
    </BaseModal>
  );

}
function AssetsTable({ items,zone }){

  if (!items.length) {
    return <div className="text-center text-muted py-4 my-4">No hay activos dentro de esta zona</div>
  }
  return (
    <div>
        {
          items.map(item => (
            <div className="d-flex justify-content-between align-items-center py-3 px-4 border-bottom" key={item.id}>
              <div>
                <span className="mr-2">{item.name}</span>

                {
                  item.tags.map(item => (
                    <div key={item.id} className="badge badge-info py-1 px-2 mr-2" style={{ fontSize: '.9em' }}>{ item.name }</div>
                  ))
                }
              </div>
              
              <DestroyButton
              confirmMessage="¿Está seguro que desea eliminar este activo?"
              successMessage="Activo eliminado correctamente"
              deletePath={`assets/${item.id}`}
              invalidate={`assets?ZoneId=${zone.id}`}
              size="sm"
              >
              <FontAwesomeIcon icon={faTimesCircle} />
              </DestroyButton>
            </div>
          ))
        }
    </div>
  )
}

function ZoneItem({ zone }) {
  const query = useQuery(`assets?ZoneId=${zone.id}`, {
    keepPreviousData: true
  })
  const [showAssetModal,setShowAssetModal]=useState(false);

  const assets = query.data && query.data.result || []
  return (
    <div >
      {showAssetModal?(
      <NewAssetModal
        onClose={() => setShowAssetModal(false)}
        zone_id={zone.id}
        refetch={query.refetch}
      />
      ):null}
    <div className="card shadow-sm mb-3 ">
      
        <div className="card-header d-flex justify-content-between">
          <div>
            {zone.name}
          </div>
          <button
            className="btn btn-outline-secondary ml-2"
            onClick={()=>setShowAssetModal(true)}>
              + Agregar activo
          </button>
        </div>
       
        <div className="">
      {
         query.isLoading ?
         <div className="text-center py-4 my-4">
           <Spinner />
         </div>
       :
       <AssetsTable items={assets} zone={zone} />
      }
      </div>
    </div>
    </div>
  );
}

export default function AssetsIdentification({ currentSva }) {
  const [showZoneModal, setShowZoneModal] = useState(false);

  const currentCompany = useContext(CurrentCompanyContext)
  const zonesQuery = useQuery(
    `zones?FacilityId=${currentSva.facility_id}&sort=name-asc`
  );
  const zones = (zonesQuery.data && zonesQuery.data.result) || [];

  return (
    <div className="row">
      {showZoneModal ? (
        <NewZoneModal
          FacilityId={currentSva.facility_id}
          company_id={currentCompany.id}
          refetch={zonesQuery.refetch}
          onClose={() => setShowZoneModal(false)}
        />
      ) : null}

      <div className="col-8">
        {zonesQuery.isLoading ? (
          <Spinner />
        ) : zones.length ? (
          <div className="mt-4">
            <div className="text-right mb-3 d-flex justify-content-between align-items-center">
              <div className="text-muted text-uppercase px-2 ">
                Activos por zona
              </div>
              <button
                className="btn btn-outline-secondary"
                onClick={() => setShowZoneModal(true)}
              >
                + Agregar zona
              </button>  
            </div>
            {zones.map((zone) => (
              <ZoneItem key={zone.id} zone={zone} />
            ))}
          </div>
        ) : (
          <div
            className="text-center"
            style={{ padding: "5em 0", color: "#666", fontSize: "1.2em" }}
          >
            <p>No se encontraron zonas en esta instalación</p>
            <button 
              className="btn btn-link"
              onClick={()=>setShowZoneModal(true)}
            >
              + Crear zona</button>
          </div>
        )}
      </div>

      <div className="col-4"></div>
    </div>
  );
}
