import React from 'react';

import SyncLoader from "react-spinners/SyncLoader";
import ClipLoader from "react-spinners/ClipLoader";

export default function Spinner(props){
  let { full, inline } = props
  //color = color || "#ff1065"

  if(inline){
    return (
      <div
        className={ `inline` }
        style={ props.show ? { visibility: 'visible' } : { visibility: 'hidden' } }
      >
        <ClipLoader
          className="spinner"
          size={20}
          color={"#ff1065"}
          loading={true}
          {...props}
        />
      </div>
    )
  }else{
    return (
      <div className={ `spinner-wrapper ${full ? 'full' : ''}` }>
        <SyncLoader
          className="spinner"
          size={full ? 30 : 15}
          color={"#ff1065"}
          loading={true}
          {...props}
        />
      </div>
    )
  }
}

