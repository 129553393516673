import React, { useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Container } from '../layout/AppLayout.js';
import Row from 'react-bootstrap/Row';
import { useMutationClient } from '../shared/hooks.js'
import { useNotifications } from '../shared/Notifications.js'
import Header from "../shared/Header.js"
import { CurrentUserContext } from '../../App.js';
import { isAllowed } from '../shared/FeatureChecker.js';
import SvaWizard from './wizard/SvaWizard.js'
import FacilityDetailSubmenu from '../facilities/FacilityDetailSubmenu.js'

export default function SvaWizardPage(){
  const params = useParams()
  const mutationClient = useMutationClient()
  const notifications = useNotifications()
  const navigate = useNavigate()
  const currentUser = useContext (CurrentUserContext)

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser,feature: "UPDATE_SVA"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  return (
    <Container>
      <Row>
        <Header title="Nuevo SVA" items={[
          { label: `Listado de instalaciones`, to: "/facilities" },
          { label: `Detalle de instalación`, to: `/facilities/${params.FacilityId}` },
          { label: "Listado de sva's", to: `/facilities/${params.FacilityId}/sva` },
          { label: "Nuevo SVA" }
        ]} />
      </Row>

      <Row>
        <FacilityDetailSubmenu FacilityId={params.FacilityId} />
      </Row>

      <Row>
        <div className="col-12">
          <SvaWizard />
        </div>
      </Row>
    </Container>
  )
}

