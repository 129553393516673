import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faDoorClosed, faVectorSquare, faImage } from '@fortawesome/free-solid-svg-icons';

import { formatDistanceStrict, parseISO, differenceInSeconds } from 'date-fns';
import { es } from 'date-fns/locale'
import { formatDate, toUTCString } from '../../utils.js';

import Spinner from '../shared/Spinner.js';
import useSortHeaders from '../shared/SortHeaders.js';
import TableList, { TableListBody } from '../shared/TableList.js';
import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';

import './LogsTable.css'
import { Table } from 'react-bootstrap';

export function EntryStateIndicator({ entry }){
  let state;
  let style = {
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'inline-block',
    height: '8px',
    width: '8px',
  }

  switch( entry.name ){
    case "OpeningRequest":
      state = 'Entrada Autorizada'

      style = {
        ...style,
        backgroundColor: 'green',
        boxShadow: '0px 0px 8px 2px green',
      }
    
    break;

    case "ExitAuthorized":
      state = 'Salida Autorizada'

      style = {
        ...style,
        backgroundColor: 'orange',
        boxShadow: '0px 0px 8px 2px orange',
      }
    
    break;

    case "OpeningRequest-button":
      state = 'Entrada Autorizada'

      style = {
        ...style,
        backgroundColor: 'green',
        boxShadow: '0px 0px 8px 2px green',
      }
    
    break;

    case "ExitAuthorized-button":
      state = 'Salida Autorizada'

      style = {
        ...style,
        backgroundColor: 'orange',
        boxShadow: '0px 0px 8px 2px orange',
      }
    
    break;
    case "ClosingRequestBle":
      state = 'Cerrado'

      style = {
        ...style,
        backgroundColor: 'green',
        boxShadow: '0px 0px 8px 2px green',
      }
    
    break;
    case "OpeningRequestBle":
      state = 'Abierto'

      style = {
        ...style,
        backgroundColor: 'red',
        boxShadow: '0px 0px 8px 2px red',
      }
    
    break;
    case "authorizedOpening":
      state= 'Apertura autorizada'

      style = {
        ...style,
        backgroundColor: 'green',
        boxShadow: '0px 0px 8px 2px green',
      }

    break;
    case "unauthorizedOpening":
      state= 'Apertura no autorizada'

      style = {
        ...style,
        backgroundColor: 'red',
        boxShadow: '0px 0px 8px 2px red',
      }

    break;

  }

  return (
    <div className="d-flex justify-content-start align-items-center">
      <span className="mr-2" style={style}></span>
      <span>{ state }</span>
    </div>
  )
}

function LogsValue({item}){
  const CheckFeatures = useFeatureChecker()

  return(
    <>
        <TableList.Column>
          <CheckFeatures feature='DETAIL_ENTRY'>
            <AllowedFeature>
              <Link style={{ fontFamily: 'monospace' }} to={"/entries/" + item.id}>#{item.id}</Link>
            </AllowedFeature>
            <DeniedFeature>
              #{item.id}
            </DeniedFeature>
          </CheckFeatures>
        </TableList.Column>
        
        <TableList.Column>
          <div className="d-flex justify-content-start align-items-center">
            {<EntryStateIndicator entry={item} />}
            {item.events_count ?
              <FontAwesomeIcon icon={faImage} className="ml-2" />
            : null}
          </div>
        </TableList.Column>
                 
        <TableList.Column>
          {item.user_id ?
            <CheckFeatures feature='DETAIL_USER'>
              <AllowedFeature>
                <Link to={"/members/" + item.user_id}> {item.user_name} </Link>
              </AllowedFeature>
              <DeniedFeature>
                {item.user_name}
              </DeniedFeature>
            </CheckFeatures>
          : 'No encontrado'}
        </TableList.Column> 
        
        
        <TableList.Column>
          {formatDate(item.created_at)}
        </TableList.Column>
        
        
        <TableList.Column>
          <CheckFeatures feature='DETAIL_DOOR'>
            <AllowedFeature>
              <Link to={"/doors/" + item.door_id}>{item.door_name}</Link>
            </AllowedFeature>
            <DeniedFeature>
              {item.door_name}
            </DeniedFeature>
          </CheckFeatures>
          {item.zone_name ?
            <CheckFeatures feature='DETAIL_ZONE'>
              <AllowedFeature>
                <Link to={`/zones/${item.zone_id}`}>{item.zone_name}</Link>
              </AllowedFeature>
              <DeniedFeature>
                <span className="pl-1">({item.zone_name})</span>
              </DeniedFeature>
            </CheckFeatures>
          : null}
        </TableList.Column>
    </>
  )
}

export default function LogsTable({ logs }) {
  if(logs && logs.length && logs.length && logs[0].pagination.totalItems === 0){
    return (
      <div className="text-center text-muted py-4 my-4">
        Sin resultados para los filtros especificados
      </div>
    )
  }

  function duration(item) {
    let startsAt = parseISO(item.entry_date)

    if (item.entry_date && item.closed_at) {
      const endsAt = parseISO(item.closed_at)
      return formatDistanceStrict(startsAt, endsAt, { locale: es })
    } else {
      const endsAt = parseISO(new Date().toISOString())
      return formatDistanceStrict(startsAt, endsAt, { locale: es }) + ' (En curso)'
    }
  }

  function recentItemStyle(item){
    const now = parseISO(toUTCString(new Date()))
    const diff = differenceInSeconds(now, parseISO(item.entry_date.replace('Z', '')))

    if(diff < 20){
      return {
        backgroundColor: '#FFF8E1',
        fontWeight: 'bold',
      }
    }
  }
  let flattenLogs=[]
  for(let group of logs){
    flattenLogs.push(...group.result)
  }
  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="#" />
        <TableList.HeaderItem value="Tipo"  />
        <TableList.HeaderItem value="Usuario" />
        <TableList.HeaderItem value="Fecha" />
        <TableList.HeaderItem value="Puerta" />
      </TableList.Header>
      <TableList.Body>
        {
          (flattenLogs ).map(item=>(
            <TableList.Row key={item.id}>
              <LogsValue  item={item}/>
            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}
