import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useMutation } from 'react-query'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { useMutationClient } from '../shared/hooks.js'

export default function RecoverPasswordPage() {
  const mutationClient = useMutationClient()

  const [ emailNotFound, setEmailNotFound ] = useState('');
  const [ email, setEmail ] = useState('');

  const mutation = useMutation(
    data => mutationClient.post('login/password/recover', data)
  )

  function handleSubmit(e){
    e.preventDefault()
    mutation.reset()
    mutation.mutate({ email })
  }

  return (
    <div className="col-12 col-md-3 col-lg-3 mx-auto">
      <div className="card shadow-sm">
        {
          mutation.isSuccess ?
            <div className="card-body text-center">
              <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '3em' }} className="my-4 text-success" />
              <p>Se ha enviado un correo electrónico con un link para cambiar tu contraseña</p>
              <div className="form-group text-center mb-0">
                <Link to="/login" className="btn btn-link btn-block">Volver</Link>
              </div>
            </div>
          :
            <div className="card-body">
              <h4 className="py-2 text-center text-muted">Recuperar contraseña</h4>

              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="font-weight-bold">Correo electrónico</label>
                  <input
                    className="form-control"
                    type="text"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    disabled={mutation.isLoading}
                  />
                </div>

                {
                  mutation.error ?
                    <div className="text-center text-danger mb-3">Hubo un error al recuperar la contraseña</div>
                  : null
                }

                <div className="form-group text-center">
                  <input
                    type="submit"
                    className="btn btn-primary btn-block"
                    value={`${ mutation.isLoading ? 'Enviando...' : 'Enviar' }`}
                    disabled={mutation.isLoading}
                  />
                </div>

                <div className="form-group text-center mb-0">
                  <Link to="/login" className="btn btn-link btn-block">Cancelar</Link>
                </div>
              </form>
            </div>
        }
      </div>
    </div>
  );
}

