import { Link, useParams, useNavigate } from 'react-router-dom';
import { Container } from '../layout/AppLayout.js'
import { useEffect, useContext } from 'react';
import useFeatureChecker,{ isAllowed, AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';
import { useQuery } from 'react-query';
import Spinner from '../shared/Spinner.js';
import Header from "../shared/Header.js"
import { CurrentUserContext } from '../../App.js';
import { formatDate } from '../../utils.js';
import translate from './translate.js';

function RenderComponent({ query }) {
    if (!query.data || !query.data.result) {
      return null;
    }
  
    const { entity_type, entity_data } = query.data.result;
  
    // Definir un objeto de mapeo de renderizado
    const renderMapping = {
      Zone: () => ( 
        <div className="form-group">
            <label className="form-label font-weight-bold">Nombre de la zona</label>
            <p>{entity_data.name }</p>
        </div>
        ),
      Door: () =>( 
        <div className="form-group">
            <label className="form-label font-weight-bold">Nombre de la puerta</label>
            <p>{entity_data.name }</p>
        </div>
      ),
      DoorAccess: () => (
        <div className="form-group">
            <label className="form-label font-weight-bold">Permiso para la puerta</label>
            <p>{entity_data.door_id }</p>
            <label className="form-label font-weight-bold">Usuario del permiso</label>
            <p>{entity_data.user_id }</p>
        </div>
      ),
      Sensor: () => (
        <div className="form-group">
            <label className="form-label font-weight-bold">Nombre del sensor</label>
            <p>{entity_data.name }</p>
        </div>
      ),
      UserIdentities: () => (
        <div className="form-group">
            <label className="form-label font-weight-bold">Identidad de usuario </label>
            <p>{entity_data.user_id }</p>
            <label className="form-label font-weight-bold">Tipo de identidad </label>
            <p>{entity_data.type }</p>
        </div>
      ),
      UsersCompanies: () => (
        <div className="form-group">
            <label className="form-label font-weight-bold">Usuario </label>
            <p>{entity_data.user_id }</p>
            <label className="form-label font-weight-bold">Rol </label>
            <p>{entity_data.role_id }</p>
        </div>
      ),
      Sva: () => (
        <div className="form-group">
            <label className="form-label font-weight-bold">Identidad de usuario </label>
            <p>{entity_data.user_id }</p>
        </div>
      ),
      Entry: () => (
        <div className="form-group">
            <label className="form-label font-weight-bold">Permiso  </label>
            <p>{entity_data.access_id }</p>
            <label className="form-label font-weight-bold">Puerta</label>
            <p>{entity_data.door_id }</p>
        </div>
      )

    };
  
    // Obtener la función de renderizado correspondiente o usar una función predeterminada
    const renderFunction = renderMapping[entity_type] || (() => null);
  
    return renderFunction();
}

function DetailsCard({ LogId }) {
    const query = useQuery(`logs/${LogId}`)
    const CheckFeatures = useFeatureChecker()
  
    return (
      <div className="card shadow-sm mb-3">
        <div className="card-header">Detalle del cambio</div>
  
        <div className="card-body">
          {
            query.isLoading ?
              <Spinner />
              :
              <>
                <div className="form-group">
                  <label className="form-label font-weight-bold">Acción</label>
                  <p>{translate(query.data && query.data.result.action)}</p>
                </div>
  
                <div className="form-group">
                  <label className="form-label font-weight-bold">Autor</label>
                  <p>
                    {
                      query.data && query.data.result.user_name ?

                        <CheckFeatures feature ='DETAIL_USER'>
                          <AllowedFeature>
                            <Link to={`/members/${query.data.result.user_id}`} >{query.data.result.user_name}</Link>
                          </AllowedFeature>
                          <DeniedFeature>
                            {query.data.result.user_name}
                          </DeniedFeature>
                        </CheckFeatures>
                        : '--'
                    }
                  </p>
                </div>
  
                <div className="form-group">
                  <label className="form-label font-weight-bold">Fecha del cambio</label>
                  <p>{formatDate(query.data && query.data.result.created_at) }</p>
                </div>
                <div className="form-group">
                  <label className="form-label font-weight-bold">Tabla modificada</label>
                  <p>{(query.data && query.data.result.entity_type) }</p>
                </div>
                <div className="form-group">
                  <label className="form-label font-weight-bold">Id del campo modificado</label>
                  <p>{query.data && query.data.result.entity_id }</p>
                </div>

                <RenderComponent query={query} />


              </>
          }
        </div>
      </div>
    )
  }


export default function RevisionsDetailsPage() {
  const params = useParams()
  const currentUser = useContext(CurrentUserContext)
  const navigate = useNavigate()

  useEffect(() => {
    if(currentUser){
      const allowed= isAllowed({user: currentUser, feature: "DETAIL_LOGS"})
      if (!allowed){
        navigate('/error?error=403')
      }
    }

  },[currentUser])

  return (
    <Container>
        <div className="row">
            <Header title="Detalle de zona" items={[
            { label: `Historial de cambios`, to: "/revisions" },
            { label: `Detalle del cambio` },
            ]}>
            </Header>
        </div>

        <div className="row mb-2 pb-4">
            <div className="col-12 mb-2 col-lg-6">
                <DetailsCard LogId={params.LogId} />
            </div>
        </div>
    </Container>
  )
}
