import React, { useState , useMemo, useRef} from 'react'

import { FacilityTypeSelector } from '../shared/Selectors.js'
import { MapContainer, TileLayer, useMap, Marker, Popup,useMapEvents } from 'react-leaflet'
import {useAddressSearch} from '../shared/Search.js'
const center = {
  lat: -33.4377756,
  lng: -70.6504502,
}
export default function FacilityForm({ facility, currentCompany, save, isLoading }){
  const [ name, setName ] = useState(facility.name || '')
  const [ address, setAddress ] = useState(facility.address || '')
  const [ type, setType ] = useState(facility.facility_type_id || '')
  const [ errors, setErrors ] = useState({})
  const [position, setPosition] = useState(null)
  const markerRef = useRef(null)
  
  const addressSearchBox = useAddressSearch({
    defaultValue: address,
    defaultError: errors,
  
    onChange: (data) => {
      if(data){
        setAddress(data.address)
        setPosition({lat:data.latitude,lng:data.longitude})
      }
      else{
        setAddress('')
      }
    },
  })

  function DraggableMarker() {
    const map=useMap()
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            setPosition(marker.getLatLng())
          }
        },
      }),
      [],
    )
    if(position){
      map.setView(position,15)
    return (
      <Marker
        draggable={true}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
       />
      )
    }
  }
 
  const validations = []

  function validate(e) {
    e.preventDefault()

    if(!name){
      validations.push(['name', 'Nombre es requerido'])
    }

    if(!address){
      validations.push(['address', 'Dirección es requerida'])
    }

    if(!type){
      validations.push(['type', 'Tipo de instalación es requerido'])
    }

    if(validations.length){
      setErrors(validations.reduce((acc, item) => ({...acc, [item[0]]: item[1]}), {}))
      return
    }else{
      setErrors([])
    }    
    save({
      ...facility,
      name,
      address,
      facility_type_id: type,
      latitude: position.lat,
      longitude: position.lng
    })
  }

  return (
    <form onSubmit={validate}>
      <div className="form-group">
        <label>Nombre</label>
        <input
          type="text"
          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
          onChange={e => setName(e.target.value)}
          value={name}
          disabled={isLoading}
          autoFocus={true}
        />

        <div className="invalid-feedback">{errors.name}</div>
      </div>

      <div className="form-group">
        <label>Tipo de instalación</label>

        <FacilityTypeSelector
          placeholder="Seleccione tipo de instalación"
          onChange={value => setType(value)}
          defaultValue={type}
          disabled={isLoading}
          hasError={!!errors.type}
          filters={{ IndustryId: currentCompany.industry_id }}
        />

        <div className="invalid-feedback">{errors.type}</div>
      </div>

      <div className="form-group">
        <label>Dirección</label>
        {addressSearchBox}

        <MapContainer className="mt-3"center={center} zoom={12} scrollWheelZoom={false} style={{height: '50vh',width: '50wh'}}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <DraggableMarker/>
      </MapContainer>
      </div>

      <div className="form-group">
        <button className={`btn btn-primary ${isLoading ? 'loading' : ''}`} disabled={isLoading}>
          <span>Guardar</span>
          <div className='spinner-border' role='status'></div>
        </button>
      </div>
    </form>  
  )
}

