import FileSaver from 'file-saver';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Navigate, useNavigate } from 'react-router-dom';
import { CurrentCompanyContext } from '../../App.js';
import { TIME_ZONE, toQueryString, useFromQueryString } from '../../utils.js';


import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Row from 'react-bootstrap/Row';
import { Container } from '../layout/AppLayout.js';

import { FilterMenu } from '../shared/Filters.js';
import Header, { HeaderActions } from "../shared/Header.js";
import Pagination from '../shared/Pagination.js';
import Spinner from '../shared/Spinner.js';
import { useMutationClient } from '../shared/hooks.js';

import useFeatureChecker, { AllowedFeature, DeniedFeature } from '../shared/FeatureChecker.js';
import RevisionsTable from './RevisionsTable.js';

const REVISION_TYPES = [
  { label: 'Usuarios', value: 'Users' },
  { label: 'Accesos', value: 'Access' },
]
function LogsDownloadButton({ filters }){
  const [ isLoading, setIsLoading ] = useState(false)
  const mutationClient = useMutationClient()
  const currentCompany = useContext(CurrentCompanyContext)

  useEffect(() => {
    async function download(){
      const res = await mutationClient.get(`logs?download=true&${toQueryString(filters)}&CompanyId=${currentCompany.id}`)
      setIsLoading(false)

      if(res.status === 200){
        var blob = new Blob([res.data], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, `registros.csv`);
      }else{
        console.log('Error al descargar archivo');
      }
    }

    if( isLoading ){
      download()
    }
  }, [isLoading])

  return (
    <button
      className={`btn btn-primary btn-ml-1 d-none d-lg-inline-block ${ isLoading ? "loading" : "" }`}
      onClick={() => setIsLoading(true)}
      disabled={isLoading}
    >
      <FontAwesomeIcon icon={faDownload} className="mr-2" />
      {
        isLoading ? 
          <span>Generando reporte...</span>
        :
          <span>Descargar CSV</span>
      }
    </button>
  )
}

export default function RevisionsListPage(){
  const CheckFeatures = useFeatureChecker()

  const queryString = useFromQueryString()
  const navigate = useNavigate()

  const [ currentFilters, setCurrentFilters ] = useState({
    search: queryString.search || '',
    page: parseInt(queryString.page) || 1,
    limit: parseInt(queryString.limit) || 10,
    sort: queryString.sort || "created_at-desc",
    tz: TIME_ZONE
  })

  const query = useQuery(`logs?${toQueryString(currentFilters)}`, {
    keepPreviousData: true,
  })
  useEffect(() => {
    navigate('?' + toQueryString(currentFilters), { replace: true })
  }, [currentFilters])

  return (
    <Container>
      <Row>
        <Header title="Historial de cambios" items={[
          { label: "Listado de cambios" },
        ]}>
          {
            <HeaderActions>
                <CheckFeatures feature="DOWNLOAD_PDF_LOGS">
                  <LogsDownloadButton filters={currentFilters} />
                </CheckFeatures>
            </HeaderActions>
          }
        </Header>
      </Row>

      <Row className="mb-2">
        <FilterMenu
          currentFilters={currentFilters}
          onChange={filters => setCurrentFilters(prev => ({ ...prev, ...filters }))}
        />
      </Row>

      <Row className="mb-2">
        <CheckFeatures feature="LIST_LOGS">
          <AllowedFeature>
            <div className="col-12">
            {
              query.isLoading ?
                <div className="text-center my-4 py-4">
                  <Spinner />
                </div>
              :
              <RevisionsTable revisions={query.data && query.data.result || []} />
            }
            </div>
          </AllowedFeature>
          <DeniedFeature>
            <Navigate to={'/error?error=403'}/>
          </DeniedFeature>
        </CheckFeatures>
      </Row>

      <Row className="mt-2">
        <Pagination
          pagination={query.data && query.data.pagination}
          isLoading={query.isFetching}
          onChange={page => setCurrentFilters(prev => ({ ...prev, page }))}
        />
      </Row>
    </Container>
  )
}
