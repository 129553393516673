import React, { useCallback, useReducer, useMemo, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useDropzone } from 'react-dropzone'

import axios from 'axios'
import * as uuid from 'uuid'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { BaseModal } from './Modals.js'
import { useMutationClient } from './hooks.js';

function FilesListItem({ item, onRemove, onSuccess, onError }){
  const [uploadProgress, setUploadProgress] = useState(0)

  const uploadMutation = useMutation(() => {
    return axios.put(item.upload_url, item.File, {
      headers: {
        'Content-Type': item.type
      },
      onUploadProgress: (progressEvent) => {
        const { total, loaded } = progressEvent
        setUploadProgress(loaded * 100 / total)
      }
    })
  }, {
    onSuccess: (data) => {
      if(data.status === 200){
        onSuccess(item.id)
      }
    },
  })

  useEffect(() => {
    if( uploadMutation.isIdle && item.upload_url ){
      uploadMutation.mutate()
    }
  }, [item.upload_url])

  return (
    <div className="list-group-item list-group-item-action p-0">
      <div
        className="d-flex justify-content-between align-items-center p-2"
        style={{ fontSize: '.95em' }}
      >
        <div>{item.name}</div>
        <div>
          {
            uploadMutation.isSuccess ?
              <div className="text-success py-1">
                <FontAwesomeIcon icon={faCheckCircle} />
              </div>
            :
              uploadMutation.isError ?
                <div className="text-danger py-1">
                  <span>No se pudo subir el archivo. </span>
                  <a
                    href="#"
                    onClick={e =>  {
                      e.preventDefault()
                      setUploadProgress(0)
                      uploadMutation.reset()
                      uploadMutation.mutate()
                    }}
                  >Reintentar</a>
                </div>
              :
                <button
                  className="btn btn-sm btn-outline-danger"
                  onClick={() => onRemove(item.id)}
                  disabled={uploadMutation.isLoading}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
          }
        </div>
      </div>

      <div style={{
        backgroundColor: '#00adef',
        width: `${uploadProgress}%`,
        height: '2px',
        transition: 'all 0.2s linear'
      }}></div>
    </div>
  )
}

function FilesList({ items, onRemove, onSuccess }){
  return (
    <div>
      <div className="font-weight-bold mb-3">Archivos seleccionados</div>
      <div className="list-group shadow-sm">
        {
          items.map(item =>
            <FilesListItem
              key={item.id}
              item={item}
              onRemove={onRemove}
              onSuccess={onSuccess}
            />
          )
        }
      </div>
    </div>
  )
}

function useFilesReducer(){
  const mutationClient = useMutationClient()

  const [ state, dispatch ] = useReducer(
    (prevState, action) => {
      switch(action.type) {
        case 'ADD_FILES':
          const fileItems = action.files.reduce((acc, item) => {
            const id = uuid.v4()

            acc[id] = {
              id,
              name: item.name,
              size: item.size,
              type: item.type,
              isUploading: false,
              isUploaded: false,
              upload_url: null,
              File: item
            }
            return acc
          }, {})

          return {
            ...prevState,
            files: {
              ...prevState.files,
              ...fileItems
            }
          }

        case 'REMOVE_FILE':
          let newFiles = { ...prevState.files }
          delete(newFiles[action.FileId])

          return {
            ...prevState,
            files: newFiles
          }

        case 'START_UPLOAD':
          return {
            ...prevState,
            isUploading: true
          }

        case 'SUCCESS_UPLOAD':
          return {
            ...prevState,
            isUploading: false,
            isSuccess: true,
          }

        case 'START_FILE_UPLOAD':
          return {
            ...prevState,
            files: {
              ...prevState.files,
              [action.id]: {
                ...prevState.files[action.id],
                upload_url: action.upload_url,
                file_key: action.file_key,
                isUploading: true,
              }
            }
          }

        case 'SUCCESS_FILE_UPLOAD':
          return {
            ...prevState,
            files: {
              ...prevState.files,
              [action.FileId]: {
                ...prevState.files[action.FileId],
                isUploading: false,
                isUploaded: true
              }
            }
          }

        default:
          return prevState
      }
    },
    {
      files: {},
      isUploading: false,
      isSuccess: false,
    }
  )

  const requestUploadUrl = useMutation(
    data => mutationClient.post(`files`, data),
    {
      onSuccess: data => {
        if( data.status === 200 ){
          Object.keys(data.data.result).map(FileId => {
            dispatch({ type: 'START_FILE_UPLOAD', id: FileId, ...data.data.result[FileId] })
          })
        }
      }
    }
  )

  const filesUploaded = Object.values(state.files).length
    && Object.values(state.files).map(x => x.isUploaded).every(x => !!x)
    || false

  useEffect(() => {
    if (filesUploaded) {
      dispatch({ type: 'SUCCESS_UPLOAD' })
    }
  }, [filesUploaded])

  const actions = useMemo(() => ({
    addFiles: files => {
      dispatch({ type: 'ADD_FILES', files })
    },
    removeFile: FileId => {
      dispatch({ type: 'REMOVE_FILE', FileId })
    },
    startUpload: (files) => {
      dispatch({ type: 'START_UPLOAD' })

      const items = files.map(x => ({
        id: x.id,
        size: x.size,
        type: x.type,
        name: x.name
      }))

      requestUploadUrl.mutate({ files: items })
    },
    successFileUpload: (FileId) => {
      dispatch({ type: 'SUCCESS_FILE_UPLOAD', FileId })
    }
  }), [])

  return {
    files: Object.values(state.files),
    isUploading: state.isUploading,
    isSuccess: state.isSuccess,
    ...actions
  }
}

export default function FileUploaderModal({ title, description, size, onClose, onSuccess }){
  const {
    files,
    isUploading,
    isSuccess,
    addFiles,
    removeFile,
    startUpload,
    successFileUpload
  } = useFilesReducer()

  const onDrop = useCallback(acceptedFiles => {
    if( acceptedFiles && acceptedFiles.length ){
      addFiles(acceptedFiles)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  useEffect(() => {
    if(!isUploading && isSuccess){
      onSuccess(files)
    }
  }, [isUploading, isSuccess])

  return(
    <BaseModal
      title={title}
      size={ size ? size : 'lg' }
      onClose={() => onClose()}
    >
      <BaseModal.Body>
        {
          description ?
            <p className="alert alert-info">{description}</p>
          : null
        }

        {
          files.length ?
            <FilesList
              items={files}
              onRemove={removeFile}
              onSuccess={FileId => {
                successFileUpload(FileId)
              }}
            />
          :
            <div
              {...getRootProps()}
              className="bg-light text-center py-4"
              style={{
                border: "2px dashed #ddd",
                cursor: 'pointer'
              }}
            >
              <input {...getInputProps()} />
              {
                isDragActive ?
                  <p className="m-0">Suelte los archivos aquí ...</p> :
                  <p className="m-0">Arrastre archivos aquí, o haga click para seleccionar</p>
              }
            </div>
        }
      </BaseModal.Body>

      <BaseModal.Footer>
        <div 
          className={`w-100 d-flex align-items-center ${isSuccess ? 'justify-content-end': 'justify-content-between'}`}
        >
          {
            isSuccess ?
              <div className="text-right">
                <button
                  onClick={() => onClose()}
                  className="btn btn-outline-secondary"
                >Cerrar</button>
              </div>
            :
              <React.Fragment>
                <div>
                  {
                    files.length ?
                      <button
                        className="btn btn-primary"
                        onClick={() => startUpload(files)}
                        disabled={isUploading}
                      >{isUploading ? 'Subiendo archivos...' : 'Subir archivos'}</button>
                    : null
                  }
                </div>

                <button
                  className="btn btn-outline-secondary"
                  onClick={() => onClose()}
                >Cancelar</button>
              </React.Fragment>
          }
        </div>
      </BaseModal.Footer>
    </BaseModal>
  )
}

