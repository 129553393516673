import React from 'react';

import TableList from '../shared/TableList.js';

const formatAlertDate = (date) => {
    const d = new Date(Date.parse(date))
    const region = "es-CL"
    return `${d.toLocaleDateString(region)} ${d.toLocaleTimeString(region)}`
  }

export default function MaintanceTable({ maintance }) {


  if(!maintance.length) {
    return (
      <div className="text-center text-muted py-4 my-4">No se encontraron mantenciones</div>
    )
  }
  return (
    <TableList>
      <TableList.Header>
        <TableList.HeaderItem value="Fecha" width={200} />
        <TableList.HeaderItem value="Usuario" width={150}/>
        <TableList.HeaderItem value="Tipo" width={150} />
        <TableList.HeaderItem value="Comentario" width={450} />
      </TableList.Header>

      <TableList.Body>
        {
          maintance.map(item => (
            <TableList.Row key={item.id}>
                <TableList.Column>
                    { formatAlertDate(item.created_at) }
                </TableList.Column>
                <TableList.Column >
                    {item.user_name}
                </TableList.Column>
                <TableList.Column>
                    {item.report_type}
                </TableList.Column>
                <TableList.Column>
                    {item.comments}
                </TableList.Column>
            </TableList.Row>
          ))
        }
      </TableList.Body>
    </TableList>
  )
}
